import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import { isEmpty, map, size } from "lodash";
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { addCampaignEmailList, getEmailListTarget,getEmailListType, getCampaignEmailList, updateCampaignEmailList, deleteCampaignEmailList} from "../../store/actions"
import {
  Container,
  Row,
  Col,
  Button,
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Offcanvas, OffcanvasHeader, OffcanvasBody,
  FormGroup, Input, InputGroup, Label, CardBody,Card,Modal
} from "reactstrap"
import CollectionChart from "./chart/collectionchart"
import SaveButton from "components/buttons/save";
import RsAdNew from "components/buttons/Adnew";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DeleteModal from "../../components/Common/DeleteModal";
class CollectionDashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      campaignemaillist:''
  }
  this.handleProTypeSubmit = this.handleProTypeSubmit.bind(this)
  this.toggleAddNew = this.toggleAddNew.bind(this);
  this.toggleEditCanvas = this.toggleEditCanvas.bind(this);
  this.onClickDelete = this.onClickDelete.bind(this);
  }
  toggleDeleteModal = () => {
    this.setState(prevState => ({
        deleteModal: !prevState.deleteModal,
    }));
};
onClickDelete = (campaignemaillist) => {
    this.setState({ campaignemaillist: campaignemaillist });
    this.setState({ deleteModal: true });
};

handleDeleteProType = () => {
    const { onDeleteProType } = this.props;
    const { campaignemaillist } = this.state;

    if (campaignemaillist.id !== undefined) {
        onDeleteProType(campaignemaillist);
        this.setState({ deleteModal: false });
    }
};
  toggleAddNew() {
    this.setState({ isAddNew: !this.state.isAddNew });
  }
  componentDidMount() {
    const { onGetEmailListTarget, onGetEmailListType, onGetCampaignEmailList } = this.props;
    onGetEmailListTarget(localStorage.getItem('userId'));
    onGetEmailListType(localStorage.getItem('userId'));
    onGetCampaignEmailList(localStorage.getItem('userId'))
    
}
handleProTypeSubmit(value) {
  const payload = {
      agentId: parseInt(value.agentId),
      emailListTypeId:parseInt(value.emailListTypeId),
      campaignEmailListName:value.campaignEmailListName,
      emailListTargetId:parseInt(value.emailListTargetId),
      campaignEmailListDesc:value.campaignEmailListDesc,
      isActive:true
  }
  this.props.addCampaignEmailList(payload);
}
componentDidUpdate(prevProps, prevState, snapshot) {
  const { campaignemaillists } = this.props;
  if (!isEmpty(campaignemaillists) && size(prevProps.campaignemaillists) !== size(campaignemaillists)) {
      this.setState({ campaignemaillists: {}, isEdit: false });
  }
}
editToggle() {
  this.setState(prevState => ({
      isRight: !prevState.isRight
  }));
}
toggleEditCanvas() {
  this.setState({ campaignemaillist: "", isEdit: false, isRight: !this.state.isRight });
  this.editToggle();
}
toggleEditCanvas = arg => {
  const campaignemaillist = arg;
  this.setState({
      campaignemaillist: {
          id: campaignemaillist.id,
          campaignEmailListId: campaignemaillist.campaignEmailListId,
          agentId: campaignemaillist.agentId,
          emailListTypeId: campaignemaillist.emailListTypeId,
          campaignEmailListName: campaignemaillist.campaignEmailListName,
          emailListTargetId: campaignemaillist.emailListTargetId,
          campaignEmailListDesc: campaignemaillist.campaignEmailListDesc,
          isActive:campaignemaillist.isActive
      },
      isEdit: true,
  });
  this.editToggle();
};
  render() {
    const { emaillisttargets, emaillisttypes, campaignemaillists, onUpdateCampaignEmailList } = this.props;
    const { isEdit, deleteModal } = this.state;
    const campaignemaillist = this.state.campaignemaillist;
    const allLocal = localStorage.getItem('localData')
    const localization = JSON.parse(allLocal);
    return (
      <React.Fragment>
          {this.props.loading && this.props.loading ? (
                    <div className="loading">Loading&#8230;</div>
                ) : null}
                <ToastContainer autoClose={2000} />
                <DeleteModal
                    show={deleteModal}
                    onDeleteClick={this.handleDeleteProType}
                    onCloseClick={() => this.setState({ deleteModal: false })}
                />
        <div className="page-content project-page-content">
          <MetaTags>
            <title>Collection | Realty Space</title>
          </MetaTags>
          <Container fluid className="ps-0 pe-0">
          <Row className="project-header">
            <Col xl="7">
             <Breadcrumbs
              title={this.props.t("Collection")}
              breadcrumbItem={this.props.t("Collection Dashboard")}
            />
            </Col>
            <Col xl="5">
            <div className="pt-2 float-end mt-2">
                  
                  <div className="">
                    
                  
                  
                  <RsAdNew to="#" onClick={ this.toggleAddNew } className="btn btn-light btn-md" iconClass="bx bx-plus-medical">Add New</RsAdNew>
                  
                    
                  </div>
                 
                </div>
            </Col>
            </Row>
            <CollectionChart />
           <Row>
            <Col sm="12">
                <Card>
                    <CardBody>
                    <div className="row gy-2 gx-3 float-end mb20">
          
          
          
          <div className="col-sm-auto">
            <label className="visually-hidden" htmlFor="autoSizingSelect">Preference</label>
            <select defaultValue="0" className="form-select">
              <option value="0">All...</option>
              <option value="1">Leads</option>
              <option value="2"> Contacts</option>
              <option value="3"> Contacts - Client Only</option>
              <option value="3"> Contacts - VIP Only</option>
             
            </select>
          </div>
          
          <div className="col-sm-auto">
            <button type="submit" className="btn btn-primary w-md">Filter</button>
          </div>
        </div>
        <div className="clearfix"></div>
                    <div className="">
            <table className="table table-bordered border-primary align-middle table-nowrap mb-0">
              <thead className="">
                <tr>
                <th className="align-middle"><div className="form-check font-size-16 align-middle">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="customCheck1"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="customCheck1"
                      >
                        &nbsp;
                      </label>
                    </div></th>
                  <th className="align-middle">Name</th>
                  <th className="align-middle">Description</th>
                  <th className="align-middle">Target</th>
                  
                  <th className="align-middle">Created date</th>
                  <th className="align-middle">Created by</th>
                  <th className="align-middle"># Accounts</th>
                  <th className="align-middle">View List</th>
                </tr>
              </thead>
                <tbody>
                {map(campaignemaillists, (campaignemaillist, classkey) => (
                    <tr key={classkey}>
                        <td><div className="form-check font-size-16 align-middle">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id={`customCheck1` + classkey }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="customCheck1"
                      >
                        &nbsp;
                      </label>
                    </div></td>
                        <td>{campaignemaillist.campaignEmailListName}</td>
                        <td>{campaignemaillist.campaignEmailListDesc	}</td>
                        <td>{campaignemaillist.emailListTypeId	}</td>
                        <td>23 June 2023</td>
                        <td>Mohit Grover</td>
                        <td>10</td>
                        <td>
                        <UncontrolledDropdown direction="up">
                                                <DropdownToggle className="btn btn-action" type="button" id="defaultDropdown">
                                                  Action <i className="mdi mdi-chevron-down"></i>
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                  
                                                  <DropdownItem href="/collection/list">View List</DropdownItem>
                                                  <DropdownItem onClick={() => this.toggleEditCanvas(campaignemaillist)} href="#">Edit</DropdownItem>
                                                  <DropdownItem onClick={() => this.onClickDelete(campaignemaillist)} href="#">Delete</DropdownItem>
                                                
                                                 
                                                  
                                                </DropdownMenu>
                                              </UncontrolledDropdown>
                        </td>
                    </tr>
              ))}
                </tbody>
            </table>
            </div>

                    </CardBody>
                </Card>
            </Col>
           </Row>
          </Container>
          <Offcanvas
                      isOpen={this.state.isAddNew}
                      direction="end"
                      toggle={this.toggleAddNew}
                    >
                      <OffcanvasHeader toggle={this.toggleAddNew}>
                        Add New
                      </OffcanvasHeader>
                      <OffcanvasBody>
                      <Formik
                                    enableReinitialize={true}
                                    initialValues={{
                                        campaignEmailListName: '',
                                        emailListTypeId: '',
                                        emailListTargetId: '',
                                        campaignEmailListDesc: '',
                                        agentId: parseInt(localStorage.getItem('userId'))
                                    }}
                                    validationSchema={Yup.object().shape({
                                        emailListTypeId: Yup.string().required("This is required"),
                                        emailListTargetId: Yup.string().required("This is required"),
                                        campaignEmailListName: Yup.string().required("This is required"),
                                    })}

                                    onSubmit={this.handleProTypeSubmit}
                                >
                                    {({ errors, touched, values, handleChange, setFieldValue }) => (
                                        <Form
                                            className="needs-validation"
                                        >
                                            <Row>

                                            <Col sm="12">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="emailListTypeId">
                                                    {this.props.t("List Type")}
                                                    </Label>
                                                    <Field as="select" name="emailListTypeId" 
                                                    className={
                                                    "form-control" +
                                                    (errors.emailListTypeId && touched.emailListTypeId
                                                        ? " is-invalid"
                                                        : "")
                                                    }
                                                    
                                                    onChange={handleChange}>
                                                        <option value="">Select</option>
                                                        {map(emaillisttypes, (emaillisttype, classkey) => (
                                                            <option value={emaillisttype.id} key={classkey}>{emaillisttype.emailListTypeTitle}</option>
                                                        ))}
                                                    </Field>
                                                    <ErrorMessage
                                                    name="emailListTypeId"
                                                    component="div"
                                                    className="invalid-feedback"
                                                    />
                                                </FormGroup>
                                                </Col>
                                                <Col sm="12">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="emailListTargetId">
                                                    {this.props.t("List Target")}
                                                    </Label>
                                                    <Field as="select" name="emailListTargetId" 
                                                    className={
                                                    "form-control" +
                                                    (errors.emailListTargetId && touched.emailListTargetId
                                                        ? " is-invalid"
                                                        : "")
                                                    }
                                                    
                                                    onChange={handleChange}>
                                                        <option value="">Select</option>
                                                        {map(emaillisttargets, (emaillisttarget, classkey) => (
                                                            <option value={emaillisttarget.id} key={classkey}>{emaillisttarget.emailListTargetTitle}</option>
                                                        ))}
                                                    </Field>
                                                    <ErrorMessage
                                                    name="emailListTargetId"
                                                    component="div"
                                                    className="invalid-feedback"
                                                    />
                                                </FormGroup>
                                                </Col>
                                                <Col sm="12">
                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="campaignEmailListName">
                                                            {this.props.t("Title")}
                                                        </Label>
                                                        <Field
                                                            name="campaignEmailListName"
                                                            onChange={handleChange}

                                                            type="text"

                                                            className={
                                                                "form-control" +
                                                                (errors.campaignEmailListName && touched.campaignEmailListName
                                                                    ? " is-invalid"
                                                                    : "")
                                                            }

                                                            id="campaignEmailListName"
                                                        />
                                                        <ErrorMessage
                                                            name="campaignEmailListName"
                                                            component="div"
                                                            className="invalid-feedback"
                                                        />
                                                    </FormGroup>

                                                </Col>
                                                <Col sm="12">
                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="campaignEmailListDesc">
                                                            {this.props.t("Description")}
                                                        </Label>
                                                        <Field
                                                            name="campaignEmailListDesc"
                                                            onChange={handleChange}

                                                            type="text"

                                                            className={
                                                                "form-control" +
                                                                (errors.campaignEmailListDesc && touched.campaignEmailListDesc
                                                                    ? " is-invalid"
                                                                    : "")
                                                            }

                                                            id="campaignEmailListDesc"
                                                        />
                                                        <ErrorMessage
                                                            name="campaignEmailListDesc"
                                                            component="div"
                                                            className="invalid-feedback"
                                                        />
                                                    </FormGroup>

                                                </Col>                
                                                <Col sm="12">
                                                   <SaveButton>Save</SaveButton>
                                                </Col>

                                            </Row>
                                        </Form>
                                    )}
                                </Formik>

                      </OffcanvasBody>
         </Offcanvas>


         <Offcanvas
                    isOpen={this.state.isRight}
                    direction="end"
                    toggle={this.toggleEditCanvas}
                        >
                            <OffcanvasHeader toggle={this.toggleEditCanvas}>
                                Update
                            </OffcanvasHeader>
                            <OffcanvasBody>
                                <Formik
                                    enableReinitialize={true}
                                    initialValues={{
                                        id: campaignemaillist.id,
                                        campaignEmailListId: campaignemaillist.campaignEmailListId,
                                        agentId: campaignemaillist.agentId,
                                        campaignEmailListName: campaignemaillist.campaignEmailListName,
                                        emailListTypeId: campaignemaillist.emailListTypeId,
                                        emailListTargetId: campaignemaillist.emailListTargetId,
                                        campaignEmailListDesc: campaignemaillist.campaignEmailListDesc


                                    }}
                                    validationSchema={Yup.object().shape({
                                        emailListTypeId: Yup.string().required("This is required"),
                                        emailListTargetId: Yup.string().required("This is required"),
                                        campaignEmailListName: Yup.string().required("This is required"),


                                    })}

                                    onSubmit={values => {

                                        if (isEdit) {
                                            const updateData = {
                                                id: parseInt(values.id),
                                                campaignEmailListId: values.campaignEmailListId,
                                                agentId: parseInt(values.agentId),
                                                campaignEmailListName: values.campaignEmailListName,
                                                emailListTypeId: parseInt(values.emailListTypeId),
                                                emailListTargetId: parseInt(values.emailListTargetId),
                                                campaignEmailListDesc: values.campaignEmailListDesc,
                                                isActive:true
                                            };
                                            onUpdateCampaignEmailList(updateData);
                                        } else {

                                        }
                                        this.editToggle();
                                    }}
                                >
                                    {({ errors, touched, values, handleChange, setFieldValue }) => (
                                        <Form
                                            className="needs-validation"
                                        >
                                            <Row>

                                            <Col sm="12">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="emailListTypeId">
                                                    {this.props.t("List Type")}
                                                    </Label>
                                                    <Field as="select" name="emailListTypeId" 
                                                    className={
                                                    "form-control" +
                                                    (errors.emailListTypeId && touched.emailListTypeId
                                                        ? " is-invalid"
                                                        : "")
                                                    }
                                                    
                                                    onChange={handleChange}>
                                                        <option value="">Select</option>
                                                        {map(emaillisttypes, (emaillisttype, classkey) => (
                                                            <option value={emaillisttype.id} key={classkey}>{emaillisttype.emailListTypeTitle}</option>
                                                        ))}
                                                    </Field>
                                                    <ErrorMessage
                                                    name="emailListTypeId"
                                                    component="div"
                                                    className="invalid-feedback"
                                                    />
                                                </FormGroup>
                                                </Col>
                                                <Col sm="12">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="emailListTargetId">
                                                    {this.props.t("List Target")}
                                                    </Label>
                                                    <Field as="select" name="emailListTargetId" 
                                                    className={
                                                    "form-control" +
                                                    (errors.emailListTargetId && touched.emailListTargetId
                                                        ? " is-invalid"
                                                        : "")
                                                    }
                                                    
                                                    onChange={handleChange}>
                                                        <option value="">Select</option>
                                                        {map(emaillisttargets, (emaillisttarget, classkey) => (
                                                            <option value={emaillisttarget.id} key={classkey}>{emaillisttarget.emailListTargetTitle}</option>
                                                        ))}
                                                    </Field>
                                                    <ErrorMessage
                                                    name="emailListTargetId"
                                                    component="div"
                                                    className="invalid-feedback"
                                                    />
                                                </FormGroup>
                                                </Col>
                                                <Col sm="12">
                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="campaignEmailListName">
                                                            {this.props.t("Title")}
                                                        </Label>
                                                        <Field
                                                            name="campaignEmailListName"
                                                            onChange={handleChange}

                                                            type="text"

                                                            className={
                                                                "form-control" +
                                                                (errors.campaignEmailListName && touched.campaignEmailListName
                                                                    ? " is-invalid"
                                                                    : "")
                                                            }

                                                            id="campaignEmailListName"
                                                        />
                                                        <ErrorMessage
                                                            name="campaignEmailListName"
                                                            component="div"
                                                            className="invalid-feedback"
                                                        />
                                                    </FormGroup>

                                                </Col>
                                                <Col sm="12">
                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="campaignEmailListDesc">
                                                            {this.props.t("Description")}
                                                        </Label>
                                                        <Field
                                                            name="campaignEmailListDesc"
                                                            onChange={handleChange}

                                                            type="text"

                                                            className={
                                                                "form-control" +
                                                                (errors.campaignEmailListDesc && touched.campaignEmailListDesc
                                                                    ? " is-invalid"
                                                                    : "")
                                                            }

                                                            id="campaignEmailListDesc"
                                                        />
                                                        <ErrorMessage
                                                            name="campaignEmailListDesc"
                                                            component="div"
                                                            className="invalid-feedback"
                                                        />
                                                    </FormGroup>

                                                </Col>                
                                                <Col sm="12">
                                                    <button type="submit" className="btn btn-primary w-md">Update</button>
                                                </Col>

                                            </Row>
                                        </Form>
                                    )}
                                </Formik>
                            </OffcanvasBody>
                        </Offcanvas>
        </div>
      </React.Fragment>
    )
  }
}
CollectionDashboard.propTypes = {
  t: PropTypes.any,
  match: PropTypes.object,
  loading: PropTypes.object,
  addCampaignEmailList: PropTypes.func,
  onGetEmailListTarget: PropTypes.func,
  emaillisttargets: PropTypes.array,
  onGetEmailListType:PropTypes.func,
  emaillisttypes:PropTypes.array,
  onUpdateCampaignEmailList: PropTypes.func,
  campaignemaillists:PropTypes.array,
  onGetCampaignEmailList:PropTypes.func,
  onDeleteProType: PropTypes.func
   
}
const mapStateToProps = ({ AppoinmentObject }) => (
  {
      emaillisttargets: AppoinmentObject.emaillisttargets,
      emaillisttypes:AppoinmentObject.emaillisttypes,
      campaignemaillists:AppoinmentObject.campaignemaillists,
      loading: AppoinmentObject.loading
  })
const mapDispatchToProps = dispatch => ({

  addCampaignEmailList: (data) => dispatch(addCampaignEmailList(data)),
  onGetEmailListTarget: (agentId) => dispatch(getEmailListTarget(agentId)),
  onGetEmailListType: (agentId) => dispatch(getEmailListType(agentId)),
  onUpdateCampaignEmailList: (data) => dispatch(updateCampaignEmailList(data)),
  onGetCampaignEmailList:(agentId) => dispatch(getCampaignEmailList(agentId)),
  onDeleteProType: (id) => dispatch(deleteCampaignEmailList(id))

});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CollectionDashboard))
