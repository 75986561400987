import React , { Component } from "react"
import PropTypes from "prop-types"
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import { Container, Row, Col, Card, CardBody, Offcanvas, OffcanvasBody, OffcanvasHeader} from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Link } from "react-router-dom"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios"
import Board from 'react-trello'
import styled from 'styled-components';
import { API_URL } from "../../../helpers/app_url"
import { addLeadLeadStageBoard } from "../../../store/actions"
import WelcomeComp from "../WelcomeComp"
import FilterMenu from "./filter-menu";
import Moment from 'react-moment';
import EditLead from "../forms/lead-edit";
const laneColors = ['#FF9999', '#99FF99', '#9999FF', '#FFFF99', '#FF99FF', '#99FFFF'];
const StyledBoard = styled(Board)`
  padding: 20px;

  .react-trello-lane:nth-child(1) header{
    //background-color: #0D6EFD;
    border-bottom:3px solid #0D6EFD !important;
    border:1px solid #ccc;
    //text-align:left;
    color:#000;
  }
  .react-trello-lane:nth-child(2) header{
    //background-color: rgb(255, 222, 89);
     border-bottom:3px solid #198754 !important;
      border:1px solid #ccc;
    //text-align:center;
    color:#000;
  }
   .react-trello-lane:nth-child(3) header{
    //background-color: rgb(92, 225, 230);
    border-bottom:3px solid #0DCAF0 !important;
    border:1px solid #ccc;
    //text-align:center;
    color:#000;
  }
  .react-trello-lane:nth-child(4) header{
    //background-color: rgb(126, 217, 87);
    border-bottom:3px solid #AB2E3C !important;
    border:1px solid #ccc;
    //text-align:center;
    color:#000;
  }
  .react-trello-lane:nth-child(5) header{
    //background-color: rgb(255, 189, 89);
   border-bottom:3px solid #FFC107 !important;
   border:1px solid #ccc;
    //text-align:center;
    color:#000;
  }

  .react-trello-lane header{
    //background-color: rgb(0, 0, 0);
    //text-align:center;
    border-bottom:3px solid #000 !important;
    border:1px solid #ccc;
    color:#000;
    padding: 10px 1.25rem !important;
  }
  .react-trello-lane{ background-color:transparent !important; padding: 10px 5px !important; }
  .CIzYb{ font-size:16px; padding-left: 1.25rem; text-transform: uppercase;}
`;
const CustomCard = ({ onClick, title, description, metadata, toggleRightCanvas  }) => {
  return (
    <Card className="mb-3 custom-card" onClick={onClick}>
      <CardBody >
      <div className="float-end ms-2"><span className="badge rounded-pill badge-soft-success font-size-12">{metadata.status}</span></div>
      <div className="d-flex align-items-center">
        <h5 className="card-title mb-2 lead-box-title"><Link to="#">{title}</Link></h5>
      </div>
      {/* <div><i className="bx bx-phone align-middle"></i>: {metadata.mobile}</div>
      <div>  <i className=" bx bxs-envelope align-middle"></i>: {metadata.email}</div> */}
      <div><i className=" bx bxs-timer align-middle"></i>: {metadata.source}  </div>
      <div> <i className=" bx  bxs-calendar align-middle"></i>:  {metadata.submittedDate}</div>
      <div className="row pt-1">
      {/* <div className="col-3 "><Link to="#" onClick={toggleRightCanvas}><i className=" bx bx-edit-alt font-size-20"></i></Link></div>
      <div className="col-3 "><Link to="#"><i className=" bx  bxs-envelope font-size-20"></i></Link></div>
      <div className="col-3 "><Link to="#"><i className=" bx bx-phone font-size-20"></i></Link></div> */}
      <div className="col-12"><Link className="float-end" to={`/lead/lead-detail/`+metadata.id+"/"+metadata.link}><i className=" bx bx-link-external font-size-20"></i></Link></div>
      </div>
      </CardBody>
    </Card>
  );
};

CustomCard.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  metadata: PropTypes.shape({
    mobile: PropTypes.string,
    email: PropTypes.string,
    status: PropTypes.string,
    source: PropTypes.string,
    submittedDate: PropTypes.string,
    id:PropTypes.number,
    link:PropTypes.string
  }),
  toggleRightCanvas: PropTypes.func
};
// Custom Lane component

const LaneHeader = styled.div`
  background-color: ${props => props.color} !important;
  color: white !important;
  padding: 10px !important;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
  font-weight: bold !important;
`;
const LaneBody = styled.div`
  background-color: #f8f9fa;
  padding: 10px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;
const getLaneColor = (index) => {
  const colors = ['#007bff', '#28a745', '#ffc107', '#dc3545', '#17a2b8', '#6610f2'];
  return colors[index % colors.length];
};

const CustomLane = ({ index, title, children }) => {
  const color = getLaneColor(index);
  return (
    <div>
      <LaneHeader color={color}>{title}</LaneHeader>
      <LaneBody>{children}</LaneBody>
    </div>
  );
};

// const CustomLanes = ({ index, title, children }) => {
//   const color = getLaneColor(index);
//   return (
//     <div style={{ backgroundColor: '#f8f9fa' }}>
//       <div style={{ backgroundColor: color }}>
//         {title}
//       </div>
//       {children}
//     </div>
//   );
// };
CustomLane.propTypes = {
  index: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};




class LeadStage extends Component{
  constructor(props) {
    super(props)
    this.state = {
      leads: [],
    stages:[],
    data: [],
    isRight: false,

    }
    this.toggleRightCanvas = this.toggleRightCanvas.bind(this);
  }

toggleRightCanvas() {
  this.setState({ isRight: !this.state.isRight });

}
  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    const token = obj.resultData.bearerToken;
    const str = 'Bearer ' + token;
    const agentId = parseInt(localStorage.getItem("userId"));

    try {
      const [leadsRes, stagesRes] = await Promise.all([
        axios.get(`${API_URL}Lead/allforagent`, {
          params: { agentId },
          headers: { Authorization: str }
        }),
        axios.get(`${API_URL}LeadStage/allforagent`, {
          params: { agentId },
          headers: { Authorization: str }
        })
      ]);

      this.setState({
        leads: leadsRes.data,
        stages: stagesRes.data
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  onCardMoveAcrossLanes = (fromLaneId, toLaneId, cardId, index) => {
    const pastData = {
      id: cardId,
      oldLeadStageId: fromLaneId,
      newLeadStageId: toLaneId
    };
    this.props.addLeadLeadStageBoard(pastData, this.props.history);
  };
  render () {
    const { leads, stages } = this.state;
    const allLocal = localStorage.getItem('localData')
    const localization = JSON.parse(allLocal);
    const data = {
      lanes: stages.map(stage => ({
        id: stage.id,
        title: stage.leadStageTitle,
        cards: leads
          .filter(lead => lead.stageId === stage.id)
          .map(lead => ({
            id: lead.id,
            title: `${lead.firstName} ${lead.lastName}`,
            description: `Phone: ${lead.phone}`,
            draggable: true,
            label: '',
            metadata: {
              mobile: lead.mobile,
              email: lead.email,
              status: (lead.leadStatusId) ? localization["LeadStatus_"+ lead.leadStatusId] : 'N/A',
              source:  (lead.leadSourceId) ? localization["LeadSource_"+ lead.leadSourceId] : 'N/A',
              submittedDate: <Moment format="D MMM YY">{lead.submittedDate}</Moment>,
              id:lead.id,
              link: lead.leadId,
            }
          }))
      }))
    };

    return (
        <React.Fragment>
            <ToastContainer autoClose={2000} />
        <div className="page-content project-page-content">
            <MetaTags>
                <title>Lead Dashboard | Realty Space</title>
            </MetaTags>
            <Container fluid className="ps-0 pe-0">

                <Row className="project-header">
                    <Col xl="7">
                        <Breadcrumbs
                            title={this.props.t("Lead")}
                            breadcrumbItem={this.props.t("Stage View")}
                        />
                    </Col>
                    <Col xl="5">
                        <WelcomeComp />
                    </Col>
                </Row>
               <FilterMenu />
               <StyledBoard
              style={{backgroundColor: 'white'}}
              data={data}
              onCardMoveAcrossLanes={this.onCardMoveAcrossLanes}
              draggable
              laneDraggable={false}
              cardDraggable
              components={{
                Card: props => <CustomCard {...props} toggleRightCanvas={this.toggleRightCanvas} />,
                Lane: CustomLane
              }}
            />

            </Container>
        </div>
        <Offcanvas
                      isOpen={this.state.isRight}
                      direction="end"
                      toggle={this.toggleRightCanvas}
                    >
                      <OffcanvasHeader toggle={this.toggleRightCanvas}>
                        Update Lead
                      </OffcanvasHeader>
                      <OffcanvasBody>
                      <EditLead
                       closed={this.toggleRightCanvas}
                       match={this.props.match}
                      />
                      </OffcanvasBody>
                    </Offcanvas>
    </React.Fragment>
    )
}
}

LeadStage.propTypes = {
  t: PropTypes.any,
  addLeadLeadStageBoard:PropTypes.func,
  history: PropTypes.object,
  match:PropTypes.object
}
const mapStateToProps = ({ leads }) => (

  {




  })
  const mapDispatchToProps = dispatch => ({

    addLeadLeadStageBoard:(data) => dispatch(addLeadLeadStageBoard(data)),

  });
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LeadStage))
