import React, { Component } from "react"
import PropTypes from 'prop-types'
import { connect } from "react-redux"
//import { withTranslation } from "react-i18next"
import { withRouter } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import {  getLeadCallLog, addLeadCallLog} from "../../../store/actions"
import Moment from 'react-moment';
import { isEmpty, map } from "lodash";
import {
  Row,
  Col,
  FormGroup,
  Label
} from "reactstrap"
import classnames from "classnames"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { withTranslation } from "react-i18next";
import SaveButton from "components/buttons/save";
class CallLog extends Component {
  constructor(props) {
    super(props)
    this.state = {
     
      
    }
    this.handSubmit = this.handSubmit.bind(this)
  }  
  handSubmit(value) {
    this.props.addLeadCallLog(value, this.props.history)
  }
  componentDidMount() {
    const { match:{params}, onGetLeadCallLog} = this.props;
    onGetLeadCallLog(params.id);
}
  render() {
    const allLocal = localStorage.getItem('localData')
    const localization = JSON.parse(allLocal);
    const { calllogs } = this.props;
    return (
      <React.Fragment>
        <div className="mb-5 h4 card-title">Call Log</div>
        <Formik
              enableReinitialize={true}
              initialValues={{
                actionType: '',
                addedDate: new Date(),
                refId: this.props.match.params.id,
                callNote : (this.state && this.state.callNote) || "",


              }}
              validationSchema={Yup.object().shape({
                actionType: Yup.string().required(
                  "Please Select Action"
                ),
                callNote: Yup.string().max(500, 'This filed contains only 500 characters only').required("This is Required")
              })}

              onSubmit={this.handSubmit}
            >
              {({ errors, touched, values, handleChange, setFieldValue }) => (
                <Form
                  className="needs-validation"
                >

                  <Row className="mt20 mb20">



                    <Col lg="12">
                      <div role="group" className="btnCheckGroup" aria-labelledby="my-radio-group">
                        <label>
                          <Field type="radio" name="actionType" id="actionType1" value="No Answer" onChange={handleChange} />
                          No Answer
                        </label>
                        <label>
                          <Field type="radio" name="actionType" id="actionType2" value="Left Voicemail" onChange={handleChange} />
                          Left Voicemail
                        </label>
                        <label>
                          <Field type="radio" name="actionType" id="actionType2" value="Bad Number" onChange={handleChange} />
                          Bad Number
                        </label>
                        <ErrorMessage
                          name="actionType"
                          component="div"
                          className="text-danger"
                        />  

                      </div>
                      

                    </Col>

                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="addedDate">
                          Log Date
                        </Label>
                        <Flatpickr
                          className={
                            "form-control" +
                            (errors.addedDate && touched.addedDate
                              ? " is-invalid"
                              : "")
                          }
                          placeholder=""
                          value={values.addedDate}
                          onChange={(value) => setFieldValue('addedDate', value[0])}
                          options={{
                            altInput: true,
                            altFormat: "F j, Y",
                            dateFormat: "Y-m-d"
                          }}
                        />
                        <ErrorMessage
                          name="addedDate"
                          component="div"
                          className="invalid-feedback"
                        />

                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="callNote">
                          Add Message
                        </Label>
                        <textarea
                          name="callNote"
                          onChange={handleChange}
                          type="textarea"

                          className={
                            "form-control" +
                            (errors.callNote && touched.callNote
                              ? " is-invalid"
                              : "")
                          }

                          id="callNote"

                        />
                        <ErrorMessage
                          name="callNote"
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <div className="clearfix">
                    <SaveButton>Save</SaveButton>
                   </div>


                </Form>
              )}
            </Formik>
            <div className="mb-5 h4 card-title mt-20">Call Log History</div>
                                <ul className="verti-timeline list-unstyled">

                                  {map(calllogs, (callLog, callkey) => (

                                    <li className="event-list eventlist" key={"_call_" + callkey}>
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle font-size-18" />
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-shrink-0 me-3">
                                          <h5 className="font-size-14">
                                            <Moment format="D MMM YY">{callLog.addedDate}</Moment>

                                            <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                                          </h5>
                                        </div>
                                        <div className="flex-grow-1">
                                          <h5 className="font-size-14">{localization["ActivitySubType_"+ callLog.activityType+"_"+callLog.activitySubType]}</h5>
                                        </div>
                                      </div>
                                      <div className="exNote">{callLog.activityRemarks}</div>
                                    </li>
                                  ))}

                                </ul>  
                                
                </React.Fragment>
    )
  }
}
CallLog.propTypes = {
  history:PropTypes.object,
  t: PropTypes.any,
  match:PropTypes.object,
  onGetLeadCallLog:PropTypes.func,
  calllogs:PropTypes.array,
  addLeadCallLog:PropTypes.func

}
const mapStateToProps = ({ leads }) => (
  { 
    calllogs:leads.calllogs,
  })
  const mapDispatchToProps = dispatch => ({
   
    addLeadCallLog:(data) => dispatch(addLeadCallLog(data)),
    onGetLeadCallLog:(leadId) => dispatch(getLeadCallLog(leadId)),
   
  });
  export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CallLog)))

