import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { createLeadData, getLeadStage } from "../../store/actions"
import LeadStatusOption from 'components/dropdowns/Lead/LeadStatus'
import LeadTypeOption from 'components/dropdowns/Lead/LeadType'
import LeadStageOption from 'components/dropdowns/Lead/LeadStatge'
import LeadSourceOption from 'components/dropdowns/Lead/LeadSource'
import OtherDropdowns from "components/dropdowns/Other/OtherDropdowns"
import TitleDropdowns from "components/dropdowns/Other/titleDropdowns"
import SaveButton from "components/buttons/save"
import RsLink from "components/buttons/rsLink"
import {Row,Col,FormGroup,Label} from "reactstrap"
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
class CreateLead extends Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.handleSubmitLead = this.handleSubmitLead.bind(this);
  }
  handlePlaceSelected = (address, setFieldValue) => {
    geocodeByAddress(address)
      .then(results => {
        const place = results[0];
        const addressComponents = place.address_components;
        
        const getComponent = (types) => {
          const component = addressComponents.find((component) =>
            types.every((type) => component.types.includes(type))
          );
          return component ? component.long_name : '';
        };

        const streetNumber = getComponent(['street_number']);
        const route = getComponent(['route']);
        const city = getComponent(['locality', 'political']);
        const province = getComponent(['administrative_area_level_1', 'political']);
        const postalCode = getComponent(['postal_code']);
        const country = getComponent(['country', 'political']);

        const modifiedAddress = `${streetNumber} ${route}`;
        const latitude = place.geometry.location.lat();
        const longitude = place.geometry.location.lng();
        console.log(modifiedAddress)
        setFieldValue(`fullAddress`, place.formatted_address);
        setFieldValue(`addressLine1`, modifiedAddress);
        setFieldValue(`addressLine2`, city);
        setFieldValue(`city`, city);
        setFieldValue(`province`, province);
        setFieldValue(`postal`, postalCode);
        setFieldValue(`country`, country);
        setFieldValue(`longitude`, longitude);
        setFieldValue(`latitude`, latitude);

      })
      .catch(error => console.error('Error', error));
  };
  handleSubmitLead = (values, actions) => {
    console.log(values);
    const payload = {
      firstName: values.firstName,
      lastName: values.lastName,
      leadStatusId: (values.leadStatusId) ? parseInt(values.leadStatusId) : 0,
      leadTypeId: (values.leadTypeId) ? parseInt(values.leadTypeId) : 0,
      leadSourceId: (values.leadSourceId) ? parseInt(values.leadSourceId) : 0,
      email: values.email,
      phone: values.phone,
      phoneExt: values.phoneExt,
      mobile: values.mobile,
      agentId: parseInt(localStorage.getItem("userId")),
      referedById:values.referedById,
      referedBy:values.referedBy,
      referralCode:values.referralCode,
      group: (values.group) ? parseInt(values.group) : 0,
      approxDealValue: (values.approxDealValue) ? parseInt(values.approxDealValue) : 0,
      notes: values.notes,
      submittedDate:values.submittedDate,
      title:values.title,
      stageId: (values.stageId) ? parseInt(values.stageId) : 0,
      fullAddress:values.fullAddress,
      addressLine1:values.addressLine1,
      addressLine2:values.addressLine2,
      nearestIntersection:values.nearestIntersection,
      city:values.city,
      province:values.province,
      postal:values.postal,
      country:values.country,
      longitude:values.longitude,
      latitude:values.latitude
    }
    this.props.createLeadData(payload);
    this.props.toggleAddNew();
  }
  render() {
    const { toggleAddNew } = this.props;
    return (
      <React.Fragment>
          
                      <Formik
                        enableReinitialize={true}
                        initialValues={{
                          title:'',
                          firstName: (this.state && this.state.firstName) || "",
                          lastName: (this.state && this.state.lastName) || "",
                          email: (this.state && this.state.email) || "",
                          phone: "",
                          mobile: "",
                          leadStatusId:"",
                          fullAddress:'',
                          addressLine1:'',
                          addressLine2:'',
                          nearestIntersection:'',
                          city:'',
                          province:'',
                          postal:'',
                          country:'',
                          longitude:0,
                          latitude:0,   
                          submittedDate: new Date(),
                        }}
                        validationSchema={Yup.object().shape({
                          title: Yup.string().required('This field is required'),
                          firstName: Yup.string().required("This Field in required"),
                          lastName: Yup.string().required("This Field in required"),
                          email: Yup.string().email('Invalid email').required('This Field in required'),
                          mobile: Yup.string().matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, 'Enter number is not valid').required('This is required'),
                          phone: Yup.string().matches(/(?:\(?\+\d{2}\)?\s*)?\d+(?:[ -]*\d+)*$/, 'Enter number is not valid'),
                        })}

                        onSubmit={this.handleSubmitLead}
                      >
                        {({ errors, touched, values, handleChange, setFieldValue }) => (
                          <Form
                            className="needs-validation"
                          >
                            <Row>
                            <Col md="3">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="submittedDate">
                                    {this.props.t("Submitted Date")}
                                  </Label>
                                  <Flatpickr
                                    className={
                                      "form-control" +
                                      (errors.submittedDate && touched.submittedDate
                                        ? " is-invalid"
                                        : "")
                                    }
                                    placeholder=""
                                    value={values.submittedDate}
                                    onChange={(value) => setFieldValue('submittedDate', value[0])}
                                    options={{
                                      altInput: true,
                                      altFormat: "F j, Y",
                                      dateFormat: "Y-m-d"
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                            <Col md="2">
                            <TitleDropdowns
                                name="title"
                                label="Title *"
                                fieldName="Agent:Title"
                                
                              />
                              
                              </Col>
                              <Col md="4">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="firstName">
                                    {this.props.t("First Name *")}
                                  </Label>
                                  <Field
                                    name="firstName"
                                    type="text"
                                    onChange={handleChange}
                                    className={
                                      "form-control" +
                                      (errors.firstName && touched.firstName
                                        ? " is-invalid"
                                        : "")
                                    }
                                    id="firstName"
                                  />
                                  <ErrorMessage
                                    name="firstName"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </FormGroup>
                              </Col>

                              <Col md="3">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="lastName">
                                    {this.props.t("Last Name *")}
                                  </Label>
                                  <Field
                                    name="lastName"
                                    type="text"
                                    onChange={handleChange}
                                    className={
                                      "form-control" +
                                      (errors.lastName && touched.lastName
                                        ? " is-invalid"
                                        : "")
                                    }
                                    id="lastName"
                                  />
                                  <ErrorMessage
                                    name="lastName"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="12">
                                        <FormGroup className="mb-3">
                                          <Label htmlFor="fullAddress">
                                            {this.props.t("Project Location")}
                                          </Label>


                                          <PlacesAutocomplete
                                             value={values.fullAddress}
                                             onChange={(value) => setFieldValue('fullAddress', value)}
                                            onSelect={(value) => this.handlePlaceSelected(value, setFieldValue)}
                                          >
                                       {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                  <div>
                                                    <input
                                                      {...getInputProps({
                                                        placeholder: 'Enter Address...',
                                                        className: 'form-control',
                                                      
                                                      })}
                                                    />
                                                    <div className="">
                                                      {loading ? <div>Loading...</div> : null}

                                                      {suggestions.map((suggestion, i) => {
                                                        const className = suggestion.active
                                                          ? 'suggestion-item--active'
                                                          : 'suggestion-item';
                                                        const style = suggestion.active
                                                          ? { backgroundColor: '#007BFF', color: '#fff', cursor: 'pointer', padding: '10px' }
                                                          : { backgroundColor: '#fff', cursor: 'pointer', padding: '10px' };

                                                        return (
                                                          <div
                                                          key={i}
                                                            {...getSuggestionItemProps(suggestion, {
                                                              style,
                                                            })}
                                                          >
                                                            {suggestion.description}
                                                          </div>
                                                        );
                                                      })}
                                                    </div>
                                                  </div>
                                                )}
                                              </PlacesAutocomplete>


                                        </FormGroup>
                                      </Col>
                              <Col md="4">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="email">
                                    {this.props.t("Email *")}
                                  </Label>
                                  <Field
                                    name="email"
                                    type="text"
                                    
                                    onChange={handleChange}
                                    className={
                                      "form-control" +
                                      (errors.email && touched.email
                                        ? " is-invalid"
                                        : "")
                                    }
                                    id="email"
                                  />
                                  <ErrorMessage
                                    name="email"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </FormGroup>
                              </Col>
                             
                              <Col md="3">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="mobile">
                                    {this.props.t("Mobile *")}
                                  </Label>
                                  <Field
                                    name="mobile"
                                    type="text"
                                   
                                    onChange={handleChange}
                                    className={
                                      "form-control" +
                                      (errors.mobile && touched.mobile
                                        ? " is-invalid"
                                        : "")
                                    }
                                    id="mobile"
                                  />
                                  <ErrorMessage
                                    name="mobile"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="3">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="phone">
                                    {this.props.t("Secondary Phone")}
                                  </Label>
                                  <Field
                                    name="phone"
                                    type="text"
                                   
                                    onChange={handleChange}
                                    className={
                                      "form-control" +
                                      (errors.phone && touched.phone
                                        ? " is-invalid"
                                        : "")
                                    }
                                    id="phone"
                                  />
                                  <ErrorMessage
                                    name="phone"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="2">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="phoneExt">
                                    {this.props.t("Ext")}
                                  </Label>
                                  <Field
                                    name="phoneExt"
                                    type="text"
                                    //value={values.phone}
                                    onChange={handleChange}
                                    className={
                                      "form-control" +
                                      (errors.phoneExt && touched.phoneExt
                                        ? " is-invalid"
                                        : "")
                                    }
                                    id="phoneExt"
                                  />
                                  <ErrorMessage
                                    name="phoneExt"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </FormGroup>
                              </Col> 
                              <Col md="3">
                               <LeadTypeOption />
                              </Col>

                              <Col md="3">
                                    <LeadStatusOption />
                               
                              </Col>  
                              
                              <Col md="3">
                                    <LeadStageOption />
                              </Col> 
                              <Col md="3">
                              <OtherDropdowns
                                name="group"
                                label="Group"
                               fieldName="Lead:Group"
                              />
                              </Col>
                              <Col md="3">
                              <LeadSourceOption />
                              </Col>    
                              <Col md="2">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="approxDealValue">
                                    {this.props.t("Deal Value")}
                                  </Label>
                                  <Field
                                    name="approxDealValue"
                                    type="text"
                                    onChange={handleChange}
                                    className={
                                      "form-control" +
                                      (errors.approxDealValue && touched.approxDealValue
                                        ? " is-invalid"
                                        : "")
                                    }
                                    id="approxDealValue"
                                  />
                                </FormGroup>
                              </Col>
                             
                                 
                              <Col md="2">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="referedBy">
                                    {this.props.t("Referred by")}
                                  </Label>
                                  <Field
                                    name="referedBy"
                                    type="text"
                                    //value={values.referedBy}
                                    onChange={handleChange}
                                    className={
                                      "form-control" +
                                      (errors.referedBy && touched.referedBy
                                        ? " is-invalid"
                                        : "")
                                    }
                                    id="referedBy"
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="2">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="referralCode">
                                    {this.props.t("Referral Code")}
                                  </Label>
                                  <Field
                                    name="referralCode"
                                    type="text"
                                    //value={values.referedBy}
                                    onChange={handleChange}
                                    className={
                                      "form-control" +
                                      (errors.referralCode && touched.referralCode
                                        ? " is-invalid"
                                        : "")
                                    }
                                    id="referralCode"
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                    
                            <Row>
                              <Col md="12">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="notes">
                                    {this.props.t("Notes ")}
                                  </Label>
                                  <Field
                                    name="notes"
                                    onChange={handleChange}
                                    as="textarea"

                                    className="form-control"

                                    id="notes"

                                  />
                                </FormGroup>
                              </Col>



                            </Row>






                            <div><SaveButton>{this.props.t("Save")}</SaveButton>{ " " } <RsLink onClick={toggleAddNew}>Close</RsLink></div>

                          </Form>
                        )}
                      </Formik>
                    
      </React.Fragment>
    )
  }
}

CreateLead.propTypes = {
  t: PropTypes.any,
  history: PropTypes.object,
  error: PropTypes.any,
  createLeadData: PropTypes.func,
  toggleAddNew:PropTypes.object
}
const mapDispatchToProps = dispatch => ({
  createLeadData: (data) => dispatch(createLeadData(data)),
 })
export default connect('',mapDispatchToProps)(withTranslation()(CreateLead))
