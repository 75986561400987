import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";
import "@fullcalendar/bootstrap/main.css";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import axios from "axios";
import { API_URL } from "helpers/app_url"
class Calendar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      resultArray: [],
      isLoading: false
       
    }
   
}
componentDidMount() {
  const obj = JSON.parse(localStorage.getItem("authUser"));
  const token = obj?.resultData?.bearerToken;
  const userId = localStorage.getItem('userId');
  this.setState({ isLoading: true });
    axios.get(`${API_URL}Google/SyncGoogleEvents/${userId}`, {
    headers: { Authorization: `Bearer ${token}` }
    })
    .then(response => {
        if(response.data==='Success'){
        
          axios.get(`${API_URL}Google/GetGoogleEventsFromLocal/${userId}`, {
            headers: { Authorization: `Bearer ${token}` }
        })
        .then(secondResponse => {
            console.log('Response from the second API:', secondResponse.data);
            const events = secondResponse.data.map(event => ({
              id: event.id,
              title: event.summary,
              start: event.startTime,
              end: event.endTime,
              location: event.location, 
              url: event.htmlLink,
            }));
            this.setState({
              resultArray: events,
              isLoading: false,
            });
        })
        .catch(error => {
            console.error('Error fetching data from second API:', error);
            this.setState({ isLoading: false });
        });
        }
  
    })
    .catch(error => {
        console.error('Error fetching data from API:', error);
        this.setState({ isLoading: false });
    });

}

  render() {
    const { resultArray, isLoading} = this.state;
    return (
      <div className="page-content">
          {isLoading ? (
                    <div className="rs-loader-overlay">
                    <div className="rs-loader"></div>
                    </div>
                ) : null}
        <Container fluid={true}>
          <Breadcrumbs title="Calendar" breadcrumbItem="Full Calendar" />
          <Row>
            <Col className="col-12">
            <Card>
              <CardBody>
              <FullCalendar
                plugins={[
                  dayGridPlugin,
                  interactionPlugin,
                  BootstrapTheme,
                ]}
                initialView="dayGridMonth"
                headerToolbar={{
                  left: "prev,next today",
                  center: "title",
                  right: "dayGridMonth,dayGridWeek,dayGridDay",
                }}
                events={resultArray}
                editable={true}
                droppable={true}
                selectable={true}
              />
              </CardBody>
            </Card>
             
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Calendar;
