import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import { Link } from "react-router-dom"
import PropTypes from 'prop-types'
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Label,
    InputGroup,
} from "reactstrap"
import { connect } from "react-redux"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { withTranslation } from "react-i18next"
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Moment from 'react-moment';
import { addOpHouseLead } from "../../store/clients/actions"
// import images


class ClientLeadForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
        this.handleSellerOphouseLead =  this.handleSellerOphouseLead.bind(this)
    }
    handleSellerOphouseLead(value){
        //console.log(value);
        this.props.addOpHouseLead(value, this.props.history);
      }
    render() {
        return (
            <React.Fragment>
                 <ToastContainer autoClose={2000} />
                <div className="account-pages my-5 pt-sm-5">
                    <MetaTags>
                        <title>Add Open House Lead</title>
                    </MetaTags>
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="text-center mb-5 text-muted">

                                    <p className="mt-3">Openhouse Visitor : M123455</p>
                                </div>
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <Col md={12} lg={12} xl={12}>
                                <Card>
                                    <CardBody>
                                        <div className="p-2">
                                            <div className="">

                                                <div className="p-2 mt-4">
                                                    <Formik
                                                        enableReinitialize={true}
                                                        initialValues={{
                                                            //clientListingId: (this.state && this.state.clientListingId) || "",

                                                            firstName: (this.state && this.state.firstName) || "",
                                                            email: (this.state && this.state.email) || "",
                                                            phone: (this.state && this.state.phone) || "",
                                                            clientOpenhouseLeadSourceId: this.props.match.params.clientOpenhouseLeadSourceId
                                                           

                                                        }}
                                                        validationSchema={Yup.object().shape({
                                                            //clientListingId: Yup.string().required("This is Required"),
                                                            firstName: Yup.string().required("This is Required"),
                                                            phone: Yup.string().matches(
                                                                /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
                                                                "Enter valid Contact number"
                                                            ).required(
                                                                "Please Enter Contact Number"
                                                            ),
                                                            email: Yup.string().email("Must be a valid Email")
                                                                .max(255)
                                                                .required("Email is required")
                                                        })}

                                                        onSubmit={this.handleSellerOphouseLead}
                                                    >
                                                        {({ errors, touched, values, handleChange, setFieldValue }) => (
                                                            <Form
                                                                className="needs-validation"
                                                            >

                                                                <Row>
                                                                    <Col sm="4">
                                                                        <FormGroup className="mb-3">
                                                                            <Label htmlFor="firstName">
                                                                                {this.props.t("Your Name")}
                                                                            </Label>
                                                                            <Field
                                                                                name="firstName"
                                                                                onChange={handleChange}
                                                                                type="text"
                                                                                className={
                                                                                    "form-control" +
                                                                                    (errors.firstName && touched.firstName
                                                                                        ? " is-invalid"
                                                                                        : "")
                                                                                }
                                                                                id="firstName"
                                                                            />
                                                                            <ErrorMessage
                                                                                name="firstName"
                                                                                component="div"
                                                                                className="invalid-feedback"
                                                                            />
                                                                        </FormGroup>
                                                                    </Col>

                                                                    <Col sm="4">
                                                                        <FormGroup className="mb-3">
                                                                            <Label htmlFor="yourEmail">
                                                                                {this.props.t("Your Email")}
                                                                            </Label>
                                                                            <Field
                                                                                name="email"
                                                                                onChange={handleChange}
                                                                                type="text"
                                                                                className={
                                                                                    "form-control" +
                                                                                    (errors.email && touched.email
                                                                                        ? " is-invalid"
                                                                                        : "")
                                                                                }
                                                                                id="email"
                                                                            />
                                                                            <ErrorMessage
                                                                                name="email"
                                                                                component="div"
                                                                                className="invalid-feedback"
                                                                            />
                                                                        </FormGroup>
                                                                    </Col>

                                                                    <Col sm="4">
                                                                        <FormGroup className="mb-3">
                                                                            <Label htmlFor="yourContact">
                                                                                {this.props.t("Your Phone")}
                                                                            </Label>
                                                                            <Field
                                                                                name="phone"
                                                                                onChange={handleChange}
                                                                                type="text"
                                                                                className={
                                                                                    "form-control" +
                                                                                    (errors.phone && touched.phone
                                                                                        ? " is-invalid"
                                                                                        : "")
                                                                                }
                                                                                id="phone"
                                                                            />
                                                                            <ErrorMessage
                                                                                name="phone"
                                                                                component="div"
                                                                                className="invalid-feedback"
                                                                            />
                                                                        </FormGroup>
                                                                    </Col>

                                                                    <Col sm="4">
                                                                        <FormGroup className="mb-3">
                                                                            <Label htmlFor="cmbPreApproved">
                                                                                {this.props.t("Are you pre-approved?")}
                                                                            </Label>
                                                                            <Field as="select" name="cmbPreApproved" className="form-control" onChange={handleChange}>
                                                                                <option value="">Select</option>
                                                                                <option value="1">Yes</option>
                                                                                <option value="0">No</option>

                                                                            </Field>
                                                                          
                                                                        </FormGroup>
                                                                    </Col>

                                                                    <Col sm="4">
                                                                        <FormGroup className="mb-3">
                                                                            <Label htmlFor="cmbHowSoonLookingFor">
                                                                                {this.props.t("How soon are you looking to move?")}
                                                                            </Label>
                                                                            <Field as="select" name="cmbHowSoonLookingFor" className="form-control" onChange={handleChange}>
                                                                                <option value="">Select</option>
                                                                                <option value="ASAP">As Soon As Possible</option>
                                                                                <option value="1 Month">In 1 Month</option>
                                                                                <option value="3 Months">In 3 Months</option>
                                                                                <option value="6 Months">In 6 Months</option>
                                                                                <option value="After 6 Months">After 6 Months</option>

                                                                            </Field>
                                                                            
                                                                        </FormGroup>
                                                                    </Col>

                                                                    <Col sm="4">
                                                                        <FormGroup className="mb-3">
                                                                            <Label htmlFor="cmbWorkingWithAgent">
                                                                                {this.props.t("Are you currently working with an agent?")}
                                                                            </Label>
                                                                            <Field as="select" name="cmbWorkingWithAgent" className="form-control" onChange={handleChange}>
                                                                                <option value="">Select</option>
                                                                               
                                                                                <option value="1">Yes</option>
                                                                                <option value="0">No</option>

                                                                            </Field>
                                                                            
                                                                        </FormGroup>
                                                                    </Col>

                                                                </Row>

                                                                <div className="clearfix"><button type="submit" className="btn btn-primary w-md float-end">Save</button></div>


                                                            </Form>
                                                        )}
                                                    </Formik>
                                                </div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>

                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}
ClientLeadForm.propTypes = {
    t: PropTypes.any,
    history:PropTypes.object,
    addOpHouseLead:PropTypes.func,
    match:PropTypes.object,

}
const mapStateToProps = ({ Clients }) => (
    {
     
  
    })
    const mapDispatchToProps = dispatch => ({
        addOpHouseLead: (data) => dispatch(addOpHouseLead(data))    
       
      });
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ClientLeadForm))