import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import prefrence from "../../assets/images/rsicons/prefrence.png"

//import Lead from "./Lead";
import ClientLink from "./client-link";
import { getClientDetail, addClientImportantDate, addClientChild, getClientImportantDate, getClientChild, deleteClientImportant, updateClientProfile} from "../../store/clients/actions"
import Switch from "react-switch"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import {  isEmpty, map, size } from "lodash";
import Moment from 'react-moment';
import DeleteModal from "../../components/Common/DeleteModal";
import ClientSidebar from "components/VerticalLayout/ClientSidebar";
import SaveButton from "components/buttons/save";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Input,
  Button,
  Collapse
} from "reactstrap"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios"
import ClientProfileMenu from "./menu/client-profile-menu";
import ClientProfileBreadcrumbs from "components/Common/ClientProfileBreadcrumb";
class ClientProfile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      rows: [],
      rows1: [],
      clientTypes:[],
      titles:[],
      importantdate:'',
      sources:[],
      reports: [
        { title: "Upcoming Closings", iconClass: "bx-copy-alt", description: "14" },
        {
          title: "Upcoming appointments",
          iconClass: "bx-archive-in",
          description: "6",
        },
        {
          title: "Active Clients ",
          iconClass: "bx-purchase-tag-alt",
          description: "5",
        },
        {
          title: "Messages Received",
          iconClass: "bx-purchase-tag-alt",
          description: "5",
        },
        {
          title: "Investment interest",
          iconClass: "bx-purchase-tag-alt",
          description: "5",
        },
      ],
      col9: false,
      col8: false,
      col15: false,
      col10: false,
      col11: false,
      col12: false,
    }
    this.handleImportantDateSubmit = this.handleImportantDateSubmit.bind(this)
    this.handleChildSubmit = this.handleChildSubmit.bind(this)
    this.onClickDelete = this.onClickDelete.bind(this);
    this.t_col8 = this.t_col8.bind(this)
    this.t_col9 = this.t_col9.bind(this)
    this.t_col10 = this.t_col10.bind(this)
    this.t_col11 = this.t_col11.bind(this)
    this.t_col12 = this.t_col12.bind(this)
    this.t_col15 = this.t_col15.bind(this)
  }

  toggleDeleteModal = () => {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal,
    }));
  };
  onClickDelete = (importantdate) => {
    this.setState({ importantdate: importantdate });
    this.setState({ deleteModal: true });
  };
  handleDeleteImportantDate = () => {
    const { onDeleteImportantDate } = this.props;
    const { importantdate } = this.state;
    
    if (importantdate.id !== undefined) {
      onDeleteImportantDate(importantdate);
      this.setState({ deleteModal: false });
    }
  };
  handleImportantDateSubmit(value){
    this.props.addClientImportantDate(value, this.props.history);
  }

  handleChildSubmit(value){
    this.props.addClientChild(value, this.props.history);
  }
  t_col8() {
    this.setState({
      col8: !this.state.col8,
      col9: false,
      col10: false,
      col11: false,
      col12: false,
      col15: false
    })
  }

  t_col9() {
    this.setState({
      col8: false,
      col9: !this.state.col9,
      col10: false,
      col11: false,
      col12: false,
      col15: false
    })
  }

  t_col10() {
    this.setState({
      col8: false,
      col10: !this.state.col10,
      col9: false,
      col11: false,
      col12: false,
      col15: false
    })
  }

  t_col11() {
    this.setState({
      col8: false,
      col11: !this.state.col11,
      col9: false,
      col10: false,
      col12: false,
      col15: false
    })
  }
  t_col12() {
    this.setState({
      col8: false,
      col12: !this.state.col12,
      col11: false,
      col9: false,
      col10: false,
      col15: false
    })
  }
  t_col15() {
    this.setState({
      col8: false,
      col15: !this.state.col15,
      col11: false,
      col9: false,
      col10: false,
      col12: false
    })
  }

componentDidMount() {
  const { match: { params }, onGetClientDetail, onGetClientImportantDate, onGetClientChild } = this.props;
  onGetClientDetail(params.clientId);
  onGetClientImportantDate(params.id);
  onGetClientChild(params.id)
 
  const obj = JSON.parse(localStorage.getItem("authUser"));
  const token = obj.resultData.bearerToken;

  const str = 'Bearer ' + token;
  axios.get('https://rscoreapi.azurewebsites.net/api/LeadType/all', { headers: { Authorization: str } })
    .then(res => {
      const clientTypes = res.data;
      this.setState({ clientTypes });
    }),
    axios.get('https://rscoreapi.azurewebsites.net/api/FieldValue/allbyname', {
        params: {
          fieldName: 'Agent:Title',
        }, headers: { Authorization: str }
      })
        .then(res => {
          const titles = res.data;
          this.setState({ titles });
        }),
        axios.get('https://rscoreapi.azurewebsites.net/api/LeadSource/all', { headers: { Authorization: str } })
        .then(res => {
          const sources = res.data;
          this.setState({ sources });
        })
    
}
  render() {
    const { clietDetail, importantdates, childs, loading, onUpdateClientProfile } = this.props;
    const importantdate = this.state.importantdate;
    const {  deleteModal } = this.state;
    //console.log(loading);
    const Offsymbol = () => {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            fontSize: 12,
            color: "#fff",
            paddingRight: 2
          }}
        >
          {" "}
          Inactive
        </div>
      )
    }

    const OnSymbol = props => {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            fontSize: 12,
            color: "#fff",
            paddingRight: 2
          }}
        >
          {" "}
          Active
        </div>
      )
    }

    return (
      <React.Fragment>
        {this.props.loading && this.props.loading ? (
         <div className="loading">Loading&#8230;</div>
         ) : null}
        <ToastContainer autoClose={2000} />
        <div className="page-content project-page-content">
          <MetaTags>
            <title>Client Dashboard | Realty Space</title>
          </MetaTags>
          <Container fluid className="ps-0">
          <DeleteModal
          show={deleteModal}
          onDeleteClick={this.handleDeleteImportantDate}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />

            <Row>
              <Col sm='2' className="pe-0">
                <ClientSidebar id={this.props.match.params.id} clientId={this.props.match.params.clientId} />
              </Col>
              <Col sm='10' className="ps-0 pe-0">
              <Row className="project-header">
              <Col lg="8" className="ps-0">
             <ClientProfileBreadcrumbs
              title={this.props.t("Clients")}
              breadcrumbItem={this.props.t(clietDetail.firstName +" " + clietDetail.lastName + " Profile")}
            />
            </Col>
            <Col lg="3">
            <FormGroup className="mt-4 float-end">
                           
                           <Switch
                           uncheckedIcon={<Offsymbol />}
                           className="me-1 mb-sm-8 mb-2"
                           checkedIcon={<OnSymbol />}
                           onColor="#626ed4"
                           onChange={() =>
                             this.setState({ switch1: !this.state.switch1 })
                           }
                           checked={this.state.switch1}
                         />
                         </FormGroup>
              </Col>
            </Row>

            <ClientProfileMenu />
            <Row>
              
              
              <Col xl="12">
            
              <Card>
                          <CardBody>

                          <Formik
                        enableReinitialize={true}
                        initialValues={{
                          // id: clietDetail?.primaryContact?.id,
                          // clientContactId: clietDetail?.primaryContact?.clientContactId,
                          // clientId: clietDetail?.primaryContact?.clientId,
                          contactFName: clietDetail?.primaryContact?.contactFName,
                          contactLName: clietDetail?.primaryContact?.contactLName,
                          petName: clietDetail?.primaryContact?.petName,
                          birthDay: clietDetail?.primaryContact?.birthDay,
                          anniversary: clietDetail?.primaryContact?.anniversary,
                          cContactId: clietDetail?.primaryContact?.cContactId,
                          cContactIsActive: clietDetail?.primaryContact?.cContactIsActive,
                          title: clietDetail?.primaryContact?.title,
                          organization: clietDetail?.primaryContact?.organization,
                          jobTitle: clietDetail?.primaryContact?.jobTitle,
                          contactFName: clietDetail?.primaryContact?.contactFName,
                          industry: clietDetail?.primaryContact?.industry,
                          phone: clietDetail?.primaryContact?.cContact?.phone,
                          phoneExt: clietDetail?.primaryContact?.cContact?.phoneExt,
                          email: clietDetail?.primaryContact?.cContact?.email,
                          mobile: clietDetail?.primaryContact?.cContact?.mobile,
                          office: clietDetail?.primaryContact?.cContact?.office,
                          fax: clietDetail?.primaryContact?.cContact?.fax,
                         
                        

                        }}
                        validationSchema={Yup.object().shape({
                          // title: Yup.string().required("This is Required"),
                          contactFName :Yup.string().max(100,"100 Characters are allowed").required("This is Required"),
                          contactLName : Yup.string().max(100,"100 Characters are allowed"),
                          mobile : Yup.string().min(10,"10 Digits are allowed").max(10,"10 Digits are allowed").required("This is Required"),
                          phone : Yup.string().min(10,"10 Digits are allowed").max(10,"10 Digits are allowed"),
                          email : Yup.string().max(100,"100 Characters are allowed").email().required("This is Required"),
                          office : Yup.string().min(10,"10 Digits are allowed").max(10,"10 Digits are allowed"),
                          phoneExt : Yup.string().min(3,"Min. 3 Digts are Required").max(6,"Max. 6 Digts are Required"),

                        
                        })}

                        onSubmit={values => {
                          //console.log(isEdit);

                          const ClientLeadDetails = clietDetail;

                        
                            const updatePrimary = {
                          id: clietDetail?.primaryContact?.id,
                          clientContactId: clietDetail?.primaryContact?.clientContactId,
                          clientId:clietDetail?.primaryContact?.clientId,
                          contactFName:      values.contactFName,
                          contactLName: values.contactLName,
                          petName:values.petName,
                          birthDay: values.birthDay,
                          title: values.title,
                          anniversary: values.anniversary,
                          cContactId:clietDetail?.primaryContact?.cContactId,
                          cContactIsActive:clietDetail?.primaryContact?.cContactIsActive,
                          birthDay:clietDetail?.primaryContact?.birthDay,
                          anniversary:clietDetail?.primaryContact?.anniversary,
                          organization:values.organization,
                          jobTitle:values.jobTitle,
                          industry:values.industry,
                          cContact:{
                            id : clietDetail?.primaryContact?.cContact?.id,
                            communicationId : clietDetail?.primaryContact?.cContact?.communicationId,
                            phone :  values.phone,
                            phoneExt :  values.phoneExt,
                            email :  values.email,
                            mobile :  values.mobile,
                            office :  values.office,
                            fax :  values.fax,

                            secondaryEmail : clietDetail?.primaryContact?.cContact?.secondaryEmail,
                            secondaryPhone : clietDetail?.primaryContact?.cContact?.secondaryPhone,
                            secondaryMobile : clietDetail?.primaryContact?.cContact?.secondaryMobile,
                            createdBy : clietDetail?.primaryContact?.cContact?.createdBy,
                            createdDate : clietDetail?.primaryContact?.cContact?.createdDate,
                            lastModifiedBy : clietDetail?.primaryContact?.cContact?.lastModifiedBy,
                            lastModifiedDate : clietDetail?.primaryContact?.cContact?.lastModifiedDate,
                          },
                         
                        };
                            onUpdateClientProfile(clietDetail);
                            clietDetail.primaryContact = updatePrimary;
                            console.log(clietDetail)
                        
                          
                        }}
                      >
                        {({ errors, touched, values, handleChange, setFieldValue }) => (
                          <Form
                          className="needs-validation"
                        >
                          {/* <h5 className="card-title">Primary Contact Details</h5> */}
              
              <Row>
                   <Col sm="1">
                   <FormGroup className="mb-3">
                       <Label htmlFor="title">
                         {this.props.t("Title*")}
                       </Label>
                       <Field as="select" name="title" 
                       className={
                         "form-control" +
                         (errors.title && touched.title
                           ? " is-invalid"
                           : "")
                       }
                       
                       onChange={handleChange}>
                           <option value="">Select</option>
                               {
                                 this.state.titles
                                   .map(title =>
                                     <option key={title.id} value={title.id}>{title.fieldValueName}</option>
                                   )
                               }
                       </Field>
                       <ErrorMessage
                         name="primaryTitle"
                         component="div"
                         className="invalid-feedback"
                       />
                     </FormGroup>
                   </Col>

                   <Col sm="3">
                   <FormGroup className="mb-3">
                       <Label htmlFor="contactFName">
                         {this.props.t("First Name*")}
                       </Label>
                       <Field
                         name="contactFName"
                         onChange={handleChange}  
                            
                         type="text"
                         
                         className={
                           "form-control" +
                           (errors.contactFName && touched.contactFName
                             ? " is-invalid"
                             : "")
                         }
                        
                         id="contactFName"
                       />
                        <ErrorMessage
                         name="contactFName"
                         component="div"
                         className="invalid-feedback"
                       />
                     </FormGroup>
                   </Col>
                   <Col sm="3">
                   <FormGroup className="mb-3">
                       <Label htmlFor="contactLName">
                         {this.props.t("Last Name")}
                       </Label>
                       <Field
                         name="contactLName"
                         onChange={handleChange}                            
                         type="text"
                         
                         className={
                           "form-control" +
                           (errors.contactLName && touched.contactLName
                             ? " is-invalid"
                             : "")
                         }
                        
                         id="contactLName"
                       />
                        <ErrorMessage
                         name="contactLName"
                         component="div"
                         className="invalid-feedback"
                       />
                     </FormGroup>
                   </Col>    
                   <Col sm="3">
                   <FormGroup className="mb-3">
                       <Label htmlFor="petName">
                         {this.props.t("Pet Name ")}
                       </Label>
                       <Field
                         name="petName"
                         onChange={handleChange}                            
                         type="text"
                         
                         className={
                           "form-control" +
                           (errors.petName && touched.petName
                             ? " is-invalid"
                             : "")
                         }
                        
                         id="petName"
                       />
                        <ErrorMessage
                         name="petName"
                         component="div"
                         className="invalid-feedback"
                       />
                     </FormGroup>
                   </Col>     

                   <Col sm="2">
                   <FormGroup className="mb-3">
                       <Label htmlFor="mobile">
                         {this.props.t("Mobile*")}
                       </Label>
                       <Field
                         name="mobile"
                         onChange={handleChange}                            
                         type="text"
                         
                         className={
                           "form-control" +
                           (errors.mobile && touched.mobile
                             ? " is-invalid"
                             : "")
                         }
                        
                         id="mobile"
                       />
                        <ErrorMessage
                         name="mobile"
                         component="div"
                         className="invalid-feedback"
                       />
                     </FormGroup>
                   </Col>
             
               <Col sm="2">
                   <FormGroup className="mb-3">
                       <Label htmlFor="phone">
                         {this.props.t("Home Phone")}
                       </Label>
                       <Field
                         name="phone"
                        
                         onChange={handleChange}                            
                         type="text"
                         
                         className={
                           "form-control" +
                           (errors.phone && touched.phone
                             ? " is-invalid"
                             : "")
                         }
                        
                         id="phone"
                       />
                        <ErrorMessage
                         name="phone"
                         component="div"
                         className="invalid-feedback"
                       />
                     </FormGroup>
                   </Col>     
                       <Col sm="3">
                       <FormGroup className="mb-3">
                       <Label htmlFor="email">
                         {this.props.t("Email*")}
                       </Label>
                       <Field
                         name="email"
                         onChange={handleChange}                            
                         type="text"
                        
                         className={
                           "form-control" +
                           (errors.email && touched.email
                             ? " is-invalid"
                             : "")
                         }
                        
                         id="email"
                       />
                        <ErrorMessage
                         name="email"
                         component="div"
                         className="invalid-feedback"
                       />
                     </FormGroup>

                       </Col>
                       <Col sm="3">
                       <FormGroup className="mb-3">
                       <Label htmlFor="organization">
                         {this.props.t("Company Name")}
                       </Label>
                       <Field
                         name="organization"
                         onChange={handleChange}                            
                         type="text"
                         
                         className={
                           "form-control" +
                           (errors.organization && touched.organization
                             ? " is-invalid"
                             : "")
                         }
                        
                         id="organization"
                       />
                        <ErrorMessage
                         name="organization"
                         component="div"
                         className="invalid-feedback"
                       />
                     </FormGroup>

                       </Col>

                       <Col sm="3">
                       <FormGroup className="mb-3">
                       <Label htmlFor="jobTitle">
                         {this.props.t("Job Title")}
                       </Label>
                       <Field
                         name="jobTitle"
                         onChange={handleChange}                            
                         type="text"
                         
                         className={
                           "form-control" +
                           (errors.jobTitle && touched.jobTitle
                             ? " is-invalid"
                             : "")
                         }
                        
                         id="jobTitle"
                       />
                        <ErrorMessage
                         name="jobTitle"
                         component="div"
                         className="invalid-feedback"
                       />
                     </FormGroup>

                       </Col>

                       <Col sm="2">
                       <FormGroup className="mb-3">
                       <Label htmlFor="office">
                         {this.props.t("Office Phone")}
                       </Label>
                       <Field
                         name="office"
                         onChange={handleChange}                            
                         type="text"
                         
                         className={
                           "form-control" +
                           (errors.office && touched.office
                             ? " is-invalid"
                             : "")
                         }
                        
                         id="office"
                       />
                        <ErrorMessage
                         name="office"
                         component="div"
                         className="invalid-feedback"
                       />
                     </FormGroup>

                       </Col>
                       <Col sm="1">
                       <FormGroup className="mb-3">
                       <Label htmlFor="phoneExt">
                         {this.props.t("Ext")}
                       </Label>
                       <Field
                         name="phoneExt"
                         onChange={handleChange}                            
                         type="text"
                         
                         className={
                           "form-control" +
                           (errors.phoneExt && touched.phoneExt
                             ? " is-invalid"
                             : "")
                         }
                        
                         id="phoneExt"
                       />
                        <ErrorMessage
                         name="phoneExt"
                         component="div"
                         className="invalid-feedback"
                       />
                     </FormGroup>

                       </Col>

                       <Col sm="3">
                       <FormGroup className="mb-3">
                       <Label htmlFor="industry">
                         {this.props.t("Industry")}
                       </Label>
                       <Field
                         name="industry"
                         onChange={handleChange}                            
                         type="text"
                         
                         className={
                           "form-control" +
                           (errors.industry && touched.industry
                             ? " is-invalid"
                             : "")
                         }
                        
                         id="industry"
                       />
                        <ErrorMessage
                         name="industry"
                         component="div"
                         className="invalid-feedback"
                       />
                     </FormGroup>

                       </Col>
                       <div className="clearfix"><SaveButton>Save</SaveButton></div>

                       </Row>
                          </Form>
                           )}
                           </Formik>
                          </CardBody>
                        </Card>

{/* 
              <Formik
                  enableReinitialize={true}
                  initialValues={{
                    id: clietDetail.id,
                    clientId: clietDetail.clientId,
                    agentId:clietDetail.agentId,
                    userLogin:clietDetail.userLogin,
                    title:      clietDetail.title,
                    firstName: clietDetail.firstName,
                    lastName: clietDetail.lastName,
                    clientPropertyTypeId:clietDetail.clientPropertyTypeId,
                    clientTypeId: clietDetail.clientTypeId,
                    clientSourceId: clietDetail.clientSourceId,
                    primaryContactId:clietDetail.primaryContactId,
                    secondaryContactId:clietDetail.secondaryContactId,
                    addressId:clietDetail.addressId,
                    isActive:clietDetail.isActive,
                    referedBy:clietDetail.referedBy,
                    howOffenClient:clietDetail.howOffenClient,
                    notes:clietDetail.notes,

                    fullAddress:clietDetail.clientAddress.fullAddress,
                    addressLine1:clietDetail.clientAddress.addressLine1,
                    addressLine2:clietDetail.clientAddress.addressLine2,
                    nearestIntersection:clietDetail.clientAddress.nearestIntersection,
                    city:clietDetail.clientAddress.nearestIntersection,
                    province:clietDetail.clientAddress.province,
                    postal:clietDetail.clientAddress.postal,
                    country:clietDetail.clientAddress.country,
                    longitude:clietDetail.clientAddress.longitude,
                    latitude:clietDetail.clientAddress.latitude,

                    primaryTitle:clietDetail.primaryContact.title ?? '',
                    contactFName: clietDetail.primaryContact.contactFName,
                    contactLName: clietDetail.primaryContact.contactLName,
                    organization:clietDetail.primaryContact.organization,
                    jobTitle:clietDetail.primaryContact.jobTitle,
                    industry:clietDetail.primaryContact.industry,


                    mobile: clietDetail.primaryContact.cContact.mobile,
                    email: clietDetail.primaryContact.cContact.email,
                    phone: clietDetail.primaryContact.cContact.phone,
                    office: clietDetail.primaryContact.cContact.office,
                    phoneExt: clietDetail.primaryContact.cContact.phoneExt ??'',

                    secondaryTitle:clietDetail.secondaryContact.title ?? '',
                    secondaryContactFName:clietDetail.secondaryContact.contactFName,
                    secondaryContactLName:clietDetail.secondaryContact.contactLName,
                    secondaryOrganization:clietDetail.secondaryContact.organization,
                    secondaryJobTitle:clietDetail.secondaryContact.jobTitle,
                    secondaryIndustry:clietDetail.secondaryContact.industry,

                    secondaryMobile:clietDetail.secondaryContact.mobile,
                    secondaryEmail:clietDetail.secondaryContact.email,
                    secondaryOffice:clietDetail.secondaryContact.office,
                    secondaryPhoneExt:clietDetail.secondaryContact.phoneExt ??'',
                   

                  }}
                  validationSchema={Yup.object().shape({
                    clientTypeId: Yup.string().required("This is Required"),
                    title: Yup.string().required("This is Required"),
                    primaryTitle : Yup.string().required("This is Required"),
                    contactFName :Yup.string().max(100,"100 Characters are allowed").required("This is Required"),
                    contactLName : Yup.string().max(100,"100 Characters are allowed"),
                    mobile : Yup.string().min(10,"10 Digits are allowed").max(10,"10 Digits are allowed").required("This is Required"),
                    phone : Yup.string().min(10,"10 Digits are allowed").max(10,"10 Digits are allowed"),
                    email : Yup.string().max(100,"100 Characters are allowed").email().required("This is Required"),
                    office : Yup.string().min(10,"10 Digits are allowed").max(10,"10 Digits are allowed"),
                    phoneExt : Yup.string().min(3,"Min. 3 Digts are Required").max(6,"Max. 6 Digts are Required"),
                    secondaryTitle :  Yup.string().required("This is Required"),
                    secondaryContactFName : Yup.string().max(100,"100 Characters are allowed").required("This is Required"),
                    secondaryContactLName : Yup.string().max(100,"100 Characters are allowed"),
                    secondaryMobile : Yup.string().min(10,"10 Digits are allowed").max(10,"10 Digits are allowed").required("This is Required"),
                    secondaryEmail : Yup.string().max(100,"100 Characters are allowed").email().required("This is Required"),
                    secondaryOffice : Yup.string().min(10,"10 Digits are allowed").max(10,"10 Digits are allowed"),
                    secondaryPhoneExt : Yup.string().min(3,"Min. 3 Digts are Required").max(6,"Max. 6 Digts are Required"),
                    notes : Yup.string().max(2000,"Max. 2000 Characters are allowed").required("This is Required"),

                   
                  })}

                  onSubmit={values => {
                    //console.log(isEdit);
                   
                      const updateClient = {
                    id: values.id,
                    clientId: values.clientId,
                    agentId:values.agentId,
                    //userLogin:values.userLogin,
                    title:      values.title,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    clientPropertyTypeId:values.clientPropertyTypeId,
                    clientTypeId: values.clientTypeId,
                    clientSourceId: values.clientSourceId,
                    primaryContactId:values.primaryContactId,
                    secondaryContactId:values.secondaryContactId,
                    addressId:values.addressId,
                    isActive:values.isActive,
                    referedBy:values.referedBy,
                    howOffenClient:values.howOffenClient,
                    notes:values.notes,

                    fullAddress:values.fullAddress,
                    addressLine1:values.addressLine1,
                    addressLine2:values.addressLine2,
                    nearestIntersection:values.nearestIntersection,
                    city:values.nearestIntersection,
                    province:values.province,
                    postal:values.postal,
                    country:values.country,
                    longitude:values.longitude,
                    latitude:values.latitude,

                    primaryTitle:values.title,
                    contactFName: values.contactFName,
                    contactLName: values.contactLName,
                    organization:values.organization,
                    jobTitle:values.jobTitle,
                    industry:values.industry,


                    mobile: values.mobile,
                    email: values.email,
                    phone: values.phone,
                    office: values.office,
                    phoneExt: values.phoneExt,

                    secondaryTitle:values.secondaryTitle,
                    secondaryContactFName:values.secondaryContactFName,
                    secondaryContactLName:values.secondaryContactLName,
                    secondaryOrganization:values.secondaryOrganization,
                    secondaryJobTitle:values.secondaryJobTitle,
                    secondaryIndustry:values.secondaryIndustry,

                    secondaryMobile:values.secondaryMobile,
                    secondaryEmail:values.secondaryEmail,
                    secondaryOffice:values.secondaryOffice,
                    secondaryPhoneExt:values.secondaryPhoneExt,
                  };
                      onUpdateClientProfile(updateClient);
                  
                    
                  }}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
             

             <Card> 
                    <CardBody>
                    <h5 className="card-title">Client Type</h5>
                    <Row>
                        <Col sm="4">
                        <FormGroup className="mb-3">
                            <Label htmlFor="clientTypeId">
                              {this.props.t("Type")}
                            </Label>
                            <Field as="select" name="clientTypeId" 
                            className={
                              "form-control" +
                              (errors.clientTypeId && touched.clientTypeId
                                ? " is-invalid"
                                : "")
                            }
                            
                            onChange={handleChange}>
                                <option value="">Select</option>
                                    {
                                      this.state.clientTypes
                                        .map(clientType =>
                                          <option key={clientType.id} value={clientType.id}>{clientType.leadTypeTitle}</option>
                                        )
                                    }
                            </Field>
                            <ErrorMessage
                              name="clientTypeId"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>

                        <Col sm="4">
                        <FormGroup className="mb-3">
                            <Label htmlFor="howOffenClient">
                              {this.props.t("How did you hear about us?")}
                            </Label>
                            <Field as="select" name="howOffenClient" 
                            className={
                              "form-control" +
                              (errors.howOffenClient && touched.howOffenClient
                                ? " is-invalid"
                                : "")
                            }
                            
                            onChange={handleChange}>
                                <option value="">Select</option>
                                    {
                                      this.state.sources
                                        .map(source =>
                                          <option key={source.id} value={source.id}>{source.leadSourceTitle}</option>
                                        )
                                    }
                            </Field>
                            <ErrorMessage
                              name="clientTypeId"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>
                        </Row>

                    </CardBody>

              </Card>   
                <Card> 
                    <CardBody>
                    <h5 className="card-title">Primary Contact Details</h5>
              
                   <Row>
                        <Col sm="1">
                        <FormGroup className="mb-3">
                            <Label htmlFor="primaryTitle">
                              {this.props.t("Title*")}
                            </Label>
                            <Field as="select" name="primaryTitle" 
                            className={
                              "form-control" +
                              (errors.primaryTitle && touched.primaryTitle
                                ? " is-invalid"
                                : "")
                            }
                            
                            onChange={handleChange}>
                                <option value="">Select</option>
                                    {
                                      this.state.titles
                                        .map(title =>
                                          <option key={title.id} value={title.id}>{title.fieldValueName}</option>
                                        )
                                    }
                            </Field>
                            <ErrorMessage
                              name="primaryTitle"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>

                        <Col sm="3">
                        <FormGroup className="mb-3">
                            <Label htmlFor="contactFName">
                              {this.props.t("First Name*")}
                            </Label>
                            <Field
                              name="contactFName"
                              onChange={handleChange}  
                                 
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.contactFName && touched.contactFName
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="contactFName"
                            />
                             <ErrorMessage
                              name="contactFName"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="3">
                        <FormGroup className="mb-3">
                            <Label htmlFor="contactLName">
                              {this.props.t("Last Name")}
                            </Label>
                            <Field
                              name="contactLName"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.contactLName && touched.contactLName
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="contactLName"
                            />
                             <ErrorMessage
                              name="contactLName"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>     
                        <Col sm="2">
                        <FormGroup className="mb-3">
                            <Label htmlFor="mobile">
                              {this.props.t("Mobile*")}
                            </Label>
                            <Field
                              name="mobile"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.mobile && touched.mobile
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="mobile"
                            />
                             <ErrorMessage
                              name="mobile"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>
                  
                    <Col sm="2">
                        <FormGroup className="mb-3">
                            <Label htmlFor="homePhone">
                              {this.props.t("Home Phone")}
                            </Label>
                            <Field
                              name="phone"
                             
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.phone && touched.phone
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="phone"
                            />
                             <ErrorMessage
                              name="phone"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>     
                 
                        </Row>
                            <Row>
                            <Col sm="3">
                            <FormGroup className="mb-3">
                            <Label htmlFor="email">
                              {this.props.t("Email*")}
                            </Label>
                            <Field
                              name="email"
                              onChange={handleChange}                            
                              type="text"
                             
                              className={
                                "form-control" +
                                (errors.email && touched.email
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="email"
                            />
                             <ErrorMessage
                              name="email"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>

                            </Col>

                            <Col sm="9">
                            <FormGroup className="mb-3">
                            <Label htmlFor="address">
                              {this.props.t("Address")}
                            </Label>
                            <Field
                              name="address"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.address && touched.address
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="address"
                            />
                             <ErrorMessage
                              name="address"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>

                            </Col>

                            </Row>

                            <Row>
                            <Col sm="3">
                            <FormGroup className="mb-3">
                            <Label htmlFor="organization">
                              {this.props.t("Company Name")}
                            </Label>
                            <Field
                              name="organization"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.organization && touched.organization
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="organization"
                            />
                             <ErrorMessage
                              name="organization"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>

                            </Col>

                            <Col sm="3">
                            <FormGroup className="mb-3">
                            <Label htmlFor="jobTitle">
                              {this.props.t("Job Title")}
                            </Label>
                            <Field
                              name="jobTitle"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.jobTitle && touched.jobTitle
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="jobTitle"
                            />
                             <ErrorMessage
                              name="jobTitle"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>

                            </Col>

                            <Col sm="2">
                            <FormGroup className="mb-3">
                            <Label htmlFor="office">
                              {this.props.t("Office Phone")}
                            </Label>
                            <Field
                              name="office"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.office && touched.office
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="office"
                            />
                             <ErrorMessage
                              name="office"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>

                            </Col>
                            <Col sm="1">
                            <FormGroup className="mb-3">
                            <Label htmlFor="phoneExt">
                              {this.props.t("Ext")}
                            </Label>
                            <Field
                              name="phoneExt"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.phoneExt && touched.phoneExt
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="phoneExt"
                            />
                             <ErrorMessage
                              name="phoneExt"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>

                            </Col>

                            <Col sm="3">
                            <FormGroup className="mb-3">
                            <Label htmlFor="industry">
                              {this.props.t("Industry")}
                            </Label>
                            <Field
                              name="industry"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.industry && touched.industry
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="industry"
                            />
                             <ErrorMessage
                              name="industry"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>

                            </Col>

                            </Row>
             
             </CardBody>
                </Card>


             


                <Card> 
                    <CardBody>
                    <h5 className="card-title">Secondary Contact Details</h5>
              
                   <Row>
                        <Col sm="1">
                        <FormGroup className="mb-3">
                            <Label htmlFor="secondaryTitle">
                              {this.props.t("Title*")}
                            </Label>
                            <Field as="select" name="secondaryTitle" className={
                                "form-control" +
                                (errors.secondaryTitle && touched.secondaryTitle
                                  ? " is-invalid"
                                  : "")
                              } onChange={handleChange}>
                            <option value="">Select</option>
                            {
                                      this.state.titles
                                        .map(title =>
                                          <option key={title.id} value={title.id}>{title.fieldValueName}</option>
                                        )
                                    }
                            </Field>
                            <ErrorMessage
                              name="secondaryTitle"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>

                        <Col sm="3">
                        <FormGroup className="mb-3">
                            <Label htmlFor="secondaryContactFName">
                              {this.props.t("First Name")}
                            </Label>
                            <Field
                              name="secondaryContactFName"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.secondaryContactFName && touched.secondaryContactFName
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="secondaryContactFName"
                            />
                             <ErrorMessage
                              name="secondaryContactFName"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="3">
                        <FormGroup className="mb-3">
                            <Label htmlFor="secondaryContactLName">
                              {this.props.t("Last Name")}
                            </Label>
                            <Field
                              name="secondaryContactLName"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.secondaryContactLName && touched.secondaryContactLName
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="secondaryContactLName"
                            />
                             <ErrorMessage
                              name="secondaryContactLName"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>     
                        <Col sm="2">
                        <FormGroup className="mb-3">
                            <Label htmlFor="secondaryMobile">
                              {this.props.t("Mobile*")}
                            </Label>
                            <Field
                              name="secondaryMobile"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.secondaryMobile && touched.secondaryMobile
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="secondaryMobile"
                            />
                             <ErrorMessage
                              name="secondaryMobile"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>
                  
                        <Col sm="2">
                        <FormGroup className="mb-3">
                            <Label htmlFor="secondaryEmail">
                              {this.props.t("Email*")}
                            </Label>
                            <Field
                              name="secondaryEmail"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.secondaryEmail && touched.secondaryEmail
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="secondaryEmail"
                            />
                             <ErrorMessage
                              name="secondaryEmail"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>

                            </Col>

                 
                        </Row>
                           
                            <Row>
                            <Col sm="3">
                            <FormGroup className="mb-3">
                            <Label htmlFor="secondaryOrganization">
                              {this.props.t("Company Name")}
                            </Label>
                            <Field
                              name="secondaryOrganization"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.secondaryOrganization && touched.secondaryOrganization
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="secondaryOrganization"
                            />
                             <ErrorMessage
                              name="secondaryOrganization"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>

                            </Col>

                            <Col sm="3">
                            <FormGroup className="mb-3">
                            <Label htmlFor="secondaryJobTitle">
                              {this.props.t("Job Title")}
                            </Label>
                            <Field
                              name="secondaryJobTitle"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.secondaryJobTitle && touched.secondaryJobTitle
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="secondaryJobTitle"
                            />
                             <ErrorMessage
                              name="secondaryJobTitle"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>

                            </Col>

                            <Col sm="2">
                            <FormGroup className="mb-3">
                            <Label htmlFor="secondaryOffice">
                              {this.props.t("Office Phone")}
                            </Label>
                            <Field
                              name="secondaryOffice"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.secondaryOffice && touched.secondaryOffice
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="secondaryOffice"
                            />
                             <ErrorMessage
                              name="secondaryOffice"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>

                            </Col>
                            <Col sm="1">
                            <FormGroup className="mb-3">
                            <Label htmlFor="secondaryPhoneExt">
                              {this.props.t("Ext")}
                            </Label>
                            <Field
                              name="secondaryPhoneExt"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.secondaryPhoneExt && touched.secondaryPhoneExt
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="secondaryPhoneExt"
                            />
                             <ErrorMessage
                              name="secondaryPhoneExt"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>

                            </Col>

                            <Col sm="3">
                            <FormGroup className="mb-3">
                            <Label htmlFor="secondaryIndustry">
                              {this.props.t("Industry")}
                            </Label>
                            <Field
                              name="secondaryIndustry"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.secondaryIndustry && touched.secondaryIndustry
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="secondaryIndustry"
                            />
                             <ErrorMessage
                              name="secondaryIndustry"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>

                            </Col>

                            </Row>
             
             </CardBody>
                </Card>

                <Card> 
                    <CardBody>
                    <h5 className="card-title">Notes / Comments*</h5>
              
                   <Row>
                        <Col sm="12">
                        <FormGroup className="mb-3">
                            
                            <Field
                              name="notes"
                              as="textarea"
                             
                              className={
                                "form-control" +
                                (errors.notes && touched.notes
                                  ? " is-invalid"
                                  : "")
                              }
                             
                             
                              id="notes"
                            />
                             <ErrorMessage
                              name="notes"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>
                        <div className="clearfix"><button type="submit" className="btn btn-primary w-md float-left mt-27">Save</button></div>                 
                    </Row>
                      
             </CardBody>
                </Card>

</Form>
)}
</Formik>
<Formik
                  enableReinitialize={true}
                  initialValues={{
                    clientId: this.props.match.params.id,
                    agentId: localStorage.getItem('userId'),
                    dateTitle: (this.state && this.state.dateTitle) || "",
                    dateMonth: (this.state && this.state.dateMonth) || "",
                    dateDay: (this.state && this.state.dateDay) || "",
                   
                   

                  }}
                  validationSchema={Yup.object().shape({
                    dateTitle: Yup.string().required("This is Required"),
                    dateMonth: Yup.string().required("This is Required"),
                    dateDay: Yup.string().required("This is Required"),
                   
                  })}

                  onSubmit={this.handleImportantDateSubmit}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => 
                    {const selectedMonth = values.dateMonth ;
                    const selectedYear = new Date().getFullYear();
                    const daysInMonth = selectedMonth ? new Date(selectedYear, selectedMonth, 0).getDate() : 31;
                    return(
                    <Form
                      className="needs-validation"
                    >
                <Card> 
                    <CardBody>
                    <h5 className="card-title">Important dates </h5>
                     <Row>
                          <Col sm="3">
                          <FormGroup className="mb-3">
                            <Label htmlFor="dateTitle">
                              {this.props.t("Type of date")}
                            </Label>
                            <Field
                              name="dateTitle"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.dateTitle && touched.dateTitle
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="dateTitle"
                            />
                             <ErrorMessage
                              name="dateTitle"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>

                          </Col>

                          <Col sm="3">
                          <FormGroup className="mb-3">
                            <Label htmlFor="dateMonth">
                              {this.props.t("Month")}
                            </Label>
                            <Field as="select" name="dateMonth" className={`form-control${errors.dateMonth && touched.dateMonth ? " is-invalid" : ""}`} onChange={(e) => {
                              handleChange(e);
                              const selectedMonth = parseInt(e.target.value, 10);
                              // setFieldValue("dateDay", ''); // Reset day value
                              setFieldValue("dateMonth", e.target.value); // Set month value
                            }}>
                              <option value="">Select</option>
                              {[...Array(12)].map((_, index) => (
                                <option key={index + 1} value={index + 1}>{this.props.t(new Date(2000, index, 1).toLocaleString(undefined, { month: 'long' }))}</option>
                              ))}
                            </Field>
                             <ErrorMessage
                              name="dateMonth"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>

                          </Col>

                          <Col sm="3">
                          <FormGroup className="mb-3">
                            <Label htmlFor="dateDay">
                              {this.props.t("Day")}
                            </Label>
                            <Field as="select" name="dateDay" className={`form-control${errors.dateDay && touched.dateDay ? " is-invalid" : ""}`} onChange={handleChange}>
                                <option value="">Select</option>
                                {[...Array(daysInMonth)].map((_, index) => (
                                  <option key={index + 1} value={index + 1}>{index + 1}</option>
                                ))}
                              </Field>
                             <ErrorMessage
                              name="dateMonth"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>

                          </Col>
                          <Col sm="1">
                          <button type="submit" className="btn btn-primary mt-27"><i className=" bx bx-plus-medical"></i></button>

                          </Col>
                    </Row>           
                   
                   <Row>
                    <Col sm="12">
                    <h5 className="card-title">Importants Date</h5>
					<div className="table-responsive">
          <table className="table table-bordered border-primary">
              <thead className="">
							  <tr>
								<th style={{width:"10%"}}>#</th>
								<th style={{width:"80%"}}>Title</th>
								<th style={{width:"80%"}}>Month</th>
                <th style={{width:"80%"}}>Day</th>             
								<th style={{width:"10%"}}>Action</th>
							  </tr>
							</thead>
							<tbody>
              {map(importantdates, (importantdate, datekey) => (
							  <tr key={"_note_" + datekey}>
								<td>{ datekey + 1 }</td>
								<td> {importantdate.dateTitle} </td>
                <td> {importantdate.dateMonth} </td>
                <td> {importantdate.dateDay} </td>
								
								<td><Link to="#" onClick={() => this.onClickDelete(importantdate)}  className="btn-del"><i className="bx bxs-trash"></i></Link></td>
							  </tr>
              ))} 
							</tbody>
						  </table>
						</div>
                    </Col>
                   </Row>
             </CardBody>
                </Card>
  </Form> 
  )}}
  </Formik>

  <Formik
                  enableReinitialize={true}
                  initialValues={{
                    
                    clientId: this.props.match.params.id,
                    childName: (this.state && this.state.dateTitle) || "",
                    birthDay: (this.state && this.state.birthDay) || "",
  
                  }}
                  validationSchema={Yup.object().shape({
                    childName: Yup.string().required("This is Required"),
                    birthDay: Yup.string().required("This is Required"),
                  })}

                  onSubmit={this.handleChildSubmit}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
                <Card> 
                    <CardBody>
                    <h5 className="card-title">Add children</h5>
                     <Row>
                          <Col sm="3">
                          <FormGroup className="mb-3">
                            <Label htmlFor="childName">
                              {this.props.t("Child Name")}
                            </Label>
                            <Field
                              name="childName"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.childName && touched.childName
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="childName"
                            />
                             <ErrorMessage
                              name="childName"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>

                          </Col>

                          <Col sm="3">
                          <FormGroup className="mb15">

                              <Label htmlFor="birthDay">
                                {this.props.t("Brith Day")}
                              </Label>
                              <Flatpickr
                                className={
                                  "form-control" +
                                  (errors.birthDay && touched.birthDay
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder=""
                                
                                onChange={(value) => setFieldValue('birthDay', value[0])}
                                options={{
                                  altInput: true,
                                  altFormat: "F j, Y",
                                  dateFormat: "Y-m-d"
                                }}
                              />
                              <ErrorMessage
                                name="birthDay"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>

                          </Col>

                         
                          <Col sm="1">
                          <button type="submit" className="btn btn-primary mt-27"><i className=" bx bx-plus-medical"></i></button>

                          </Col>
                    </Row>           
                    <Row>
                    <Col sm="12">
                    <h5 className="card-title">Childs</h5>
					<div className="table-responsive">
          <table className="table table-bordered border-primary">
              <thead className="">
							  <tr>
								<th style={{width:"10%"}}>#</th>
								<th style={{width:"80%"}}>Name</th>
								<th style={{width:"80%"}}>BirthDay</th>            
								<th style={{width:"10%"}}>Action</th>
							  </tr>
							</thead>
							<tbody>
              {map(childs, (child, childkey) => (
							  <tr key={"_note_" + childkey}>
								<td>{ childkey + 1 }</td>
								<td> {child.childName} </td>
                <td> <Moment format="D MMM YY">{child.birthDay}</Moment> </td>
               
								
								<td><Link to="#" onClick={() => this.onClickDelete(child)}  className="btn-del"><i className="bx bxs-trash"></i></Link></td>
							  </tr>
              ))} 
							</tbody>
						  </table>
						</div>
                    </Col>
                   </Row>
             </CardBody>
                </Card>
  </Form>
  )}
  </Formik> */}
            
            </Col>
            
            </Row>
            </Col>
            </Row>
           
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
ClientProfile.propTypes = {
  t: PropTypes.any,
  history:PropTypes.object,
  match: PropTypes.object,
  onGetClientDetail:PropTypes.func,
  clietDetail: PropTypes.object,
  addClientImportantDate:PropTypes.func,
  addClientChild:PropTypes.func,
  onGetClientImportantDate:PropTypes.func,
  onGetClientChild:PropTypes.func,
  importantdates:PropTypes.array,
  childs:PropTypes.array,
  onDeleteImportantDate:PropTypes.func,
  loading:PropTypes.object,
  onUpdateClientProfile:PropTypes.func

}
const mapStateToProps = ({ Clients }) => (
  {
    loading: Clients.loading,
    clietDetail: Clients.clietDetail,
    importantdates:Clients.importantdates,
    childs:Clients.childs
    

  })
const mapDispatchToProps = dispatch => ({
  onGetClientDetail: clientId => dispatch(getClientDetail(clientId)),
  addClientImportantDate:(data) => dispatch(addClientImportantDate(data)),
  addClientChild:(data) => dispatch(addClientChild(data)),
  onGetClientImportantDate: clientId => dispatch(getClientImportantDate(clientId)),
  onGetClientChild: clientId => dispatch(getClientChild(clientId)),
  onDeleteImportantDate : id => dispatch(deleteClientImportant(id)),
  onUpdateClientProfile: (data) => dispatch(updateClientProfile(data))

});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ClientProfile))
