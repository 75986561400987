
import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import MetaTags from "react-meta-tags";
import { withTranslation } from "react-i18next";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Container, Row, Col, Card, CardBody, FormGroup, Label } from 'reactstrap';
import SaveButton from 'components/buttons/save';
import { API_URL } from "../../../helpers/app_url";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LeadData from "./../forms/lead-detail"
import LeadDetailMenu from '../menu/lead-detail-menu';
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { getLeadDetail } from "../../../store/actions";
class BuyerQuestionAnswer extends Component {
    constructor(props) {
        super(props);
        this.state = {
           locations:[],
           decisionStage: [],
           mortgageStatus: [],
           reason: [],
           buyersQuestions:[],
           buyerAnswers:[],
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentDidMount() {
        const { match: { params }, onGetLeadDetail} = this.props;
        if (params && params.id && params.leadId) {
        onGetLeadDetail(params.leadId);
        }
        const obj = JSON.parse(localStorage.getItem("authUser"));
        const token = obj.resultData.bearerToken;
        const str =  `Bearer ${token}`;
        const authHeader = { Authorization: `Bearer ${token}` };
        axios
        .get(`${API_URL}QualificationQuestion/allagentQualificationQuestionsAnswers?agentId=${localStorage.getItem("userId")}`, { headers: authHeader })
        .then((res) => {
            const data = res.data;
            this.setState({
              
              locations: data.find(q => q.question === 'Location') || [],
              decisionStage: data.find(q => q.question === 'Decision Stage') || [],
              mortgageStatus: data.find(q => q.question === 'Mortgage Status') || [],
              reason: data.find(q => q.question === 'Reason') || []
            });
        });
          axios.get( API_URL + `QuestionAnswer/allforagenttype`, {
            params: {
              forType: 1,

            }, headers: { Authorization: str }
          })
            .then(res => {
              const buyersQuestions = res.data;
              this.setState({ buyersQuestions });
            });
            axios.get( API_URL + `LeadQuestionAnswer/all`, {
              params: {
                LeadId: this.props.match.params.id,
  
              }, headers: { Authorization: str }
            })
              .then(res => {
                const buyerAnswers = res.data;
                this.setState({ buyerAnswers });
              });
      }
      handleSubmit(values) {
        const formattedData = {
            buyerQuestionAnswers: this.state.buyersQuestions.map(question => ({
                leadId: parseInt(this.props.match.params.id),
                questionAnswerId: question.id || 0,
                answersOption: values.questionAnswers[question.id]?.answersOption || '',
                isActive: true
            }))
            .filter(item => typeof item.answersOption === 'string' && item.answersOption.trim() !== ''),
    
            questionAnswers:[
              {
                "id": 0,
                "leadQualificationQuestionAnswerId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
                "questionAnswerId": 0,
                "questionPoint": 0,
                "leadId": parseInt(this.props.match.params.id),
                "answerOption": 0,
                "answerPoint": 0,
                "isActive": true,
                "questionAnswerIdPoint" : values.location
              },
              {
                "id": 0,
                "leadQualificationQuestionAnswerId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
                "questionAnswerId": 0,
                "questionPoint": 0,
                "leadId": parseInt(this.props.match.params.id),
                "answerOption": 0,
                "answerPoint": 0,
                "isActive": true,
                "questionAnswerIdPoint" : values.decisionStage
              },
              {
                "id": 0,
                "leadQualificationQuestionAnswerId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
                "questionAnswerId": 0,
                "questionPoint": 0,
                "leadId": parseInt(this.props.match.params.id),
                "answerOption": 0,
                "answerPoint": 0,
                "isActive": true,
                "questionAnswerIdPoint" : values.mortgageStatus
              },
              {
                "id": 0,
                "leadQualificationQuestionAnswerId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
                "questionAnswerId": 0,
                "questionPoint": 0,
                "leadId": parseInt(this.props.match.params.id),
                "answerOption": 0,
                "answerPoint": 0,
                "isActive": true,
                "questionAnswerIdPoint" : values.reason
              }
            ]
        };
       // console.log(formattedData);
        const obj = JSON.parse(localStorage.getItem("authUser"));
        const token = obj.resultData.bearerToken;
        const str =  `Bearer ${token}`;
        fetch(`${API_URL}LeadQualificationQuestionAnswer/bulkaddwithquesans`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': str,
          },
          body: JSON.stringify(formattedData)
      })
      .then(response => response.json())
      .then(data => {
          console.log('Success:', data);
      })
      .catch(error => {
          console.error('Error:', error);
      });
    }
    
   
  


    render() {
        const { locations, decisionStage, mortgageStatus, reason, buyerAnswers } = this.state;
        const {leads } = this.props;
        return (
          <React.Fragment>
          <ToastContainer autoClose={2000} />
          <div className="page-content project-page-content">
            <MetaTags>
              <title>Lead Detail | Realty Space</title>
            </MetaTags>
            <Container fluid className="ps-0">
           <Row>
            <Col sm="3" className="pe-0">
                <LeadData />
            </Col>
              <Col sm="9" className="ps-0 pe-0">
              <Row className="project-header">
                <Col sm='8'>
                <Breadcrumbs
                  title="Lead"
                  breadcrumbItem="Lead Detail"
                />
           
            </Col>
            <Col sm='4'>
            <h2>{leads.firstName + " " + leads.lastName }</h2>
            </Col>
            </Row>
                 
            <LeadDetailMenu /> 
            
            <Card>
              <CardBody>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    location:'',
                    decisionStage:'',
                    mortgageStatus:'',
                    reason:'',
                    otherLocation: '',
                    questionAnswers: this.state.buyersQuestions.reduce((acc, question) => {
                      const answer = this.state.buyerAnswers?.find(
                        (ans) => ans.questionAnswerId === question.id
                      );
                      acc[question.id] = { answersOption: answer?.answersOption || '' };
                      return acc;
                    }, {}),
                  }}

                validationSchema={ Yup.object().shape({
                    location: Yup.string().required('Required'),
                    decisionStage: Yup.string().required('Required'),
                    mortgageStatus: Yup.string().required('Required'),
                    reason: Yup.string().required('Required'),
                    otherLocation: Yup.string().when('location', {
                      is: (val) => val && val.includes('Other'),
                      then: Yup.string().required('Other Location is required'),
                      otherwise: Yup.string()
                  })
                })}
                onSubmit={this.handleSubmit}
            >
                {({ errors,  touched, values, handleChange, setFieldValue }) => (
                    <Form className="needs-validation">
                        <CardBody>
                        <div className="row mb-3">
                                        <div className="col-sm-3">
                                            <FormGroup>
                                                <Label
                                                    htmlFor="location"
                                                    className="form-label label-text"
                                                >
                                                   Location*
                                                </Label>
                                                <Field
                                                    as="select"
                                                    className={
                                                        "form-control" +
                                                        (errors.location &&
                                                            touched.location
                                                            ? " is-invalid"
                                                            : "")
                                                    }
                                                    name="location"
                                                    id="location"
                                                    onChange={(e) => {
                                                      handleChange(e);
                                                      if (e.target.value === "Other") {
                                                          setFieldValue("otherLocation", "");
                                                      } else {
                                                          setFieldValue("otherLocation", undefined); 
                                                      }
                                                  }}
                                                >
                                                    <option value="">
                                                        Select
                                                    </option>
                                                    {locations?.answers?.map((loc) => (
                                                      <option key={loc.id} value={locations.id+","+loc.id+","+ locations.weightage +","+ loc.scoringValue}>{loc.answer}</option>
                                                  ))}
                                                  <option value="Other">
                                                      Other
                                                    </option>
                                                </Field>
                                                <ErrorMessage
                                                    name="location"
                                                    component="div"
                                                    className="invalid-feedback"
                                                />
                                            </FormGroup>
                                        </div>
                                        {values.location && values.location.includes("Other") && (
                                                  <div className="col-sm-3">
                                                      <FormGroup>
                                                          <Label
                                                              htmlFor="otherLocation"
                                                              className="form-label label-text"
                                                          >
                                                              Other Location*
                                                          </Label>
                                                          <Field
                                                              name="otherLocation"
                                                              type="text"
                                                              className={
                                                                  "form-control" +
                                                                  (errors.otherLocation && touched.otherLocation
                                                                      ? " is-invalid"
                                                                      : "")
                                                              }
                                                          />
                                                          <ErrorMessage
                                                              name="otherLocation"
                                                              component="div"
                                                              className="invalid-feedback"
                                                          />
                                                      </FormGroup>
                                                  </div>
                                              )}        
                                        <div className="col-sm-3 ">
                                            <FormGroup>
                                                <Label
                                                    htmlFor="decisionStage"
                                                    className="form-label label-text "
                                                >
                                                    Decision Stage*
                                                </Label>
                                                <Field as="select" name="decisionStage" className={`form-control${errors.decisionStage && touched.decisionStage ? ' is-invalid' : ''}`}>
                                            <option value="">Select</option>
                                            {decisionStage?.answers?.map((stage) => (
                                                <option key={stage.id} value={decisionStage.id+","+stage.id+","+ decisionStage.weightage +","+ stage.scoringValue}>{stage.answer	}</option>
                                            ))}
                                        </Field>
                                                <ErrorMessage
                                                    name="decisionStage"
                                                    component="div"
                                                    className="invalid-feedback"
                                                />
                                            </FormGroup>
                                        </div>

                                        <div className="col-sm-3  ">
                                            <FormGroup>
                                                <Label
                                                    htmlFor="mortgageStatus"
                                                    className="form-label label-text"
                                                >
                                                    Mortgage Status*
                                                </Label>
                                                <Field as="select" name="mortgageStatus" className={`form-control${errors.mortgageStatus && touched.mortgageStatus ? ' is-invalid' : ''}`}>
                                            <option value="">Select</option>
                                            {mortgageStatus?.answers?.map((status) => (
                                                <option key={status.id} value={mortgageStatus.id+","+status.id+","+ mortgageStatus.weightage +","+ status.scoringValue}>{status.answer}</option>
                                            ))}
                                        </Field>

                                                <ErrorMessage
                                                    name="mortgageStatus"
                                                    component="div"
                                                    className="invalid-feedback"
                                                />
                                            </FormGroup>
                                        </div>

                                        <div className="col-sm-3  ">
                                            <FormGroup>
                                                <Label
                                                    htmlFor="reason"
                                                    className="form-label label-text"
                                                >
                                                    Reason*
                                                </Label>
                                                <Field as="select" name="reason" className={`form-control${errors.reason && touched.reason ? ' is-invalid' : ''}`}>
                                                  <option value="">Select</option>
                                                  {reason?.answers?.map((rsn) => (
                                                      <option key={rsn.id} value={reason.id+","+ rsn.id+","+ reason.weightage +","+ rsn.scoringValue}>{rsn.answer}</option>
                                                  ))}
                                                 
                                              </Field>
                                                <ErrorMessage
                                                    name="reason"
                                                    component="div"
                                                    className="invalid-feedback"
                                                />
                                            </FormGroup>
                                        </div>


                                    </div>

                                    <hr className="mt-2 mb-3" />
                            <div className="row mb-3">
                            {
                                              this.state.buyersQuestions
                                                .map(buyersQuestion =>

                                                  <div key={buyersQuestion.key} className={buyersQuestion.layoutClass}>
                                                    <FormGroup>

                                                      <Label htmlFor={"buyerQuest" + buyersQuestion.id}>
                                                        {buyersQuestion.question}
                                                      </Label>
                                                      {(() => {
                                                        if (buyersQuestion.questionType == 1) {

                                                          const asns = buyersQuestion.answersOptions;
                                                          const res = asns.split(',');
                                                          const result = res.map((item, index) => <option key={index} value={item}



                                                          >{item}</option>);
                                                          return (
                                                            <div>
                                                              <Field as="select" className="form-control" name={`questionAnswers.${buyersQuestion.id}.answersOption`}>
                                                                <option value="">Select</option>
                                                                {result}
                                                              </Field>
                                                            </div>
                                                          )
                                                        } else if (buyersQuestion.questionType == 2) {

                                                          return (

                                                              <Field
                                                                name={`questionAnswers.${buyersQuestion.id}.answersOption`}
                                                                type="checkbox"
                                                                id={"buyerQuest" + buyersQuestion.id}
                                                                className="questCheckbox"
                                                                onChange={(e) => setFieldValue(
                                                                    `questionAnswers.${buyersQuestion.id}.answersOption`,
                                                                    e.target.checked ? 'Yes' : 'No'
                                                                  )}

                                                              />



                                                          )
                                                        } else if (buyersQuestion.questionType == 6) {
                                                          return (
                                                            <div>
                                                             <Field
                                                                name={`questionAnswers.${buyersQuestion.id}.answersOption`}
                                                                type="text"
                                                                onChange={handleChange}
                                                                className="form-control"

                                                              />
                                                            </div>
                                                          )
                                                        } else if (buyersQuestion.questionType == 7) {
                                                          return (
                                                            <div>
                                                              <Field
                                                                name={`questionAnswers.${buyersQuestion.id}.answersOption`}
                                                                type="text"
                                                                onChange={handleChange}
                                                                className="form-control"

                                                              />
                                                            </div>
                                                          )
                                                        } else if (buyersQuestion.questionType == 8) {
                                                          return (
                                                            <div>
                                                              <textarea name={`questionAnswers.${buyersQuestion.id}.answersOption`} className="form-control" onChange={handleChange} />

                                                            </div>
                                                          )
                                                        } else {
                                                          return (
                                                            <div></div>
                                                          )
                                                        }
                                                      })()}



                                                    </FormGroup>
                                                  </div>



                                                )}



                                    </div>

                                    <hr className="mt-2 mb-3" />


                            <Row className="mb15">
                                <Col sm="12" md="6">
                                    <SaveButton>Save Questions</SaveButton>

                                </Col>

                                <Col
                                    sm="12"
                                    md="6"
                                    className="text-end"
                                >


                                </Col>
                            </Row>
                        </CardBody>
                    </Form>
                )}
            </Formik>
            </CardBody>
            </Card>
            </Col>
            </Row>
            </Container>
            </div>
            </React.Fragment>
           
            
        );
    }
}
BuyerQuestionAnswer.propTypes = {
  match: PropTypes.object,
  onGetLeadDetail: PropTypes.object,
  leads: PropTypes.object
}
const mapStateToProps = ({ leads }) =>
  ({
    leads: leads.leads,
   
  });
const mapDispatchToProps = dispatch => ({
  onGetLeadDetail: leadId => dispatch(getLeadDetail(leadId)),
});
export default  withRouter(connect(mapStateToProps,mapDispatchToProps)(BuyerQuestionAnswer))