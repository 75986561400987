import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import { isEmpty, map, size } from "lodash";
import BreadcrumbClient from "../../components/Common/BreadcrumbClient"
import { withTranslation } from "react-i18next"
//import Lead from "./Lead";
import ClientLink from "./client-link";
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import Calender from "../Activities/calander"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios"
import DeleteModal from "../../components/Common/DeleteModal";
import { addClientAppointment, getClientDetail, getClientAppointment, deleteClientAppointment, updateClientAppointment} from "../../store/clients/actions"
import Moment from 'react-moment';
import Switch from "react-switch"
import ClientSidebar from "components/VerticalLayout/ClientSidebar";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Input,
  Button,
  InputGroup,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  
} from "reactstrap"
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import ClientActivityBreadcrumbs from "components/Common/ClientActivityBreadcrumb";
import ClientActivityMenu from "./menu/client-activity-menu";

class ClientApp extends Component {
  constructor(props) {
    super(props)
    this.state = {
      appTypes: [],
      appPriories: [],
      appLocTypes: [],
      appointment:'',
      checked: true,
    
    }
    this.handleSwitch = this.handleSwitch.bind(this);
    this.handelValidAppointment = this.handelValidAppointment.bind(this)
    this.onClickDelete = this.onClickDelete.bind(this);
    this.toggleRightCanvas = this.toggleRightCanvas.bind(this);
    this.toggleBackdrop = this.toggleBackdrop.bind(this);

  }
  handleSwitch(checked) {
    this.setState({ checked });
  }

  toggleBackdrop() {
    this.setState({ isBackdrop: !this.state.isBackdrop });
  }
  toggleDeleteModal = () => {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal,
    }));
  };
  toggle() {
    this.setState(prevState => ({
      isRight: !prevState.isRight
    }));
  }
  onClickDelete = (appointment) => {
    this.setState({ appointment: appointment });
    this.setState({ deleteModal: true });
  };
  handleDeleteAppointment = () => {
    const { onDeleteAppointment } = this.props;
    const { appointment } = this.state;
    
    if (appointment.id !== undefined) {
      onDeleteAppointment(appointment);
      this.setState({ deleteModal: false });
    }
  };
  handelValidAppointment(value){
    //console.log(value)
    this.props.addClientAppointment(value, this.props.history);

  }
  componentDidMount() {
    const { match: { params }, onGetClientDetail, onGetClientAppointment } = this.props;
    onGetClientDetail(params.clientId);
    onGetClientAppointment(localStorage.getItem('userId'),params.id)
   

    const obj = JSON.parse(localStorage.getItem("authUser"));
    const token = obj.resultData.bearerToken;
    const str = 'Bearer ' + token;
    axios.get('https://rscoreapi.azurewebsites.net/api/AppointmentType/all', {
      headers: { Authorization: str }
    })
      .then(res => {
        const appTypes = res.data;
        this.setState({ appTypes });
      }),
      axios.get('https://rscoreapi.azurewebsites.net/api/AppointmentPriority/all', {
        headers: { Authorization: str }
      })
        .then(res => {
          const appPriories = res.data;
          this.setState({ appPriories });
        }),
      axios.get('https://rscoreapi.azurewebsites.net/api/AppointmentLocationType/all', {
        headers: { Authorization: str }
      })
        .then(res => {
          const appLocTypes = res.data;
          this.setState({ appLocTypes });
        })
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { appointments } = this.props;
    if (!isEmpty(appointments) && size(prevProps.appointments) !== size(appointments)) {
      this.setState({ appointments: {}, isEdit: false });
    }
  }
  toggleRightCanvas() {
    this.setState({ appointment: "", isEdit: false, isRight: !this.state.isRight });
    this.toggle();
  }
  toggleRightCanvas = arg => {
    const appointment = arg;
  
    this.setState({
      appointment: {
    
        id:appointment.id,
        appointmentId: appointment.appointmentId,
        appointmentTitle:appointment.appointmentTitle,
        contactName: appointment.contactName,
        contactPhone: appointment.contactPhone,
        contactEmail: appointment.contactEmail,
        appointmentStartDate: appointment.appointmentStartDate,
        startTime: appointment.startTime,
        endTime: appointment.endTime,
        appointmentType: appointment.appointmentType,
        appointmentStatus: appointment.appointmentStatus,
        appointmentPriority: appointment.appointmentPriority,
        appointmentReminder: appointment.appointmentReminder,
        clientId: appointment.clientId,
        appointmentDescription:appointment.appointmentDescription,
        appointmentLocationType:appointment.appointmentLocationType,
        appointmentLocation:appointment.appointmentLocation,
        locationLatitude:appointment.locationLatitude,
        locationLongitude:appointment.locationLongitude,
        isRepeat:appointment.isRepeat,
        repeatEveryType:appointment.repeatEveryType,
        repeatStep:appointment.repeatStep,
        repeatEndDate:appointment.repeatEndDate,
        agentId:appointment.agentId,
        isActive:appointment.isActive,
        agentNotes: appointment.agentNotes,
        addedBy: appointment.addedBy
      },
      isEdit: true,
    });
    this.toggle();
  };
  handleChange = arg =>  {
    const appointment = arg;
    const { onUpdateStatus } = this.props;
    const updateReg = {
      id:appointment.id,
      appointmentId: appointment.appointmentId,
      rejected: (appointment.rejected==true) ? false : true,
      appointmentTitle:appointment.appointmentTitle,
        contactName: appointment.contactName,
        contactPhone: appointment.contactPhone,
        contactEmail: appointment.contactEmail,
        appointmentStartDate: appointment.appointmentStartDate,
        startTime: appointment.startTime,
        endTime: appointment.endTime,
        appointmentType: appointment.appointmentType,
        appointmentStatus: appointment.appointmentStatus,
        appointmentPriority: appointment.appointmentPriority,
        appointmentReminder: appointment.appointmentReminder,
        clientId: appointment.clientId,
        appointmentDescription:appointment.appointmentDescription,
        appointmentLocationType:appointment.appointmentLocationType,
        appointmentLocation:appointment.appointmentLocation,
        locationLatitude:appointment.locationLatitude,
        locationLongitude:appointment.locationLongitude,
        isRepeat:appointment.isRepeat,
        repeatEveryType:appointment.repeatEveryType,
        repeatStep:appointment.repeatStep,
        repeatEndDate:appointment.repeatEndDate,
        agentId:appointment.agentId,
        isActive:appointment.isActive,
        contactNotes: appointment.contactNotes,
        addedBy: appointment.addedBy
    }

    onUpdateStatus(updateReg);
  };

  render() {
    const { clietDetail, appointments , onUpdateClientAppointment, loading} = this.props;
    const now = new Date();
    now.setMinutes(now.getMinutes() + 30);
    const edTime = new Date(now);
    const selectedValue = '';
    const { isEdit, deleteModal } = this.state;
    const appointment = this.state.appointment;
    const { checked } = this.state;

    const allLocal = localStorage.getItem('localData')
    const localization = JSON.parse(allLocal);

    console.log(localization);
    return (
      <React.Fragment>
          {this.props.loading && this.props.loading ? (
         <div className="loading">Loading&#8230;</div>
         ) : null}
         <ToastContainer autoClose={2000} />
         <DeleteModal
          show={deleteModal}
          onDeleteClick={this.handleDeleteAppointment}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />
        <div className="page-content project-page-content">
          <MetaTags>
            <title>Client Dashboard | Realty Space</title>
          </MetaTags>
          <Container fluid className="ps-0">
            <Row>
              <Col sm='2' className="pe-0">
                <ClientSidebar id={this.props.match.params.id} clientId={this.props.match.params.clientId} />
              </Col>
              <Col sm='10' className="ps-0 pe-0">
              <Row className="project-header">
                <Col sm="7">
             <ClientActivityBreadcrumbs
              title={this.props.t("Clients")}
              breadcrumbItem={this.props.t( " Deal")}
            />
            </Col>
            <Col sm="4" className="">
                <h2 className="">{this.props.t( clietDetail.firstName + " " + clietDetail.lastName)}</h2>
                </Col>
            </Row>
            <ClientActivityMenu />
            <Row>
              {/* <Col xl="3">
              <ClientLink id={this.props.match.params.id} clientId={this.props.match.params.clientId} />
              </Col> */}

              <Col xl="12">
               
                <Row>
                  <Col sm="12">
                    <Card>
                      <CardBody>
                        <h5 className="card-title mb20">Deals </h5>

                        <div className="table-responsive">
          {/* <table className="table table-bordered border-primary align-middle table-nowrap mb-0">
              <thead className="">
							  <tr>
								
								<th>Id#</th>
								<th>Type</th>
								<th>Asking Price</th>
								<th>Final Price</th>
                <th>Commision</th>
                <th>Value</th>
                <th>Amount</th>
                <th>Closing Date</th>
                <th>Notes</th>
                <th>Document</th>
                <th>Action</th>
							  </tr>
							</thead>
							<tbody>
              <tr>
                <td><a href='#'>#H335234</a></td>
                <td>
                  <select className="form-control" name="type" >
                    <option value={"Sale"}>Sale</option>
                    <option value={"Lease"}>Lease</option>
                  </select>
                </td>
                <td>$849,000</td>
                <td>$869,000</td>
                <td>
                  <select className="form-control" name="commision">
                    <option value={"Fixed"}>Fixed</option>
                    <option value={"%"}>%</option>
                  </select>
                </td>
                <td><input className="form-control" defaultValue={'2.5%'} /></td>
                <td><input className="form-control" defaultValue={'$21,725'} /></td>
                <td><Flatpickr
                                className={"form-control" }
                                options={{
                                  altInput: false,
                                  // minDate: "today",
                                  defaultDate: 'today',
                                  altFormat: "F j, Y",
                                  dateFormat: "j F, Y"
                                }}
                              />
                              
                           </td>
                <td></td>
                <td>Upload</td>
                <td><a href='#' ><b><span onClick={() => this.toggleRightCanvas(appointment)} >Edit</span> | <span onClick={() => this.onClickDelete(appointment)}>Remove</span></b></a></td>
                
              </tr>

              <tr>
                <td><a href='#'>#F325643</a></td>
                <td>
                  <select className="form-control" name="type" >
                    <option value={"Sale"}>Sale</option>
                    <option value={"Lease"}>Lease</option>
                  </select>
                </td>
                <td>$979,000</td>
                <td>$679,000</td>
                <td>
                  <select className="form-control" name="commision">
                    <option value={"Fixed"}>Fixed</option>
                    <option value={"%"}>%</option>
                  </select>
                </td>
                <td><input className="form-control" defaultValue={'3.5%'} /></td>
                <td><input className="form-control" defaultValue={'$25,725'} /></td>
                <td><Flatpickr
                                className={"form-control" }
                                options={{
                                  altInput: false,
                                  // minDate: "today",
                                  defaultDate: 'today',
                                  altFormat: "F j, Y",
                                  dateFormat: "j F, Y"
                                }}
                              />
                              
                           </td>
                <td></td>
                <td>Upload</td>
                <td><a href='#' ><b><span onClick={() => this.toggleRightCanvas(appointment)} >Edit</span> | <span onClick={() => this.onClickDelete(appointment)}>Remove</span></b></a></td>
                
              </tr>

              <tr>
                <td><a href='#'>#E755234</a></td>
                <td>
                  <select className="form-control" name="type" >
                    <option value={"Sale"}>Sale</option>
                    <option value={"Lease"}>Lease</option>
                  </select>
                </td>
                <td>$649,000</td>
                <td>$769,000</td>
                <td>
                  <select className="form-control" name="commision">
                    <option value={"Fixed"}>Fixed</option>
                    <option value={"%"}>%</option>
                  </select>
                </td>
                <td><input className="form-control" defaultValue={'4.5%'} /></td>
                <td><input className="form-control" defaultValue={'$35,725'} /></td>
                <td><Flatpickr
                                className={"form-control" }
                                options={{
                                  altInput: false,
                                  // minDate: "today",
                                  defaultDate: 'today',
                                  altFormat: "F j, Y",
                                  dateFormat: "j F, Y"
                                }}
                              />
                              
                           </td>
                <td></td>
                <td>Upload</td>
                <td><a href='#' ><b><span onClick={() => this.toggleRightCanvas(appointment)} >Edit</span> | <span onClick={() => this.onClickDelete(appointment)}>Remove</span></b></a></td>
                
              </tr>

              <tr>
                <td><a href='#'>#F235234</a></td>
                <td>
                  <select className="form-control" name="type" >
                    <option value={"Sale"}>Sale</option>
                    <option value={"Lease"}>Lease</option>
                  </select>
                </td>
                <td>$959,000</td>
                <td>$970,000</td>
                <td>
                  <select className="form-control" name="commision">
                    <option value={"Fixed"}>Fixed</option>
                    <option value={"%"}>%</option>
                  </select>
                </td>
                <td><input className="form-control" defaultValue={'3.5%'} /></td>
                <td><input className="form-control" defaultValue={'$32,725'} /></td>
                <td><Flatpickr
                                className={"form-control" }
                                options={{
                                  altInput: false,
                                  // minDate: "today",
                                  defaultDate: 'today',
                                  altFormat: "F j, Y",
                                  dateFormat: "j F, Y"
                                }}
                              />
                              
                           </td>
                <td></td>
                <td>Upload</td>
                <td><a href='#' ><b><span onClick={() => this.toggleRightCanvas(appointment)} >Edit</span> | <span onClick={() => this.onClickDelete(appointment)}>Remove</span></b></a></td>
                
              </tr>
              </tbody>


              </table> */}

              <DataTable className="table table-bordered data-table align-middle table-nowrap mb-0 me-2" value={[{id:"#H335234",type:"1",a_price:"$849,000",f_price:"$869,000",comm:"1",val:"2.5%",amt:"$21,725",closing_date:"",note:"tsting",doc:"Upload"},{id:"#H335234",type:"1",a_price:"$759,000",f_price:"$999,000",comm:"1",val:"2.0%",amt:"$11,725",closing_date:"",note:"testing",doc:"Upload"},{id:"#H385534",type:"1",a_price:"$823,000",f_price:"$861,000",comm:"1",val:"3.5%",amt:"$31,725",closing_date:"",note:"tsting",doc:"Upload"},{id:"#H335234",type:"1",a_price:"$849,000",f_price:"$869,000",comm:"1",val:"2.5%",amt:"$21,725",closing_date:"",note:"tsting",doc:"Upload"}]} paginator rows={10} rowsPerPageOptions={[10, 20, 30, 50, 100, 150, 200, 500 ]} currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries " paginatorTemplate=" FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown   " tableStyle={{ minWidth: '50rem' }}   >
                  <Column  field="lastContact" header="Id#" body={(rowData) => (
                     <a href='#'>{rowData.id}</a>
                  )}></Column>
                  <Column  field="lastContact" header="Type" body={(rowData) => (
                      <select className="form-control" name="type" >
                      <option value={"Sale"}>Sale</option>
                      <option value={"Lease"}>Lease</option>
                    </select>
                  )}></Column>
                  <Column  field="a_price" header="Asking Price" ></Column>
                  <Column  field="f_price" header="Final Price" ></Column>
                  <Column  field="lastContact" header="Commision" body={(rowData) => (
                      <select className="form-control" name="commision">
                      <option value={"Fixed"}>Fixed</option>
                      <option value={"%"}>%</option>
                    </select>
                  )}></Column>
                  <Column  field="lastContact" header="Value" body={(rowData) => (
                     <input className="form-control" defaultValue={rowData.val} />
                  )}></Column>
                  <Column  field="lastContact" header="Amount" body={(rowData) => (
                     <input className="form-control" defaultValue={rowData.amt} />

                  )}></Column>
                  <Column  field="lastContact" header="Closing Date" body={(rowData) => (
                      <Flatpickr
                      className={"form-control" }
                      options={{
                        altInput: false,
                        // minDate: "today",
                        defaultDate: 'today',
                        altFormat: "F j, Y",
                        dateFormat: "j F, Y"
                      }}
                    />
                  )}></Column>
                  <Column  field="note" header="Notes" ></Column>
                  <Column  field="doc" header="Document"></Column>
                  <Column  field="lastContact" header="Action" body={(rowData) => (
                     <a href='#' ><b><span onClick={() => this.toggleRightCanvas(appointment)} >Edit</span> | <span onClick={() => this.onClickDelete(appointment)}>Remove</span></b></a>
                  )}></Column>
              </DataTable>
              </div>
                      
                      </CardBody>
                    </Card>
                  </Col>
                </Row>


              </Col>

            </Row>
            </Col>
            </Row>
            <Offcanvas
                      isOpen={this.state.isRight}
                      direction="end"
                      toggle={this.toggleRightCanvas}
                    >
                      <OffcanvasHeader toggle={this.toggleRightCanvas}>
                        Update 
                      </OffcanvasHeader>
                      <OffcanvasBody>
                      <Formik
                    enableReinitialize={true}
                    initialValues={{
                      id:appointment.id,
                      appointmentId: appointment.appointmentId,
                      appointmentTitle:appointment.appointmentTitle,
                      contactName: appointment.contactName,
                      contactPhone: appointment.contactPhone,
                      contactEmail: appointment.contactEmail,
                      appointmentStartDate: appointment.appointmentStartDate,
                      startTime: appointment.startTime,
                      endTime: appointment.endTime,
                      appointmentType: appointment.appointmentType,
                      appointmentStatus: appointment.appointmentStatus,
                      appointmentPriority: appointment.appointmentPriority,
                      appointmentReminder: appointment.appointmentReminder,
                      clientId: appointment.clientId,
                      appointmentDescription:appointment.appointmentDescription,
                      appointmentLocationType:appointment.appointmentLocationType,
                      appointmentLocation:appointment.appointmentLocation,
                      locationLatitude:appointment.locationLatitude,
                      locationLongitude:appointment.locationLongitude,
                      isRepeat:appointment.isRepeat,
                      repeatEveryType:appointment.repeatEveryType,
                      repeatStep:appointment.repeatStep,
                      repeatEndDate:appointment.repeatEndDate,
                      agentId:appointment.agentId,
                      isActive:appointment.isActive,
                      agentNotes: appointment.agentNotes,
                      addedBy: appointment.addedBy

                      
                    }}


                    validationSchema={Yup.object().shape({
                      appointmentTitle: Yup.string().max(150,"150 Characters are allowed").required("Please Enter Meeting Agenda"),
                      appointmentType : Yup.number().required("This field is required"),
                      appointmentPriority   :  Yup.number().required("This field is required"),
                      appointmentLocationType  :  Yup.number().required("This field is required"),
                    })}

                    onSubmit={values => {
                      //console.log(isEdit);
                      if (isEdit) {
                        const updateApp = {
                          id:values.id,
                          appointmentId: values.appointmentId,
                          appointmentTitle:values.appointmentTitle,
                          contactName: values.contactName,
                          contactPhone: values.contactPhone,
                          contactEmail: values.contactEmail,
                          appointmentStartDate: values.appointmentStartDate,
                          startTime: values.startTime,
                          endTime: values.endTime,
                          appointmentType: (values.appointmentType) ? parseInt(values.appointmentType) : 0,
                          appointmentStatus: (values.appointmentStatus) ? parseInt(values.appointmentStatus) : 0,
                          appointmentPriority: (values.appointmentPriority) ? parseInt(values.appointmentPriority) : 0,
                          appointmentReminder: values.appointmentReminder,
                          clientId: values.clientId,
                          appointmentDescription:values.appointmentDescription,
                          appointmentLocationType:values.appointmentLocationType,
                          appointmentLocation:values.appointmentLocation,
                          locationLatitude:values.locationLatitude,
                          locationLongitude:values.locationLongitude,
                          isRepeat:values.isRepeat,
                          repeatEveryType:values.repeatEveryType,
                          repeatStep:values.repeatStep,
                          repeatEndDate:values.repeatEndDate,
                          agentId:values.agentId,
                          isActive:values.isActive,
                          agentNotes: values.agentNotes,
                          addedBy: values.addedBy
                        };
  
                        onUpdateClientAppointment(updateApp);
                      } else {
                        
                      }
                      this.toggle();
                    }}
                  >
                    {({ errors, touched, values, handleChange, handelBlur, setFieldValue }) => (
                      <Form
                        className="needs-validation"
                      >
                        <Row>

                        <Col md="12">
                            <FormGroup className="mb15">

                              <Label htmlFor="type">
                                {this.props.t("type")}
                              </Label>
                              <Field as="select" name="type" className="form-control" onChange={handleChange}>
                                <option value="">Select</option>
                               <option>Sale</option>
                               <option>Lease</option>
                              </Field>

                            </FormGroup>
                          </Col>

                          <Col md="12">
                            <FormGroup className="mb-3">
                              <Label htmlFor="asking_price">
                                {this.props.t("Asking Price")}
                              </Label>
                              <input
                                name="asking_price"
                                type="text"
                                value={values.asking_price}
                                onChange={handleChange}
                                className={
                                  "form-control" +
                                  (errors.asking_price && touched.asking_price
                                    ? " is-invalid"
                                    : "")
                                }
                                id="appAjenda"
                              />
                              <ErrorMessage
                                name="asking_price"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </Col>
                          <Col md="12">
                            <FormGroup className="mb-3">
                              <Label htmlFor="final_price">
                                {this.props.t("Final Price")}
                              </Label>
                              <input
                                name="final_price"
                                type="text"
                                value={values.final_price}
                                onChange={handleChange}
                                className={
                                  "form-control" +
                                  (errors.final_price && touched.final_price
                                    ? " is-invalid"
                                    : "")
                                }
                                id="appAjenda"
                              />
                              <ErrorMessage
                                name="final_price"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </Col>
                          
                    
                          <Col md="12">
                            <FormGroup className="mb15">

                              <Label htmlFor="commision">
                                {this.props.t("commision")}
                              </Label>
                              <Field as="select" name="commision" className="form-control" onChange={handleChange}>
                                <option value="">Select</option>
                               <option>Fixed</option>
                               <option>%</option>
                              </Field>

                            </FormGroup>
                          </Col>
                          <Col md="12">
                            <FormGroup className="mb-3">
                              <Label htmlFor="value">
                                {this.props.t(" value")}
                              </Label>
                              <input
                                name="value"
                                type="text"
                                value={values.value}
                                onChange={handleChange}
                                className={
                                  "form-control" +
                                  (errors.value && touched.value
                                    ? " is-invalid"
                                    : "")
                                }
                                id="appAjenda"
                              />
                              <ErrorMessage
                                name="value"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </Col>
                          

                          <Col md="12">
                            <FormGroup className="mb-3">
                              <Label htmlFor="amount">
                                {this.props.t("Amount")}
                              </Label>
                              <input
                                name="amount"
                                type="text"
                                value={values.amount}
                                onChange={handleChange}
                                className={
                                  "form-control" +
                                  (errors.amount && touched.amount
                                    ? " is-invalid"
                                    : "")
                                }
                                id="amount"
                              />
                              <ErrorMessage
                                name="amount"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </Col>
                          


                          <Col md="12">
                            <FormGroup className="mb15">

                              <Label htmlFor="appointmentStartDate">
                                {this.props.t("Date*")}
                              </Label>
                              <Flatpickr
                                className={
                                  "form-control" +
                                  (errors.appointmentStartDate && touched.appointmentStartDate
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder=""
                                value={values.appointmentStartDate}
                                onChange={(value) => setFieldValue('appointmentStartDate', value[0])}
                                options={{
                                  altInput: true,
                                  altFormat: "F j, Y",
                                  dateFormat: "Y-m-d",
                                  defaultDate:'today'
                                }}
                              />
                              <ErrorMessage
                                name="appointmentStartDate"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </Col>
                        


                        

                          <Col md="12">
                            <FormGroup className="mb15">

                              <Label htmlFor="agentNotes">
                                {this.props.t("Note")}
                              </Label>
                              <Field as="textarea" name="agentNotes" className="form-control" onChange={handleChange}>
                               
                              </Field>

                            </FormGroup>
                          </Col>

                          <Col md="12">
                            <FormGroup className="mb15">

                              <Label htmlFor="file">
                                {this.props.t("Document")}
                              </Label>
                              <input type='file' name="file" className="form-control" onChange={handleChange}/>
                               

                            </FormGroup>
                          </Col>

                        </Row>




                        <div className="clearfix"><button type="submit" className="btn btn-primary w-md">{this.props.t("Update")}</button></div>
                      </Form>
                    )}
                  </Formik>
                      </OffcanvasBody>
                      </Offcanvas>   
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
ClientApp.propTypes = {
  t: PropTypes.any,
  history:PropTypes.object,
  addClientAppointment:PropTypes.func,
  match:PropTypes.object,
  onGetClientDetail:PropTypes.func,
  clietDetail : PropTypes.object,
  appointments:PropTypes.array,
  onGetClientAppointment:PropTypes.func,
  onDeleteAppointment:PropTypes.func,
  onUpdateClientAppointment:PropTypes.func,
  loading:PropTypes.object,
  onUpdateStatus:PropTypes.func

}
const mapStateToProps = ({ Clients }) => (
  {
      clietDetail:Clients.clietDetail,
      appointments:Clients.appointments,
      loading:Clients.loading

  })
const mapDispatchToProps = dispatch => ({
  addClientAppointment: (data) => dispatch(addClientAppointment(data)),
  onGetClientDetail: clientId => dispatch(getClientDetail(clientId)),
  onGetClientAppointment:(agentId, clientId) => dispatch(getClientAppointment(agentId, clientId)),
  onDeleteAppointment:(id) => dispatch(deleteClientAppointment(id)),
  onUpdateClientAppointment:(data) => dispatch(updateClientAppointment(data)),
  onUpdateStatus:(data) =>  dispatch(updateClientAppointment(data)),
 
});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ClientApp))
