import React, { Component } from "react"
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import { Link } from "react-router-dom"
import {
  Container,
  Row,
  Col,
  CardBody,
  Card,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"
import { connect } from "react-redux"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import { getCampaign } from "../../store/campaign/actions"
//i18n
import { withTranslation } from "react-i18next"
import { isEmpty, map, size } from "lodash";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import Flatpickr from "react-flatpickr"
import DashboardChart from "./charts/dashboard-chart";
import CampaignSidebar from "components/VerticalLayout/CampaignSidebar";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Moment from "react-moment";

class CreateComp extends Component {
  constructor(props) {
    super(props);

  }
  componentDidMount() {
    const { onGetCampaign } = this.props;
    onGetCampaign(localStorage.getItem('userId'));
  }
  handleSelect(ranges){
    console.log(ranges);
    // {
    //   selection: {
    //     startDate: [native Date Object],
    //     endDate: [native Date Object],
    //   }
    // }
  }
  render() {
    const { campaigns } = this.props;
    const selectionRange = {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    }
    const tData = [
      {date:"2024-04-17",type:"Newsletters",taget:"Leads",name:"Feb Newsletter",theme:"Theme 1",email:"walter.white@gmail.com",status:"published",action:"View"},
      {date:"2024-04-11",type:"Referral",taget:"Clients",name:"Referral VIP",theme:"Theme 2",email:"cool.parker@gmail.com",status:"published",action:"View"},
      {date:"2024-04-12",type:"Reviews",taget:"Clients",name:"Reviews-Condo",theme:"Theme 3",email:"alex.bliss@gmail.com",status:"published",action:"View"},
      {date:"2024-04-15",type:"Seller",taget:"Leads",name:"Seller",theme:"Theme 1",email:"Cristian.slater@gmail.com",status:"published",action:"View"},
      {date:"2024-04-25",type:"Survey",taget:"Clients",name:"Survey",theme:"Theme 2",email:"Martin.colby@gmail.com",status:"published",action:"View"},
    ]
    return (
      <React.Fragment>
        {this.props.loading && this.props.loading ? (
                    <div className="loading">Loading&#8230;</div>
                ) : null}
        <div className="page-content project-page-content">
          <MetaTags>
            <title>Create Template | Realty Space</title>
          </MetaTags>
          <Container fluid className="ps-0">
            {/* Render Breadcrumb */}
            <Row>
              <Col sm='2' className="pe-0">
              <CampaignSidebar  />
              </Col>
              <Col sm='10' className="ps-0 pe-0">
              <Row className="project-header">
                <Col sm='7'>
            {/* <Row>
              <Col sm="6"> */}
                <Breadcrumbs
                  title={this.props.t("Campaign")}
                  breadcrumbItem={this.props.t("Dashboard")}
                />
              </Col>
            <Col sm="4" className="pt-4">
                <Link
                  to="/create-campaign"
                  className="btn btn-add btn-md "
                >
                  <i className="mdi mdi-plus-box-outline ms-1" /> {" "} Create New Campaign
                </Link>
              </Col>
            </Row>
           
            <Row>
              <Col sm="12">
                <Card>
              <div className="row gy-2 gx-3 mb20 mt-2 ms-2">
         
         <div className="col-md-auto">
           <label className="" htmlFor="autoSizingSelect">Select Category</label>
           <select defaultValue="0" className="form-select">
             <option value="0">All</option>
             <option value="21">Client satisfaction survey</option>
               <option value="20">Newsletter</option>
               <option value="16">Referral</option>
               <option value="17">Review</option>
               <option value="22">Seller Pages</option>
           </select>
         </div>
         <div className="col-md-auto">
         <label className="" htmlFor="autoSizingSelect">Select Date Range</label>
         <Flatpickr
                            className="form-control d-block"
                            placeholder="DD/MM/YYYY"
                            options={{
                              mode: "range",
                              dateFormat: "Y-m-d"
                            }}
                          />
          </div>
         <div className="col-sm-auto">
           <button type="submit" className="btn btn-primary w-md" style={{marginTop:27}}>Filter</button>
         </div>
                            {/* <hr style={{marginTop:20, marginBottom:20, border:'2px solid #000', width:"98%", marginLeft:12, opacity:1}}></hr> */}
       </div>
       </Card>
              </Col>
            </Row>
            <DashboardChart />  
            <Row>
              <Col sm="12">
                
                <Card>
                  <CardBody>
                
          <div className="clearfix"></div>
                    <div className="table-responsive">
                     

                      <DataTable className="table table-bordered data-table align-middle table-nowrap mb-0 me-2" value={tData} paginator rows={10} rowsPerPageOptions={[10, 20, 30, 50, 100, 150, 200, 500 ]} currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries " paginatorTemplate=" FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown   " tableStyle={{ minWidth: '50rem' }}   >
                          <Column  field="date" header="Date" body={(rowData) => (
                              rowData.date ? <Moment format="D MMM YY">{rowData.date}</Moment> : ''
                          )}></Column>
                          <Column  field="type" header="Type">  </Column>
                          <Column  field="target" header="Target">  </Column>

                          <Column  field="name" header="Name">  </Column>

                          <Column  field="theme" header="Theme">  </Column>

                          <Column  field="email" header="Emails"></Column>
                          <Column  field="status" header="Status">  </Column>

                          <Column  field="action" header="Action">  </Column>

                      </DataTable>


                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            </Col>
            </Row>
          </Container>


        </div>
      </React.Fragment>
    )
  }



}


CreateComp.propTypes = {
  t:  PropTypes.any,
  campaigns:PropTypes.array,
  onGetCampaign:PropTypes.func,
  loading: PropTypes.object

}
const mapStateToProps = ({ campaign }) => (
  {

    campaigns: campaign.campaigns,
    loading: campaign.loading

  })
const mapDispatchToProps = dispatch => ({
  onGetCampaign: (agentId) => dispatch(getCampaign(agentId))
});

export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(CreateComp))