import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import { capitalize, isEmpty, map, size } from "lodash";
import BreadcrumbClient from "../../components/Common/BreadcrumbClient"
import { withTranslation } from "react-i18next"
import classnames from "classnames"
import ClientLink from "./client-link";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { Formik, Field, Form, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import axios from "axios"
import DeleteModal from "../../components/Common/DeleteModal";
import { addClientAddListing, getClientAddListing, getClientDetail, deleteClientAddListing, ClientAddListingUpdate, 
  getListingType, getUserDetail, getTransStatusType, getTransCommType, addClientBuyerOffer, updateClientBuyerOffer, getClientBuyerOffer } from "../../store/actions"
import Moment from 'react-moment';
import CurrencyFormat from 'react-currency-format';
import Switch from "react-switch"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import Dropzone from "react-dropzone"
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  InputGroup,
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Dropdown,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"
import ClientSidebar from "components/VerticalLayout/ClientSidebar";
import UpdateButton from "components/buttons/updateBtn";
import RsLink from "components/buttons/rsLink";
import SaveButton from "components/buttons/save";
import ClientBuyerBreadcrumbs from "components/Common/ClientBuyerBreadcrumb";
import ClientBuyerMenu from "./menu/client-buyer-menu";
import buyer from "pages/Lead/questions/buyer";
class ClientListing extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab1: "1",
      addListing:'',
      activeFilter:'All'
    }

    this.handleAddListingSubmit = this.handleAddListingSubmit.bind(this)
    this.toggleRightCanvas = this.toggleRightCanvas.bind(this);
    this.toggleBackdrop = this.toggleBackdrop.bind(this);
    this.onClickDelete = this.onClickDelete.bind(this);
    this.toggle1 = this.toggle1.bind(this);
    //this.offerSubmitted = this.offerSubmitted.bind(this)
    
  }
  toggleBackdrop() {
    this.setState({ isBackdrop: !this.state.isBackdrop });
  }
  toggleDeleteModal = () => {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal,
    }));
  };
  onClickDelete = (addListing) => {
    this.setState({ addListing: addListing });
    this.setState({ deleteModal: true });
  };
  toggleRight() {
    this.setState(prevState => ({
      isRight: !prevState.isRight,
    }));
  }
 
  toggle1(tab) {
    if (this.state.activeTab1 !== tab) {
      this.setState({
        activeTab1: tab,
      });
    }
  }
  handleDeleteAddListing = () => {
    const { onDeleteAddListing } = this.props;
    const { addListing } = this.state;

    if (addListing.id !== undefined) {
      onDeleteAddListing(addListing);
      this.setState({ deleteModal: false });
      this.toggleRight();
    }
  };
  
  handlePlaceSelected = (address, index, setFieldValue) => {
    geocodeByAddress(address)
      .then(results => {
        const place = results[0];
        const addressComponents = place.address_components;
        
        const getComponent = (types) => {
          const component = addressComponents.find((component) =>
            types.every((type) => component.types.includes(type))
          );
          return component ? component.long_name : '';
        };
        const latitude = place.geometry.location.lat();
        const longitude = place.geometry.location.lng();
        setFieldValue(`createClientPotentialListingLists.${index}.listingAddress`, place.formatted_address);
        setFieldValue(`createClientPotentialListingLists.${index}.listingCity`,getComponent(['locality', 'political']));
      
        //setFieldValue('longitude', longitude);
        //setFieldValue('latitude', latitude);
      })
      .catch(error => console.error('Error', error));
  };
  handleEditPlaceSelected = (address, setFieldValue) => {
    geocodeByAddress(address)
      .then(results => {
        const place = results[0];
        const addressComponents = place.address_components;
        
        const getComponent = (types) => {
          const component = addressComponents.find((component) =>
            types.every((type) => component.types.includes(type))
          );
          return component ? component.long_name : '';
        };
        setFieldValue('listingAddress', place.formatted_address);
        setFieldValue('listingCity', getComponent(['locality', 'political']));
      })
      .catch(error => console.error('Error', error));
  };
  handleAddListingSubmit = (values, actions) => {
    values.createClientPotentialListingLists.forEach(listing => {
      const payload = {
      clientId:parseInt(listing.clientId),
      agentId : parseInt(listing.agentId),
      mlsId: listing.mlsId,
      mlsurl: listing.mlsurl,
      listingDate: listing.listingDate,
      listingStatusId: 0,
      isInterested: listing.isInterested,
      isActive: listing.isActive,
      addedBy: listing.addedBy,
      listedPrice: listing.listedPrice ? parseInt(listing.listedPrice) : 0,
      listingType: listing.listingType ? parseInt(listing.listingType) : 0,
      listingBedroom: listing.listingBedroom ?  parseInt(listing.listingBedroom) : 0,
      listingBathroom: listing.listingBathroom ? parseInt(listing.listingBathroom) : 0,
      listingParking: listing.listingParking ? parseInt(listing.listingParking) : 0,
      listingAddress:listing.listingAddress,
      listingCity: listing.listingCity,
      notes: listing.notes,
      statusId: 0
    };
        this.props.addClientAddListing(payload)
    });
    
     
    
    
  }
  componentDidMount() {
    const { match: { params }, onGetClientDetail, onGetClientAddListing, onGetListingType, onGetUserDetail, onGetTransactonStatusType, onGetTransactonCommType} = this.props;
    onGetClientDetail(params.clientId);
    onGetClientAddListing(params.id, localStorage.getItem('userId'));
    onGetListingType(localStorage.getItem('userId'))
    onGetUserDetail(localStorage.getItem('userId'))
    onGetTransactonStatusType(localStorage.getItem('userId'), 'Buyer');
    onGetTransactonCommType(localStorage.getItem('userId'));
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { addListings } = this.props;
    if (!isEmpty(addListings) && size(prevProps.addListings) !== size(addListings)) {
      this.setState({ addListings: {}, isEdit: false });
    }
  }
  toggleRightCanvas() {
    this.setState({ addListing: "", isEdit: false, isRight: !this.state.isRight });
    this.toggleRight();
  }

  toggleRightCanvas = arg => {
    const addListing = arg;

    this.setState({
      addListing: {

        id: addListing.id,
        clientPotentialListingId: addListing.clientPotentialListingId,
        clientId: addListing.clientId,
        agentId: addListing.agentId,
        mlsId: addListing.mlsId,
        mlsurl: addListing.mlsurl,
        listingDate: addListing.listingDate,
        listingStatusId: addListing.listingStatusId,
        isInterested: addListing.isInterested,
        isActive: addListing.isActive,
        addedBy: addListing.addedBy,
        listedPrice: addListing.listedPrice,
        listingType: addListing.listingType,
        listingBedroom: addListing.listingBedroom,
        listingBathroom: addListing.listingBathroom,
        listingParking: addListing.listingParking,
        listingAddress: addListing.listingAddress,
        listingCity: addListing.listingCity,
        notes: addListing.notes,
        showingDate: addListing.showingDate,
        startTime: addListing.startTime,
        endTime: addListing.endTime,
        clientNotes:addListing.clientNotes,
        likeDislike:addListing.likeDislike,
        statusId:addListing.statusId,
        feedback:addListing.feedback
      },
      isEdit: true,
    });
    this.toggleRight();
  };
  offertoggle() {
    this.setState(prevState => ({
      isOffer: !prevState.isOffer,
    }));
  }
  toggleOfferAccepted() {
    this.setState({  isOffer: !this.state.isOffer });
  }
  
  toggleOfferAccepted = arg => {
    const addListing = arg;

    const { onGetClientBuyerOffer } = this.props;
    onGetClientBuyerOffer(addListing.id);
    this.setState({
      addListing: {
        id:addListing.id,
        agentId: addListing.agentId,
        mlsId: addListing.mlsId,
        mlsurl: addListing.mlsurl,
        listingType: addListing.listingType,
        listingAddress: addListing.listingAddress,
        listedPrice: addListing.listedPrice,
      },
    });
    this.offertoggle();
  };
  handleChange = (activeStatus, itemId) => {

  };
  handleTabClick = (tabName) => {
    this.setState({ activeFilter: tabName });
    
  };
  offerSubmitted(value){
    console.log(value);
  }
  offerSubmitted = (values) => {
    const payload = {
         agentId : parseInt(localStorage.getItem('userId')),
         clientId:parseInt(values.clientId),
         propertyURL: values.propertyURL,
         propertyType: values.propertyType,
         propertyAddress:values.propertyAddress,
         transTypeId:2,
         commissionTypeId:parseInt(values.commissionTypeId),
         commisisonValue:parseInt(values.commisisonValue),
         askingPrice:parseInt(values.askingPrice),
         offerPrice:parseInt(values.offerPrice),
         offerDate:values.offerDate,
         closingDate:values.closingDate,
         refId:parseInt(values.refId), 
         refTypeId:1, 
         statusId:parseInt(values.statusId),
         isAccepted:'',
         conditions:'',
         notes:values.notes
    }
     this.props.addClientBuyerOffer(payload);
    };
    offerUpdated = (values) => {
      const payload = {
           id:      parseInt(values.id),
           offerId:  values.offerId,
           agentId : parseInt(localStorage.getItem('userId')),
           clientId: parseInt(this.props.match.params.id),
           propertyURL: values.propertyURL,
           propertyType: values.propertyType,
           propertyAddress:values.propertyAddress,
           transTypeId:2,
           commissionTypeId:parseInt(values.commissionTypeId),
           commisisonValue:parseInt(values.commisisonValue),
           askingPrice:parseInt(values.askingPrice),
           offerPrice:parseInt(values.offerPrice),
           offerDate:values.offerDate,
           closingDate:values.closingDate,
           refId:parseInt(values.refId), 
           refTypeId:1, 
           statusId:parseInt(values.statusId),
           isAccepted:'',
           conditions:'',
           notes:values.notes
      }
       this.props.updateClientBuyerOffer(payload);
      };  
  render() {
    const { clietDetail, listingtypes, addListings, onUpdateClientAddListing, userdetail, transStatusTypes, transactionCommTypes, buyeroffer} = this.props;
    const { isEdit, deleteModal, addListing, activeFilter } = this.state;
    const allLocal = localStorage.getItem('localData')
    const localization = JSON.parse(allLocal);
    const hasAcceptedOffer = addListings.some(addListing => addListing.anyAcceptedOffer);
   
    return (
      <React.Fragment>
        {this.props.loading && this.props.loading ? (
          <div className="loading">Loading&#8230;</div>
        ) : null}
        <ToastContainer autoClose={2000} />
        <DeleteModal
          show={deleteModal}
          onDeleteClick={this.handleDeleteAddListing}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />

        <div className="page-content project-page-content">
          <MetaTags>
            <title>Client Dashboard | Realty Space</title>
          </MetaTags>
          <Container fluid className="ps-0">
            <Row>
              <Col sm='2' className="pe-0">
                <ClientSidebar id={this.props.match.params.id} clientId={this.props.match.params.clientId} />
              </Col>
              <Col sm='10' className="ps-0 pe-0">
                <Row className="project-header">
                  <Col sm="7">
                    <ClientBuyerBreadcrumbs
                      title={this.props.t("Clients")}
                      breadcrumbItem={this.props.t(" Showing")}
                    />
                  </Col>
                  <Col sm="4" className="">
                    <h2 className="">{this.props.t(clietDetail.firstName + " " + clietDetail.lastName)}</h2>
                  </Col>
                </Row>
                <ClientBuyerMenu />
                <Row>
                  {/* <Col xl="3">
              <ClientLink id={this.props.match.params.id} clientId={this.props.match.params.clientId} />
              </Col> */}

                  <Col xl="12">
                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                       
                        createClientPotentialListingLists: [{
                          clientId: parseInt(this.props.match.params.id),
                          agentId: parseInt(localStorage.getItem('userId')),
                          mlsId: (this.state && this.state.mlsId) || "",
                          mlsurl: (this.state && this.state.mlsurl) || "",
                          listingDate: new Date(),
                          isInterested: true,
                          isActive: true,
                          addedBy: 'agent',
                          listingAddress:'',
                          listingtype:'',
                          listedPrice: (this.state && this.state.listedPrice) || "",
                          listingCity: (this.state && this.state.listingCity) || "",
                          notes: (this.state && this.state.notes) || "",
                          listingBedroom: (this.state && this.state.listingBedroom) || "",
                          listingBathroom: (this.state && this.state.listingBathroom) || "",
                        }]
                      }}
                      validationSchema={Yup.object().shape({
                        createClientPotentialListingLists: Yup.array().of(
                          Yup.object().shape({
                            mlsId: Yup.string().required("This is Required"),
                            listedPrice: Yup.number().typeError('Invalid Price').required("This is Required"),
                            listingBathroom: Yup.number().typeError('Invalid Number'),
                            listingBedroom: Yup.number().typeError('Invalid Number'),
                            listingParking: Yup.number().typeError('Invalid Number'),
                            listingCity: Yup.string().required("This is Required"),
                          })
                        )
                      })}

                      onSubmit={this.handleAddListingSubmit}

                    >
                    {({ values, errors, touched, handleChange, setFieldValue }) => (
                        <Form
                          className="needs-validation"
                        >
                          <Card>
                            <CardBody>
                              <div className="mt-0 card-title pheading">Add Listing</div>
                              <FieldArray name="createClientPotentialListingLists">
                            {({ insert, remove, push }) => (
                              <div>
                                {values.createClientPotentialListingLists.map((listing, index) => (
                                  <div key={index}>
                              <Row>

                                <Col sm="1">
                                  <FormGroup className="mb-3">
                                    <Label htmlFor={`createClientPotentialListingLists.${index}.mlsId`}>
                                      {this.props.t("Unit ID# *")}
                                    </Label>
                                    <Field
                                      name={`createClientPotentialListingLists.${index}.mlsId`}
                                      onChange={handleChange}
                                      type="text"

                                      className={"form-control" + (errors.createClientPotentialListingLists?.[index]?.mlsId && touched.createClientPotentialListingLists?.[index]?.mlsId ? " is-invalid" : "")}

                                      id={`createClientPotentialListingLists.${index}.mlsId`}
                                    />
                                    <ErrorMessage
                                      name={`createClientPotentialListingLists.${index}.mlsId`}
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </FormGroup>
                                </Col>
                                <Col sm="4">
                                  <FormGroup className="mb-3">
                                    <Label htmlFor="address">
                                      {this.props.t("Address *")}
                                    </Label>
                                    <PlacesAutocomplete
                            value={listing.listingAddress}
                              onChange={(value) => setFieldValue(`createClientPotentialListingLists.${index}.listingAddress`, value)}
                              onSelect={(value) => this.handlePlaceSelected(value, index, setFieldValue)}
                            >
                              {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div>
                                  <input
                                    {...getInputProps({
                                      placeholder: 'Enter Address...',
                                      className: 'form-control',
                                     
                                    })}
                                  />
                                  <div className="">
                                    {loading ? <div>Loading...</div> : null}

                                    {suggestions.map((suggestion, i) => {
                                      const className = suggestion.active
                                        ? 'suggestion-item--active'
                                        : 'suggestion-item';
                                      const style = suggestion.active
                                        ? { backgroundColor: '#007BFF', color: '#fff', cursor: 'pointer', padding: '10px' }
                                        : { backgroundColor: '#fff', cursor: 'pointer', padding: '10px' };

                                      return (
                                        <div
                                        key={i}
                                          {...getSuggestionItemProps(suggestion, {
                                            style,
                                          })}
                                        >
                                          {suggestion.description}
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              )}
                            </PlacesAutocomplete>

                                        <ErrorMessage
                                          name={`createClientPotentialListingLists.${index}.listingAddress`}
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                 
                                  </FormGroup>
                                </Col>
                                <Col sm="3">
                                  <FormGroup className="mb-3">
                                    <Label htmlFor={`createClientPotentialListingLists.${index}.mlsurl`}>
                                      {this.props.t("Public Link")}
                                    </Label>
                                    <Field
                                      name={`createClientPotentialListingLists.${index}.mlsurl`}
                                      onChange={handleChange}
                                      type="text"

                                      className={"form-control" + (errors.createClientPotentialListingLists?.[index]?.mlsurl && touched.createClientPotentialListingLists?.[index]?.mlsurl ? " is-invalid" : "")}

                                      id={`createClientPotentialListingLists.${index}.mlsurl`}
                                    />
                                    <ErrorMessage
                                      name={`createClientPotentialListingLists.${index}.mlsurl`}
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </FormGroup>
                                </Col>
                                <Col sm="2">
                                  <FormGroup className="mb-3">
                                    <Label htmlFor={`createClientPotentialListingLists.${index}.listedPrice`}>
                                      {this.props.t("Listed Price*")}
                                    </Label>
                                    <Field
                                      name={`createClientPotentialListingLists.${index}.listedPrice`}
                                      onChange={handleChange}
                                      type="text"

                                      className={"form-control" + (errors.createClientPotentialListingLists?.[index]?.listedPrice && touched.createClientPotentialListingLists?.[index]?.listedPrice ? " is-invalid" : "")}

                                      id={`createClientPotentialListingLists.${index}.listedPrice`}
                                    />
                                    <ErrorMessage
                                      name={`createClientPotentialListingLists.${index}.listedPrice`}
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </FormGroup>

                                </Col>

                                <Col sm="2">
                                  <FormGroup className="mb-3">
                                    <Label htmlFor={`createClientPotentialListingLists.${index}.listingCity`}>
                                      {this.props.t("City")}
                                    </Label>
                                    <Field
                                      name={`createClientPotentialListingLists.${index}.listingCity`}
                                      onChange={handleChange}
                                      type="text"

                                      className={"form-control" + (errors.createClientPotentialListingLists?.[index]?.listingCity && touched.createClientPotentialListingLists?.[index]?.listingCity ? " is-invalid" : "")}

                                      id={`createClientPotentialListingLists.${index}.listingCity`}
                                    />
                                    <ErrorMessage
                                      name={`createClientPotentialListingLists.${index}.listingCity`}
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </FormGroup>

                                </Col>
                                <Col sm="2">
                                  <FormGroup className="mb-3">
                                    <Label htmlFor={`createClientPotentialListingLists.${index}.listingType`}>
                                      {this.props.t("Type")}
                                    </Label>
                                    <Field as="select" name={`createClientPotentialListingLists.${index}.listingType`}
                                       className={"form-control" + (errors.createClientPotentialListingLists?.[index]?.listingType && touched.createClientPotentialListingLists?.[index]?.listingType ? " is-invalid" : "")}

                                      onChange={handleChange}>
                                      <option value="">Select</option>
                                      {map(listingtypes, (listingtype, classkey) => (
                                        <option key={classkey} value={listingtype.id}>{listingtype.listingTypeTitle}</option>
                                      ))}
                                    </Field>
                                    <ErrorMessage
                                      name={`createClientPotentialListingLists.${index}.listingType`}
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </FormGroup>
                                </Col>
                                <Col sm="1">
                                  <FormGroup className="mb-3">
                                    <Label htmlFor={`createClientPotentialListingLists.${index}.listingBedroom`}>
                                      {this.props.t("Bedroom")}
                                    </Label>
                                    <Field
                                      name={`createClientPotentialListingLists.${index}.listingBedroom`}
                                      onChange={handleChange}
                                      type="text"

                                      className={"form-control" + (errors.createClientPotentialListingLists?.[index]?.listingBedroom && touched.createClientPotentialListingLists?.[index]?.listingBedroom ? " is-invalid" : "")}

                                      id={`createClientPotentialListingLists.${index}.listingBedroom`}
                                    />
                                    <ErrorMessage
                                      name={`createClientPotentialListingLists.${index}.listingBedroom`}
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </FormGroup>

                                </Col>
                                <Col sm="1">
                                  <FormGroup className="mb-3">
                                    <Label htmlFor={`createClientPotentialListingLists.${index}.listingBathroom`}>
                                      {this.props.t("Bathroom")}
                                    </Label>
                                    <Field
                                      name={`createClientPotentialListingLists.${index}.listingBathroom`}
                                      onChange={handleChange}
                                      type="text"

                                      className={"form-control" + (errors.createClientPotentialListingLists?.[index]?.listingBathroom && touched.createClientPotentialListingLists?.[index]?.listingBathroom ? " is-invalid" : "")}

                                      id={`createClientPotentialListingLists.${index}.listingBathroom`}
                                    />
                                    <ErrorMessage
                                      name={`createClientPotentialListingLists.${index}.listingBathroom`}
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </FormGroup>

                                </Col>
                                <Col sm="1">
                                  <FormGroup className="mb-3">
                                    <Label htmlFor={`createClientPotentialListingLists.${index}.listingParking`}>
                                      {this.props.t("Parking")}
                                    </Label>
                                    <Field
                                      name={`createClientPotentialListingLists.${index}.listingParking`}
                                      onChange={handleChange}
                                      type="text"

                                      className={"form-control" + (errors.createClientPotentialListingLists?.[index]?.listingParking && touched.createClientPotentialListingLists?.[index]?.listingParking ? " is-invalid" : "")}

                                      id="mls"
                                    />
                                    <ErrorMessage
                                      name={`createClientPotentialListingLists.${index}.listingParking`}
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </FormGroup>

                                </Col>
                                <Col sm="5">
                                  <FormGroup className="mb-3">
                                    <Label htmlFor={`createClientPotentialListingLists.${index}.notes`}>
                                      {this.props.t("Notes")}
                                    </Label>
                                    <Field
                                      name={`createClientPotentialListingLists.${index}.notes`}
                                      onChange={handleChange}
                                      type="text"

                                      className={"form-control" + (errors.createClientPotentialListingLists?.[index]?.notes && touched.createClientPotentialListingLists?.[index]?.notes ? " is-invalid" : "")}

                                      id={`createClientPotentialListingLists.${index}.notes`}
                                    />
                                    <ErrorMessage
                                      name={`createClientPotentialListingLists.${index}.notes`}
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </FormGroup>

                                </Col>
                                      <Col sm="2">
                                      {index == 0 && (   
                                      <Button type="button" style={{marginTop:33}} className="btn btn-primary" onClick={() => push({
                                          clientId: parseInt(this.props.match.params.id),
                                          agentId: parseInt(localStorage.getItem('userId')),
                                          mlsId: "",
                                          mlsurl: "",
                                          listingAddress:"",
                                          listedPrice: "",
                                          listingType: "",
                                          listingCity: "",
                                          notes: "",
                                          listingBedroom: "",
                                          listingBathroom: "",
                                          listingParking: "",
                                          listingDate: new Date(),
                                          isInterested: true,
                                          isActive: true,
                                          addedBy: 'agent',
                                        })}><i className=" bx bx-plus-medical" /></Button>
                                      )}
                                      
                                        {index !== 0 && (          
                              <Button
                                type="button"
                                className="btn btn-danger"
                                onClick={() => remove(index)}
                                style={{ marginTop: 33 }}
                              >
                                <i className="bx bx-trash"></i>
                              </Button>
                              )}
                                      </Col>
                                </Row>
                                
                                  </div>
                                   ))}
                             </div>
                              )}
                            </FieldArray>
                            <SaveButton></SaveButton>
                           


                            </CardBody>
                          </Card>

                        </Form>
                      )}
                    </Formik>
                    <Row>
                      <Col sm="12">
                        <Card>
                          <CardBody>

                            <h5 className="card-title">Listing History
                              <span className="float-end">
                                <i className='bx bxs-cloud-upload icon-upload'></i> <small>Sent /</small> <i className='bx bxs-cloud-download icon-download'></i> <small>Recieved</small>
                              </span></h5>
                            <Row>
                              <Col lg="12">
                                <Nav tabs className="projectTab projectNav b-list-table">
                                <NavItem>
                                  <NavLink
                                    className={`nav-link ${activeFilter === 'All' ? 'active' : ''}`}
                                    onClick={() => this.handleTabClick('All')}
                                  >
                                    <span className="d-none d-sm-block">{this.props.t("All")}</span>
                                  </NavLink>
                                </NavItem>
                                <NavItem>
                                  <NavLink
                                    className={`nav-link ${activeFilter === 'Upcoming' ? 'active' : ''}`}
                                    onClick={() => this.handleTabClick('Upcoming')}
                                  >
                                    <span className="d-none d-sm-block">{this.props.t("Upcoming")}</span>
                                  </NavLink>
                                </NavItem>
                                <NavItem>
                                  <NavLink
                                    className={`nav-link ${activeFilter === 'Scheduled' ? 'active' : ''}`}
                                    onClick={() => this.handleTabClick('Scheduled')}
                                  >
                                    <span className="d-none d-sm-block">{this.props.t("Scheduled")}</span>
                                  </NavLink>
                                </NavItem>
                                  <NavItem>
                                    <Link  
                                       className={`nav-link ${activeFilter === 'Sent' ? 'active' : ''}`}
                                       onClick={() => this.handleTabClick('Sent')}

                                    >
                                      <span className="d-none d-sm-block">{this.props.t("Sent")}</span>

                                    </Link>
                                  </NavItem>
                                  <NavItem>
                                    <Link 
                                       className={`nav-link ${activeFilter === 'Received' ? 'active' : ''}`}
                                       onClick={() => this.handleTabClick('Received')}
                                    >
                                      <span className="d-none d-sm-block">{this.props.t("Received")}</span>

                                    </Link>
                                  </NavItem>

                                 
                                </Nav>

                              </Col>

                            </Row>
                            <div className="table-responsive">
                              <table className="table table-bordered border-primary align-middle table-nowrap mb-0">
                                <thead className="">
                                  <tr>

                                    <th>Date</th>
                                    <th>Address</th>
                                    <th>Asking price</th>
                                    <th>City </th>


                                    <th>Listing Status </th>
                                    <th>Showing Status  </th>
                                    <th>Action </th>
                                  </tr>
                                </thead>
                                <tbody>
                               
                                {map(addListings, (addListing, index) => (
                                 <tr key={index}>
                                    <td><Moment format='DD MMM, YYYY'>{addListing.listingDate}</Moment> / <a href={addListing.mlsurl}>{addListing.mlsId} <i className=" bx bx-link-external"></i></a></td>
                                    <td> {addListing.listingAddress} </td>
                                    <td><CurrencyFormat value={addListing.listedPrice} displayType={'text'} thousandSeparator={true} prefix={ userdetail?.agentDefaultCurrencyCode + " "	} /></td>
                                    <td>{addListing.listingCity} </td>
                                    <td>{ addListing.listingStatusId ? localization["AgentClientPotentialListingStatus_"+ addListing.listingStatusId]: 'N/A'}</td>
                                    <td>{ addListing.listingStatusId ? localization["AgentClientPotentialShowingStatus_"+ addListing.listingStatusId]: 'N/A'}</td>

                                    <td><Link to="#" className="btn btn-action" onClick={() => this.toggleRightCanvas(addListing)}>Manage</Link> {" "} 
                                    { hasAcceptedOffer ? (
                                    <Link 
                                    to="#" 
                                    className="btn btn-green" 
                                    onClick={() => this.toggleOfferAccepted(addListing)}
                                  >
                                    <i className="bx bx-dollar"></i> Offer
                                  </Link>
                                    ) : (
                                      <button className="btn btn-green" disabled>
                                        <i className="bx bx-dollar"></i> Offer
                                      </button>
                                    )}
                                   </td>
                                  </tr>
                                ))}
                                </tbody>
                              </table>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Col>

                </Row>
              </Col>
            </Row>

          </Container>
        </div>

        <Offcanvas
          isOpen={this.state.isRight}
          direction="end"
          toggle={this.toggleRightCanvas}
        >
          <OffcanvasHeader toggle={this.toggleRightCanvas}>
            Manage
          </OffcanvasHeader>
          <OffcanvasBody>
            <Nav pills className="navtab-bg nav-justified">
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: this.state.activeTab1 === "1",
                  })}
                  onClick={() => {
                    this.toggle1("1");
                  }}
                >
                  Detail
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: this.state.activeTab1 === "2",
                  })}
                  onClick={() => {
                    this.toggle1("2");
                  }}
                >
                  Message
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: this.state.activeTab1 === "3",
                  })}
                  onClick={() => {
                    this.toggle1("3");
                  }}
                >
                  Images
                </NavLink>
              </NavItem>

            </Nav>
            <TabContent
              activeTab={this.state.activeTab1}
              className="p-3 text-muted"
            >
              <TabPane tabId="1">
                <div className="tabContent">
                  <Formik
                    enableReinitialize={true}
                    initialValues={{
                      id: addListing.id,
                      clientPotentialListingId: addListing.clientPotentialListingId,
                      clientId: addListing.clientId,
                      agentId: addListing.agentId,
                      mlsId: addListing.mlsId,
                      mlsurl: addListing.mlsurl,
                      listingDate: addListing.listingDate,
                      listingStatusId: addListing.listingStatusId,
                      isInterested: addListing.isInterested,
                      isActive: addListing.isActive,
                      addedBy: addListing.addedBy,
                      listedPrice: addListing.listedPrice,
                      listingType: addListing.listingType,
                      listingBedroom: addListing.listingBedroom,
                      listingBathroom: addListing.listingBathroom,
                      listingParking: addListing.listingParking,
                      listingAddress: addListing.listingAddress,
                      listingCity: addListing.listingCity,
                      notes: addListing.notes,
                      showingDate: addListing.showingDate,
                      startTime: addListing.startTime,
                      endTime: addListing.endTime,
                      clientNotes:addListing.clientNotes,
                      likeDislike:addListing.likeDislike,
                      statusId:addListing.statusId,
                      feedback:addListing.feedback
                    }}
                    validationSchema={Yup.object().shape({
                   
                      mlsId: Yup.string().required("This is Required"),
                      listedPrice: Yup.number().typeError('Invalid Price').required("This is Required"),
                      listingBathroom: Yup.number().typeError('Invalid Number'),
                      listingBedroom: Yup.number().typeError('Invalid Number'),
                      listingParking: Yup.number().typeError('Invalid Number'),
                      listingCity: Yup.string().required("This is Required"),

                    })}

                    onSubmit={values => {
                      if (isEdit) {
                        const updateAddListing = {
                          id: values.id,
                          clientPotentialListingId: values.clientPotentialListingId,
                          clientId: parseInt(values.clientId),
                          agentId: parseInt(values.agentId),
                          mlsId: values.mlsId,
                          mlsurl: values.mlsurl,
                          listingDate: values.listingDate,
                          listingStatusId: parseInt(values.listingStatusId),
                          isInterested: values.isInterested,
                          isActive: values.isActive,
                          addedBy: values.addedBy,
                          listedPrice: parseInt(values.listedPrice),
                          listingType: values.listingType ? parseInt(values.listingType) : 0,
                          listingBedroom: values.listingBedroom ? parseInt(values.listingBedroom) : 0,
                          listingBathroom: values.listingBathroom?  parseInt(values.listingBathroom): 0,
                          listingParking: values.listingParking ?  parseInt(values.listingParking) : 0,
                          listingAddress: values.listingAddress,
                          listingCity: values.listingCity,
                          notes: values.notes,
                          showingDate: values.showingDate,
                          startTime: values.startTime,
                          endTime: values.endTime,
                          clientNotes:values.clientNotes,
                          likeDislike:values.likeDislike,
                          statusId:parseInt(values.statusId),
                          feedback:values.feedback
                        };
  
                        onUpdateClientAddListing(updateAddListing);
                      } else {
                        
                      }
                      this.toggleRight();
                    }}

                  >
                    {({ errors, touched, values, handleChange, setFieldValue }) => (
                      <Form
                        className="needs-validation"
                      >
                        <Row>
                          <Col sm="12">
                              <h5>Client Response : </h5>
                            </Col>
                            <Col sm="12">
                            <FormGroup className="mb-3">
                              <Label htmlFor="mlsId">
                                {this.props.t("Property Status")}
                              </Label>
                              <Field as="select" name="listingType"
                                      className={
                                        "form-control" +
                                        (errors.listingType && touched.listingType
                                          ? " is-invalid"
                                          : "")
                                      }

                                      onChange={handleChange}>
                                      <option value="">Select</option>
                                      {map(listingtypes, (listingtype, classkey) => (
                                        <option key={classkey} value={listingtype.id}>{listingtype.listingTypeTitle}</option>
                                      ))}
                                    </Field>
                              <ErrorMessage
                                name="mlsId"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                            </Col>
                            <hr />
                          <Col sm="12">
                            <FormGroup className="mb-3">
                              <Label htmlFor="mlsId">
                                {this.props.t("Unit ID#")}
                              </Label>
                              <Field
                                name="mlsId"
                                onChange={handleChange}
                                type="text"

                                className={
                                  "form-control" +
                                  (errors.mlsId && touched.mlsId
                                    ? " is-invalid"
                                    : "")
                                }

                                id="mlsId"
                              />
                              <ErrorMessage
                                name="mlsId"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </Col>
                          <Col sm="12">
                            <FormGroup className="mb-3">
                              <Label htmlFor="listingAddress">
                                {this.props.t("Address")}
                              </Label>
                              <PlacesAutocomplete
                            value={values.listingAddress}
                              onChange={(value) => setFieldValue('listingAddress', value)}
                              onSelect={(value) => this.handleEditPlaceSelected(value, setFieldValue)}
                            >
                              {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div>
                                  <input
                                    {...getInputProps({
                                      placeholder: 'Enter Address...',
                                      className: 'form-control',
                                     
                                    })}
                                  />
                                  <div className="">
                                    {loading ? <div>Loading...</div> : null}

                                    {suggestions.map((suggestion, i) => {
                                      const className = suggestion.active
                                        ? 'suggestion-item--active'
                                        : 'suggestion-item';
                                      const style = suggestion.active
                                        ? { backgroundColor: '#007BFF', color: '#fff', cursor: 'pointer', padding: '10px' }
                                        : { backgroundColor: '#fff', cursor: 'pointer', padding: '10px' };

                                      return (
                                        <div
                                        key={i}
                                          {...getSuggestionItemProps(suggestion, {
                                            style,
                                          })}
                                        >
                                          {suggestion.description}
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              )}
                            </PlacesAutocomplete>
                              <ErrorMessage
                                name="address"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </Col>
                          <Col sm="12">
                            <FormGroup className="mb-3">
                              <Label htmlFor="mlsurl">
                                {this.props.t("Public Link")}
                              </Label>
                              <Field
                                name="mlsurl"
                                onChange={handleChange}
                                type="text"

                                className={
                                  "form-control" +
                                  (errors.mlsId && touched.mlsurl
                                    ? " is-invalid"
                                    : "")
                                }

                                id="mlsurl"
                              />
                              <ErrorMessage
                                name="mlsurl"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </Col>
                          <Col sm="4">
                            <FormGroup className="mb-3">
                              <Label htmlFor="listedPrice">
                                {this.props.t("Listed Price*")}
                              </Label>
                              <Field
                                name="listedPrice"
                                onChange={handleChange}
                                type="text"

                                className={
                                  "form-control" +
                                  (errors.listedPrice && touched.listedPrice
                                    ? " is-invalid"
                                    : "")
                                }

                                id="listedPrice"
                              />
                              <ErrorMessage
                                name="listedPrice"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>

                          </Col>

                          <Col sm="4">
                            <FormGroup className="mb-3">
                              <Label htmlFor="listingCity">
                                {this.props.t("City")}
                              </Label>
                              <Field
                                name="listingCity"
                                onChange={handleChange}
                                type="text"

                                className={
                                  "form-control" +
                                  (errors.listingCity && touched.listingCity
                                    ? " is-invalid"
                                    : "")
                                }

                                id="listingCity"
                              />
                              <ErrorMessage
                                name="listingCity"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>

                          </Col>
                          <Col sm="4">
                            <FormGroup className="mb-3">
                              <Label htmlFor="listingType">
                                {this.props.t("Type")}
                              </Label>
                              <Field as="select" name="listingType"
                                      className={
                                        "form-control" +
                                        (errors.listingType && touched.listingType
                                          ? " is-invalid"
                                          : "")
                                      }

                                      onChange={handleChange}>
                                      <option value="">Select</option>
                                      {map(listingtypes, (listingtype, classkey) => (
                                        <option key={classkey} value={listingtype.id}>{listingtype.listingTypeTitle}</option>
                                      ))}
                                    </Field>
                              <ErrorMessage
                                name="listingType"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </Col>
                          <Col sm="4">
                            <FormGroup className="mb-3">
                              <Label htmlFor="listingBedroom">
                                {this.props.t("Bedroom")}
                              </Label>
                              <Field
                                name="listingBedroom"
                                onChange={handleChange}
                                type="text"

                                className={
                                  "form-control" +
                                  (errors.listingBedroom && touched.listingBedroom
                                    ? " is-invalid"
                                    : "")
                                }

                                id="listingBedroom"
                              />
                              <ErrorMessage
                                name="listingBedroom"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>

                          </Col>
                          <Col sm="4">
                            <FormGroup className="mb-3">
                              <Label htmlFor="listingBathroom">
                                {this.props.t("Bathroom")}
                              </Label>
                              <Field
                                name="listingBathroom"
                                onChange={handleChange}
                                type="text"

                                className={
                                  "form-control" +
                                  (errors.listingBathroom && touched.listingBathroom
                                    ? " is-invalid"
                                    : "")
                                }

                                id="listingBathroom"
                              />
                              <ErrorMessage
                                name="listingBathroom"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>

                          </Col>
                          <Col sm="4">
                            <FormGroup className="mb-3">
                              <Label htmlFor="listingParking">
                                {this.props.t("Parking")}
                              </Label>
                              <Field
                                name="listingParking"
                                onChange={handleChange}
                                type="text"

                                className={
                                  "form-control" +
                                  (errors.listingParking && touched.listingParking
                                    ? " is-invalid"
                                    : "")
                                }

                                id="listingParking"
                              />
                              <ErrorMessage
                                name="listingParking"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>

                          </Col>
                          <Col sm="12">
                            <FormGroup className="mb-3">
                              <Label htmlFor="notes">
                                {this.props.t("Notes")}
                              </Label>
                              <Field
                                name="notes"
                                onChange={handleChange}
                                as="textarea"

                                className={
                                  "form-control" +
                                  (errors.notes && touched.notes
                                    ? " is-invalid"
                                    : "")
                                }

                                id="notes"
                              />
                              <ErrorMessage
                                name="notes"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>

                          </Col>

                        </Row>
                        <Row>

                          <div className="clearFix">
                          <UpdateButton>{this.props.t("Update")}</UpdateButton>{ " " } <RsLink onClick={this.toggleRightCanvas}>Cancel</RsLink> {" "} <RsLink onClick={() => this.onClickDelete(addListing)}>Delete</RsLink>
                            
                           </div>

                        </Row>
                      </Form>
                    )}
                  </Formik>
                </div>
              </TabPane>
              <TabPane tabId="2">
                <div className="tabContent">
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                      clientId: parseInt(this.props.match.params.id),
                      agentId: parseInt(localStorage.getItem('userId')),
                      mlsId: (this.state && this.state.mlsId) || "",
                      mlsurl: (this.state && this.state.mlsurl) || "",
                      listingDate: new Date(),
                      listingStatusId: 1,
                      showingType:"1",
                      isInterested: true,
                      isActive: true,
                      addedBy: 'agent',
                      listedPrice: (this.state && this.state.listedPrice) || "",
                      listingCity: (this.state && this.state.listingCity) || "",
                      notes: (this.state && this.state.notes) || "",
                    }}
                    validationSchema={Yup.object().shape({
                      //mlsId: Yup.string().required("This is Required"),
                      mlsurl: Yup.string().required("This is Required"),
                      listedPrice: Yup.number().typeError('Price should be a number').required("This is Required")

                    })}

                    onSubmit={this.handleAddListingSubmit}

                  >
                    {({ errors, touched, values, handleChange, setFieldValue }) => (
                      <Form
                        className="needs-validation"
                      >
                        <Row>

                        <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="builderName">
                          {this.props.t("Type Of Activity")}
                        </Label>
                        <div className="clearfix"></div>
                        <div className="btn-group" role="group">
                          <Field type="radio" className="btn-check" onChange={handleChange}  name="showingType" id="btnradio4" value="1" autoComplete="off" />
                          <label className="btn btn-outline-secondary" htmlFor="btnradio4">New</label>

                          <Field type="radio" className="btn-check" onChange={handleChange}       name="showingType" id="btnradio5" value="2" autoComplete="off" />
                          <label className="btn btn-outline-secondary" htmlFor="btnradio5">Schedule listing</label>

                          <Field type="radio" className="btn-check" onChange={handleChange} name="showingType" id="btnradio6"  value="3" autoComplete="off" />
                          <label className="btn btn-outline-secondary" htmlFor="btnradio6">Re-schedule listing</label>
                         
                          
                        </div>
                        <ErrorMessage
                          name="activityType"
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                        </Col>  
                        { values.showingType != '1' && (
                          <>
                    <Col sm="4">
                        <FormGroup className="mb-3">
                                  <Label htmlFor="showingDate">
                                    {this.props.t("Schedule Date ")}
                                  </Label>
                                  <Flatpickr
                                    className={
                                      "form-control" +
                                      (errors.showingDate && touched.showingDate
                                        ? " is-invalid"
                                        : "")
                                    }
                                    placeholder=""
                                    value={values.showingDate}
                                    onChange={(value) => setFieldValue('showingDate', value[0])}
                                    options={{
                                      altInput: true,
                                      altFormat: "F j, Y",
                                      dateFormat: "Y-m-d"
                                    }}
                                  />
                                  

                                </FormGroup>
                        </Col>
                        <Col sm="4">
                        <FormGroup className="mb15">

                      <Label htmlFor="startTime">
                        {this.props.t("Start Time")}
                      </Label>
                      <InputGroup>
                        <Flatpickr
                          className="form-control d-block"
                          placeholder="Select time"
                          value={values.startTime}
                          onChange={(value) => setFieldValue('startTime', value[0])}
                          options={{
                            enableTime: true,
                            noCalendar: true,
                            dateFormat: "h:i"
                          }}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">
                            <i className="mdi mdi-clock-outline" />
                          </span>
                        </div>
                      </InputGroup>

                      </FormGroup>
                        </Col>
                        <Col md="4">
                            <FormGroup className="mb15">

                              <Label htmlFor="endTime">
                                {this.props.t("End Time")}
                              </Label>
                              <InputGroup>
                                <Flatpickr
                                  className="form-control d-block"
                                  placeholder="Select time"
                                  value={values.endTime}
                                  onChange={(value) => setFieldValue('endTime', value[0])}
                                  options={{
                                    enableTime: true,
                                    noCalendar: true,
                                    dateFormat: "h:i"
                                  }}
                                />
                                <div className="input-group-append">
                                  <span className="input-group-text">
                                    <i className="mdi mdi-clock-outline" />
                                  </span>
                                </div>
                              </InputGroup>

                            </FormGroup>
                          </Col>
                      </>
                        )}
                          <Col sm="12">
                            <FormGroup className="mb-3">
                              <Label htmlFor="notes">
                                {this.props.t("Enter your message")}
                              </Label>
                              <Field
                                name="notes"
                                onChange={handleChange}
                                as="textarea"

                                className={
                                  "form-control" +
                                  (errors.notes && touched.notes
                                    ? " is-invalid"
                                    : "")
                                }

                                id="mls"
                              />
                              <ErrorMessage
                                name="notes"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>

                          </Col>

                        </Row>
                        <Row>

                          <div className="clearFix">

                            <button type="submit" className="btn btn-primary w-md  mt20" name="addActivity" id="addActivity"> {this.props.t("Send")}</button>{" "}
                            <button type="button" className="btn btn-del-cancle w-md  mt20" onClick={this.toggleRightCanvas}> {this.props.t("Cancel")}</button>{" "}
                           </div>

                        </Row>
                      </Form>
                    )}
                  </Formik>

                    <div style={{marginTop:40}}>
                  <Table className="table table-sm m-0">
                      <Tr>
                        <Th>Date: 10th July (Recieved)</Th>
                      </Tr>
                      <Tr>
                        <Td>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, 
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>&nbsp;</Td>
                      </Tr>
                      <Tr>
                        <Th>Date: 10th July (Sent)</Th>
                      </Tr>
                      <Tr>
                        <Td>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, 
                        </Td>
                      </Tr>
                  </Table>
                  </div>
                </div>
              </TabPane>

              <TabPane tabId="3">
                <div className="tabContent">
                <Dropzone onDrop={this.handleAcceptedFiles}>
          {({ getRootProps, getInputProps }) => (
            <div className="dropzone">
              <div className="dz-message needsclick" {...getRootProps()}>
                <input {...getInputProps()} />
                <div className="mb-3">
                  <i className="display-4 text-muted bx bxs-cloud-upload" />
                </div>
                <h4>Drop files here or click to upload.</h4>
              </div>
            </div>
          )}
        </Dropzone>
                </div>
              </TabPane>

            </TabContent>

          </OffcanvasBody>
        </Offcanvas>

       

        <Offcanvas
                      isOpen={this.state.isOffer}
                      direction="end"
                      toggle={this.toggleOfferAccepted}
                    >
                      <OffcanvasHeader toggle={this.toggleOfferAccepted}>
                        Offer Accepted 
                      </OffcanvasHeader>
                      <OffcanvasBody>
                      <Formik
                  enableReinitialize={true}
                  initialValues={{
                    id:(buyeroffer?.id) ? buyeroffer?.id : 0,
                    offerId:(buyeroffer?.offerId) ? buyeroffer?.offerId : '',
                    agentId:  addListing.agentId,
                    clientId: this.props.match.params.id,
                    propertyURL: addListing.mlsurl,
                    propertyType:(buyeroffer?.propertyType) ? buyeroffer?.propertyType : addListing.listingType,
                    propertyAddress: (buyeroffer?.propertyAddress) ? buyeroffer?.propertyAddress : addListing.listingAddress,
                    transTypeId: 2,
                    commissionTypeId: (buyeroffer?.commissionTypeId) ? buyeroffer?.commissionTypeId : 0,
                    commisisonValue: (buyeroffer?.commisisonValue) ? buyeroffer?.commissionTypeId : 0,
                    askingPrice: (buyeroffer?.askingPrice) ? buyeroffer?.askingPrice : addListing.listedPrice,
                    offerPrice: (buyeroffer?.offerPrice) ? buyeroffer?.offerPrice : 0,
                    offerDate: (buyeroffer?.offerDate) ? buyeroffer?.offerDate: new Date(),
                    closingDate: (buyeroffer?.closingDate) ? buyeroffer?.closingDate: new Date(),
                    refId: addListing.id,
                    refTypeId:0,
                    statusId:(buyeroffer?.statusId) ? buyeroffer?.statusId : 0,
                    isAccepted: '',
                    conditions:'',
                    notes:(buyeroffer?.notes) ? buyeroffer?.notes : ''
                  }}
                  validationSchema={Yup.object().shape({
                   
                    propertyURL: Yup.string().required("This is Required"),
      
                  })}

                  onSubmit={(values) => {
                    if (buyeroffer && buyeroffer.id) {
                      this.offerUpdated(values); // Call offerUpdated if buyeroffer exists
                    } else {
                      this.offerSubmitted(values); // Call offerSubmitted if buyeroffer is empty
                    }
                  }}
                  
                  >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
             <Row>
                        <Col sm="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="propertyURL">
                              {this.props.t("Property URL link")}
                            </Label>
                            <Field
                              name="propertyURL"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.propertyURL && touched.propertyURL
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="mlsurl"
                            />
                             <ErrorMessage
                              name="propertyURL"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="6">
                            <FormGroup className="mb-3">
                              <Label htmlFor="propertyType">
                                {this.props.t("Type")}
                              </Label>
                              <Field as="select" name="propertyType"
                                      className={
                                        "form-control" +
                                        (errors.propertyType && touched.propertyType
                                          ? " is-invalid"
                                          : "")
                                      }

                                      onChange={handleChange}>
                                      <option value="">Select</option>
                                      {map(listingtypes, (listingtype, classkey) => (
                                        <option key={classkey} value={listingtype.listingTypeTitle}>{listingtype.listingTypeTitle}</option>
                                      ))}
                                    </Field>
                              <ErrorMessage
                                name="listingType"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </Col>
                          <Col sm="6">
                            <FormGroup className="mb-3">
                              <Label htmlFor="statusId">
                                {this.props.t("Offer Status")}
                              </Label>
                              <Field as="select" name="statusId"
                                      className={
                                        "form-control" +
                                        (errors.statusId && touched.statusId
                                          ? " is-invalid"
                                          : "")
                                      }

                                      onChange={handleChange}>
                                      <option value="">Select</option>
                                      {map(transStatusTypes, (transStatusType, classkey) => (
                                      <option value={transStatusType.id} key={classkey}>{transStatusType.title}</option>
                                    ))}
                                    
                                    </Field>
                              <ErrorMessage
                                name="statusId"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </Col>
                          <Col sm="12">
                                  <FormGroup className="mb-3">
                                    <Label htmlFor="address">
                                      {this.props.t("Property address")}
                                    </Label>
                                    <PlacesAutocomplete
                              value={values.propertyAddress}
                              onChange={(value) => setFieldValue(`propertyAddress`, value)}
                              //onSelect={(value) => this.handlePlaceSelected(value, setFieldValue)}
                            >
                              {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div>
                                  <input
                                    {...getInputProps({
                                      placeholder: 'Enter Address...',
                                      className: 'form-control',
                                     
                                    })}
                                  />
                                  <div className="">
                                    {loading ? <div>Loading...</div> : null}

                                    {suggestions.map((suggestion, i) => {
                                      const className = suggestion.active
                                        ? 'suggestion-item--active'
                                        : 'suggestion-item';
                                      const style = suggestion.active
                                        ? { backgroundColor: '#007BFF', color: '#fff', cursor: 'pointer', padding: '10px' }
                                        : { backgroundColor: '#fff', cursor: 'pointer', padding: '10px' };

                                      return (
                                        <div
                                        key={i}
                                          {...getSuggestionItemProps(suggestion, {
                                            style,
                                          })}
                                        >
                                          {suggestion.description}
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              )}
                            </PlacesAutocomplete>

                                        <ErrorMessage
                                          name={`propertyAddress`}
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                 
                                  </FormGroup>
                                </Col>
                        <Col sm="6">
                        <FormGroup className="mb-3">
                            <Label htmlFor="askingPrice">
                              {this.props.t("Asking Price")}
                            </Label>
                            <Field
                              name="askingPrice"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.askingPrice && touched.askingPrice
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="askingPrice"
                            />
                             <ErrorMessage
                              name="askingPrice"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="6">
                        <FormGroup className="mb-3">
                            <Label htmlFor="offerPrice">
                              {this.props.t("Final Price")}
                            </Label>
                            <Field
                              name="offerPrice"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.offerPrice && touched.offerPrice
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="offerPrice"
                            />
                             <ErrorMessage
                              name="offerPrice"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>
                       

                        <Col sm="6">
                        <FormGroup className="mb-3">
                            <Label htmlFor="commissionTypeId">
                              {this.props.t("Commision Type")}
                            </Label>
                            <Field as="select" name="commissionTypeId" id="commissionTypeId"  className={
                                    "form-control" +
                                    (errors.commision_type && touched.commision_type
                                      ? " is-invalid"
                                      : "")
                                  }
                                  onChange={handleChange}>
                                <option value="">Select</option>
                                {map(transactionCommTypes, (transactionCommType, classkey) => (
                                <option value={transactionCommType.id} key={classkey}>{transactionCommType.title}</option>
                              ))}
                               
                              </Field>
                             <ErrorMessage
                              name="commissionTypeId"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                       
                        </Col>


                        <Col sm="6">
                        <FormGroup className="mb-3">
                            <Label htmlFor="commisisonValue">
                              {this.props.t("Commission value ")}
                            </Label>
                            <Field
                              name="commisisonValue"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.commisisonValue && touched.commisisonValue
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="mls"
                            />
                             <ErrorMessage
                              name="commisisonValue"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                       
                        </Col>
                        

                        <Col sm="6">
                        <FormGroup className="mb-3">
                                  <Label htmlFor="offerDate">
                                    {this.props.t("Offer Date")}
                                  </Label>
                                  <Flatpickr
                                    className={
                                      "form-control" +
                                      (errors.offerDate && touched.offerDate
                                        ? " is-invalid"
                                        : "")
                                    }
                                    placeholder=""
                                    value={values.offerDate}
                                    onChange={(value) => setFieldValue('offerDate', value[0])}
                                    options={{
                                      altInput: true,
                                      altFormat: "F j, Y",
                                      dateFormat: "Y-m-d"
                                    }}
                                  />
                                  

                                </FormGroup>
                                </Col>
                                <Col sm="6">
                        <FormGroup className="mb-3">
                                  <Label htmlFor="closingDate">
                                    {this.props.t("Closing Date")}
                                  </Label>
                                  <Flatpickr
                                    className={
                                      "form-control" +
                                      (errors.closingDate && touched.closingDate
                                        ? " is-invalid"
                                        : "")
                                    }
                                    placeholder=""
                                    value={values.closingDate}
                                    onChange={(value) => setFieldValue('closingDate', value[0])}
                                    options={{
                                      altInput: true,
                                      altFormat: "F j, Y",
                                      dateFormat: "Y-m-d"
                                    }}
                                  />
                                  

                                </FormGroup>
                                </Col>
                                             
                        <Col sm="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="notes">
                              {this.props.t("Note")}
                            </Label>
                            <textarea
                              name="notes"
                              onChange={handleChange}
                              type="textarea"
                              value={values.notes}
                              className={
                                "form-control" +
                                (errors.notes && touched.notes
                                  ? " is-invalid"
                                  : "")
                              }
                              id="notes"
                            />
                             <ErrorMessage
                              name="notes"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                       
                        </Col>
                        <Col sm="12">
                        <div className="clearfix">
                          
                        <UpdateButton>{this.props.t("Update")}</UpdateButton>{ " " } <RsLink onClick={closed}>Close</RsLink>
                          </div>
                        </Col>
                    </Row>
                
            </Form>
                  )}
			 </Formik>

                      </OffcanvasBody>
        </Offcanvas>
      </React.Fragment>
    )
  }
}
ClientListing.propTypes = {
  t: PropTypes.any,
  history: PropTypes.object,
  match: PropTypes.object,
  addClientAddListing: PropTypes.func,
  onGetClientDetail: PropTypes.func,
  clietDetail: PropTypes.object,
  addListings: PropTypes.array,
  onGetClientAddListing: PropTypes.func,
  onDeleteAddListing: PropTypes.func,
  onUpdateClientAddListing: PropTypes.func,
  loading: PropTypes.object,
  onGetListingType:PropTypes.func,
  listingtypes:PropTypes.array,
  onGetUserDetail:PropTypes.func,
  userdetail:PropTypes.object,
  onGetTransactonStatusType:PropTypes.func,
  transStatusTypes: PropTypes.array,
  onGetTransactonCommType: PropTypes.func,
  transactionCommTypes: PropTypes.array,
  addClientBuyerOffer:PropTypes.func,
  onGetClientBuyerOffer:PropTypes.func,
  buyeroffer : PropTypes.object,
  updateClientBuyerOffer:PropTypes.func,
}
const mapStateToProps = ({ Clients,AppoinmentObject, Login }) => (
  {
    clietDetail: Clients.clietDetail,
    addListings: Clients.addListings,
    listingtypes:AppoinmentObject.listingtypes,
    loading: Clients.loading,
    userdetail : Login.userdetail,
    transStatusTypes: AppoinmentObject.transStatusTypes,
    transactionCommTypes: AppoinmentObject.transactionCommTypes,
    buyeroffer : Clients.buyeroffer,
  })
const mapDispatchToProps = dispatch => ({
  onGetClientDetail: clientId => dispatch(getClientDetail(clientId)),
  onGetListingType: agentId => dispatch(getListingType(agentId)),
  addClientAddListing: (data) => dispatch(addClientAddListing(data)),
  onGetClientAddListing: (clientId, agentId) => dispatch(getClientAddListing(clientId, agentId)),
  onDeleteAddListing: (id) => dispatch(deleteClientAddListing(id)),
  onUpdateClientAddListing: (data) => dispatch(ClientAddListingUpdate(data)),
  onGetUserDetail: agentId  => dispatch(getUserDetail(agentId)),
  onGetTransactonStatusType: (agentId, forType) => dispatch(getTransStatusType(agentId, forType)),
  onGetTransactonCommType: (agentId) => dispatch(getTransCommType(agentId)),
  addClientBuyerOffer: (data) => dispatch(addClientBuyerOffer(data)),
  onGetClientBuyerOffer:(refId) => dispatch(getClientBuyerOffer(refId)),
  updateClientBuyerOffer:(data) => dispatch(updateClientBuyerOffer(data))

});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ClientListing))
