import PropTypes from "prop-types"
import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"
import TabNavigation from "components/tablink/tablink"

class ClientActivityMenu extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }
    render() {
        const navItems = [
            {
                id: 'engagement',
                label: 'Engagements',
                link: `/clients/activity/engagement/${this.props.match.params.id}/${this.props.match.params.clientId}`
            },
            {
                id: 'deal',
                label: 'Deals',
                link: `/clients/activity/deal/${this.props.match.params.id}/${this.props.match.params.clientId}`
            },
            {
                id: 'appointment',
                label: 'Appointments',
                link: `/clients/activity/appointment/${this.props.match.params.id}/${this.props.match.params.clientId}`
            },
            {
                id: 'documents',
                label: 'Documents',
                link: `/clients/activity/documents/${this.props.match.params.id}/${this.props.match.params.clientId}`
            },
            {
                id: 'message',
                label: 'Messages',
                link: `/clients/activity/message/${this.props.match.params.id}/${this.props.match.params.clientId}`
            },
            {
                id: 'note',
                label: 'Notes',
                link: `/clients/activity/note/${this.props.match.params.id}/${this.props.match.params.clientId}`
            }
        ];
        return (
            <React.Fragment>
                <TabNavigation navItems={navItems}   />             

              
            </React.Fragment>
        )
    }
}
ClientActivityMenu.propTypes = {
    match: PropTypes.object
}
export default withRouter(ClientActivityMenu)
