import React, { Component } from "react"
import PropTypes from 'prop-types'

import { Alert, Card, CardBody, Col, Container, Row } from "reactstrap"

// Redux
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"


// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation"

//import { loginUserAction } from '../../actions/authenticationActions';
import { apiError, loginUser } from "../../store/actions"

// import images
import profile from "../../assets/images/profile-img.png"
import realtyspacelogo from "../../assets/images/realtyspace.png"
import lightlogo from "../../assets/images/logo-light.svg"
import brokerLogin from "../../assets/img/broker-login.png"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {}

    // handleValidSubmit
    this.handleValidSubmit = this.handleValidSubmit.bind(this)
  }

  // handleValidSubmit
  handleValidSubmit(event, values) {
    this.props.loginUser(values, this.props.history)
    //console.log(values);
  }

  componentDidMount() {
    this.props.apiError("")
  }

  

  render() {
   
    
    return (
      <React.Fragment>
        <ToastContainer autoClose={2000} />
        <div className="account-pages">
        <Container>
            <Row className="justify-content-center align-items-center vh-100">
              <Col md={10}>
                <div className="overflow-hidden my-5">
                 
                  <CardBody className="pt-4">
                    <Row>
                   
                      <Col lg={4}>
                      <div className="login-heading">
                    <h1>Broker Login</h1>
                        </div>
                        <div className="p-2">
                        <AvForm
                            className="form-horizontal"
                            method="post"
                            onValidSubmit={this.handleValidSubmit}
                          >
                            {this.props.error && this.props.error ? (
                              <Alert color="danger">{this.props.error}</Alert>
                            ) : null}
                            <div className="mb-3 label-white">
                              <AvField
                                name="email"
                                label="Email Address"
                                value=""
                                className="form-control border-r-50"
                                placeholder="Enter email"
                                type="email"
                                required
                              />
                            </div>

                            <div className="mb-3 label-white">
                              <AvField
                                name="password"
                                label="Password"
                                value=""
                                type="password"
                                required
                                 className="form-control border-r-50"
                                placeholder="Enter Password"
                              />
                            </div>

                            <div className="form-check">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="customControlInline"
                              />
                              <label
                                className="form-check-label text-white"
                                htmlFor="customControlInline"
                              >
                                Remember me
                              </label>
                            </div>

                            <div className="mt-3 d-grid">
                              <button
                                className="btn btn-login btn-block"
                                type="submit"
                              >
                                Submit
                              </button>
                            </div>

                            <div className="mt-4 text-white">
                              <Link to="/forgot-password" className="text-white">
                                <i className="mdi mdi-lock me-1" /> Forgot your
                                password?
                              </Link>
                            </div>
                          </AvForm>
                        </div>
                      </Col>
                      <Col lg={2}></Col>
                      <Col md={6} lg={6} xl={5}>
                      <img src={brokerLogin} className="img-fluid" />
                    </Col>
                    </Row>
                  
                  </CardBody>
                </div>
                
              </Col>
             
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

Login.propTypes = {
  apiError: PropTypes.any,
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,

}

const mapStateToProps = state => {
  const { error } = state.Login
  return { error }
}

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError })(Login)
)