import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import { isEmpty, map, size } from "lodash";
import BreadcrumbClient from "../../components/Common/BreadcrumbClient"
import { withTranslation } from "react-i18next"
//import Lead from "./Lead";
import ClientLink from "./client-link";
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import Calender from "../Activities/calander"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios"
import DeleteModal from "../../components/Common/DeleteModal";
import { addClientAppointment, getClientDetail, getClientAppointment, deleteClientAppointment, updateClientAppointment} from "../../store/clients/actions"
import Moment from 'react-moment';
import Switch from "react-switch"
import ClientSidebar from "components/VerticalLayout/ClientSidebar";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Input,
  Button,
  ButtonGroup,
  InputGroup,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  
} from "reactstrap"
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import SaveButton from "components/buttons/save";
import ClientActivityBreadcrumbs from "components/Common/ClientActivityBreadcrumb";
import ClientActivityMenu from "./menu/client-activity-menu";

class ClientApp extends Component {
  constructor(props) {
    super(props)
    this.state = {
      appTypes: [],
      appPriories: [],
      appLocTypes: [],
      appointment:'',
      checked: true,
    
    }
    this.handleSwitch = this.handleSwitch.bind(this);
    this.handelValidAppointment = this.handelValidAppointment.bind(this)
    this.onClickDelete = this.onClickDelete.bind(this);
    this.toggleRightCanvas = this.toggleRightCanvas.bind(this);
    this.toggleBackdrop = this.toggleBackdrop.bind(this);

  }
  handleSwitch(checked) {
    this.setState({ checked });
  }

  toggleBackdrop() {
    this.setState({ isBackdrop: !this.state.isBackdrop });
  }
  toggleDeleteModal = () => {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal,
    }));
  };
  toggle() {
    this.setState(prevState => ({
      isRight: !prevState.isRight
    }));
  }
  onClickDelete = (appointment) => {
    this.setState({ appointment: appointment });
    this.setState({ deleteModal: true });
  };
  handleDeleteAppointment = () => {
    const { onDeleteAppointment } = this.props;
    const { appointment } = this.state;
    
    if (appointment.id !== undefined) {
      onDeleteAppointment(appointment);
      this.setState({ deleteModal: false });
    }
  };
  handelValidAppointment(value){
    //console.log(value)
    this.props.addClientAppointment(value, this.props.history);

  }
  componentDidMount() {
    const { match: { params }, onGetClientDetail, onGetClientAppointment } = this.props;
    onGetClientDetail(params.clientId);
    onGetClientAppointment(localStorage.getItem('userId'),params.id)
   

    const obj = JSON.parse(localStorage.getItem("authUser"));
    const token = obj.resultData.bearerToken;
    const str = 'Bearer ' + token;
    axios.get('https://rscoreapi.azurewebsites.net/api/AppointmentType/all', {
      headers: { Authorization: str }
    })
      .then(res => {
        const appTypes = res.data;
        this.setState({ appTypes });
      }),
      axios.get('https://rscoreapi.azurewebsites.net/api/AppointmentPriority/all', {
        headers: { Authorization: str }
      })
        .then(res => {
          const appPriories = res.data;
          this.setState({ appPriories });
        }),
      axios.get('https://rscoreapi.azurewebsites.net/api/AppointmentLocationType/all', {
        headers: { Authorization: str }
      })
        .then(res => {
          const appLocTypes = res.data;
          this.setState({ appLocTypes });
        })
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { appointments } = this.props;
    if (!isEmpty(appointments) && size(prevProps.appointments) !== size(appointments)) {
      this.setState({ appointments: {}, isEdit: false });
    }
  }
  toggleRightCanvas() {
    this.setState({ appointment: "", isEdit: false, isRight: !this.state.isRight });
    this.toggle();
  }
  toggleRightCanvas = arg => {
    const appointment = arg;
  
    this.setState({
      appointment: {
    
        id:appointment.id,
        appointmentId: appointment.appointmentId,
        appointmentTitle:appointment.appointmentTitle,
        contactName: appointment.contactName,
        contactPhone: appointment.contactPhone,
        contactEmail: appointment.contactEmail,
        appointmentStartDate: appointment.appointmentStartDate,
        startTime: appointment.startTime,
        endTime: appointment.endTime,
        appointmentType: appointment.appointmentType,
        appointmentStatus: appointment.appointmentStatus,
        appointmentPriority: appointment.appointmentPriority,
        appointmentReminder: appointment.appointmentReminder,
        clientId: appointment.clientId,
        appointmentDescription:appointment.appointmentDescription,
        appointmentLocationType:appointment.appointmentLocationType,
        appointmentLocation:appointment.appointmentLocation,
        locationLatitude:appointment.locationLatitude,
        locationLongitude:appointment.locationLongitude,
        isRepeat:appointment.isRepeat,
        repeatEveryType:appointment.repeatEveryType,
        repeatStep:appointment.repeatStep,
        repeatEndDate:appointment.repeatEndDate,
        agentId:appointment.agentId,
        isActive:appointment.isActive,
        agentNotes: appointment.agentNotes,
        addedBy: appointment.addedBy
      },
      isEdit: true,
    });
    this.toggle();
  };
  handleChange = arg =>  {
    const appointment = arg;
    const { onUpdateStatus } = this.props;
    const updateReg = {
      id:appointment.id,
      appointmentId: appointment.appointmentId,
      rejected: (appointment.rejected==true) ? false : true,
      appointmentTitle:appointment.appointmentTitle,
        contactName: appointment.contactName,
        contactPhone: appointment.contactPhone,
        contactEmail: appointment.contactEmail,
        appointmentStartDate: appointment.appointmentStartDate,
        startTime: appointment.startTime,
        endTime: appointment.endTime,
        appointmentType: appointment.appointmentType,
        appointmentStatus: appointment.appointmentStatus,
        appointmentPriority: appointment.appointmentPriority,
        appointmentReminder: appointment.appointmentReminder,
        clientId: appointment.clientId,
        appointmentDescription:appointment.appointmentDescription,
        appointmentLocationType:appointment.appointmentLocationType,
        appointmentLocation:appointment.appointmentLocation,
        locationLatitude:appointment.locationLatitude,
        locationLongitude:appointment.locationLongitude,
        isRepeat:appointment.isRepeat,
        repeatEveryType:appointment.repeatEveryType,
        repeatStep:appointment.repeatStep,
        repeatEndDate:appointment.repeatEndDate,
        agentId:appointment.agentId,
        isActive:appointment.isActive,
        contactNotes: appointment.contactNotes,
        addedBy: appointment.addedBy
    }

    onUpdateStatus(updateReg);
  };

  render() {
    const { clietDetail, appointments , onUpdateClientAppointment, loading} = this.props;
    const now = new Date();
    now.setMinutes(now.getMinutes() + 30);
    const edTime = new Date(now);
    const selectedValue = '';
    const { isEdit, deleteModal } = this.state;
    const appointment = this.state.appointment;
    const { checked } = this.state;

    const allLocal = localStorage.getItem('localData')
    const localization = JSON.parse(allLocal);

    console.log(localization);
    return (
      <React.Fragment>
          {this.props.loading && this.props.loading ? (
         <div className="loading">Loading&#8230;</div>
         ) : null}
         <ToastContainer autoClose={2000} />
         <DeleteModal
          show={deleteModal}
          onDeleteClick={this.handleDeleteAppointment}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />
        <div className="page-content project-page-content">
          <MetaTags>
            <title>Client Dashboard | Realty Space</title>
          </MetaTags>
          <Container fluid className="ps-0">
            <Row>
              <Col sm='2' className="pe-0">
                <ClientSidebar id={this.props.match.params.id} clientId={this.props.match.params.clientId} />
              </Col>
              <Col sm='10' className="ps-0 pe-0">
              <Row className="project-header">
                <Col sm="7">
             <ClientActivityBreadcrumbs
              title={this.props.t("Clients")}
              breadcrumbItem={this.props.t( " Engagement")}
            />
            </Col>
            <Col sm="4" className="">
                <h2 className="">{this.props.t( clietDetail.firstName + " " + clietDetail.lastName)}</h2>
                </Col>
            </Row>
            <ClientActivityMenu />
            <Row>
              {/* <Col xl="3">
              <ClientLink id={this.props.match.params.id} clientId={this.props.match.params.clientId} />
              </Col> */}

              <Col xl="12">
               
                <Row>
                  <Col sm="12">
                    <Card>
                      <CardBody>
                        <h5 className="card-title mb20">Active Engagement Page </h5>
                        <Row>
                          <Col sm='5'>
                          <h5 className="card-title mb20">Current Active Engagement</h5>
                          <Formik
                       enableReinitialize={true}
                       initialValues={{
                         
                       }}
                       validationSchema={Yup.object().shape({
                       
                       })}

                       onSubmit={values => {
                        
                     }}
                     >
                       {({ errors, touched, values, handleChange, setFieldValue }) => (
                         <Form
                         className="needs-validation"
                       >


                        <div className="d-flex justify-content-between mb-1">
                          <div>
                            <Field
                              name="isBuyer"
                              onChange={handleChange}   
                              className='form-check-input me-2'                         
                              type="checkbox"
                              id="isBuyer"
                            />
                            <Label htmlFor="isBuyer" className="form-check-label">
                              {this.props.t("Buy or Rent")}
                            </Label> 
                            </div>
                            <div>
                              
                            {/* <ButtonGroup>
                              <Button
                                color=""
                                onClick={() => setFieldValue('buyerType', 1)}
                                className={values.buyerType === 1 ? "t-button-active" : "btn btn-outline-secondary"}
                              >
                                Rent
                              </Button>
                              <Button
                                color=""
                                onClick={() => setFieldValue('buyerType', 2)}
                                className={values.buyerType === 2  ? "t-button-active" : "btn btn-outline-secondary"}
                              >
                                Purchase
                              </Button>
                            </ButtonGroup> */}
                            </div>
                        {/* </FormGroup> */}
                        </div>
                        <div className="d-flex justify-content-between mb-1">
                          <div>
                            <Field
                              name="isSeller"
                              onChange={handleChange}   
                              className='form-check-input me-2'                         
                              type="checkbox"
                              id="isSeller"
                              // checked='true'
                            />
                            <Label htmlFor="isSeller" className="form-check-label">
                              {this.props.t("Sell or Lease")}
                            </Label> 
                            </div>
                            <div>
                            {/* <ButtonGroup className="">
                              <Button
                                color="btn-outline-secondary"
                                onClick={() => setFieldValue('sellerType', 1)}
                                className={values.sellerType === 1 ? "t-button-active" : "btn btn-outline-secondary"}
                              >
                                Sell
                              </Button>
                              <Button
                                color=""
                                onClick={() => setFieldValue('sellerType', 2)}
                                className={values.sellerType === 2  ? "t-button-active" : "btn btn-outline-secondary"}
                              >
                                Lease
                              </Button>
                            </ButtonGroup> */}
                            {/* <Label htmlFor="buyType" className="form-check-label">
                              {this.props.t("purchase or Renat")}
                            </Label>  */}
                            </div>
                        {/* </FormGroup> */}
                        </div>
                        <FormGroup className="mb-3">
                            <Field
                              name="isInvestor"
                              onChange={handleChange}   
                              className='form-check-input me-2'                         
                              type="checkbox"
                              id="isInvestor"
                              // checked='true'
                            />
                            <Label htmlFor="isInvestor" className="form-check-label">
                              {this.props.t("Investor")}
                            </Label>
                        </FormGroup>
                        
                        <FormGroup className="mb-3">
                            <Label htmlFor="upload">
                              {this.props.t("Document")}
                            </Label>
                            <Field
                              name="upload"
                              onChange={handleChange}                            
                              type="file"
                              className={
                                "form-control" 
                              }
                              id="upload"
                            />
                          </FormGroup>
                          <div className="d-flex">
                          <h5 className="me-4">xyz.pdf</h5> <Link>Remove</Link>
                        </div>
                         <SaveButton />

                        </Form>
                       )}
                       </Formik>
                          </Col>
                          <Col sm='3'></Col>
                          <Col sm='4'>
                            {/* <div className="d-flex">
                          <span className="card-title me-3">Status</span>
                          <Switch 
                            name="status"
                            uncheckedIcon={false}
                            checkedIcon={false}
                            // onChange={handleChange}   
                            />
                            </div> */}
                          </Col>
                        </Row>
                        
                        
                        <hr/>
                        <h3>Past Engagement</h3>

                        <div className="table-responsive">
          {/* <table className="table table-bordered border-primary align-middle table-nowrap mb-0">
              <thead className="">
							  <tr>
								
								<th>Property#</th>
								<th>Address</th>
								<th>Date</th>
								<th>Type</th>
                <th>Document</th>
                <th>Action</th>
							  </tr>
							</thead>
							<tbody>
              <tr>
                <td><a href='#'>#H335234</a></td>
                <td>1815|Iron Stone sda asd ads</td>
                <td><Flatpickr
                                className={"form-control" }
                                options={{
                                  altInput: false,
                                  // minDate: "today",
                                  defaultDate: 'today',
                                  altFormat: "F j, Y",
                                  dateFormat: "j F, Y"
                                }}
                              />
                           </td>
                <td>Buyer(Purchase)</td>
                <td>adc.pdf</td>
                <td><a href='#' ><b><span onClick={() => this.toggleRightCanvas(appointment)} >Edit</span> | <span onClick={() => this.onClickDelete(appointment)}>Remove</span></b></a></td>
                
              </tr>

              </tbody>


              </table> */}

              <DataTable className="table table-bordered data-table align-middle table-nowrap mb-0 me-2" value={[{p:"#H335234",add:"1815|Iron Stone sda asd ads",date:"2024-04-12",type:"Buyer(Purchase)",doc:"abc,pdf"}]} paginator rows={10} rowsPerPageOptions={[10, 20, 30, 50, 100, 150, 200, 500 ]} currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries " paginatorTemplate=" FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown   " tableStyle={{ minWidth: '50rem' }}   >
                  <Column  field="p" header="Property#" body={(rowData) => (
                    <a href='#'>{rowData.p}</a>
                  )}></Column>
                  <Column  field="add" header="Address" ></Column>
                  <Column  field="lastContact" header="Date" body={(rowData) => (
                      <Flatpickr
                      className={"form-control" }
                      options={{
                        altInput: false,
                        // minDate: "today",
                        defaultDate: 'today',
                        altFormat: "F j, Y",
                        dateFormat: "j F, Y"
                      }}
                    />
                  )}></Column>
                  <Column  field="type" header="Type" ></Column>
                  <Column  field="doc" header="Document" ></Column>
                  <Column  field="lastContact" header="Action" body={(rowData) => (
                      <a href='#' ><b><span onClick={() => this.toggleRightCanvas(appointment)} >Edit</span> | <span onClick={() => this.onClickDelete(appointment)}>Remove</span></b></a>
                  )}></Column>
              </DataTable>
              </div>
                      
                      </CardBody>
                    </Card>
                  </Col>
                </Row>


              </Col>

            </Row>
            </Col>
            </Row>
            <Offcanvas
                      isOpen={this.state.isRight}
                      direction="end"
                      toggle={this.toggleRightCanvas}
                    >
                      <OffcanvasHeader toggle={this.toggleRightCanvas}>
                        Update 
                      </OffcanvasHeader>
                      <OffcanvasBody>
                      <Formik
                    enableReinitialize={true}
                    initialValues={{
                      id:appointment.id,
                      appointmentId: appointment.appointmentId,
                      appointmentTitle:appointment.appointmentTitle,
                      contactName: appointment.contactName,
                      contactPhone: appointment.contactPhone,
                      contactEmail: appointment.contactEmail,
                      appointmentStartDate: appointment.appointmentStartDate,
                      startTime: appointment.startTime,
                      endTime: appointment.endTime,
                      appointmentType: appointment.appointmentType,
                      appointmentStatus: appointment.appointmentStatus,
                      appointmentPriority: appointment.appointmentPriority,
                      appointmentReminder: appointment.appointmentReminder,
                      clientId: appointment.clientId,
                      appointmentDescription:appointment.appointmentDescription,
                      appointmentLocationType:appointment.appointmentLocationType,
                      appointmentLocation:appointment.appointmentLocation,
                      locationLatitude:appointment.locationLatitude,
                      locationLongitude:appointment.locationLongitude,
                      isRepeat:appointment.isRepeat,
                      repeatEveryType:appointment.repeatEveryType,
                      repeatStep:appointment.repeatStep,
                      repeatEndDate:appointment.repeatEndDate,
                      agentId:appointment.agentId,
                      isActive:appointment.isActive,
                      agentNotes: appointment.agentNotes,
                      addedBy: appointment.addedBy,
                      buyerType:1,
                      sellerType:1,

                      
                    }}


                    validationSchema={Yup.object().shape({
                      appointmentTitle: Yup.string().max(150,"150 Characters are allowed").required("Please Enter Meeting Agenda"),
                      appointmentType : Yup.number().required("This field is required"),
                      appointmentPriority   :  Yup.number().required("This field is required"),
                      appointmentLocationType  :  Yup.number().required("This field is required"),
                    })}

                    onSubmit={values => {
                      //console.log(isEdit);
                      if (isEdit) {
                        const updateApp = {
                          id:values.id,
                          appointmentId: values.appointmentId,
                          appointmentTitle:values.appointmentTitle,
                          contactName: values.contactName,
                          contactPhone: values.contactPhone,
                          contactEmail: values.contactEmail,
                          appointmentStartDate: values.appointmentStartDate,
                          startTime: values.startTime,
                          endTime: values.endTime,
                          appointmentType: (values.appointmentType) ? parseInt(values.appointmentType) : 0,
                          appointmentStatus: (values.appointmentStatus) ? parseInt(values.appointmentStatus) : 0,
                          appointmentPriority: (values.appointmentPriority) ? parseInt(values.appointmentPriority) : 0,
                          appointmentReminder: values.appointmentReminder,
                          clientId: values.clientId,
                          appointmentDescription:values.appointmentDescription,
                          appointmentLocationType:values.appointmentLocationType,
                          appointmentLocation:values.appointmentLocation,
                          locationLatitude:values.locationLatitude,
                          locationLongitude:values.locationLongitude,
                          isRepeat:values.isRepeat,
                          repeatEveryType:values.repeatEveryType,
                          repeatStep:values.repeatStep,
                          repeatEndDate:values.repeatEndDate,
                          agentId:values.agentId,
                          isActive:values.isActive,
                          agentNotes: values.agentNotes,
                          addedBy: values.addedBy
                        };
  
                        onUpdateClientAppointment(updateApp);
                      } else {
                        
                      }
                      this.toggle();
                    }}
                  >
                    {({ errors, touched, values, handleChange, handelBlur, setFieldValue }) => (
                      <Form
                        className="needs-validation"
                      >
                        <Row>

                        

                          <Col md="12">
                            <FormGroup className="mb-3">
                              <Label htmlFor="address">
                                {this.props.t("Address")}
                              </Label>
                              <input
                                name="address"
                                type="text"
                                value={values.address}
                                onChange={handleChange}
                                className={
                                  "form-control" +
                                  (errors.address && touched.address
                                    ? " is-invalid"
                                    : "")
                                }
                                id="address"
                              />
                              <ErrorMessage
                                name="address"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </Col>
                         
                    
                        
                       

                          <Col md="12">
                            <FormGroup className="mb15">

                              <Label htmlFor="appointmentStartDate">
                                {this.props.t("Date*")}
                              </Label>
                              <Flatpickr
                                className={
                                  "form-control" +
                                  (errors.appointmentStartDate && touched.appointmentStartDate
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder=""
                                value={values.appointmentStartDate}
                                onChange={(value) => setFieldValue('appointmentStartDate', value[0])}
                                options={{
                                  altInput: true,
                                  altFormat: "F j, Y",
                                  dateFormat: "Y-m-d",
                                  defaultDate:'today'
                                }}
                              />
                              <ErrorMessage
                                name="appointmentStartDate"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </Col>
                        

                          <div className="d-flex justify-content-between mb-1">
                          <div>
                            <Field
                              name="isBuyer"
                              onChange={handleChange}   
                              className='form-check-input me-2'                         
                              type="checkbox"
                              id="isBuyer"
                              checked='true'
                            />
                            <Label htmlFor="isBuyer" className="form-check-label">
                              {this.props.t("Buyer")}
                            </Label> 
                            </div>
                            <div>
                              
                            <ButtonGroup>
                              <Button
                                color="primary"
                                onClick={() => setFieldValue('buyerType', 1)}
                                className={values.buyerType === 1 ? "t-button-active" : ""}
                              >
                                Rent
                              </Button>
                              <Button
                                color="primary"
                                onClick={() => setFieldValue('buyerType', 2)}
                                className={values.buyerType === 2  ? "t-button-active" : ""}
                              >
                                Purchase
                              </Button>
                            </ButtonGroup>
                            </div>
                        {/* </FormGroup> */}
                        </div>
                        <div className="d-flex justify-content-between mb-1">
                          <div>
                            <Field
                              name="isSeller"
                              onChange={handleChange}   
                              className='form-check-input me-2'                         
                              type="checkbox"
                              id="isSeller"
                              // checked='true'
                            />
                            <Label htmlFor="isSeller" className="form-check-label">
                              {this.props.t("Seller")}
                            </Label> 
                            </div>
                            <div>
                            <ButtonGroup>
                              <Button
                                color="primary"
                                onClick={() => setFieldValue('sellerType', 1)}
                                className={values.sellerType === 1 ? "t-button-active" : ""}
                              >
                                Sell
                              </Button>
                              <Button
                                color="primary"
                                onClick={() => setFieldValue('sellerType', 2)}
                                className={values.sellerType === 2  ? "t-button-active" : ""}
                              >
                                Lease
                              </Button>
                            </ButtonGroup>
                            {/* <Label htmlFor="buyType" className="form-check-label">
                              {this.props.t("purchase or Renat")}
                            </Label>  */}
                            </div>
                        {/* </FormGroup> */}
                        </div>
                        <FormGroup className="mb-3">
                            <Field
                              name="isInvestor"
                              onChange={handleChange}   
                              className='form-check-input me-2'                         
                              type="checkbox"
                              id="isInvestor"
                              // checked='true'
                            />
                            <Label htmlFor="isInvestor" className="form-check-label">
                              {this.props.t("Investor")}
                            </Label>
                        </FormGroup>
                        
                          <Col md="12">
                            <FormGroup className="mb15">

                              <Label htmlFor="file">
                                {this.props.t("Document")}
                              </Label>
                              <input type='file' name="file" className="form-control" onChange={handleChange}/>
                               

                            </FormGroup>
                          </Col>

                        </Row>




                        <div className="clearfix"><button type="submit" className="btn btn-primary w-md">{this.props.t("Update")}</button></div>
                      </Form>
                    )}
                  </Formik>
                      </OffcanvasBody>
                      </Offcanvas>   
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
ClientApp.propTypes = {
  t: PropTypes.any,
  history:PropTypes.object,
  addClientAppointment:PropTypes.func,
  match:PropTypes.object,
  onGetClientDetail:PropTypes.func,
  clietDetail : PropTypes.object,
  appointments:PropTypes.array,
  onGetClientAppointment:PropTypes.func,
  onDeleteAppointment:PropTypes.func,
  onUpdateClientAppointment:PropTypes.func,
  loading:PropTypes.object,
  onUpdateStatus:PropTypes.func

}
const mapStateToProps = ({ Clients }) => (
  {
      clietDetail:Clients.clietDetail,
      appointments:Clients.appointments,
      loading:Clients.loading

  })
const mapDispatchToProps = dispatch => ({
  addClientAppointment: (data) => dispatch(addClientAppointment(data)),
  onGetClientDetail: clientId => dispatch(getClientDetail(clientId)),
  onGetClientAppointment:(agentId, clientId) => dispatch(getClientAppointment(agentId, clientId)),
  onDeleteAppointment:(id) => dispatch(deleteClientAppointment(id)),
  onUpdateClientAppointment:(data) => dispatch(updateClientAppointment(data)),
  onUpdateStatus:(data) =>  dispatch(updateClientAppointment(data)),
 
});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ClientApp))
