import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom"
import './rsbutton.css'
import { children } from 'solid-js';
const ActionEdit = ({to,onClick, children}) => {
  return (
    <Link
      to={to}
      className={`btn btn-edit`}
      onClick={onClick}
    >
     <i className={`font-size-16 align-middle btn-i bx bx-edit`}></i> {children}
    </Link>
  );
};

ActionEdit.propTypes = {
to:PropTypes.string,
onClick: PropTypes.func,
children: PropTypes.string,
};
ActionEdit.defaultProps = {
    to:'#',
    onClick: () => {},
    children:''
  };
export default ActionEdit;
