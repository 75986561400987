import React, { Component } from "react"
import PropTypes from 'prop-types'
import { connect } from "react-redux"
import { withRouter } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { map } from "lodash"
import SaveButton from "components/buttons/save";
import { Row, Col, FormGroup, Label, InputGroup } from "reactstrap"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { withTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from 'react-moment';
import { addLeadAPP , getAppLeadTab, getAppointmentType, getAppointmentPriority, getAppLocationType } from "../../../store/actions"
class AppointmentLog extends Component {
  constructor(props) {
    super(props)
    this.state = {
      appTypes: [],
      appPriories:[],
      appLocTypes:[]
      
    }
    this.handelValidAppLead = this.handelValidAppLead.bind(this)
   

  }  
  handelValidAppLead(value) {
   this.props.addLeadAPP(value, this.props.history)
  
  }
  componentDidMount() {
    const { match:{params} , onGetAppLead, onGetAppointmentType, onGetAppointmentPriority, onGetAppLocationType } = this.props;
    onGetAppLead(parseInt(localStorage.getItem("userId")),params.id);
    onGetAppointmentType(localStorage.getItem("userId"));
    onGetAppointmentPriority(localStorage.getItem("userId"));
    onGetAppLocationType(localStorage.getItem("userId"));
   

  }
  
  render() {
    const now = new Date();
    now.setMinutes(now.getMinutes() + 30);
    const edTime = new Date(now);
    const selectedValue = '';
    const { appointments, types, priorities, locationTypes } = this.props;
   
    return (
      <React.Fragment>
        <ToastContainer autoClose={2000} />
        <div className="h4 card-title">Create New Appointment</div>
                                <Formik
                                  enableReinitialize={true}
                                  initialValues={{
                                    appAjenda: (this.state && this.state.appAjenda) || "",
                                    apptype: '',
                                    appPriority: '',
                                    appointmentLocationType: '',
                                    appADate: new Date(),
                                    appStat: new Date(),
                                    appEnd: edTime,
                                    contactName: '',
                                    contactPhone: '',
                                    contactEmail: '',
                                    refId: '',
                                    leadId:parseInt(this.props.match.params.id)
                                  }}

                                
                                  validationSchema={Yup.object().shape({
                                    appAjenda: Yup.string().max(150,"150 Characters are allowed").required(
                                      "Please Enter Meeting Agenda"
                                    ),
                                    apptype : Yup.number().required("This field is required"),
                                    appPriority :  Yup.number().required("This field is required"),
                                    appointmentLocationType :  Yup.number().required("This field is required"),
                                    // appointmentLocation :  Yup.number().required("This field is required")
                                    
                                  })}

                                  onSubmit={this.handelValidAppLead}
                                >
                                  {({ errors, touched, values, handleChange, handelBlur, setFieldValue }) => (
                                    <Form
                                      className="needs-validation"
                                    >
                                      <Row>
                                        <Col md="5">
                                          <FormGroup className="mb-3">
                                            <Label htmlFor="appAjenda">
                                              {this.props.t("Agenda*")}
                                            </Label>
                                            <input
                                              name="appAjenda"
                                              type="text"
                                              value={values.appAjenda}
                                              onChange={handleChange}
                                              className={
                                                "form-control" +
                                                (errors.appAjenda && touched.appAjenda
                                                  ? " is-invalid"
                                                  : "")
                                              }
                                              id="appAjenda"
                                            />
                                            <ErrorMessage
                                              name="appAjenda"
                                              component="div"
                                              className="invalid-feedback"
                                            />
                                          </FormGroup>
                                        </Col>
                                        <Col md="3">
                                          <FormGroup className="mb15">

                                            <Label htmlFor="appADate">
                                              {this.props.t("Date")}
                                            </Label>
                                            <Flatpickr
                                              className={
                                                "form-control" +
                                                (errors.appADate && touched.appADate
                                                  ? " is-invalid"
                                                  : "")
                                              }
                                              placeholder=""
                                              value={values.appADate}
                                              onChange={(value) => setFieldValue('appADate', value[0])}
                                              options={{
                                                altInput: true,
                                                altFormat: "F j, Y",
                                                dateFormat: "Y-m-d",
                                                minDate:'today'
                                              }}
                                            />
                                            <ErrorMessage
                                              name="appADate"
                                              component="div"
                                              className="invalid-feedback"
                                            />
                                          </FormGroup>
                                        </Col>
                                        <Col md="2">
                                          <FormGroup className="mb15">

                                            <Label htmlFor="appStat">
                                              {this.props.t("Start Time")}
                                            </Label>
                                            <InputGroup>
                                              <Flatpickr
                                                className="form-control d-block"
                                                placeholder="Select time"
                                                value={values.appStat}
                                                onChange={(value) => setFieldValue('appStat', value[0])}
                                                options={{
                                                  enableTime: true,
                                                  noCalendar: true,
                                                  dateFormat: "H:i",
                                                  maxDate: values.appEnd,
                                                }}
                                              />
                                              <div className="input-group-append">
                                                <span className="input-group-text">
                                                  <i className="mdi mdi-clock-outline" />
                                                </span>
                                              </div>
                                            </InputGroup>

                                          </FormGroup>
                                        </Col>
                                        <Col md="2">
                                          <FormGroup className="mb15">

                                            <Label htmlFor="appEnd">
                                              {this.props.t("End Time")}
                                            </Label>
                                            <InputGroup>
                                              <Flatpickr
                                                className="form-control d-block"
                                                placeholder="Select time"
                                                value={values.appEnd}
                                                onChange={(value) => setFieldValue('appEnd', value[0])}
                                                options={{
                                                  enableTime: true,
                                                  noCalendar: true,
                                                  dateFormat: "H:i",
                                                  minDate: values.appStat,
                                                }}
                                              />
                                              <div className="input-group-append">
                                                <span className="input-group-text">
                                                  <i className="mdi mdi-clock-outline" />
                                                </span>
                                              </div>
                                            </InputGroup>

                                          </FormGroup>
                                        </Col>

                                        <Col md="3">
                                          <FormGroup className="mb15">

                                            <Label htmlFor="apptype">
                                              {this.props.t("Appointment Type*")}
                                            </Label>
                                            <Field as="select" name="apptype" className={
                                                "form-control" +
                                                (errors.apptype && touched.apptype
                                                  ? " is-invalid"
                                                  : "")
                                              }>
                                              <option value="">Select</option>
                                              {map(types, (type, typekey) => (
                                            
                                                    <option key={type} value={type.id}>{type.appointmentTypeTitle}</option>
                                                  ))}
                                              
                                            </Field>
                                            <ErrorMessage
                                              name="apptype"
                                              component="div"
                                              className="invalid-feedback"
                                            />

                                          </FormGroup>
                                        </Col>

                                        <Col md="3">
                                          <FormGroup className="mb15">

                                            <Label htmlFor="appPriority">
                                              {this.props.t("Appointment Priority*")}
                                            </Label>
                                            <Field as="select" name="appPriority" className={
                                                "form-control" +
                                                (errors.appPriority && touched.appPriority
                                                  ? " is-invalid"
                                                  : "")
                                              }>
                                              <option value="">Select</option>
                                              {map(priorities, (priority, prioritykey) => (
                                                    <option key={prioritykey} value={priority.id}>{priority.appointmentPriorityTitle}</option>
                                              ))}
                                            </Field>
                                            <ErrorMessage
                                              name="appPriority"
                                              component="div"
                                              className="invalid-feedback"
                                            />

                                          </FormGroup>
                                        </Col>

                                        <Col md="3">
                                          <FormGroup className="mb15">

                                            <Label htmlFor="appointmentLocationType">
                                              {this.props.t("Location Type*")}
                                            </Label>
                                            <Field as="select" name="appointmentLocationType" className={
                                                "form-control" +
                                                (errors.appointmentLocationType && touched.appointmentLocationType
                                                  ? " is-invalid"
                                                  : "")
                                              }>
                                              <option value=''>Select</option>
                                              {map(locationTypes, (locationType, locationTypekey) => (
                                                    <option key={locationTypekey} value={locationType.id}>{locationType.appointmentLocationTypeTitle}</option>
                                              ))}
                                            </Field>
                                            <ErrorMessage
                                              name="appointmentLocationType"
                                              component="div"
                                              className="invalid-feedback"
                                            />

                                          </FormGroup>
                                        </Col>

                                        <Col md="3">
                                          <FormGroup className="mb15">
                                            {(() => {
                                              if (selectedValue == 3) {
                                                return (
                                                  <>
                                                    <Label htmlFor="appointmentLocation">
                                                      {this.props.t("Location")}
                                                    </Label>
                                                    <input
                                                      type="text"
                                                      name="appointmentLocation"
                                                      className="form-control"
                                                      id="autocomplete"
                                                    />
                                                  </>
                                                )
                                              } else if (selectedValue == 2) {
                                                return (
                                                  <>
                                                    <Label htmlFor="appointmentLocation">
                                                      {this.props.t("Phone")}
                                                    </Label>
                                                    <input
                                                      type="text"
                                                      name="appointmentLocation"
                                                      className="form-control"
                                                      id="appointmentLocation"
                                                    />
                                                  </>
                                                )
                                              } else {
                                                return (
                                                  <>
                                                    <Label htmlFor="appointmentLocation">
                                                      {this.props.t("Online Link")}
                                                    </Label>
                                                    <input
                                                      type="text"
                                                      name="appointmentLocation"
                                                      className="form-control"
                                                      id="appointmentLocation"
                                                    />
                                                  </>
                                                )
                                              }
                                            })()}



                                          </FormGroup>
                                        </Col>
                                      </Row>


                                  

                                      <div className="clearfix"><SaveButton>Save</SaveButton></div>
                                    </Form>
                                  )}
                                </Formik>

                                <div className="mb-5 h4 card-title mt-20">Appointment History</div>
                                <ul className="verti-timeline list-unstyled">
                                {map(appointments, (appointment, appointmentkey) => (
                                  <li className="event-list" key={appointmentkey}>
                                    <div className="event-timeline-dot">
                                      <i className="bx bx-right-arrow-circle font-size-18" />
                                    </div>
                                    <div className="d-flex">
                                      <div className="flex-shrink-0 me-3">
                                        <h5 className="font-size-14">
                                        <Moment format="D MMM">{appointment.appointmentStartDate}</Moment> / <Moment format="h:m a">{appointment.startTime}</Moment>  - <Moment format="h:m a">{appointment.endTime}</Moment>
                                          <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                                        </h5>
                                      </div>
                                      <div className="flex-grow-1">
                                        <div>{appointment.appointmentTitle	}</div>
                                      </div>
                                    </div>
                                  </li>
                                    ))}
                              </ul>
                                
                </React.Fragment>
    )
  }
}
AppointmentLog.propTypes = {
  t: PropTypes.any,
  history: PropTypes.object,
  match: PropTypes.object,
  addLeadAPP:PropTypes.func,
  onGetAppLead: PropTypes.func,
  appointments: PropTypes.array,
  onGetAppointmentType:PropTypes.func,
  types:PropTypes.array,
  onGetAppointmentPriority:PropTypes.func,
  priorities:PropTypes.array,
  locationTypes:PropTypes.array,
  onGetAppLocationType:PropTypes.func


}
const mapStateToProps = ({ leads, AppoinmentObject }) => (
  {
    appointments: leads.appointments,
    types:AppoinmentObject.types,
    priorities:AppoinmentObject.priorities,
    locationTypes:AppoinmentObject.locationTypes
    
  })
const mapDispatchToProps = dispatch => ({
  addLeadAPP: (data) => dispatch(addLeadAPP(data)),
  onGetAppLead: (agentId, leadId) => dispatch(getAppLeadTab(agentId, leadId)),
  onGetAppointmentType:(agentId) => dispatch(getAppointmentType(agentId)),
  onGetAppointmentPriority:(agentId) => dispatch(getAppointmentPriority(agentId)),
  onGetAppLocationType:(agentId) => dispatch(getAppLocationType(agentId))
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AppointmentLog)));