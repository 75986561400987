import { takeEvery, put, call } from "redux-saga/effects"
import { toast } from "react-toastify";
// Login Redux States
import { ADD_BROKER_RESOURCE, GET_BROKER_RESOURCE } from "./actionTypes"
import { addBrokerResourceError, addBrokerResourceSuccess, getBrokerResourceFail, getBrokerResourceSuccess } from "./actions"
 
import { getBBrokerResourseData, postBrokerResourseData } from "../../helpers/backend_helper"

function* submitBrokerResource({ payload: { resource, history } }) {
  try {
    toast.loading("Please wait...")
      const response = yield call(postBrokerResourseData, {
            resourceTitle: resource.resourceTitle,
            resourceType: parseInt(resource.resourceType),
            resourceLink: resource.resourceLink,
            resourceContent: resource.resourceContent,
            agentId:parseInt(localStorage.getItem('userId')),
            isActive:true,
      })
        yield put(addBrokerResourceSuccess(response))
        if (response.success == true) {
          toast.dismiss();
          toast.success("Resource Added");
          const response = yield call(getBBrokerResourseData,parseInt(localStorage.getItem('userId')))
        yield put(getBrokerResourceSuccess(response))
        } else {
          toast.warn("Some Error. Please try after some time");
        }
    }
catch (error) {

  yield put(addBrokerResourceError('Some Error'))
}
}

function* fetchResource({brokerId}) {
  try {
    const response = yield call(getBBrokerResourseData,brokerId)
    yield put(getBrokerResourceSuccess(response))
  } catch (error) {
    yield put(getBrokerResourceFail(error))
  }
}
function* resourceSaga() {
  yield takeEvery(ADD_BROKER_RESOURCE, submitBrokerResource)
  yield takeEvery(GET_BROKER_RESOURCE, fetchResource)
}

export default resourceSaga
