import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import WelcomeComp from "../WelcomeComp"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {  getLeads } from "../../../store/actions"
import { Container, Row, Col, Card, CardBody, Offcanvas, OffcanvasHeader, OffcanvasBody, Label, FormGroup } from "reactstrap"
import FilterMenu from "./filter-menu";
import Moment from 'react-moment';
class LeadEngageMentView extends Component {
  constructor(props) {
    super(props)
    this.state = {
     
    }
   
  }
  
  componentDidMount() {
    const { onGetAllLeads } = this.props;
    onGetAllLeads(localStorage.getItem('userId'));
  }

realtorDate(isoDate) {
  let date = new Date(isoDate);
  let dtString = ''
  let monthString = ''
  if (date.getDate() < 10) {
    dtString = '0' + date.getDate();
  } else {
    dtString = String(date.getDate())
  }
  if (date.getMonth()+1 < 10) {
    monthString = '0' + Number(date.getMonth()+1);
  } else {
    monthString = String(date.getMonth()+1);
  }
  return date.getFullYear()+'-' + monthString + '-'+dtString
  //return monthString +'-' + date.getFullYear()
}

getDateFromTodayPlus(numberOfDays)
{
    const d = new Date();
    d.setDate(d.getDate() + numberOfDays);
    return this.realtorDate(d);
}

  render() {

    const {  allLeads, gridLoading } = this.props;
    const lastSevenDay = this.getDateFromTodayPlus(-7);
    const lastEightDay = this.getDateFromTodayPlus(-8);
    const lastFigteenDay = this.getDateFromTodayPlus(-15);
    const lastSixteenDay = this.getDateFromTodayPlus(-16);
    const lastTwentyThreeDay = this.getDateFromTodayPlus(-23);
    const lastTwentyFourDay = this.getDateFromTodayPlus(-24);
    const lastThirtDay = this.getDateFromTodayPlus(-30);
    const lastThirtdayPlay = this.getDateFromTodayPlus(-30);
    var dateObj = new Date();
    var dateFormatOptions = {
      day:  'numeric',
      month: 'numeric',
      year: 'numeric'
    };
  const brColor = [{borderBottom:'3px solid #0D6EFD'},{borderBottom:'3px solid #198754'},{borderBottom:'3px solid #0DCAF0'}, {borderBottom:'3px solid #AB2E3C'},{borderBottom:'3px solid #FFC107'}];
   var activites = [];
   activites.push({
    "title": 'Last 7 Days',
     "leads" : allLeads.filter(d => d.lastActivity !== null && lastSevenDay <= this.realtorDate(d.lastActivity))
   })
   activites.push({
    "title": '8-15 Days',
    "leads" : allLeads.filter(d => d.lastActivity !== null && lastEightDay >= this.realtorDate(d.lastActivity) &&  lastFigteenDay <= this.realtorDate(d.lastActivity))
   })
   activites.push({
    "title": '16-23 Days',
    "leads" : allLeads.filter(d => d.lastActivity !== null && lastSixteenDay >= this.realtorDate(d.lastActivity) && lastTwentyThreeDay <= this.realtorDate(d.lastActivity))
   })
   activites.push({
    "title": ' 24-30 Days',
    "leads" : allLeads.filter(d => d.lastActivity !== null && lastTwentyFourDay >= this.realtorDate(d.lastActivity) && lastThirtDay <= this.realtorDate(d.lastActivity))
   })
   activites.push({
    "title": ' No Activity in 30 Days',
    "leads" : allLeads.filter(d => d.lastActivity !== null && lastThirtDay > this.realtorDate(d.lastActivity) )
   })
  

   const allLocal = localStorage.getItem('localData')
   const localization = JSON.parse(allLocal);
    return (
      <React.Fragment>
         {gridLoading ? (
                    <div className="rs-loader-overlay">
                    <div className="rs-loader"></div>
                    </div>
                ) : null}
         <ToastContainer autoClose={2000} />
        <div className="page-content project-page-content">
          <MetaTags>
            <title>Engagement View | Realty Space</title>
          </MetaTags>
          <Container fluid className="ps-0 pe-0">
            <Row className="project-header">
              <Col xl="7">
                <Breadcrumbs
                  title={this.props.t("Lead")}
                  breadcrumbItem={this.props.t("Engagement View")}
                />
              </Col>
              <Col xl="5">
                <WelcomeComp />
              </Col>
            </Row>
            <FilterMenu />
            <Row>
                  <Col sm="12">
                    <Card>
                      <CardBody>
                        <Row className="toHeadingRow ">
                          <Col sm="1"></Col>
                        { activites.map((activite,index) => 
                            <Col sm="2" className="" key={activite.title}>
                                <div className="topHeading" style={brColor[index]}>
                                <span className="sc-kEqXSa CIzYb">{activite.title}</span>
                                 
                                </div>
                            </Col>
                         )} 
                         </Row>
                        
                        <Row className="toRowContect">
                        <Col sm="1"></Col>
                        { activites.map(activite => 
                        <Col sm="2" className="" key={activite.title}>
                                <div >
                                { activite.leads.map(lead => 
                                <div className="container p-0" key={lead.id} style={{marginTop:10}}>
                                  <div className="mb-3 card custom-card">
                                   
                                    <div className="card-body">
                                    <div className="float-end ms-2"><span className="badge rounded-pill badge-soft-success font-size-12">{ lead.leadStatusId  ? localization["LeadStatus_"+ lead.leadStatusId]  : 'N/A' }</span></div>
                                      <div className="d-flex align-items-center">
                                        <h5 className="card-title mb-2 lead-box-title"><Link to={"/lead/lead-detail/" + lead.id + "/" + lead.leadId }>{lead.firstName + " "+ lead.lastName}</Link></h5>
                                       
                                      </div>
                                      <div><i className=" bx bxs-timer align-middle"></i>: { lead.lastActivityType ? lead.lastActivityType : 'No Activity' } </div>
                                      <div> <i className=" bx  bxs-calendar align-middle"></i>:  { lead.lastActivity  ? <Moment format="D MMM YY">{lead.lastActivity}</Moment> : '' }</div>
                                      <div className="row pt-1">
                                       {/* <div className="col-3 "><Link to="#"><i className=" bx bx-edit-alt font-size-20"></i></Link></div>
                                        <div className="col-3 "><Link to="#"><i className=" bx  bxs-envelope font-size-20"></i></Link></div>
                                        <div className="col-3 "><Link to="#"><i className=" bx bx-phone font-size-20"></i></Link></div> */}
                                        <div className="col-12"><Link className="float-end" to={`/lead/lead-detail/`+ lead.id + "/" + lead.leadId}><i className=" bx bx-link-external font-size-20"></i></Link></div>
                                      </div>
                                  </div>
                                </div>
                              </div>
                              )} 
                              
                                   
                                </div>

                               
                            </Col>
                         )}    
                          </Row>
                        
                      </CardBody>
                    </Card>
                  </Col>
                </Row>  
          </Container>
        
        </div>
      </React.Fragment>
    )
  }
}
LeadEngageMentView.propTypes = {
  t: PropTypes.any,
  onGetAllLeads:PropTypes.func,
  allLeads:PropTypes.array,
  gridLoading:PropTypes.object
}
const mapStateToProps = ({  leads }) => (
  {
    allLeads:leads.allLeads,
    gridLoading:leads.gridLoading
  })
  const mapDispatchToProps = dispatch => ({
    onGetAllLeads:(agentId) => dispatch(getLeads(agentId)),
    });
export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(LeadEngageMentView))
