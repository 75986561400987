import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
//import Lead from "./Lead";
import ClientLink from "./client-link";
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import Calender from "../Activities/calander"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Input,
  Button,
  InputGroup
} from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import ClientSidebar from "components/VerticalLayout/ClientSidebar";

class OpenList extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }
  render() {
    return (
      <React.Fragment>
        <div className="page-content project-page-content">
          <MetaTags>
            <title>Client Dashboard | Realty Space</title>
          </MetaTags>
          <Container fluid className="ps-0">
            <Row>
              <Col sm='2' className="pe-0">
                <ClientSidebar  />
              </Col>
              <Col sm='10' className="ps-0 pe-0">
              <Row className="project-header">
                {/* <Col sm="7"> */}
             <Breadcrumbs
              title={this.props.t("Clients")}
              breadcrumbItem={this.props.t( "Open House")}
            />
            {/* </Col>
            <Col sm="4" className="">
                <h2 className="">{this.props.t( clietDetail.firstName + " " + clietDetail.lastName)}</h2>
                </Col> */}
            </Row>
             <Row>
				<Col sm="12">
					 <Card> 
                    <CardBody>
                    <h5 className="card-title">Visitors</h5>
					<div className="table-responsive">
						  <table className="table mb-0 table">
							<thead>
							  <tr>
                                <th><Input type="checkbox" className="selection-input-4" /></th>
								<th>Name</th>
								<th>Date</th>
								<th>Questions</th>
								<th>Contact</th>
                <th>Detail</th>
                
							  </tr>
							</thead>
							<tbody>
						
							  
							</tbody>
						  </table>
						</div>
					</CardBody>
					</Card>
				</Col>
			</Row>
			
            </Col>
            
            </Row>
            
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
OpenList.propTypes = {
  t: PropTypes.any

}
export default connect()(withTranslation()(OpenList))
