import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import { capitalize, isEmpty, map } from "lodash";
import BreadcrumbClient from "../../components/Common/BreadcrumbClient"
import { withTranslation } from "react-i18next"
//import Lead from "./Lead";
import ClientLink from "./client-link";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Moment from 'react-moment';
import { getClientDetail, addClientMessage, getClientMessage } from "../../store/clients/actions"
import ClientSidebar from "components/VerticalLayout/ClientSidebar";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Input,
  Button
} from "reactstrap"
import SaveButton from "components/buttons/save";
import ClientActivityBreadcrumbs from "components/Common/ClientActivityBreadcrumb";
import ClientActivityMenu from "./menu/client-activity-menu";


class ClientMessage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      
    }
    this.handleMessageSubmit = this.handleMessageSubmit.bind(this)
  }
  handleMessageSubmit(value){
    //console.log(value)
    this.props.addClientMessage(value, this.props.history);

  }
  componentDidMount() {
    const { match: { params }, onGetClientDetail, onGetClientMessage } = this.props;
    onGetClientDetail(params.clientId);
    onGetClientMessage(params.id);
}
  render() {
    const { messages,clietDetail, loading } = this.props;
   
    return (
      <React.Fragment>
         {this.props.loading && this.props.loading ? (
         <div className="loading">Loading&#8230;</div>
         ) : null}
        <ToastContainer autoClose={2000} />
        <div className="page-content project-page-content">
          <MetaTags>
            <title>Client Dashboard | Realty Space</title>
          </MetaTags>
          <Container fluid className="ps-0">
            <Row>
              <Col sm='2' className="pe-0">
                <ClientSidebar id={this.props.match.params.id} clientId={this.props.match.params.clientId} />
              </Col>
              <Col sm='10' className="ps-0 pe-0">
              <Row className="project-header">
                <Col sm="7">
             <ClientActivityBreadcrumbs
              title={this.props.t("Clients")}
              breadcrumbItem={this.props.t(" Message")}
            />
            </Col>
            <Col sm="4" className="">
                <h2 className="">{this.props.t( clietDetail.firstName + " " + clietDetail.lastName)}</h2>
                </Col>
            </Row>
            <ClientActivityMenu />
            <Row>
              {/* <Col xl="3">
              <ClientLink id={this.props.match.params.id} clientId={this.props.match.params.clientId} />
              </Col> */}

              <Col xl="12">
              <Card>
                      <CardBody>
                      <div className="mt-0 card-title pheading">Add Messages</div>
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    addMessage: (this.state && this.state.addMessage) || "",
                    messageFromId: localStorage.getItem('userId'),
                    messageToId: this.props.match.params.id,
                    messageFrom: "realtor",
                    messageTo: "client",
                    agentId: localStorage.getItem('userId')


                  }}
                  validationSchema={Yup.object().shape({
                    addMessage: Yup.string().max(140, "Message Text must not exceed 140").required("This is Required")
                  })}

                  onSubmit={this.handleMessageSubmit}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >

                      <Row>
                        <Col md="12">
                          <FormGroup className="mb-3">
                           
                            <textarea
                              name="addMessage"
                              onChange={handleChange}
                              type="textarea"
                              className={
                                "form-control" +
                                (errors.addMessage && touched.addMessage
                                  ? " is-invalid"
                                  : "")
                              }
                              id="addMessage"
                            />
                            <ErrorMessage
                              name="addMessage"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <div className="clearfix">
                      <SaveButton iconClass=" bx bx-mail-send">Send Message</SaveButton>
                      </div>


                    </Form>
                  )}
                </Formik>
                
                </CardBody>
                </Card>
                <Row>
                  <Col lg="12">
                    <Card>
                      <CardBody>
                        <CardTitle className="mb-5 h4">Messages History</CardTitle>
                        <div className="">
                    <div className="container py-5">
    <div className="main-timeline">
    {map(messages, (message, messagekey) => (
      <div className={ messagekey % 2 == 0 ? "timeline left": "timeline right" } key={"_note_" + messagekey}>
        {/* <div className="card"> */}
          <div className="card-body p-4">
            <h6 className="float-end">From:{capitalize(message.messageFrom)}</h6>
            <h5 ><Moment format="D MMM YY h:m a">{message.createdDate}</Moment></h5>
            <p className="mb-0">{message.messageText}</p>
          </div>
        </div>
      // </div>
    ))}
    </div>
  </div>
                    </div> 
                      </CardBody>
                    </Card>
                  </Col>
                </Row>

              </Col>

            </Row>
            </Col>
            </Row>

          </Container>
        </div>
      </React.Fragment>
    )
  }
}
ClientMessage.propTypes = {
  t: PropTypes.any,
  history: PropTypes.object,
  addClientMessage:PropTypes.func,
  onGetClientMessage:PropTypes.func,
  messages:PropTypes.array,
  match: PropTypes.object,
  onGetClientDetail:PropTypes.func,
  clietDetail: PropTypes.object,
  loading:PropTypes.object

}

const mapStateToProps = ({ Clients }) => (
  {
    clietDetail: Clients.clietDetail,
    messages:Clients.messages,
    loading:Clients.loading

  })
const mapDispatchToProps = dispatch => ({
  onGetClientDetail: clientId => dispatch(getClientDetail(clientId)),
  addClientMessage: (data) => dispatch(addClientMessage(data)),
  onGetClientMessage:(clientId) => dispatch(getClientMessage(clientId))
 
});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ClientMessage))
