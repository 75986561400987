import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import LeadBySource from "./lead"
import Apaexlinecolumn from "./apaexlinecolumn"
import Revnue from "../AllCharts/apex/revnueLine"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import WelcomeComp from "./WelcomeComp"
//import CalenderShort from "../Activities/calander-short"
import appointment from "../../assets/images/rsicons/appointment.png"
import activiti from "../../assets/images/rsicons/activities.png"
import call from "../../assets/images/rsicons/call.png"
import notes from "../../assets/images/rsicons/notes.png"
import todo from "../../assets/images/rsicons/to-do.png"
//import LatestLeads from "../Lead/LatestLeads"

import primg1 from "../../assets/images/project/img1.jpg"
import primg2 from "../../assets/images/project/img2.jpg"
import primg3 from "../../assets/images/project/img3.jpg"
import primg4 from "../../assets/images/project/img4.jpg"

import primg5 from "../../assets/images/project/img5.jpg"
import primg6 from "../../assets/images/project/img6.jpg"
import primg7 from "../../assets/images/project/img7.jpg"
import primg8 from "../../assets/images/project/img8.jpg"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Select
} from "reactstrap"
import { API_URL } from 'helpers/app_url';
import axios from "axios"


class DashboardNew extends Component {
  constructor(props) {
    super(props)
    this.state = {
      totalContact: 0,
      totalClient: 0,
      totalLead: 0,

      reports: [
      ]
    }
  }
  componentDidMount() {

    const obj = JSON.parse(localStorage.getItem("authUser"));
    const token = obj.resultData.bearerToken;
    const str = 'Bearer ' + token;

    axios.get(`${API_URL}ProjectExt/getProjectCountByAgent`, {
      params: {
        agentId: localStorage.getItem("userId"),
      }, headers: { Authorization: str }
    })
      .then(res => {

        this.setState({ totalProject: res.data.countProject, totalClient: res.data.countClient, totalLead: res.data.countLead, totalLeadtoClient: res.data.countLeadtoClient });
      });

  }
  render() {
    const {totalContact,totalClient,totalLead } = this.state;
    this.state.reports= [
      { title: "Total Leads", iconClass: "bx-copy-alt", description:totalLead, goto: '/lead/dashboard', viewTxt: "Manage Your Lead" },
      {
        title: "Total Contacts",
        iconClass: "bx-archive-in",
        description: totalContact,
        goto: '/contacts',
        viewTxt: "Manage Your Contacts"
      },
      {
        title: "Total Clients",
        iconClass: "bx-purchase-tag-alt",
        description: totalClient,
        goto: '/client',
        viewTxt: "Manage Your Clients"
      }
    ]

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Lead Dashboard | Realty Space</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs
              title={this.props.t("Lead")}
              breadcrumbItem={this.props.t("Dashboard")}
            />
            <Row>
              <Col lg="4">
                <WelcomeComp />
              </Col>
              <Col lg="8">
                <Row>
                  {this.state.reports.map((report, key) => (
                    <Col md="4" key={"_col_" + key}>
                      <Link to={report.goto}><Card className="mini-stats-wid">
                        <CardBody className="rs-card-sm">
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <p className="text-muted fw-medium big-text">
                                {report.title}
                              </p>
                              <h4 className="mb-0">{report.description}</h4>

                            </div>
                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                              <span className="avatar-title">
                                <i
                                  className={
                                    "bx " + report.iconClass + " font-size-24"
                                  }
                                />
                              </span>
                            </div>

                          </div>

                        </CardBody>
                        <div className="clearfix"></div>
                        <h6 className="float-none link-bg"><Link to={report.goto}>{report.viewTxt}</Link></h6>
                      </Card></Link>

                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>

            <h4>Currently Selling</h4>

            <Row>
              <Col sm="3">
                <div className="product">
                  <div className="pro-img">
                    <div className="icon-check"><i className=" bx bx-check-shield"></i></div>
                    <img src={primg1} className="img-fluid" />
                  </div>
                  <div className="prd-info">
                    <h5 className="location">Toronto, ON</h5>
                    <h4 className="prd-title"><Link to="/projects/project-details">Liberty Market Tower</Link>  <Link to="/project-compare"><i className="compare-ico bx bx-git-compare float-end"></i></Link></h4>
                  </div>
                </div>

              </Col>

              <Col sm="3">
                <div className="product">
                  <div className="pro-img">

                    <img src={primg2} className="img-fluid" />
                  </div>
                  <div className="prd-info">
                    <h5 className="location">65 Grand Magazine Street, Toronto</h5>
                    <h4 className="prd-title"> Fortune at Fort York <Link to="/project-compare"><i className="compare-ico bx bx-git-compare float-end"></i></Link> <Link to="#"><i className="wish-list float-end bx bx-heart"></i></Link></h4>
                  </div>
                </div>

              </Col>

              <Col sm="3">
                <div className="product">
                  <div className="pro-img">
                    <div className="icon-check"><i className=" bx bx-check-shield"></i></div>
                    <img src={primg3} className="img-fluid" />
                  </div>
                  <div className="prd-info">
                    <h5 className="location">Toronto, ON</h5>
                    <h4 className="prd-title">Central Park Tower  <Link to="/project-compare"><i className="compare-ico bx bx-git-compare float-end"></i></Link></h4>
                  </div>
                </div>

              </Col>

              <Col sm="3">
                <div className="product">
                  <div className="pro-img">

                    <img src={primg4} className="img-fluid" />
                  </div>
                  <div className="prd-info">
                    <h5 className="location">Toronto, ON</h5>
                    <h4 className="prd-title">Manhattan View <Link to="/project-compare"><i className="compare-ico bx bx-git-compare float-end"></i></Link> <Link to="#"><i className="wish-list float-end bx bx-heart"></i></Link></h4>
                  </div>
                </div>

              </Col>
            </Row>


            <h4>Launching Soon</h4>

            <Row>
              <Col sm="3">
                <div className="product">
                  <div className="pro-img">
                    <div className="icon-check"><i className=" bx bx-check-shield"></i></div>
                    <img src={primg5} className="img-fluid" />
                  </div>
                  <div className="prd-info">
                    <h5 className="location">Toronto, ON</h5>
                    <h4 className="prd-title"><Link to="/project-detail">Country Pointe Meadows at Yaphank </Link>  <Link to="/project-compare"><i className="compare-ico bx bx-git-compare float-end"></i></Link></h4>
                  </div>
                </div>

              </Col>

              <Col sm="3">
                <div className="product">
                  <div className="pro-img">

                    <img src={primg6} className="img-fluid" />
                  </div>
                  <div className="prd-info">
                    <h5 className="location">65 Grand Magazine Street, Toronto</h5>
                    <h4 className="prd-title">  Umbrella Factory  <Link to="/project-compare"><i className="compare-ico bx bx-git-compare float-end"></i></Link> <Link to="#"><i className="wish-list float-end bx bx-heart"></i></Link></h4>
                  </div>
                </div>

              </Col>

              <Col sm="3">
                <div className="product">
                  <div className="pro-img">
                    <div className="icon-check"><i className=" bx bx-check-shield"></i></div>
                    <img src={primg7} className="img-fluid" />
                  </div>
                  <div className="prd-info">
                    <h5 className="location">Toronto, ON</h5>
                    <h4 className="prd-title"> The West
                      <Link to="#"><i className="compare-ico bx bx-git-compare float-end"></i></Link></h4>
                  </div>
                </div>

              </Col>

              <Col sm="3">
                <div className="product">
                  <div className="pro-img">

                    <img src={primg8} className="img-fluid" />
                  </div>
                  <div className="prd-info">
                    <h5 className="location">Toronto, ON</h5>
                    <h4 className="prd-title"> 222 East 81st Street  <Link to="/project-compare"><i className="compare-ico bx bx-git-compare float-end"></i></Link> <Link to="#"><i className="wish-list float-end bx bx-heart"></i></Link></h4>
                  </div>
                </div>

              </Col>
            </Row>

          </Container>
        </div>
      </React.Fragment>
    )
  }
}
DashboardNew.propTypes = {
  t: PropTypes.any

}
export default connect()(withTranslation()(DashboardNew))
