
import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
import MetaTags from "react-meta-tags";
import { isEmpty, map } from "lodash";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Container, Row, Col, Card, CardBody, FormGroup, Label, ButtonGroup, Button, Table, Offcanvas, OffcanvasHeader, OffcanvasBody, InputGroup } from 'reactstrap';
import SaveButton from 'components/buttons/save';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import RsLink from 'components/buttons/rsLink';
import EditLead from "./lead-edit";
import { getLeadDetail } from "../../../store/actions";
class LeadData extends Component {
    constructor(props) {
        super(props);
        this.state = {
          
        };
        this.toggleRightCanvas = this.toggleRightCanvas.bind(this);
        this.toggleRightCanvas2 = this.toggleRightCanvas2.bind(this);
        this.toggleRightCanvas3 = this.toggleRightCanvas3.bind(this);
    }
    toggleRightCanvas() {
        this.setState({ isRight: !this.state.isRight });
      }
      toggleRightCanvas2() {
        this.setState({ isRight2: !this.state.isRight2 });
      }
    
      toggleRightCanvas3() {
        this.setState({ isRight3: !this.state.isRight3 });
      }
    
      toggleBackdrop() {
        this.setState({ isBackdrop: !this.state.isBackdrop });
      }
    componentDidMount() {
        const { match: { params }, onGetLeadDetail } = this.props;
        if (params && params.id && params.leadId) {
          onGetLeadDetail(params.leadId);
        }
       
      }


    render() {
        const {leads} = this.props;
        const allLocal = localStorage.getItem("localData");
        const localization = JSON.parse(allLocal);
        return (
                <>
                <Card className="overflow-hidden">
                 
                  <div className="pt-3">
                    <Row>
                      <Col sm="12">
                        <div className="pt-2">
                          <div className="text-center">
                           
                              <RsLink onClick={this.toggleRightCanvas3} className="btn-light border-blue w-md" iconClass="">Save as Contact</RsLink>{" "}

                              <RsLink onClick={this.toggleRightCanvas2} className="btn-light border-green w-md" iconClass="">Save as Client</RsLink>{" "}
                              
                             <RsLink onClick={this.toggleRightCanvas} className="btn-purpul w-md" iconClass="">Edit</RsLink>
                               
                          </div>
                        </div>
                      </Col>
                      <Col sm="12">
                        <div className="pt-2">
                          <div className="text-center">
                         
                              
                          
                          </div>
                        </div>
                      </Col>
                      
                    </Row>
                  </div>
                </Card>
                <Card>
               
                  <CardBody>
                    <h4 className="card-title mb-4">
                    
                    </h4>
                    <div className="clearfix"></div>
                    {!isEmpty(leads) && (
                      <div className="table-responsive">
                        <Table className="table mb-0">
                          <tbody>
                            <tr>
                              <th scope="row"> Name</th>
                              <td> {leads.title+ " "+ leads.firstName +" "+ leads.lastName} </td>
                            </tr>
                            <tr>
                              <th scope="row"> Address</th>
                              <td></td>
                            </tr>
                           
                            

                            <tr>
                              <th scope="row"> Phone</th>
                              <td> {leads.phone} </td>
                            </tr>
                            <tr>
                              <th scope="row"> Mobile</th>
                              <td> {leads.mobile} </td>
                            </tr>
                            <tr>
                              <th scope="row"> Email</th>
                              <td> {leads.email} </td>
                            </tr>
                            <tr>
                              <th scope="row">Source</th>
                              <td>
                                {" "}
                                {leads.leadSourceId
                                  ? localization[
                                      "LeadSource_" + leads.leadSourceId
                                    ]
                                  : "N/A"}
                              </td>
                            </tr>

                            <tr>
                              <th scope="row">Status</th>
                              <td>
                                {leads.leadStatusId
                                  ? localization[
                                      "LeadStatus_" + leads.leadStatusId
                                    ]
                                  : "N/A"}{" "}
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">Type</th>
                              <td>
                                {" "}
                                {leads.leadTypeId
                                  ? localization["LeadType_" + leads.leadTypeId]
                                  : "N/A"}{" "}
                              </td>
                            </tr>


                            <tr>
                              <th scope="row">Stage</th>
                              <td>
                                {" "}
                                {leads.stageId
                                  ? localization[
                                      "LeadStage_" + leads.stageId
                                    ]
                                  : "N/A"}
                              </td>
                            </tr>

                            <tr>
                              <th scope="row">Group</th>
                              <td>
                                {" "}
                                {leads.stageId
                                  ? localization[
                                      "LeadStage_" + leads.stageId
                                    ]
                                  : "N/A"}
                              </td>
                            </tr>

                            <tr>
                              <th scope="row">Deal Value</th>
                              <td>
                                {leads.approxDealValue}
                               </td> 
                            </tr>
                            
                            <tr>
                              <th scope="row">Referred by</th>
                              <td>
                                {leads.referedBy}
                               </td> 
                            </tr>
                            <tr>
                              <th scope="row">Referral Code</th>
                              <td>
                                {leads.referralCode}
                               </td> 
                            </tr>
        
                          </tbody>
                        </Table>
                      </div>
                    )}
                  </CardBody>
                </Card>
                <Offcanvas
                  isOpen={this.state.isRight}
                  direction="end"
                  toggle={this.toggleRightCanvas}
                  style={{width:800}}
                >
                  <OffcanvasHeader toggle={this.toggleRightCanvas}>
                    Update Lead
                  </OffcanvasHeader>
                  <OffcanvasBody>
                      <EditLead
                       closed={this.toggleRightCanvas} 
                       match={this.props.match}
                      />
                  </OffcanvasBody>
                </Offcanvas>

                <Offcanvas
                  isOpen={this.state.isRight2}
                  direction="end"
                  toggle={this.toggleRightCanvas2}
                >
                  <OffcanvasHeader toggle={this.toggleRightCanvas2}>
                    <h2 className="text-center">Review</h2>
                  </OffcanvasHeader>
                  <OffcanvasBody>
                    <h2 className="offcanvas-heading">Profile Info</h2>
                    <p className="offcanvas-info">
                      {" "}
                      To make any changes to profile Info, please Edit the Lead
                    </p>
                    <br />
                    <p className="form-label">
                      Name : {leads.firstName + " " + leads.lastName}{" "}
                    </p>
                    <p className="form-label">Phone : {leads.phone}</p>
                    <p className="form-label">Email : {leads.email} </p>
                    <p className="form-label">Address : </p>
                    <hr />
                    <h2 className="offcanvas-heading">
                      Type of Client (<small>Check all that apply</small>)
                    </h2>
                    {/* <h5  >Check all that apply</h5> */}
                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        leadId: leads.id,
                        linkingId: 0,
                        contactId: 0,
                        clientId: 0,
                        buyerType: 1,
                        sellerType: 1,
                      }}
                      validationSchema={Yup.object().shape({
                        loginEmail: Yup.string().required("email is required"),
                        confirmEmail: Yup.string().oneOf(
                          [Yup.ref("loginEmail"), null],
                          "Email must match"
                        ),
                      })}
                      onSubmit={values => {
                        const updateData = {
                          leadId: values.leadId,
                          linkingId: values.linkingId,
                          contactId: values.contactId,
                          clientId: values.clientId,
                        };
                        onConvertLeadToClient(updateData);
                      }}
                    >
                      {({
                        errors,
                        touched,
                        values,
                        handleChange,
                        setFieldValue,
                      }) => (
                        <Form className="needs-validation">
                          {/* <FormGroup className="mb-3"> */}
                          <div className="d-flex justify-content-between mb-1">
                            <div>
                              <Field
                                name="isBuyer"
                                className="form-check-input me-2 offcanvas-chk-box"
                                type="checkbox"
                                id="isBuyer"
                                onClick={e => handleChange(e)}
                              />
                              <Label
                                htmlFor="isBuyer"
                                className="form-check-label"
                              >
                                {this.props.t("Buyer")}
                              </Label>
                            </div>
                            <div className="d-flex">
                              <ButtonGroup>
                                <Button
                                  color="primary"
                                  onClick={() => setFieldValue("sellerType", 1)}
                                  className={
                                    values.sellerType === 1
                                      ? "t-button-active"
                                      : ""
                                  }
                                >
                                  Rent
                                </Button>
                                <Button
                                  color="primary"
                                  onClick={() => setFieldValue("sellerType", 2)}
                                  className={
                                    values.sellerType === 2
                                      ? "t-button-active"
                                      : ""
                                  }
                                >
                                  Purchase
                                </Button>
                              </ButtonGroup>
                            </div>
                            {/* </FormGroup> */}
                          </div>
                          <div className="d-flex justify-content-between mb-1">
                            <div>
                              <Field
                                name="isSeller"
                                className="form-check-input me-2 offcanvas-chk-box"
                                type="checkbox"
                                id="isSeller"
                                onClick={e => handleChange(e)}
                                // checked='true'
                              />
                              <Label
                                htmlFor="isSeller"
                                className="form-check-label"
                              >
                                {this.props.t("Seller")}
                              </Label>
                            </div>
                            <div className="d-flex">
                              {/* <h5>Rnet </h5>
                            <Switch 
                            name="buyType"
                            uncheckedIcon={false}
                            checkedIcon={false}
                            height={18}
                            width={50}
                             value={values.buyType}
                             onChange={(value) => setFieldValue('buyType', !value)}
                            />
                            <h5>Rnet </h5> */}
                              <ButtonGroup>
                                <Button
                                  color="primary"
                                  onClick={() => setFieldValue("buyerType", 1)}
                                  className={
                                    values.buyerType === 1
                                      ? "t-button-active"
                                      : ""
                                  }
                                >
                                  Sell
                                </Button>
                                <Button
                                  color="primary"
                                  onClick={() => setFieldValue("buyerType", 2)}
                                  className={
                                    values.buyerType === 2
                                      ? "t-button-active"
                                      : ""
                                  }
                                >
                                  Lease
                                </Button>
                              </ButtonGroup>
                              {/* <Label htmlFor="buyType" className="form-check-label">
                              {this.props.t("purchase or Renat")}
                            </Label>  */}
                            </div>
                            {/* </FormGroup> */}
                          </div>
                          <FormGroup className="mb-3">
                            <Field
                              name="isInvestor"
                              className="form-check-input me-2 offcanvas-chk-box"
                              type="checkbox"
                              id="isInvestor"
                              onClick={e => handleChange(e)}
                              // checked='true'
                            />
                            <Label
                              htmlFor="isInvestor"
                              className="form-check-label"
                            >
                              {this.props.t("Investor")}
                            </Label>
                          </FormGroup>
                          <hr />
                          <h2 className="offcanvas-heading">Agreement Info</h2>
                          <FormGroup className="mb-3">
                            <Label htmlFor="openhouseDate">
                              {this.props.t("Sign Date")}
                            </Label>
                            <InputGroup>
                              <Flatpickr
                                className={
                                  "form-control" +
                                  (errors.openhouseDate && touched.openhouseDate
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder=""
                                value={values.openhouseDate}
                                onChange={value =>
                                  setFieldValue("openhouseDate", value[0])
                                }
                                options={{
                                  altInput: true,
                                  altFormat: "F j, Y",
                                  dateFormat: "Y-m-d",
                                }}
                              />
                              <div className="input-group-append">
                                <span className="input-group-text">
                                  <i className="mdi mdi-clock-outline" />
                                </span>
                              </div>
                            </InputGroup>
                          </FormGroup>

                          <hr />

                          <h2 className="offcanvas-heading">
                            Client Login Info
                          </h2>
                          <FormGroup className="mb-3">
                            <Label htmlFor="loginEmail">
                              {this.props.t("Login email")}
                            </Label>
                            <Field
                              name="loginEmail"
                              type="text"
                              place
                              // placeholder="Login email"
                              //value={values.referedBy}
                              onChange={handleChange}
                              className={
                                "form-control" +
                                (errors.loginEmail && touched.loginEmail
                                  ? " is-invalid"
                                  : "")
                              }
                              id="loginEmail"
                            />
                            <ErrorMessage
                              name="loginEmail"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>

                          <FormGroup className="mb-3">
                            <Label htmlFor="confirmEmail">
                              {this.props.t("Confirm email")}
                            </Label>
                            <Field
                              name="confirmEmail"
                              type="text"
                              //value={values.referedBy}
                              onChange={handleChange}
                              className={
                                "form-control" +
                                (errors.confirmEmail && touched.confirmEmail
                                  ? " is-invalid"
                                  : "")
                              }
                              id="confirmEmail"
                            />
                            <ErrorMessage
                              name="confirmEmail"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                          <p className="offcanvas-info">
                            The login email cannot be changed at a later date
                          </p>
                          <p className="offcanvas-info">
                            A system generated password is sent to the client
                          </p>

                          <div>
                            <button
                              type="submit"
                              className="btn btn-primary w-md"
                            >
                              Convert{" "}
                            </button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </OffcanvasBody>
                </Offcanvas>
                <Offcanvas
                  isOpen={this.state.isRight3}
                  direction="end"
                  toggle={this.toggleRightCanvas3}
                >
                  <OffcanvasHeader toggle={this.toggleRightCanvas3}>
                    <h2 className="text-center">Convert to Contact</h2>
                  </OffcanvasHeader>
                  <OffcanvasBody>
                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        leadId: leads.id,
                        linkingId: 0,
                        contactId: 0,
                        type: leads.name,
                      }}
                      validationSchema={Yup.object().shape({
                        // loginEmail: Yup.string().required('email is required'),
                        // confirmEmail: Yup.string()
                        // .oneOf([Yup.ref('loginEmail'), null], 'Email must match')
                      })}
                      onSubmit={values => {
                        const updateData = {
                          leadId: values.leadId,
                          linkingId: values.linkingId,
                          contactId: values.contactId,
                        };
                        onCovertLeadIntoContact(updateData);
                      }}
                    >
                      {({
                        errors,
                        touched,
                        values,
                        handleChange,
                        setFieldValue,
                      }) => (
                        <Form className="needs-validation">
                          {/* <FormGroup className="mb-3"> */}

                          <FormGroup className="mb-3">
                            <Label htmlFor="name">{this.props.t("Name")}</Label>
                            {/* <p className="form-label" >Name : {leads.firstName+" "+leads.lastName} </p> */}
                            <Field
                              name="name"
                              type="text"
                              value={leads.firstName + " " + leads.lastName}
                              // placeholder="Login email"
                              //value={values.referedBy}

                              className="form-control"
                              id="name"
                              disabled
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label
                              htmlFor="type"
                              className="form-label label-text"
                            >
                              Type
                            </Label>
                            <Field
                              as="select"
                              className={
                                "form-control" +
                                (errors.type && touched.type
                                  ? " is-invalid"
                                  : "")
                              }
                              name="type"
                              id="type"
                            >
                              <option value="">Select</option>
                              <option value="Silver">Silver </option>
                              <option value="Gold">Gold</option>
                              <option value="VIP">VIP</option>
                            </Field>
                            <ErrorMessage
                              name="type"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label
                              htmlFor="frequency"
                              className="form-label label-text"
                            >
                              Contact frequency
                            </Label>
                            <Field
                              as="select"
                              className={
                                "form-control" +
                                (errors.frequency && touched.frequency
                                  ? " is-invalid"
                                  : "")
                              }
                              name="frequency"
                              id="frequency"
                            >
                              <option value="">Select</option>
                              <option value="Monthly">Monthly </option>
                              <option value="Quatterly">Quatterly</option>
                              <option value="Bi-Annual">Bi-Annual</option>
                              <option value="Annual">Annual</option>
                            </Field>
                            <ErrorMessage
                              name="frequency"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                          <FormGroup className="mb-3">
                            <Field
                              name="isVendor"
                              className="form-check-input me-2 offcanvas-chk-box"
                              type="checkbox"
                              id="isVendor"
                              onClick={e => handleChange(e)}
                              // checked='true'
                            />
                            <Label
                              htmlFor="isVendor"
                              className="form-check-label"
                            >
                              {this.props.t("Is a Vendor")}
                            </Label>
                          </FormGroup>

                          <div>
                            <button
                              type="submit"
                              className="btn btn-primary w-md"
                            >
                              Convert{" "}
                            </button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </OffcanvasBody>
                </Offcanvas>
                </>
        );
    }
}
LeadData.propTypes = {
  t: PropTypes.any,
  match: PropTypes.object,
  onGetLeadDetail: PropTypes.object,
  leads: PropTypes.object,
}
const mapStateToProps = ({ leads }) =>
({
        leads: leads.leads,
       
});
  const mapDispatchToProps = dispatch => ({
    onGetLeadDetail: leadId => dispatch(getLeadDetail(leadId)),
  });
  
  export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(withRouter(LeadData)));
