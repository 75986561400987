import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import { isEmpty, map, size } from "lodash";
import BreadcrumbClient from "../../components/Common/BreadcrumbClient"
import { withTranslation } from "react-i18next"
//import Lead from "./Lead";
import ClientLink from "./client-link";
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import Calender from "../Activities/calander"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios"
import DeleteModal from "../../components/Common/DeleteModal";
import { addClientAppointment, getClientDetail, getClientAppointment, deleteClientAppointment, updateClientAppointment} from "../../store/clients/actions"
import Moment from 'react-moment';
import Switch from "react-switch"
import ClientSidebar from "components/VerticalLayout/ClientSidebar";
import RsAdNew from "components/buttons/Adnew";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Input,
  Button,
  InputGroup,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  
} from "reactstrap"
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import SaveButton from "components/buttons/save";
import UpdateButton from "components/buttons/updateBtn";
import RsLink from "components/buttons/rsLink";
import ClientActivityBreadcrumbs from "components/Common/ClientActivityBreadcrumb";
import ClientActivityMenu from "./menu/client-activity-menu";

class ClientApp extends Component {
  constructor(props) {
    super(props)
    this.state = {
      appTypes: [],
      appPriories: [],
      appLocTypes: [],
      appointment:'',
      checked: true,
    
    }
    this.handleSwitch = this.handleSwitch.bind(this);
    this.handelValidAppointment = this.handelValidAppointment.bind(this)
    this.onClickDelete = this.onClickDelete.bind(this);
    this.toggleRightCanvas = this.toggleRightCanvas.bind(this);
    this.toggleBackdrop = this.toggleBackdrop.bind(this);

  }
  handleSwitch(checked) {
    this.setState({ checked });
  }

  toggleBackdrop() {
    this.setState({ isBackdrop: !this.state.isBackdrop });
  }
  toggleDeleteModal = () => {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal,
    }));
  };
  toggle() {
    this.setState(prevState => ({
      isRight: !prevState.isRight
    }));
  }
  onClickDelete = (appointment) => {
    this.setState({ appointment: appointment });
    this.setState({ deleteModal: true });
  };
  handleDeleteAppointment = () => {
    const { onDeleteAppointment } = this.props;
    const { appointment } = this.state;
    
    if (appointment.id !== undefined) {
      onDeleteAppointment(appointment);
      this.setState({ deleteModal: false });
    }
  };
  handelValidAppointment(value){
    //console.log(value)
    this.props.addClientAppointment(value, this.props.history);

  }
  componentDidMount() {
    const { match: { params }, onGetClientDetail, onGetClientAppointment } = this.props;
    onGetClientDetail(params.clientId);
    onGetClientAppointment(localStorage.getItem('userId'),params.id)
   

    const obj = JSON.parse(localStorage.getItem("authUser"));
    const token = obj.resultData.bearerToken;
    const str = 'Bearer ' + token;
    axios.get('https://rscoreapi.azurewebsites.net/api/AppointmentType/all', {
      headers: { Authorization: str }
    })
      .then(res => {
        const appTypes = res.data;
        this.setState({ appTypes });
      }),
      axios.get('https://rscoreapi.azurewebsites.net/api/AppointmentPriority/all', {
        headers: { Authorization: str }
      })
        .then(res => {
          const appPriories = res.data;
          this.setState({ appPriories });
        }),
      axios.get('https://rscoreapi.azurewebsites.net/api/AppointmentLocationType/all', {
        headers: { Authorization: str }
      })
        .then(res => {
          const appLocTypes = res.data;
          this.setState({ appLocTypes });
        })
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { appointments } = this.props;
    if (!isEmpty(appointments) && size(prevProps.appointments) !== size(appointments)) {
      this.setState({ appointments: {}, isEdit: false });
    }
  }
  toggleRightCanvas() {
    this.setState({ appointment: "", isEdit: false, isRight: !this.state.isRight });
    this.toggle();
  }
  toggleRightCanvas = arg => {
    const appointment = arg;
  
    this.setState({
      appointment: {
    
        id:appointment.id,
        appointmentId: appointment.appointmentId,
        appointmentTitle:appointment.appointmentTitle,
        contactName: appointment.contactName,
        contactPhone: appointment.contactPhone,
        contactEmail: appointment.contactEmail,
        appointmentStartDate: appointment.appointmentStartDate,
        startTime: appointment.startTime,
        endTime: appointment.endTime,
        appointmentType: appointment.appointmentType,
        appointmentStatus: appointment.appointmentStatus,
        appointmentPriority: appointment.appointmentPriority,
        appointmentReminder: appointment.appointmentReminder,
        clientId: appointment.clientId,
        appointmentDescription:appointment.appointmentDescription,
        appointmentLocationType:appointment.appointmentLocationType,
        appointmentLocation:appointment.appointmentLocation,
        locationLatitude:appointment.locationLatitude,
        locationLongitude:appointment.locationLongitude,
        isRepeat:appointment.isRepeat,
        repeatEveryType:appointment.repeatEveryType,
        repeatStep:appointment.repeatStep,
        repeatEndDate:appointment.repeatEndDate,
        agentId:appointment.agentId,
        isActive:appointment.isActive,
        agentNotes: appointment.agentNotes,
        addedBy: appointment.addedBy
      },
      isEdit: true,
    });
    this.toggle();
  };
  handleChange = arg =>  {
    const appointment = arg;
    const { onUpdateStatus } = this.props;
    const updateReg = {
      id:appointment.id,
      appointmentId: appointment.appointmentId,
      rejected: (appointment.rejected==true) ? false : true,
      appointmentTitle:appointment.appointmentTitle,
        contactName: appointment.contactName,
        contactPhone: appointment.contactPhone,
        contactEmail: appointment.contactEmail,
        appointmentStartDate: appointment.appointmentStartDate,
        startTime: appointment.startTime,
        endTime: appointment.endTime,
        appointmentType: appointment.appointmentType,
        appointmentStatus: appointment.appointmentStatus,
        appointmentPriority: appointment.appointmentPriority,
        appointmentReminder: appointment.appointmentReminder,
        clientId: appointment.clientId,
        appointmentDescription:appointment.appointmentDescription,
        appointmentLocationType:appointment.appointmentLocationType,
        appointmentLocation:appointment.appointmentLocation,
        locationLatitude:appointment.locationLatitude,
        locationLongitude:appointment.locationLongitude,
        isRepeat:appointment.isRepeat,
        repeatEveryType:appointment.repeatEveryType,
        repeatStep:appointment.repeatStep,
        repeatEndDate:appointment.repeatEndDate,
        agentId:appointment.agentId,
        isActive:appointment.isActive,
        contactNotes: appointment.contactNotes,
        addedBy: appointment.addedBy
    }

    onUpdateStatus(updateReg);
  };

  render() {
    const { clietDetail, appointments , onUpdateClientAppointment, loading} = this.props;
    const now = new Date();
    now.setMinutes(now.getMinutes() + 30);
    const edTime = new Date(now);
    const selectedValue = '';
    const { isEdit, deleteModal } = this.state;
    const appointment = this.state.appointment;
    const { checked } = this.state;

    const allLocal = localStorage.getItem('localData')
    const localization = JSON.parse(allLocal);

    console.log(localization);
    return (
      <React.Fragment>
          {this.props.loading && this.props.loading ? (
         <div className="loading">Loading&#8230;</div>
         ) : null}
         <ToastContainer autoClose={2000} />
         <DeleteModal
          show={deleteModal}
          onDeleteClick={this.handleDeleteAppointment}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />
        <div className="page-content project-page-content">
          <MetaTags>
            <title>Client Dashboard | Realty Space</title>
          </MetaTags>
          <Container fluid className="ps-0">
            <Row>
              <Col sm='2' className="pe-0">
                <ClientSidebar id={this.props.match.params.id} clientId={this.props.match.params.clientId} />
              </Col>
              <Col sm='10' className="ps-0 pe-0">
              <Row className="project-header">
              <Col sm="8">
             <ClientActivityBreadcrumbs
              title={this.props.t("Clients")}
              breadcrumbItem={this.props.t(clietDetail.firstName +" " + clietDetail.lastName + " Appointment")}
            />
            </Col>
            <Col sm="4" >
            <div className="pt-2 float-end mt-2">
            <RsAdNew to="/calendar" className="btn btn-light btn-md me-2" iconClass="dripicons-download">Go to Calendar</RsAdNew>
            
           </div>
           
            </Col>
            </Row>

          <ClientActivityMenu />
          
           
          
            <Row>
              {/* <Col xl="3">
              <ClientLink id={this.props.match.params.id} clientId={this.props.match.params.clientId} />
              </Col> */}

              <Col xl="12">
                <Card>
                  <CardBody>
                  <div className="mt-0 card-title pheading">Add Appointment</div>
                  <Formik
                    enableReinitialize={true}
                    initialValues={{
                      appointmentTitle: (this.state && this.state.appointmentTitle) || "",
                      appointmentStartDate: (this.state && this.state.appointmentStartDate) || "",
                      startTime: (this.state && this.state.startTime) || "",
                      endTime: (this.state && this.state.endTime) || "",
                      contactName: clietDetail.firstName +" " + clietDetail.lastName,
                      contactPhone: '1234567890',
                      contactEmail: 'mohit@esimplified.ca',
                      clientId: this.props.match.params.id,
                      agentId: localStorage.getItem('userId'),
                      addedBy: 'agent'

                      
                    }}


                    validationSchema={Yup.object().shape({
                      // appointmentTitle: Yup.string().required("This is Required"),
                      appointmentStartDate: Yup.string().required("This is Required"),
                      startTime: Yup.string().required("This is Required"),
                      endTime: Yup.string().required("This is Required"),

                      appointmentTitle: Yup.string().max(150,"150 Characters are allowed").required("Please Enter Meeting Agenda"),
                      appointmentType : Yup.number().required("This field is required"),
                      appointmentPriority   :  Yup.number().required("This field is required"),
                      appointmentLocationType  :  Yup.number().required("This field is required"),
                    })}

                    onSubmit={this.handelValidAppointment}
                  >
                    {({ errors, touched, values, handleChange, handelBlur, setFieldValue }) => (
                      <Form
                        className="needs-validation"
                      >
                        <Row>
                          <Col md="5">
                            <FormGroup className="mb-3">
                              <Label htmlFor="appointmentTitle">
                                {this.props.t("Agenda*")}
                              </Label>
                              <input
                                name="appointmentTitle"
                                type="text"
                                value={values.appointmentTitle}
                                onChange={handleChange}
                                className={
                                  "form-control" +
                                  (errors.appointmentTitle && touched.appointmentTitle
                                    ? " is-invalid"
                                    : "")
                                }
                                id="appAjenda"
                              />
                              <ErrorMessage
                                name="appointmentTitle"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </Col>
                          <Col md="3">
                            <FormGroup className="mb15">

                              <Label htmlFor="appointmentStartDate">
                                {this.props.t("Date*")}
                              </Label>
                              <Flatpickr
                                className={
                                  "form-control" +
                                  (errors.appointmentStartDate && touched.appointmentStartDate
                                    ? " is-invalid"
                                    : "")
                                }
                                
                                
                                onChange={(value) => setFieldValue('appointmentStartDate', value[0])}
                                options={{
                                  altInput: false,
                                  minDate: "today",
                                  altFormat: "F j, Y",
                                  dateFormat: "Y-m-d"
                                }}
                              />
                              <ErrorMessage
                                name="appointmentStartDate"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </Col>
                          <Col md="2">
                            <FormGroup className="mb15">

                              <Label htmlFor="startTime">
                                {this.props.t("Start Time*")}
                              </Label>
                              <InputGroup>
                                <Flatpickr
                                  className={
                                    "form-control" +
                                    (errors.startTime && touched.startTime
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder="Select time"
                                 
                                  onChange={(value) => setFieldValue('startTime', value[0])}
                                  options={{
                                    enableTime: true,
                                    noCalendar: true,
                                    dateFormat: "h:i",
                                    maxDate: values.endTime,
                                  }}
                                />
                                <div className="input-group-append">
                                  <span className="input-group-text">
                                    <i className="mdi mdi-clock-outline" />
                                  </span>
                                </div>
                                <ErrorMessage
                                name="startTime"
                                component="div"
                                className="invalid-feedback"
                              />   
                              </InputGroup>
                              
                            </FormGroup>
                          </Col>
                          <Col md="2">
                            <FormGroup className="mb15">

                              <Label htmlFor="endTime">
                                {this.props.t("End Time*")}
                              </Label>
                              <InputGroup>
                                <Flatpickr
                               className={
                                "form-control" +
                                (errors.endTime && touched.endTime
                                  ? " is-invalid"
                                  : "")
                              }
                                  placeholder="Select time"
                                  onChange={(value) => setFieldValue('endTime', value[0])}
                                  options={{
                                    enableTime: true,
                                    noCalendar: true,
                                    dateFormat: "h:i",
                                    minDate: values.startTime,
                                  }}
                                />
                                <div className="input-group-append">
                                  <span className="input-group-text">
                                    <i className="mdi mdi-clock-outline" />
                                  </span>
                                </div>
                                <ErrorMessage
                                name="endTime"
                                component="div"
                                className="invalid-feedback"
                              />   
                              </InputGroup>

                            </FormGroup>
                          </Col>

                          <Col md="2">
                            <FormGroup className="mb15">

                              <Label htmlFor="appointmentType">
                                {this.props.t("Appointment Type*")}
                              </Label>
                              <Field as="select" name="appointmentType" id="appointmentType"  className={
                                    "form-control" +
                                    (errors.appointmentType && touched.appointmentType
                                      ? " is-invalid"
                                      : "")
                                  }
                                  onChange={handleChange}>
                                <option value="">Select</option>
                                {
                                  this.state.appTypes
                                    .map(appType =>
                                      <option key={appType.id} value={appType.id}>{appType.appointmentTypeTitle}</option>
                                    )
                                }
                              </Field>
                              <ErrorMessage
                                name="appointmentType"
                                component="div"
                                className="invalid-feedback"
                              />   

                            </FormGroup>
                          </Col>

                          <Col md="2">
                            <FormGroup className="mb15">

                              <Label htmlFor="appointmentPriority">
                                {this.props.t("Priority*")}
                              </Label>
                              <Field as="select" name="appointmentPriority"  id="appointmentPriority"  className={
                                    "form-control" +
                                    (errors.appointmentPriority && touched.appointmentPriority
                                      ? " is-invalid"
                                      : "")
                                  } onChange={handleChange}>
                                <option value="">Select</option>
                                {
                                  this.state.appPriories
                                    .map(appPriorie =>
                                      <option key={appPriorie.id} value={appPriorie.id}>{appPriorie.appointmentPriorityTitle}</option>
                                    )
                                }
                              </Field>
                              <ErrorMessage
                                name="appointmentPriority"
                                component="div"
                                className="invalid-feedback"
                              />   

                            </FormGroup>
                          </Col>

                          <Col md="3">
                            <FormGroup className="mb15">

                              <Label htmlFor="appointmentLocationType">
                                {this.props.t("Location Type*")}
                              </Label>
                              <Field as="select" name="appointmentLocationType"  id="appointmentLocationType"  className={
                                    "form-control" +
                                    (errors.appointmentLocationType && touched.appointmentLocationType
                                      ? " is-invalid"
                                      : "")
                                  } onChange={handleChange}>
                                <option value="">Select</option>
                                {
                                  this.state.appLocTypes
                                    .map(appLocType =>
                                      <option key={appLocType.id} value={appLocType.id}>{appLocType.appointmentLocationTypeTitle}</option>
                                    )
                                }
                              </Field>
                              <ErrorMessage
                                name="appointmentLocationType"
                                component="div"
                                className="invalid-feedback"
                              />   

                            </FormGroup>
                          </Col>

                          <Col md="3">
                            <FormGroup className="mb15">
                              {(() => {
                                if (selectedValue == 3) {
                                  return (
                                    <>
                                      <Label htmlFor="appointmentLocation">
                                        {this.props.t("Location")}
                                      </Label>
                                     
                                    </>
                                  )
                                } else if (selectedValue == 2) {
                                  return (
                                    <>
                                      <Label htmlFor="appointmentLocation">
                                        {this.props.t("Phone")}
                                      </Label>
                                     
                                    </>
                                  )
                                } else {
                                  return (
                                    <>
                                      <Label htmlFor="appointmentLocation">
                                        {this.props.t("Online Link / Address")}
                                      </Label>
                                      
                                    </>
                                  )
                                }
                              })()}
                              <Field
                              name="appointmentLocation"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.appointmentLocation && touched.appointmentLocation
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="appointmentLocation"
                            />
                               


                            </FormGroup>

                          </Col>
                        </Row>




                        <div className="clearfix"><SaveButton /></div>
                      </Form>
                    )}
                  </Formik>
                  </CardBody>
                </Card>
                <Row>
                  <Col sm="12">
                    <Card>
                      <CardBody>
                        <h5 className="card-title mb20">Appointment History</h5>

                        <div className="table-responsive">
          {/* <table className="table table-bordered border-primary align-middle table-nowrap mb-0">
              <thead className="">
							  <tr>
								
								<th>Date</th>
								<th>Start Tiime</th>
								<th>End Tiime</th>
								<th>Agenda</th>
                <th>Type</th>
                <th>Priority</th>
                <th>Location Type</th>
                <th>Location</th>
                <th>Accept</th>
                <th>Action</th>
							  </tr>
							</thead>
							<tbody>
              {map(appointments, (appointment, appoint) => (
                //cont app = appointment[appoint];
                  <tr key={"_app_" + appoint}>
                    <td><Moment format="Do MMMM YYYY">{appointment.appointmentStartDate}</Moment></td>
                    <td><Moment format="hh:mm A">{appointment.startTime}</Moment></td>
                    <td><Moment format="hh:mm A">{appointment.endTime}</Moment></td>
                    <td>{appointment.appointmentTitle}</td>
                    <td>{localization["AppointmentType_"+ appointment.appointmentType]}</td>
                    <td>{localization["AppointmentPriority_"+ appointment.appointmentPriority]}</td>
                    <td>{localization["AppointmentLocationType_"+ appointment.appointmentLocationType]}</td>
                    <td>{appointment.appointmentLocation}</td>
                    <td>{appointment.addedBy == 'client' ? <Switch onChange={isActive => this.handleChange(appointment)} checked={appointment.rejected} /> : '' }</td>
                    <td>
                    <UncontrolledDropdown direction="up">
                                      <DropdownToggle className="btn btn-action" type="button" id="defaultDropdown">
                                        Action <i className="mdi mdi-chevron-down"></i>
                                      </DropdownToggle>
                                      <DropdownMenu>
                                        <DropdownItem onClick={() => this.toggleRightCanvas(appointment)} href="#">Edit</DropdownItem>
                                        <DropdownItem onClick={() => this.onClickDelete(appointment)}  href="#">Delete</DropdownItem>
                                       
                                        
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                    
                    </td>
                  </tr>            
               ))}
              </tbody>


              </table> */}

              <DataTable className="table table-bordered data-table align-middle table-nowrap mb-0 me-2" value={appointments} paginator rows={10} rowsPerPageOptions={[10, 20, 30, 50, 100, 150, 200, 500 ]} currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries " paginatorTemplate=" FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown   " tableStyle={{ minWidth: '50rem' }}   >
                  <Column  field="lastContact" header="Date" body={(rowData) => (
                      <Moment format="Do MMMM YYYY">{rowData.appointmentStartDate}</Moment>
                  )}></Column>
                  <Column  field="lastContact" header="Start Tiime" body={(rowData) => (
                      <Moment format="hh:mm A">{rowData.startTime}</Moment>
                  )}></Column>
                  <Column  field="lastContact" header="End Tiime" body={(rowData) => (
                      <Moment format="hh:mm A">{rowData.startTime}</Moment>
                  )}></Column>
                  <Column  field="appointmentTitle" header="Agenda" ></Column>
                  <Column  field="lastContact" header="Type" body={(rowData) => (
                      localization["AppointmentType_"+ rowData.appointmentType]
                  )}></Column>
                  <Column  field="lastContact" header="Priority" body={(rowData) => (
                      localization["AppointmentPriority_"+ rowData.appointmentPriority]
                  )}></Column>
                  <Column  field="lastContact" header="Location Type" body={(rowData) => (
                      localization["AppointmentLocationType_"+ rowData.appointmentLocationType]
                  )}></Column>
                  <Column  field="appointmentLocation" header="Location" ></Column>
                  <Column  field="lastContact" header="Accept" body={(rowData) => (
                     rowData.addedBy == 'client' ? <Switch onChange={isActive => this.handleChange(rowData)} checked={rowData.rejected} /> : '' 
                  )}></Column>
                  <Column  field="lastContact" header="Action" body={(rowData) => (
                      <UncontrolledDropdown direction="up">
                      <DropdownToggle className="btn btn-action" type="button" id="defaultDropdown">
                        Action <i className="mdi mdi-chevron-down"></i>
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem onClick={() => this.toggleRightCanvas(rowData)} href="#">Edit</DropdownItem>
                        <DropdownItem onClick={() => this.onClickDelete(rowData)}  href="#">Delete</DropdownItem>
                       
                        
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  )}></Column>
              </DataTable>
              </div>
                      
                      </CardBody>
                    </Card>
                  </Col>
                </Row>


              </Col>

            </Row>
            </Col>

            </Row>
            <Offcanvas
                      isOpen={this.state.isRight}
                      direction="end"
                      toggle={this.toggleRightCanvas}
                    >
                      <OffcanvasHeader toggle={this.toggleRightCanvas}>
                        Update 
                      </OffcanvasHeader>
                      <OffcanvasBody>
                      <Formik
                    enableReinitialize={true}
                    initialValues={{
                      id:appointment.id,
                      appointmentId: appointment.appointmentId,
                      appointmentTitle:appointment.appointmentTitle,
                      contactName: appointment.contactName,
                      contactPhone: appointment.contactPhone,
                      contactEmail: appointment.contactEmail,
                      appointmentStartDate: appointment.appointmentStartDate,
                      startTime: appointment.startTime,
                      endTime: appointment.endTime,
                      appointmentType: appointment.appointmentType,
                      appointmentStatus: appointment.appointmentStatus,
                      appointmentPriority: appointment.appointmentPriority,
                      appointmentReminder: appointment.appointmentReminder,
                      clientId: appointment.clientId,
                      appointmentDescription:appointment.appointmentDescription,
                      appointmentLocationType:appointment.appointmentLocationType,
                      appointmentLocation:appointment.appointmentLocation,
                      locationLatitude:appointment.locationLatitude,
                      locationLongitude:appointment.locationLongitude,
                      isRepeat:appointment.isRepeat,
                      repeatEveryType:appointment.repeatEveryType,
                      repeatStep:appointment.repeatStep,
                      repeatEndDate:appointment.repeatEndDate,
                      agentId:appointment.agentId,
                      isActive:appointment.isActive,
                      agentNotes: appointment.agentNotes,
                      addedBy: appointment.addedBy

                      
                    }}


                    validationSchema={Yup.object().shape({
                      appointmentTitle: Yup.string().max(150,"150 Characters are allowed").required("Please Enter Meeting Agenda"),
                      appointmentType : Yup.number().required("This field is required"),
                      appointmentPriority   :  Yup.number().required("This field is required"),
                      appointmentLocationType  :  Yup.number().required("This field is required"),
                    })}

                    onSubmit={values => {
                      //console.log(isEdit);
                      if (isEdit) {
                        const updateApp = {
                          id:values.id,
                          appointmentId: values.appointmentId,
                          appointmentTitle:values.appointmentTitle,
                          contactName: values.contactName,
                          contactPhone: values.contactPhone,
                          contactEmail: values.contactEmail,
                          appointmentStartDate: values.appointmentStartDate,
                          startTime: values.startTime,
                          endTime: values.endTime,
                          appointmentType: (values.appointmentType) ? parseInt(values.appointmentType) : 0,
                          appointmentStatus: (values.appointmentStatus) ? parseInt(values.appointmentStatus) : 0,
                          appointmentPriority: (values.appointmentPriority) ? parseInt(values.appointmentPriority) : 0,
                          appointmentReminder: values.appointmentReminder,
                          clientId: values.clientId,
                          appointmentDescription:values.appointmentDescription,
                          appointmentLocationType:values.appointmentLocationType,
                          appointmentLocation:values.appointmentLocation,
                          locationLatitude:values.locationLatitude,
                          locationLongitude:values.locationLongitude,
                          isRepeat:values.isRepeat,
                          repeatEveryType:values.repeatEveryType,
                          repeatStep:values.repeatStep,
                          repeatEndDate:values.repeatEndDate,
                          agentId:values.agentId,
                          isActive:values.isActive,
                          agentNotes: values.agentNotes,
                          addedBy: values.addedBy
                        };
  
                        onUpdateClientAppointment(updateApp);
                      } else {
                        
                      }
                      this.toggle();
                    }}
                  >
                    {({ errors, touched, values, handleChange, handelBlur, setFieldValue }) => (
                      <Form
                        className="needs-validation"
                      >
                        <Row>
                          <Col md="12">
                            <FormGroup className="mb-3">
                              <Label htmlFor="appointmentTitle">
                                {this.props.t("Agenda*")}
                              </Label>
                              <input
                                name="appointmentTitle"
                                type="text"
                                value={values.appointmentTitle}
                                onChange={handleChange}
                                className={
                                  "form-control" +
                                  (errors.appointmentTitle && touched.appointmentTitle
                                    ? " is-invalid"
                                    : "")
                                }
                                id="appAjenda"
                              />
                              <ErrorMessage
                                name="appointmentTitle"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </Col>
                          <Col md="12">
                            <FormGroup className="mb15">

                              <Label htmlFor="appointmentStartDate">
                                {this.props.t("Date*")}
                              </Label>
                              <Flatpickr
                                className={
                                  "form-control" +
                                  (errors.appointmentStartDate && touched.appointmentStartDate
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder=""
                                value={values.appointmentStartDate}
                                onChange={(value) => setFieldValue('appointmentStartDate', value[0])}
                                options={{
                                  altInput: true,
                                  altFormat: "F j, Y",
                                  dateFormat: "Y-m-d",
                                  minDate:'today'
                                }}
                              />
                              <ErrorMessage
                                name="appointmentStartDate"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </Col>
                          <Col md="12">
                            <FormGroup className="mb15">

                              <Label htmlFor="startTime">
                                {this.props.t("Start Time*")}
                              </Label>
                              <InputGroup>
                                <Flatpickr
                                  className="form-control d-block"
                                  placeholder="Select time"
                                  value={values.startTime}
                                  onChange={(value) => setFieldValue('startTime', value[0])}
                                  options={{
                                    enableTime: true,
                                    noCalendar: true,
                                    dateFormat: "h:i",
                                    maxDate: values.endTime,
                                  }}
                                />
                                <div className="input-group-append">
                                  <span className="input-group-text">
                                    <i className="mdi mdi-clock-outline" />
                                  </span>
                                </div>
                              </InputGroup>

                            </FormGroup>
                          </Col>
                          <Col md="12">
                            <FormGroup className="mb15">

                              <Label htmlFor="endTime">
                                {this.props.t("End Time*")}
                              </Label>
                              <InputGroup>
                                <Flatpickr
                                  className="form-control d-block"
                                  placeholder="Select time"
                                  value={values.endTime}
                                  options={{
                                    enableTime: true,
                                    noCalendar: true,
                                    dateFormat: "h:i",
                                    minDate: values.startTime,
                                  }}
                                />
                                <div className="input-group-append">
                                  <span className="input-group-text">
                                    <i className="mdi mdi-clock-outline" />
                                  </span>
                                </div>
                              </InputGroup>

                            </FormGroup>
                          </Col>

                          <Col md="12">
                            <FormGroup className="mb15">

                              <Label htmlFor="appointmentType">
                                {this.props.t("Appointment Type")}
                              </Label>
                              <Field as="select" name="appointmentType" className="form-control" onChange={handleChange}>
                                <option value="">Select</option>
                                {
                                  this.state.appTypes
                                    .map(appType =>
                                      <option key={appType.id} value={appType.id}>{appType.appointmentTypeTitle}</option>
                                    )
                                }
                              </Field>

                            </FormGroup>
                          </Col>

                          <Col md="12">
                            <FormGroup className="mb15">

                              <Label htmlFor="appointmentPriority">
                                {this.props.t("Appointment Priority")}
                              </Label>
                              <Field as="select" name="appointmentPriority" className="form-control" onChange={handleChange}>
                                <option value="">Select</option>
                                {
                                  this.state.appPriories
                                    .map(appPriorie =>
                                      <option key={appPriorie.id} value={appPriorie.id}>{appPriorie.appointmentPriorityTitle}</option>
                                    )
                                }
                              </Field>

                            </FormGroup>
                          </Col>

                          <Col md="12">
                            <FormGroup className="mb15">

                              <Label htmlFor="appointmentLocationType">
                                {this.props.t("Location Type")}
                              </Label>
                              <Field as="select" name="appointmentLocationType" className="form-control" onChange={handleChange}>
                                <option value="">Select</option>
                                {
                                  this.state.appLocTypes
                                    .map(appLocType =>
                                      <option key={appLocType.id} value={appLocType.id}>{appLocType.appointmentLocationTypeTitle}</option>
                                    )
                                }
                              </Field>

                            </FormGroup>
                          </Col>

                          <Col md="12">
                            <FormGroup className="mb15">
                              {(() => {
                                if (selectedValue == 3) {
                                  return (
                                    <>
                                      <Label htmlFor="appointmentLocation">
                                        {this.props.t("Location")}
                                      </Label>
                                     
                                    </>
                                  )
                                } else if (selectedValue == 2) {
                                  return (
                                    <>
                                      <Label htmlFor="appointmentLocation">
                                        {this.props.t("Phone")}
                                      </Label>
                                     
                                    </>
                                  )
                                } else {
                                  return (
                                    <>
                                      <Label htmlFor="appointmentLocation">
                                        {this.props.t("Online Link / Address")}
                                      </Label>
                                    
                                    </>
                                  )
                                }
                              })()}

<Field
                              name="appointmentLocation"
                              onChange={handleChange}                            
                              type="text"
                              
                              className="form-control"
                             
                              id="appointmentLocation"
                            />

                            </FormGroup>

                          </Col>

                          <Col md="12">
                            <FormGroup className="mb15">

                              <Label htmlFor="agentNotes">
                                {this.props.t("Note")}
                              </Label>
                              <Field as="textarea" name="agentNotes" className="form-control" onChange={handleChange}>
                               
                              </Field>

                            </FormGroup>
                          </Col>
                        </Row>




                        <div className="clearfix">
                        <UpdateButton>{this.props.t("Update")}</UpdateButton>{ " " } <RsLink onClick={closed}>Close</RsLink>
                          </div>
                      </Form>
                    )}
                  </Formik>
                      </OffcanvasBody>
                      </Offcanvas>   
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
ClientApp.propTypes = {
  t: PropTypes.any,
  history:PropTypes.object,
  addClientAppointment:PropTypes.func,
  match:PropTypes.object,
  onGetClientDetail:PropTypes.func,
  clietDetail : PropTypes.object,
  appointments:PropTypes.array,
  onGetClientAppointment:PropTypes.func,
  onDeleteAppointment:PropTypes.func,
  onUpdateClientAppointment:PropTypes.func,
  loading:PropTypes.object,
  onUpdateStatus:PropTypes.func

}
const mapStateToProps = ({ Clients }) => (
  {
      clietDetail:Clients.clietDetail,
      appointments:Clients.appointments,
      loading:Clients.loading

  })
const mapDispatchToProps = dispatch => ({
  addClientAppointment: (data) => dispatch(addClientAppointment(data)),
  onGetClientDetail: clientId => dispatch(getClientDetail(clientId)),
  onGetClientAppointment:(agentId, clientId) => dispatch(getClientAppointment(agentId, clientId)),
  onDeleteAppointment:(id) => dispatch(deleteClientAppointment(id)),
  onUpdateClientAppointment:(data) => dispatch(updateClientAppointment(data)),
  onUpdateStatus:(data) =>  dispatch(updateClientAppointment(data)),
 
});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ClientApp))
