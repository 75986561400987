import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import {
  Button, Card, Col, Container, Input, Label, Row, TabContent, TabPane,
  Nav,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  NavItem,
  NavLink
} from "reactstrap"
import classnames from "classnames"

import { map } from "lodash"



// Import Editor
import { Editor } from "react-draft-wysiwyg"

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//Import Email Topbar
import EmailToolbar from "./email-toolbar"

//Import images
import avatar2 from "../../assets/images/users/avatar-2.jpg"
import avatar3 from "../../assets/images/users/avatar-3.jpg"
import avatar4 from "../../assets/images/users/avatar-4.jpg"
import avatar6 from "../../assets/images/users/avatar-6.jpg"

class EmailLeadInbox extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modal: false,
      activeTab: "1",
      resultArray: [],
      inboxmails: [
        {
          id: 1,
          name: "Peter",
          description: "<a class='subject' href='#'>Hello – <span class='teaser'>Trip home from Colombo has been arranged, then Jenna will come get me from Stockholm. :)</span></a>",
          read: true,
          fav: false,
          date: 'Sep 27',
        },
        {
          id: 2,
          name: "Susanna",
          description: "<a href='#' class='subject'> Since you asked... and i'm inconceivably bored at the train station – <span class='teaser'> Alright thanks. I'll have to re-book that somehow, i'll get back to you. </span></a>",
          read: true,
          fav: false,
          date: 'Sep 6',
        },
        {
          id: 3,
          name: "Web Support Dennis",
          description: "<a href='#' class='subject'> Re: New mail settings – <span class='teaser'> Will you answer him asap? </span></a>",
          read: true,
          fav: false,
          date: 'Mar 7',
        },
        
      ]
     
    }
    this.togglemodal.bind(this)
    this.toggleTab = this.toggleTab.bind(this)
  }

  componentDidMount() {
    
  }

  toggleTab(tab) {

    if (this.props.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }

  togglemodal = () => {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }))
  }

  render() {

    //meta title
    document.title = "Email | Realty Space";

    const { inboxmails} = this.state;
    
    return (
      <React.Fragment>
         <Row>
              <Col xs="12">
              
                <div className="email-rightbar mb-3">
                <ul className="message-list">
                          {map(inboxmails, (inbox, key) => (
                            <li key={key} className={inbox.read ? "" : "unread"}>
                              <div className="col-mail col-mail-1">
                                <div className="checkbox-wrapper-mail">
                                  <Input type="checkbox" id={inbox.id} />
                                  <Label htmlFor={inbox.id} className="toggle" />
                                </div>
                                <Link to="#" className="title">
                                  {inbox.name}
                                </Link>
                               
                              </div>
                              <div className="col-mail col-mail-2">

                                <div dangerouslySetInnerHTML={{ __html: inbox.description }}></div>
                                <div className="date">{inbox.date}</div>
                              </div>
                            </li>
                          ))}
                        </ul>
                  <Row>
                    <Col xs="7">Showing 1 - 20 of 20</Col>
                    <Col xs="5">
                      <div className="btn-group float-end">
                        <Button
                          type="button"
                          color="success"
                          size="sm"
                        >
                          <i className="fa fa-chevron-left" />
                        </Button>
                        <Button
                          type="button"
                          color="success"
                          size="sm"
                        >
                          <i className="fa fa-chevron-right" />
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
      </React.Fragment>
    )
  }
}

EmailLeadInbox.propTypes = {
  activeTab: PropTypes.string,
 
}

const mapStateToProps = ({ Mails }) => ({

})

const mapDispatchToProps = dispatch => ({

})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EmailLeadInbox))