import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import { isEmpty, map } from "lodash"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { addBrokerResource, getBrokerResource } from "../../store/actions"
import OfficeSidebar from "components/VerticalLayout/OfficeSidebar";
import {
    Row,
    Col,
    CardBody,
    Card,
    CardText,
    Container,
    FormGroup,
    Label,
    CardTitle,
    Offcanvas, OffcanvasHeader, OffcanvasBody
} from "reactstrap"
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

class OurBranch extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fileName: '',
      selectedImage: '',
      fname: ''
    }
    this.onFileChange = this.onFileChange.bind(this);
    this.handleResourceSubmit = this.handleResourceSubmit.bind(this)
    this.addNewCanvas = this.addNewCanvas.bind(this);
  }
  onFileChange(e) {
    let files = e.target.files;
    let fileNames = files[0];
    let fname = fileNames['name'];
    
    let fileReader = new FileReader();
    fileReader.readAsDataURL(files[0]);
    
    fileReader.onload = (event) => {
      
      this.setState({
        selectedImage: event.target.result,
       }),
       this.setState({
        fname: fname,
       })
       

    }
   
  }
  addNewCanvas() {
    this.setState({ isAddNew: !this.state.isAddNew });
  }
  handleResourceSubmit(value){
    this.props.addBrokerResource(value, this.props.history);
  }
  componentDidMount() {
    const { onGetBrokerResource } = this.props;
    onGetBrokerResource(localStorage.getItem('brokerId'));
}
  render() {
    const { resources } = this.props;
    return (
      <React.Fragment>
          <ToastContainer autoClose={2000} />
        <div className="page-content project-page-content">
          <MetaTags>
            <title>Resource | Realty Space</title>
          </MetaTags>
          <Container fluid className="">
          <Row>
             
              <Col sm='12' className="">
              <Row className="project-header">
                <Breadcrumbs
                title={this.props.t("Branch")}
                breadcrumbItem={this.props.t("Office Location")}
                />
            
            </Row>
         
            <Row className="mt-20 mb-20">
             
                <Card>
                  <CardBody>
                  <Col sm="12">
                  <CardTitle className="mb-4">Add New</CardTitle>
                    <CardText className="mb-0">
                      <Formik
                        enableReinitialize={true}
                        initialValues={{
                            branchName: (this.state && this.state.branchName) || "",
                            fullAddress: (this.state && this.state.fullAddress) || "",
                            contactEmail: '',
                            directPhone:'',
                            officePhone:''

                        }}
                        validationSchema={Yup.object().shape({
                            branchName: Yup.string().required("This Field in required"),
                            fullAddress: Yup.string().required("This Field in required"),
                            contactEmail: Yup.string().email('Invalid email'),
                            directPhone: Yup.string().matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, 'Enter number is not valid'),
                            officePhone: Yup.string().matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, 'Enter number is not valid'),
                        })}

                        onSubmit={this.handleSubmitLead}
                      >
                        {({ errors, touched, values, handleChange, setFieldValue }) => (
                          <Form
                            className="needs-validation"
                          >
                            

                            <Row>
                            <Col md="3">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="branchName">
                                    {this.props.t("Branch Name *")}
                                  </Label>
                                  <input
                                    name="branchName"
                                    type="text"
                                    onChange={handleChange}
                                    className={
                                      "form-control" +
                                      (errors.branchName && touched.branchName
                                        ? " is-invalid"
                                        : "")
                                    }
                                    id="branchName"
                                  />
                                  <ErrorMessage
                                    name="branchName"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </FormGroup>
                              </Col>
                          
                              <Col md="9">
                    <FormGroup className="mb-3">
                    <Label htmlFor="fullAddress">
                                    {this.props.t("Address")}
                                  </Label>                 
                    <PlacesAutocomplete
                             value={values.fullAddress}
                              onChange={(value) => setFieldValue('fullAddress', value)}
                              onSelect={(value) => this.handlePlaceSelected(value, setFieldValue)}
                            >
                              {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div>
                                  <input
                                    {...getInputProps({
                                      placeholder: 'Enter Address...',
                                      className: 'form-control',
                                     
                                    })}
                                  />
                                  <div className="">
                                    {loading ? <div>Loading...</div> : null}

                                    {suggestions.map((suggestion, i) => {
                                      const className = suggestion.active
                                        ? 'suggestion-item--active'
                                        : 'suggestion-item';
                                      const style = suggestion.active
                                        ? { backgroundColor: '#007BFF', color: '#fff', cursor: 'pointer', padding: '10px' }
                                        : { backgroundColor: '#fff', cursor: 'pointer', padding: '10px' };

                                      return (
                                        <div
                                        key={i}
                                          {...getSuggestionItemProps(suggestion, {
                                            style,
                                          })}
                                        >
                                          {suggestion.description}
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              )}
                            </PlacesAutocomplete>

                                        <ErrorMessage
                                          name="eventLocation"
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                      </FormGroup>
                                 </Col>
                                 <Col md="3">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="contactName">
                                    {this.props.t("Contact Name")}
                                  </Label>
                                  <input
                                    name="contactName"
                                    type="text"
                                    onChange={handleChange}
                                    className={
                                      "form-control" +
                                      (errors.contactName && touched.contactName
                                        ? " is-invalid"
                                        : "")
                                    }
                                    id="contactName"
                                  />
                                  <ErrorMessage
                                    name="contactName"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="2">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="contactEmail">
                                    {this.props.t("Contact Email")}
                                  </Label>
                                  <input
                                    name="contactEmail"
                                    type="text"
                                    //value={values.email}
                                    onChange={handleChange}
                                    className={
                                      "form-control" +
                                      (errors.contactEmail && touched.contactEmail
                                        ? " is-invalid"
                                        : "")
                                    }
                                    id="contactEmail"
                                  />
                                  <ErrorMessage
                                    name="contactEmail"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="2">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="directPhone">
                                    {this.props.t("Direct Phone")}
                                  </Label>
                                  <input
                                    name="directPhone"
                                    type="text"
                                    //value={values.mobile}
                                    onChange={handleChange}
                                    className={
                                      "form-control" +
                                      (errors.directPhone && touched.directPhone
                                        ? " is-invalid"
                                        : "")
                                    }
                                    id="directPhone"
                                  />
                                  <ErrorMessage
                                    name="directPhone"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="2">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="officePhone">
                                    {this.props.t("Office Phone")}
                                  </Label>
                                  <input
                                    name="officePhone"
                                    type="text"
                                    //value={values.phone}
                                    onChange={handleChange}
                                    className={
                                      "form-control" +
                                      (errors.officePhone && touched.officePhone
                                        ? " is-invalid"
                                        : "")
                                    }
                                    id="officePhone"
                                  />
                                  <ErrorMessage
                                    name="officePhone"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="1">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="phoneExt">
                                    {this.props.t("Ext")}
                                  </Label>
                                  <input
                                    name="phoneExt"
                                    type="text"
                                    //value={values.phone}
                                    onChange={handleChange}
                                    className={
                                      "form-control" +
                                      (errors.phoneExt && touched.phoneExt
                                        ? " is-invalid"
                                        : "")
                                    }
                                    id="phoneExt"
                                  />
                                  <ErrorMessage
                                    name="phoneExt"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="2">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="fax">
                                    {this.props.t("Fax")}
                                  </Label>
                                  <input
                                    name="fax"
                                    type="text"
                                    //value={values.phone}
                                    onChange={handleChange}
                                    className={
                                      "form-control" +
                                      (errors.fax && touched.fax
                                        ? " is-invalid"
                                        : "")
                                    }
                                    id="fax"
                                  />
                                  <ErrorMessage
                                    name="fax"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </FormGroup>
                              </Col>

                            

                            </Row>
                              
                            <div><button className="btn btn-primary" type="submit">Save</button></div>

                          </Form>
                        )}
                      </Formik>
                    </CardText>
                    </Col>
                  </CardBody>
                </Card>
             
            </Row>    
            <Row className="mb20">
           
            <Card>
        <CardBody>
        <Col lg="12">
        
          <div className="">
            <table className="table table-striped table-bordered">
              <thead>
                <tr>
                  
                  <th className="align-middle">Created</th>
                  <th className="align-middle">Branch Name</th>
                  <th className="align-middle">Contact Name</th>
                  
                  <th className="align-middle">Address</th>
                  <th className="align-middle">Phone</th>
                  <th className="align-middle">Fax</th>
                 
                  <th className="align-middle">Action</th>
                </tr>
              </thead>
              <tbody>
                        <tr>
                            <td>Apr, 10 2024</td>
                            <td>Pickering</td>
                            <td>Mohit Grover</td>
                            <td>1815 Ironstone Manor, Pickering, ON L1W 3W9, Canada</td>
                            <td>1234567890</td>
                            <td>--</td>
                            <td>
                                    <div className="d-flex gap-3">
                                    <Link className="text-purpol" href="#" onClick={ this.addNewCanvas }><i className="mdi mdi-pencil font-size-18" id="edittooltip"></i></Link> {" "} <Link className="text-danger" href="#"><i className="mdi mdi-delete font-size-18" id="edittooltip"></i></Link>
                                    </div>
                                    </td>
                        </tr>
                        </tbody>
            </table>


          </div>
          </Col>
      
        </CardBody>
      </Card>

  
            
          </Row>
      </Col>
      </Row>
           
           
           
          </Container>
        </div>

        <Offcanvas
              isOpen={this.state.isAddNew}
              direction="end"
              toggle={this.addNewCanvas}
            >
              <OffcanvasHeader toggle={this.addNewCanvas}>
              Update Branch
              </OffcanvasHeader>
              <OffcanvasBody>
              <Formik
                        enableReinitialize={true}
                        initialValues={{
                            branchName: (this.state && this.state.branchName) || "",
                            fullAddress: (this.state && this.state.fullAddress) || "",
                            contactEmail: '',
                            directPhone:'',
                            officePhone:''

                        }}
                        validationSchema={Yup.object().shape({
                            branchName: Yup.string().required("This Field in required"),
                            fullAddress: Yup.string().required("This Field in required"),
                            contactEmail: Yup.string().email('Invalid email'),
                            directPhone: Yup.string().matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, 'Enter number is not valid'),
                            officePhone: Yup.string().matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, 'Enter number is not valid'),
                        })}

                        onSubmit={this.handleSubmitLead}
                      >
                        {({ errors, touched, values, handleChange, setFieldValue }) => (
                          <Form
                            className="needs-validation"
                          >
                            

                            <Row>
                            <Col md="12">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="branchName">
                                    {this.props.t("Branch Name *")}
                                  </Label>
                                  <input
                                    name="branchName"
                                    type="text"
                                    onChange={handleChange}
                                    className={
                                      "form-control" +
                                      (errors.branchName && touched.branchName
                                        ? " is-invalid"
                                        : "")
                                    }
                                    id="branchName"
                                  />
                                  <ErrorMessage
                                    name="branchName"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </FormGroup>
                              </Col>
                          
                              <Col md="12">
                    <FormGroup className="mb-3">
                    <Label htmlFor="fullAddress">
                                    {this.props.t("Address")}
                                  </Label>                 
                    <PlacesAutocomplete
                             value={values.fullAddress}
                              onChange={(value) => setFieldValue('fullAddress', value)}
                              onSelect={(value) => this.handlePlaceSelected(value, setFieldValue)}
                            >
                              {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div>
                                  <input
                                    {...getInputProps({
                                      placeholder: 'Enter Address...',
                                      className: 'form-control',
                                     
                                    })}
                                  />
                                  <div className="">
                                    {loading ? <div>Loading...</div> : null}

                                    {suggestions.map((suggestion, i) => {
                                      const className = suggestion.active
                                        ? 'suggestion-item--active'
                                        : 'suggestion-item';
                                      const style = suggestion.active
                                        ? { backgroundColor: '#007BFF', color: '#fff', cursor: 'pointer', padding: '10px' }
                                        : { backgroundColor: '#fff', cursor: 'pointer', padding: '10px' };

                                      return (
                                        <div
                                        key={i}
                                          {...getSuggestionItemProps(suggestion, {
                                            style,
                                          })}
                                        >
                                          {suggestion.description}
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              )}
                            </PlacesAutocomplete>

                                        <ErrorMessage
                                          name="eventLocation"
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                      </FormGroup>
                                 </Col>
                                 <Col md="12">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="contactName">
                                    {this.props.t("Contact Name")}
                                  </Label>
                                  <input
                                    name="contactName"
                                    type="text"
                                    onChange={handleChange}
                                    className={
                                      "form-control" +
                                      (errors.contactName && touched.contactName
                                        ? " is-invalid"
                                        : "")
                                    }
                                    id="contactName"
                                  />
                                  <ErrorMessage
                                    name="contactName"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="12">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="contactEmail">
                                    {this.props.t("Contact Email")}
                                  </Label>
                                  <input
                                    name="contactEmail"
                                    type="text"
                                    //value={values.email}
                                    onChange={handleChange}
                                    className={
                                      "form-control" +
                                      (errors.contactEmail && touched.contactEmail
                                        ? " is-invalid"
                                        : "")
                                    }
                                    id="contactEmail"
                                  />
                                  <ErrorMessage
                                    name="contactEmail"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="12">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="directPhone">
                                    {this.props.t("Direct Phone")}
                                  </Label>
                                  <input
                                    name="directPhone"
                                    type="text"
                                    //value={values.mobile}
                                    onChange={handleChange}
                                    className={
                                      "form-control" +
                                      (errors.directPhone && touched.directPhone
                                        ? " is-invalid"
                                        : "")
                                    }
                                    id="directPhone"
                                  />
                                  <ErrorMessage
                                    name="directPhone"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="9">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="officePhone">
                                    {this.props.t("Office Phone")}
                                  </Label>
                                  <input
                                    name="officePhone"
                                    type="text"
                                    //value={values.phone}
                                    onChange={handleChange}
                                    className={
                                      "form-control" +
                                      (errors.officePhone && touched.officePhone
                                        ? " is-invalid"
                                        : "")
                                    }
                                    id="officePhone"
                                  />
                                  <ErrorMessage
                                    name="officePhone"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="3">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="phoneExt">
                                    {this.props.t("Ext")}
                                  </Label>
                                  <input
                                    name="phoneExt"
                                    type="text"
                                    //value={values.phone}
                                    onChange={handleChange}
                                    className={
                                      "form-control" +
                                      (errors.phoneExt && touched.phoneExt
                                        ? " is-invalid"
                                        : "")
                                    }
                                    id="phoneExt"
                                  />
                                  <ErrorMessage
                                    name="phoneExt"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="12">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="fax">
                                    {this.props.t("Fax")}
                                  </Label>
                                  <input
                                    name="fax"
                                    type="text"
                                    //value={values.phone}
                                    onChange={handleChange}
                                    className={
                                      "form-control" +
                                      (errors.fax && touched.fax
                                        ? " is-invalid"
                                        : "")
                                    }
                                    id="fax"
                                  />
                                  <ErrorMessage
                                    name="fax"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </FormGroup>
                              </Col>

                            

                            </Row>
                              
                            <div><button className="btn btn-primary" type="submit">Update</button></div>

                          </Form>
                        )}
                      </Formik>

              </OffcanvasBody>
            </Offcanvas>
      </React.Fragment>
    )
  }
}
OurBranch.propTypes = {
  t: PropTypes.any,
  match:PropTypes.object,
  history: PropTypes.object,
  addBrokerResource: PropTypes.func,
  onGetBrokerResource:PropTypes.func,
  resources:PropTypes.array
}
const mapStateToProps = ({ Resource }) => (
  {
    resources: Resource.resources

  })

  const mapDispatchToProps = dispatch => ({
   
    addBrokerResource: (data) => dispatch(addBrokerResource(data)),
    onGetBrokerResource:(BrokerId) => dispatch(getBrokerResource(BrokerId))
   
  });
export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(OurBranch))
