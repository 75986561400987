import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import prefrence from "../../assets/images/rsicons/prefrence.png"

//import Lead from "./Lead";
import ClientLink from "./client-link";
import { getClientDetail, addClientImportantDate, addClientChild, getClientImportantDate, getClientChild, deleteClientImportant, updateClientProfile} from "../../store/clients/actions"
import Switch from "react-switch"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import {  isEmpty, map, size } from "lodash";
import Moment from 'react-moment';
import DeleteModal from "../../components/Common/DeleteModal";
import ClientSidebar from "components/VerticalLayout/ClientSidebar";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Input,
  Button,
  Collapse
} from "reactstrap"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios"
import ClientProfileBreadcrumbs from "components/Common/ClientProfileBreadcrumb";
import ClientProfileMenu from "./menu/client-profile-menu";
class ClientImpProfile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      rows: [],
      rows1: [],
      clientTypes:[],
      titles:[],
      importantdate:'',
      sources:[],
      reports: [
        { title: "Upcoming Closings", iconClass: "bx-copy-alt", description: "14" },
        {
          title: "Upcoming appointments",
          iconClass: "bx-archive-in",
          description: "6",
        },
        {
          title: "Active Clients ",
          iconClass: "bx-purchase-tag-alt",
          description: "5",
        },
        {
          title: "Messages Received",
          iconClass: "bx-purchase-tag-alt",
          description: "5",
        },
        {
          title: "Investment interest",
          iconClass: "bx-purchase-tag-alt",
          description: "5",
        },
      ],
      col9: false,
      col8: false,
      col15: false,
      col10: false,
      col11: false,
      col12: false,
    }
    this.handleImportantDateSubmit = this.handleImportantDateSubmit.bind(this)
    this.handleChildSubmit = this.handleChildSubmit.bind(this)
    this.onClickDelete = this.onClickDelete.bind(this);
    this.t_col8 = this.t_col8.bind(this)
    this.t_col9 = this.t_col9.bind(this)
    this.t_col10 = this.t_col10.bind(this)
    this.t_col11 = this.t_col11.bind(this)
    this.t_col12 = this.t_col12.bind(this)
    this.t_col15 = this.t_col15.bind(this)
  }

  toggleDeleteModal = () => {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal,
    }));
  };
  onClickDelete = (importantdate) => {
    this.setState({ importantdate: importantdate });
    this.setState({ deleteModal: true });
  };
  handleDeleteImportantDate = () => {
    const { onDeleteImportantDate } = this.props;
    const { importantdate } = this.state;
    
    if (importantdate.id !== undefined) {
      onDeleteImportantDate(importantdate);
      this.setState({ deleteModal: false });
    }
  };
  handleImportantDateSubmit(value){
    this.props.addClientImportantDate(value, this.props.history);
  }

  handleChildSubmit(value){
    this.props.addClientChild(value, this.props.history);
  }
  t_col8() {
    this.setState({
      col8: !this.state.col8,
      col9: false,
      col10: false,
      col11: false,
      col12: false,
      col15: false
    })
  }

  t_col9() {
    this.setState({
      col8: false,
      col9: !this.state.col9,
      col10: false,
      col11: false,
      col12: false,
      col15: false
    })
  }

  t_col10() {
    this.setState({
      col8: false,
      col10: !this.state.col10,
      col9: false,
      col11: false,
      col12: false,
      col15: false
    })
  }

  t_col11() {
    this.setState({
      col8: false,
      col11: !this.state.col11,
      col9: false,
      col10: false,
      col12: false,
      col15: false
    })
  }
  t_col12() {
    this.setState({
      col8: false,
      col12: !this.state.col12,
      col11: false,
      col9: false,
      col10: false,
      col15: false
    })
  }
  t_col15() {
    this.setState({
      col8: false,
      col15: !this.state.col15,
      col11: false,
      col9: false,
      col10: false,
      col12: false
    })
  }

componentDidMount() {
  const { match: { params }, onGetClientDetail, onGetClientImportantDate, onGetClientChild } = this.props;
  onGetClientDetail(params.clientId);
  onGetClientImportantDate(params.id);
  onGetClientChild(params.id)
 
  const obj = JSON.parse(localStorage.getItem("authUser"));
  const token = obj.resultData.bearerToken;

  const str = 'Bearer ' + token;
  axios.get('https://rscoreapi.azurewebsites.net/api/LeadType/all', { headers: { Authorization: str } })
    .then(res => {
      const clientTypes = res.data;
      this.setState({ clientTypes });
    }),
    axios.get('https://rscoreapi.azurewebsites.net/api/FieldValue/allbyname', {
        params: {
          fieldName: 'Agent:Title',
        }, headers: { Authorization: str }
      })
        .then(res => {
          const titles = res.data;
          this.setState({ titles });
        }),
        axios.get('https://rscoreapi.azurewebsites.net/api/LeadSource/all', { headers: { Authorization: str } })
        .then(res => {
          const sources = res.data;
          this.setState({ sources });
        })
    
}
  render() {
    const { clietDetail, importantdates, childs, loading, onUpdateClientProfile } = this.props;
    const importantdate = this.state.importantdate;
    const {  deleteModal } = this.state;
    //console.log(loading);
    const Offsymbol = () => {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            fontSize: 12,
            color: "#fff",
            paddingRight: 2
          }}
        >
          {" "}
          Inactive
        </div>
      )
    }

    const OnSymbol = props => {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            fontSize: 12,
            color: "#fff",
            paddingRight: 2
          }}
        >
          {" "}
          Active
        </div>
      )
    }

    return (
      <React.Fragment>
        {this.props.loading && this.props.loading ? (
         <div className="loading">Loading&#8230;</div>
         ) : null}
        <ToastContainer autoClose={2000} />
        <div className="page-content project-page-content">
          <MetaTags>
            <title>Client Dashboard | Realty Space</title>
          </MetaTags>
          <Container fluid className="ps-0">
          <DeleteModal
          show={deleteModal}
          onDeleteClick={this.handleDeleteImportantDate}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />

            <Row>
              <Col sm='2' className="pe-0">
                <ClientSidebar id={this.props.match.params.id} clientId={this.props.match.params.clientId} />
              </Col>
              <Col sm='10' className="ps-0 pe-0">
              <Row className="project-header">
              <Col lg="8" className="ps-0">
             <ClientProfileBreadcrumbs
              title={this.props.t("Clients")}
              breadcrumbItem={this.props.t(clietDetail.firstName +" " + clietDetail.lastName + " Profile")}
            />
            </Col>
            <Col lg="3">
            <FormGroup className="mt-4 float-end">
                           
                           <Switch
                           uncheckedIcon={<Offsymbol />}
                           className="me-1 mb-sm-8 mb-2"
                           checkedIcon={<OnSymbol />}
                           onColor="#626ed4"
                           onChange={() =>
                             this.setState({ switch1: !this.state.switch1 })
                           }
                           checked={this.state.switch1}
                         />
                         </FormGroup>
              </Col>
            </Row>
              <ClientProfileMenu />
     
            <Row>
            
              
              <Col xl="12">
              
              <Card>
                          <CardBody>

                          <Formik
                          enableReinitialize={true}
                          initialValues={{
                            clientId: this.props.match.params.id,
                            agentId: localStorage.getItem('userId'),
                            dateTitle: (this.state && this.state.dateTitle) || "",
                            dateMonth: (this.state && this.state.dateMonth) || "",
                            dateDay: (this.state && this.state.dateDay) || "",
                           
                           
        
                          }}
                          validationSchema={Yup.object().shape({
                            dateTitle: Yup.string().required("This is Required"),
                            dateMonth: Yup.string().required("This is Required"),
                            dateDay: Yup.string().required("This is Required"),
                           
                          })}
        
                          onSubmit={this.handleImportantDateSubmit}
                        >
                       
                          {({ errors, touched, values, handleChange, setFieldValue }) => 
                          {const selectedMonth = values.dateMonth ;
                          const selectedYear = new Date().getFullYear();
                          const daysInMonth = selectedMonth ? new Date(selectedYear, selectedMonth, 0).getDate() : 31;
                          return(

                          <Form
                            className="needs-validation"
                          >
                                                {/* <h5 className="card-title">Important dates </h5> */}
                     <Row>
                          <Col sm="3">
                          <FormGroup className="mb-3">
                            <Label htmlFor="dateTitle">
                              {this.props.t("Type of date")}
                            </Label>
                            <Field
                              name="dateTitle"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.dateTitle && touched.dateTitle
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="dateTitle"
                            />
                             <ErrorMessage
                              name="dateTitle"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>

                          </Col>

                          <Col sm="3">
                          <FormGroup className="mb-3">
                            <Label htmlFor="dateMonth">
                              {this.props.t("Month")}
                            </Label>
                            <Field as="select" name="dateMonth" className={`form-control${errors.dateMonth && touched.dateMonth ? " is-invalid" : ""}`} onChange={(e) => {
                              handleChange(e);
                              const selectedMonth = parseInt(e.target.value, 10);
                              // setFieldValue("dateDay", ''); // Reset day value
                              setFieldValue("dateMonth", e.target.value); // Set month value
                            }}>
                              <option value="">Select</option>
                              {[...Array(12)].map((_, index) => (
                                <option key={index + 1} value={index + 1}>{this.props.t(new Date(2000, index, 1).toLocaleString(undefined, { month: 'long' }))}</option>
                              ))}
                            </Field>
                             <ErrorMessage
                              name="dateMonth"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>

                          </Col>

                          <Col sm="3">
                          <FormGroup className="mb-3">
                            <Label htmlFor="dateDay">
                              {this.props.t("Day")}
                            </Label>
                            <Field as="select" name="dateDay" className={`form-control${errors.dateDay && touched.dateDay ? " is-invalid" : ""}`} onChange={handleChange}>
                                <option value="">Select</option>
                                {[...Array(daysInMonth)].map((_, index) => (
                                  <option key={index + 1} value={index + 1}>{index + 1}</option>
                                ))}
                              </Field>
                             <ErrorMessage
                              name="dateMonth"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>

                          </Col>
                          <Col sm="1">
                          <button type="submit" className="btn btn-primary mt-27"><i className=" bx bx-plus-medical"></i></button>

                          </Col>
                    </Row>           
                   
                   <Row>
                    <Col sm="12">
                    <h5 className="card-title">Importants Date</h5>
					<div className="table-responsive">
          <table className="table table-bordered border-primary">
              <thead className="">
							  <tr>
								<th style={{width:"10%"}}>#</th>
								<th style={{width:"80%"}}>Title</th>
								<th style={{width:"80%"}}>Month</th>
                <th style={{width:"80%"}}>Day</th>             
								<th style={{width:"10%"}}>Action</th>
							  </tr>
							</thead>
							<tbody>
              {map(importantdates, (importantdate, datekey) => (
							  <tr key={"_note_" + datekey}>
								<td>{ datekey + 1 }</td>
								<td> {importantdate.dateTitle} </td>
                <td> {importantdate.dateMonth} </td>
                <td> {importantdate.dateDay} </td>
								
								<td><Link to="#" onClick={() => this.onClickDelete(importantdate)}  className="btn-del"><i className="bx bxs-trash"></i></Link></td>
							  </tr>
              ))} 
							</tbody>
						  </table>
						</div>
                    </Col>
                   </Row>
                            </Form>
                        )}}
                        </Formik>
                          </CardBody>
                        </Card>           
            
            </Col>
            
            </Row>
            </Col>
            </Row>
           
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
ClientImpProfile.propTypes = {
  t: PropTypes.any,
  history:PropTypes.object,
  match: PropTypes.object,
  onGetClientDetail:PropTypes.func,
  clietDetail: PropTypes.object,
  addClientImportantDate:PropTypes.func,
  addClientChild:PropTypes.func,
  onGetClientImportantDate:PropTypes.func,
  onGetClientChild:PropTypes.func,
  importantdates:PropTypes.array,
  childs:PropTypes.array,
  onDeleteImportantDate:PropTypes.func,
  loading:PropTypes.object,
  onUpdateClientProfile:PropTypes.func

}
const mapStateToProps = ({ Clients }) => (
  {
    loading: Clients.loading,
    clietDetail: Clients.clietDetail,
    importantdates:Clients.importantdates,
    childs:Clients.childs
    

  })
const mapDispatchToProps = dispatch => ({
  onGetClientDetail: clientId => dispatch(getClientDetail(clientId)),
  addClientImportantDate:(data) => dispatch(addClientImportantDate(data)),
  addClientChild:(data) => dispatch(addClientChild(data)),
  onGetClientImportantDate: clientId => dispatch(getClientImportantDate(clientId)),
  onGetClientChild: clientId => dispatch(getClientChild(clientId)),
  onDeleteImportantDate : id => dispatch(deleteClientImportant(id)),
  onUpdateClientProfile: (data) => dispatch(updateClientProfile(data))

});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ClientImpProfile))
