import React, { Component } from "react";
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom";
import { Card, CardBody, Col, Container, Media, Row, Offcanvas, OffcanvasHeader, OffcanvasBody } from "reactstrap";
import axios from 'axios';
import { API_URL } from "helpers/app_url";
import avatar2 from "../../assets/img/email-profile.jpg";

import Breadcrumbs from "../../components/Common/Breadcrumb";

import EmailSideBar from "./email-sidebar";


import EmailToolbar from "./email-toolbar";

class EmailRead extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailData: null, 
      loading: true,  
      error: null    
    };

    this.toggleRightCanvas = this.toggleRightCanvas.bind(this);
  }
  toggleRightCanvas() {
    this.setState({ isRight: !this.state.isRight });
  }
  componentDidMount() {
    this.fetchEmailData();
  }
  fetchEmailData = async () => {
    
    const { id } = this.props.match.params;
    const obj = JSON.parse(localStorage.getItem("authUser"));
    const token = obj?.resultData?.bearerToken;
    const url = `${API_URL}Gmail/GetGmailByMessageId/${id}`;
    const config = {
      headers: {
        'Authorization': `Bearer ${token}`, 
      }
    }
    try {
      const response = await axios.get(url, config);
      this.setState({
        emailData: response.data,
        loading: false
      });
    } catch (error) {
      this.setState({
        error: error.message,
        loading: false
      });
    }
  }
  render() {
    
    //meta title
    document.title = "Email | Realty Space";
    const { emailData, loading, error } = this.state;

    return (
      <React.Fragment>
         {loading ? (
                    <div className="rs-loader-overlay">
                    <div className="rs-loader"></div>
                    </div>
                ) : null}
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Email" breadcrumbItem="Read Email" />

            <Row>
              <Col xs="12">
                {/* Render Email SideBar */}
                <EmailSideBar />

                <div className="email-rightbar mb-3">
                  <Card>
                    {/* Render Email Top Tool Bar */}
                    <EmailToolbar />

                    <CardBody>
                      <div className="d-flex mb-4">
                        <img
                          className="d-flex me-3 rounded-circle avatar-sm"
                          src={avatar2}
                          alt="skote"
                        />
                        <div className="flex-grow-1">
                          <h5 className="font-size-14 mt-3">
                            {emailData?.from}
                          </h5>
                          {/* <small className="text-muted">
                            support@domain.com
                          </small> */}
                        </div>
                      </div>

                      <h4 className="mt-0 font-size-16">
                        {emailData?.subject}
                      </h4>
                      <div dangerouslySetInnerHTML={{__html: emailData?.message }}></div>
                      

                      <Link
                        to="#"
                        className="btn btn-secondary mt-4" 
                        onClick={this.toggleRightCanvas}
                      >
                        <i className="mdi mdi-reply"></i> Reply
                      </Link> 
                    </CardBody>
                  </Card>
                </div>
              </Col>
            </Row>
          </Container>

          <Offcanvas
            isOpen={this.state.isRight}
            direction="end"
            toggle={this.toggleRightCanvas}
          >
            <OffcanvasHeader toggle={this.toggleRightCanvas}>
              Offcanvas Right
            </OffcanvasHeader>
            <OffcanvasBody>...</OffcanvasBody>
          </Offcanvas>
        </div>
      </React.Fragment>
    );
  }
}
EmailRead.propTypes = {
  match: PropTypes.obj,
 
}
export default EmailRead;
