
import React, { Component } from 'react';
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import MetaTags from "react-meta-tags";
import { Container, Row, Col, Card, CardBody, FormGroup, Label } from 'reactstrap';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LeadData from "./../forms/lead-detail"
import LeadDetailMenu from '../menu/lead-detail-menu';
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import QualificationChart from '../insight-chart/charts/qualification-chart';
import { getLeadDetail } from "../../../store/actions";
class BuyerEligibility extends Component {
    constructor(props) {
        super(props);
        this.state = {
          
        };
       
    }
    componentDidMount() {
        const { match: { params }, onGetLeadDetail} = this.props;
        if (params && params.id && params.leadId) {
          onGetLeadDetail(params.leadId);
        }
       
      }
    render() {
        const {leads } = this.props;
        const leadQualificationPoints = leads?.leadQualificationPoints;
        const formattedPoints = leadQualificationPoints?.toFixed(2);
        const seriesData = [formattedPoints];
        return (
          <React.Fragment>
          <ToastContainer autoClose={2000} />
          <div className="page-content project-page-content">
            <MetaTags>
              <title>Lead Detail | Realty Space</title>
            </MetaTags>
            <Container fluid className="ps-0">
           <Row>
            <Col sm="3" className="pe-0">
                <LeadData />
            </Col>
              <Col sm="9" className="ps-0 pe-0">
              <Row className="project-header">
                <Col sm='8'>
                <Breadcrumbs
                  title="Lead"
                  breadcrumbItem="Lead Detail"
                />
           
            </Col>
            <Col sm='4'>
            <h2>{leads.firstName + " " + leads.lastName }</h2>
            </Col>
            </Row>
                 
            <LeadDetailMenu /> 
            
            <Card>
              <CardBody>
              <QualificationChart series={seriesData} />
            </CardBody>
            </Card>
            </Col>
            </Row>
            </Container>
            </div>
            </React.Fragment>
           
            
        );
    }
}
BuyerEligibility.propTypes = {
  match: PropTypes.object,
  onGetLeadDetail: PropTypes.object,
  leads: PropTypes.object
}
const mapStateToProps = ({ leads }) =>
    ({
      leads: leads.leads,
     
    });
const mapDispatchToProps = dispatch => ({
    onGetLeadDetail: leadId => dispatch(getLeadDetail(leadId)),
});
export default connect(mapStateToProps,mapDispatchToProps)(BuyerEligibility);