import PropTypes from "prop-types"
import React, { Component } from "react"

//Simple bar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"
import backIcon from "../../assets/images/rsicons/backIcon.png"
import dealIcon from "../../assets/images/rsicons/deal.png"
import engagementIcon from "../../assets/images/rsicons/engagement.png"
import activity from "../../assets/img/Activity.png"
import seller from "../../assets/img/Seller.png"
import profile from "../../assets/img/Profile.png"
import investor from "../../assets/img/investor.png"
import dashboard from "../../assets/img/Dashboard-new.png"
import buyerImg from "../../assets/img/Buyer.png"
import deleteImg from "../../assets/img/delete.png"
//i18n
import { withTranslation } from "react-i18next"
import buyer from "pages/Lead/questions/buyer"

class ClientSidebar extends Component {
  constructor(props) {
    super(props)
    this.refDiv = React.createRef()
  }

  componentDidMount() {
    this.initMenu()
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, ss) {
    if (this.props.type !== prevProps.type) {
      this.initMenu()
    }
  }

  initMenu() {
    new MetisMenu("#side-menu")

    let matchingMenuItem = null
    const ul = document.getElementById("side-menu")
    const items = ul.getElementsByTagName("a")
    for (let i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i]
       console.log(matchingMenuItem);
        break
      }
    }
    if (matchingMenuItem) {
      matchingMenuItem.classList.add("text-blue");
      this.activateParentDropdown(matchingMenuItem)
    }
  }

  // componentDidUpdate() {}

  scrollElement = item => {
    setTimeout(() => {
      if (this.refDiv.current !== null) {
        if (item) {
          const currentPosition = item.offsetTop
          if (currentPosition > window.innerHeight) {
            if (this.refDiv.current)
              this.refDiv.current.getScrollElement().scrollTop =
                currentPosition - 300
          }
        }
      }
    }, 300)
  }

  activateParentDropdown = item => {
    item.classList.add("active")
    const parent = item.parentElement

    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm2-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm2-active") // li
          parent3.childNodes[0].classList.add("mm2-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm2-active") // a tag
            }
          }
        }
      }
      this.scrollElement(item)
      return false
    }
    this.scrollElement(item)
    return false
  }

  render() {
    const userModule = localStorage.getItem('userModule');
    return (
      <React.Fragment>
        
        <SimpleBar className="h-100 project-bar" ref={this.refDiv}>
          <div id="sidebar-menu" className="setting-menu">
           
            <ul className="metismenu list-unstyled" id="side-menu">
            <li>
                <Link to={"/clients" }  className="  ">
                <img src={backIcon}  width='16px' className="mdi mdi-home-import-outline ms-1"/>
                </Link>
              </li>
              {/* <li>
                  <h2 className="ps-4"><b>{this.props.t("Client")}</b></h2>
              </li> */}
               <li><Link  to={"/clients/dashbord/" + this.props.match.params.id + "/" + this.props.match.params.clientId}  className=" project-link">
               <img src={dashboard} height="24px" style={{marginRight:10, verticalAlign:'middle'}} />
                 <span>{this.props.t("Dashboard")}</span></Link>
                </li>
                <li className="mm-data">
                <Link to={"/clients/profile/primary/" + this.props.match.params.id + "/" + this.props.match.params.clientId}  className="project-link">
                <img src={profile} height="24px" style={{marginRight:10, verticalAlign:'middle'}} />
                  <span>{this.props.t("Profile")}</span>
                </Link>
              
              </li>
              <li className="mm-data">
                <Link to={"/clients/activity/engagement/" + this.props.match.params.id + "/" + this.props.match.params.clientId} className="project-link">
                <img src={activity} height="24px" style={{marginRight:10, verticalAlign:'middle'}} />
                  <span>{this.props.t("Activity")}</span>
                </Link>
               
              </li>

              <li className="mm-data">
                <Link to={"/clients/seller/property/" + this.props.match.params.id + "/" + this.props.match.params.clientId}  className="project-link">
                <img src={seller} height="24px" style={{marginRight:10, verticalAlign:'middle'}} />
                  <span>{this.props.t("Seller")}</span>
                </Link>
              
              </li>

              <li className="mm-data">
                <Link to={"/clients/buyer/listings/" + this.props.match.params.id + "/" + this.props.match.params.clientId} className="project-link">
                <img src={buyerImg} height="24px" style={{marginRight:10, verticalAlign:'middle'}} />
                  <span>{this.props.t("Buyer")}</span>
                </Link>
               
              </li>

              <li className="mm-data">
                <Link to={"/clients/investor/investments/" + this.props.match.params.id + "/" + this.props.match.params.clientId} className="project-link">
                <img src={investor} height="24px" style={{marginRight:10, verticalAlign:'middle'}} />
                  <span>{this.props.t("Investor")}</span>
                </Link>
               
              </li>

              <li className="mm-data">
                <Link to="/" className="project-link">
                <img src={deleteImg} height="24px" style={{marginRight:10, verticalAlign:'middle'}} />
                  <span>{this.props.t("Delete Account")}</span>
                </Link>
                
                
              </li>
            
            </ul>
          </div>
        </SimpleBar>
       
      </React.Fragment>
    )
  }
}

ClientSidebar.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  type: PropTypes.string,
  match:PropTypes.object,
}

export default withRouter(withTranslation()(ClientSidebar))
