import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import BreadcrumbClient from "../../components/Common/BreadcrumbClient"
import { withTranslation } from "react-i18next"
//import Lead from "./Lead";
import ClientLink from "./client-link";
import "flatpickr/dist/themes/material_blue.css"
import Select from "react-select"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getClientDetail, addClientBuyerPref} from "../../store/clients/actions"

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Input,
  Button,
  InputGroup
} from "reactstrap"
import { AvField, AvForm, AvCheckbox } from "availity-reactstrap-validation"
import ClientSidebar from "components/VerticalLayout/ClientSidebar";
import SaveButton from "components/buttons/save";
import RsLink from "components/buttons/rsLink";
import ClientBuyerBreadcrumbs from "components/Common/ClientBuyerBreadcrumb";
import ClientBuyerMenu from "./menu/client-buyer-menu";


class ClientBuyPref extends Component {
  constructor(props) {
    super(props)
    this.state = {
        selectedGroup: null,
        buyersQuestions: [],
        buyersData: [],
        selectedvalue: '',
    }
    this.handleSelectGroup = this.handleSelectGroup.bind(this)
    this.handleValidSubmit = this.handleValidSubmit.bind(this)
  }
  handleSelectGroup = selectedGroup => {
    this.setState({ selectedGroup })
  }
  handleValidSubmit(event, values) {

    this.props.addClientBuyerPref(values, this.props.history)
    //console.log(values);
  }
  componentDidMount() {
    const { match: { params }, onGetClientDetail} = this.props;
    onGetClientDetail(params.clientId);
    const obj = JSON.parse(localStorage.getItem("authUser"));
    const token = obj.resultData.bearerToken;
    const str = 'Bearer ' + token;
    axios.get('https://realtyspace-001-site2.itempurl.com//api/QuestionAnswer/allforagenttype', {
      params: {
        forType: 1,

      }, headers: { Authorization: str }
    }).then(res => {
        const buyersQuestions = res.data;
        this.setState({ buyersQuestions });
    }),
    axios.get('https://realtyspace-001-site2.itempurl.com/api/ClientQuestionAnswer/allfortype', {
        params: {
          clientId: params.id,
          ClientTypeId: 1
        }, headers: { Authorization: str }
      })
        .then(res => {
          const buyersData = res.data;
          this.setState({ buyersData });
        })
 
}
  render() {
    const { selectedGroup } = this.state
    const { clietDetail } = this.props;
    const datafill = this.state.buyersData;
    this.state.buyersQuestions.forEach(function (record) {
      record.selectedvalue = '';
      record.selectedid = 0;
      datafill.forEach(function (buyervalue) {
        if (buyervalue.questionAnswerId == record.id) {
          record.selectedvalue = buyervalue.answersOption;
          record.selectedid = buyervalue.id;
        }

      });
    });

    const selectedValue = this.state.selectedValue;
    return (
      <React.Fragment>
             <ToastContainer autoClose={2000} />
       <div className="page-content project-page-content">
          <MetaTags>
            <title>Client Dashboard | Realty Space</title>
          </MetaTags>
          <Container fluid className="ps-0">
            <Row>
              <Col sm='2' className="pe-0">
                <ClientSidebar id={this.props.match.params.id} clientId={this.props.match.params.clientId} />
              </Col>
              <Col sm='10' className="ps-0 pe-0">
              <Row className="project-header">
                <Col sm="7">
             <ClientBuyerBreadcrumbs
              title={this.props.t("Clients")}
              breadcrumbItem={this.props.t( " Preference")}
            />
            </Col>
            <Col sm="4" className="">
                <h2 className="">{this.props.t( clietDetail.firstName + " " + clietDetail.lastName)}</h2>
                </Col>
            </Row>
            <ClientBuyerMenu />
            <Row>
            {/* <Col xl="3">
            <ClientLink id={this.props.match.params.id} clientId={this.props.match.params.clientId} />
              </Col> */}
              
            <Col xl="12">
            {/* <Card>
                          <AvForm className="needs-validation"
                            method="post"
                            onValidSubmit={this.handleValidSubmit}
                          >
                            <CardBody>
                            <div className="mt-0 card-title pheading">Add Buyer Preference</div>
                              <Row className="mb15">
                                {
                                  this.state.buyersQuestions
                                    .map(buyersQuestion =>

                                      <div key={buyersQuestion.key} className={buyersQuestion.layoutClass}>
                                        <FormGroup>
                                          <Label htmlFor={"buyerQuest" + buyersQuestion.id}>
                                            {buyersQuestion.question}
                                          </Label>
                                          {(() => {
                                            if (buyersQuestion.questionType == 1) {

                                              const asns = buyersQuestion.answersOptions;
                                              const res = asns.split(',');
                                              const result = res.map((item, index) => <option key={index} value={item}



                                              >{item}</option>);
                                              return (
                                                <div>
                                                  <AvField type="select" value={buyersQuestion.selectedvalue} name={"buyerQuest[" + buyersQuestion.id + "]"} >
                                                    <option value="">Select</option>
                                                    {result}
                                                  </AvField>
                                                </div>
                                              )
                                            } else if (buyersQuestion.questionType == 2) {

                                              return (
                                                <div className="square-switch leftdata">
                                                  <input
                                                    name={"buyerQuest[" + buyersQuestion.id + "]"}
                                                    type="checkbox"
                                                    id={"square-switch" + buyersQuestion.id}


                                                    onChange={() =>
                                                      this.setState({ sq1: !this.state.sq1 })
                                                    }
                                                  />
                                                  <label
                                                    htmlFor={"square-switch" + buyersQuestion.id}
                                                    data-on-label="Yes"
                                                    data-off-label="No"
                                                  />

                                                </div>
                                              )
                                            } else if (buyersQuestion.questionType == 6) {
                                              return (
                                                <div>
                                                  <AvField

                                                    name={"buyerQuest[" + buyersQuestion.id + "]"}
                                                    className="form-control"
                                                    type="text"
                                                    placeholder=""
                                                    value={buyersQuestion.selectedvalue}
                                                    validate={{ date: { format: 'MM/DD/YYYY' } }}

                                                  />
                                                </div>
                                              )
                                            } else if (buyersQuestion.questionType == 7) {
                                              return (
                                                <div>
                                                  <AvField

                                                    name={"buyerQuest[" + buyersQuestion.id + "]"}
                                                    className="form-control"
                                                    type="text"
                                                    value={buyersQuestion.selectedvalue}
                                                    placeholder=""

                                                  />
                                                </div>
                                              )
                                            } else if (buyersQuestion.questionType == 8) {
                                              return (
                                                <div>
                                                  <AvField
                                                    name={"buyerQuest[" + buyersQuestion.id + "]"}
                                                    className="form-control"
                                                    type="textarea"
                                                    value={buyersQuestion.selectedvalue}
                                                    placeholder=""
                                                  />
                                                </div>
                                              )
                                            } else {
                                              return (
                                                <div></div>
                                              )
                                            }
                                          })()}



                                        </FormGroup>
                                      </div>



                                    )}
                              </Row>

                              <Row className="mb15">
                              <AvField type="hidden" name="clientId" value={this.props.match.params.id} />
                                <Col lg="12">
                                  <Button
                                    color="primary"
                                  >
                                    Save Questions
                                  </Button>
                                </Col>
                              </Row>
                            </CardBody>
                          </AvForm>
                        </Card> */}
                        <Card>
                              <Formik
                                    enableReinitialize={true}
                                    initialValues={{
                                      leadId: 4,
                                      CurrentRent: (this.state && this.state.CurrentRent) || "",
                                      DownPayment: (this.state && this.state.DownPayment) || "",
                                      PaymentValue: (this.state && this.state.PaymentValue) || "",
                                      PriceRangeStart: (this.state && this.state.PriceRangeStart) || '',
                                      PriceRangeEnd: (this.state && this.state.PriceRangeEnd) || '',
                                      BuyBeforeSell: (this.state && this.state.BuyBeforeSell) || false,
                                      SignedToSell: (this.state && this.state.SignedToSell) || false ,
                                      PreapprovedLender: (this.state && this.state.PreapprovedLender) || false ,
                                      BedRoom: (this.state && this.state.BedRoom) || '',
                                      Bath: (this.state && this.state.Bath) || '',
                                      SqFt: (this.state && this.state.SqFt) || '',
                                      PrefferedLocation: (this.state && this.state.PrefferedLocation) || "",
                                      PropType: (this.state && this.state.PropType) || "",
                                      // Date: (this.state && this.state.Date) ||  new Date(),
                                      Desc: (this.state && this.state.Desc) || "",
                                    
                                    }}
                                    validationSchema={Yup.object().shape({
                                      CurrentRent: Yup.string().required("This is Required"),
                                      DownPayment: Yup.string().required("This is Required"),
                                      PaymentValue: Yup.number("Must be a number").min(1,'Value must be greater than 0').required("This is Required"),
                                      PriceRangeStart: Yup.number("Must be a number").min(1,'Value must be greater than 0').required("This is Required"),
                                      PriceRangeEnd: Yup.number("Must be a number").min(1,'Value must be greater than 0').required("This is Required").when('PriceRangeStart', (PriceRangeStart) => {
                                        if (PriceRangeStart) {
                                            return Yup.number("Must be a number").min(PriceRangeStart +1, 'Max Price Must be Greater than Min Price')
                                                .typeError('This is required')}}),
                                      BuyBeforeSell: Yup.bool(),
                                      SignedToSell: Yup.bool(),
                                      PreapprovedLender: Yup.bool(),
                                      BedRoom: Yup.number().min(1,'Value must be greater than 0').required("This is Required"),
                                      Bath: Yup.number("Must be a number").min(1,'Value must be greater than 0').required("This is Required"),
                                      SqFt: Yup.number("Must be a number").min(1,'Value must be greater than 0'),
                                      PrefferedLocation: Yup.string().required("This is Required"),
                                      PropType: Yup.string().required("This is Required"),
                                      Date: Yup.string().required("This is Required"),
                                      Desc: Yup.string().required("This is Required"),

                                    })}

                                    onSubmit={this.handleLeadNoteSubmit}
                                  >
                                    {({ errors,  touched, values, handleChange, setFieldValue }) => (

                                      // <Form
                                      // className="needs-validation"
                                      // >
                                      // <Form
                                      //   className="needs-validation"
                                      // >
                                      //   <CardBody>
                                      //     <Row className="mb15">
                                      //       {
                                      //         this.state.buyersQuestions
                                      //           .map(buyersQuestion =>

                                      //             <div key={buyersQuestion.key} className={buyersQuestion.layoutClass}>
                                      //               <FormGroup>
                                      //               <Field type="hidden"  value={buyersQuestion.id} name={`questionAnswers.${buyersQuestion.id}.questionAnswerId`} />
                                      //               {/* <Field type="text"  onChange={handleChange} className="form-control" value={buyersQuestion.id} name={`questionAnswers.${buyersQuestion.id}.questionAnswerId`} /> */}
                                      //                 <Label htmlFor={"buyerQuest" + buyersQuestion.id}>
                                      //                   {buyersQuestion.question}
                                      //                 </Label>
                                      //                 {(() => {
                                      //                   if (buyersQuestion.questionType == 1) {

                                      //                     const asns = buyersQuestion.answersOptions;
                                      //                     const res = asns.split(',');
                                      //                     const result = res.map((item, index) => <option key={index} value={item}



                                      //                     >{item}</option>);
                                      //                     return (
                                      //                       <div className="">
                                      //                         <Field as="select" className="form-control col-8" name={`questionAnswers.${buyersQuestion.id}.answersOption`}>
                                      //                           <option value="">Select</option>
                                      //                           {result}
                                      //                         </Field>
                                      //                       </div>
                                      //                     )
                                      //                   } else if (buyersQuestion.questionType == 2) {

                                      //                     return (
                                      //                       <div className="square-switch-left">
                                      //                         <input
                                      //                           name={`questionAnswers.${buyersQuestion.id}.answersOption`}
                                      //                           type="checkbox"
                                      //                           id={"square-switch-left" + buyersQuestion.id}


                                      //                           onChange={() =>
                                      //                             this.setState({ sq1: !this.state.sq1 })
                                      //                           }
                                      //                         />
                                      //                         <label
                                      //                           htmlFor={`questionAnswers.${buyersQuestion.id}.answersOption`}
                                      //                           data-on-label="Yes"
                                      //                           data-off-label="No"
                                      //                         />

                                      //                       </div>
                                      //                     )
                                      //                   } else if (buyersQuestion.questionType == 6) {
                                      //                     return (
                                      //                       <div>
                                      //                        <Field
                                      //                           name={`questionAnswers.${buyersQuestion.id}.answersOption`}
                                      //                           type="text"
                                      //                           onChange={handleChange}
                                      //                           className="form-control"

                                      //                         />
                                      //                       </div>
                                      //                     )
                                      //                   } else if (buyersQuestion.questionType == 7) {
                                      //                     return (
                                      //                       <div>
                                      //                         <Field
                                      //                           name={`questionAnswers.${buyersQuestion.id}.answersOption`}
                                      //                           type="text"
                                      //                           onChange={handleChange}
                                      //                           className="form-control"

                                      //                         />
                                      //                       </div>
                                      //                     )
                                      //                   } else if (buyersQuestion.questionType == 8) {
                                      //                     return (
                                      //                       <div>
                                      //                         <textarea name={`questionAnswers.${buyersQuestion.id}.answersOption`} className="form-control" onChange={handleChange} />
                                                              
                                      //                       </div>
                                      //                     )
                                      //                   } else {
                                      //                     return (
                                      //                       <div></div>
                                      //                     )
                                      //                   }
                                      //                 })()}



                                      //               </FormGroup>
                                      //             </div>



                                      //           )}
                                      //     </Row>

                                      //     <Row className="mb15">
                                          
                                      //       <Col lg="12">
                                      //         <Button
                                      //           color="primary"
                                      //         >
                                      //           Save Questions
                                      //         </Button>
                                      //       </Col>
                                      //     </Row>
                                      //   </CardBody>
                                      //   </Form>

                                      <Form className="needs-validation" >
                                        <CardBody>
                                          <Row className="mb15">
                                          <FormGroup>
                                            <div className="row mb-3">
                                              <div className='col-sm-2  '>
                                                <FormGroup>
                                                <Label htmlFor='CurrentRent' className='form-label label-text'>Current Rent or Own?*</Label>
                                                <Field as="select" className={
                                                                    "form-control" +
                                                                    (errors.CurrentRent && touched.CurrentRent
                                                                      ? " is-invalid"
                                                                      : "")
                                                                  } 
                                                      name='CurrentRent' id='CurrentRent'>
                                                  <option value="">Select</option>
                                                  <option value="Rent">Rent </option>
                                                  <option value="Own">Own</option>
                                                </Field>
                                                <ErrorMessage
                                                  name="CurrentRent"
                                                  component="div"
                                                  className="invalid-feedback"
                                                />
                                                </FormGroup>
                                              </div>

                                              <div className='col-sm-2 '>
                                              <FormGroup>
                                                <Label htmlFor='DownPayment' className='form-label label-text '>Down Payment Type*</Label>
                                                <Field as="select" className={
                                            "form-control" +
                                            (errors.DownPayment && touched.DownPayment
                                              ? " is-invalid"
                                              : "")
                                          }  name='DownPayment' id='DownPayment'>
                                                  <option value="">Select</option>
                                                  <option value="1">Percent</option>
                                                  <option value="2">Amount</option>
                                                </Field>
                                                <ErrorMessage
                                                  name="DownPayment"
                                                  component="div"
                                                  className="invalid-feedback"
                                                />
                                                </FormGroup>
                                              </div>

                                              <div className='col-sm-2  '>
                                              <FormGroup>
                                                <Label htmlFor='PaymentValue' className='form-label label-text'>Down Payment Value*</Label>
                                                <Field type="number" className={
                                            "form-control" +
                                            (errors.PaymentValue && touched.PaymentValue
                                              ? " is-invalid"
                                              : "")
                                          }  name='PaymentValue' id='PaymentValue'/>
                                          <ErrorMessage
                                                  name="PaymentValue"
                                                  component="div"
                                                  className="invalid-feedback"
                                                />
                                                </FormGroup>
                                              </div>

                                              <div className='col-sm-2  '>
                                              <FormGroup>
                                                <Label htmlFor='PriceRangeStart' className='form-label label-text'>Min Price*</Label>
                                                <Field as="select" className={
                                            "form-control" +
                                            (errors.PriceRangeStart && touched.PriceRangeStart
                                              ? " is-invalid"
                                              : "")
                                          } name='PriceRangeStart' id='PriceRangeStart'>
                                                  <option value="">Select</option>
                                                  <option value="15000">15000</option>
                                                  <option value="25000">25000</option>
                                                </Field>
                                                <ErrorMessage
                                                  name="PriceRangeStart"
                                                  component="div"
                                                  className="invalid-feedback"
                                                />
                                                </FormGroup>
                                              </div>

                                              <div className='col-sm-2  '>
                                              <FormGroup>
                                                <Label htmlFor='PriceRangeEnd' className='form-label label-text'>Max Price*</Label>
                                                <Field as="select" className={
                                            "form-control" +
                                            (errors.PriceRangeEnd && touched.PriceRangeEnd
                                              ? " is-invalid"
                                              : "")
                                          } name='PriceRangeEnd' id='PriceRangeEnd'>
                                                  <option value="">Select</option>
                                                  <option value="15000">15000</option>
                                                  <option value="45000">45000</option>
                                                </Field>
                                                <ErrorMessage
                                                  name="PriceRangeEnd"
                                                  component="div"
                                                  className="invalid-feedback"
                                                />
                                                </FormGroup>
                                              </div>
                                            </div>

                                            <hr className="mt-2 mb-3"/>

                                            <div className="row mb-3">
                                              <div>
                                                <Field type='checkbox' className='me-1' name='BuyBeforeSell' id='BuyBeforeSell'/>
                                                <Label htmlFor='BuyBeforeSell' className='form-label label-text'>Do you need to sell before you buy?</Label>
                                              </div>

                                              <div>
                                                <Field type='checkbox' className='me-1' name='SignedToSell' id='SignedToSell'/>
                                                <Label htmlFor='SignedToSell' className='form-label label-text'>Have you signed a listing aggrement to sell your home</Label>
                                              </div>

                                              <div>
                                                <Field type='checkbox' className='me-1' name='PreapprovedLender' id='PreapprovedLender'/>
                                                <Label htmlFor='PreapprovedLender' className='form-label label-text'>Are you pre-approved by a lender</Label>
                                              </div>
                                            </div>

                                            <hr className="mt-2 mb-3"/>

                                            <div className="row mb-3">
                                              <div className='col-sm-2  '>
                                              <FormGroup>
                                                <Label htmlFor='BedRoom' className='form-label label-text'>Bedroom*</Label>
                                                <Field as="select" className={
                                            "form-control" +
                                            (errors.BedRoom && touched.BedRoom
                                              ? " is-invalid"
                                              : "")
                                          } id='BedRoom' name='BedRoom'>
                                                  <option value="">Select</option>
                                                  <option value="1">1</option>
                                                  <option value="2">2</option>
                                                  <option value="3">3</option>
                                                  <option value="4">4</option>
                                                  <option value="5">5</option>
                                                  <option value="6">6</option>
                                                </Field>
                                                <ErrorMessage
                                                  name="BedRoom"
                                                  component="div"
                                                  className="invalid-feedback"
                                                />
                                                </FormGroup>
                                              </div>

                                              <div className='col-sm-2 '>
                                              <FormGroup>
                                                <Label htmlFor='Bath' className='form-label label-text'>Bath*</Label>
                                                <Field as="select" className={
                                            "form-control" +
                                            (errors.Bath && touched.Bath
                                              ? " is-invalid"
                                              : "")
                                          } id='Bath' name='Bath'>
                                                  <option value="">Select</option>
                                                  <option value="1">1</option>
                                                  <option value="1.5">1.5</option>
                                                  <option value="2">2</option>
                                                  <option value="2.5">25</option>
                                                  <option value="3">3</option>
                                                </Field>
                                                <ErrorMessage
                                                  name="Bath"
                                                  component="div"
                                                  className="invalid-feedback"
                                                />
                                                </FormGroup>
                                              </div>

                                              <div className='col-sm-2  '>
                                              <FormGroup>
                                                <Label htmlFor='SqFt' className='form-label label-text'>How much Sq. ft</Label>
                                                <Field type="number" className={
                                            "form-control" +
                                            (errors.SqFt && touched.SqFt
                                              ? " is-invalid"
                                              : "")
                                          }  id='SqFt' name='SqFt'/>
                                          <ErrorMessage
                                                  name="SqFt"
                                                  component="div"
                                                  className="invalid-feedback"
                                                />
                                                </FormGroup>
                                              </div>

                                              

                                              <div className='col-sm-2  '>
                                              <FormGroup>
                                                <Label htmlFor='PropType' className='form-label label-text'>Type</Label>
                                                <Field as="select" className='form-control' id='PropType' name='PropType'>
                                                  <option value="">Select</option>
                                                  <option value="1">Condo</option>
                                                  <option value="2">Townhouse</option>
                                                  <option value="3">Semi-Detached</option>
                                                  <option value="4">Detached</option>
                                                </Field>
                                                </FormGroup>
                                              </div>
                                              

                                              <div className='col-sm-3  '>
                                              <FormGroup>
                                                <Label htmlFor='Date' className='form-label label-text'>By what are you looking to move in</Label>
                                                <Field type="date" className='form-control ' name='Date'  id='Date'/>
                                                </FormGroup>
                                              </div>
                                              <div className='col-sm-3  '>
                                              <FormGroup>
                                                <Label htmlFor='PrefferedLocation' className='form-label label-text'>Preferred location</Label>
                                                <Field type="text" className='form-control'  id='PrefferedLocation' name='PrefferedLocation'/>
                                                </FormGroup>
                                              </div>
                                            </div>

                                            <hr className="mt-2 mb-3"/>

                                            <div className="row">
                                              <div className="  ">
                                              <FormGroup>
                                                <Label htmlFor='Desc' className='form-label label-text '>What else are you looking for in your home?</Label>
                                                <Field as='textarea' Row='5'   className='form-control' id='Desc' name='Desc'/>
                                                </FormGroup>
                                              </div>
                                            </div>

                                          </FormGroup>
                                          </Row>

                                          <Row className="mb15">
                                             <Col sm="12" md='6'>
                                             <SaveButton>Save Questions</SaveButton>

                                             </Col>
                                             <Col sm='12' md='6' className="text-end">
                                               <RsLink iconClass="bx bx-plus-medical">Add New</RsLink>
                                             </Col>
                                           </Row>
                                          </CardBody>
                                        </Form>
                                      
                                    )}
                      </Formik>
                        </Card>
            </Col>
            
            </Row>
            </Col>
            </Row>
           
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
ClientBuyPref.propTypes = {
  t: PropTypes.any,
  history: PropTypes.object,
  match: PropTypes.object,
  onGetClientDetail:PropTypes.func,
  clietDetail: PropTypes.object,
  addClientBuyerPref:PropTypes.func
}
const mapStateToProps = ({ Clients }) => (
  {
    clietDetail: Clients.clietDetail,
   

  })
const mapDispatchToProps = dispatch => ({
  onGetClientDetail: clientId => dispatch(getClientDetail(clientId)),
  addClientBuyerPref:(data) => dispatch(addClientBuyerPref(data))
 
});
export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(ClientBuyPref))
