import PropTypes from "prop-types"
import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"
import TabNavigation from "components/tablink/tablink"

class OfficeMenu extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }
    render() {
        const navItems = [
            {
                id: 'events',
                label: 'Events',
                link: `/communication/events`
            },
            {
                id: 'resource',
                label: 'Resource',
                link: `/communication/resource`
            },
            {
                id: 'message',
                label: 'Message',
                link: `/communication/message`
            }
        ];
        return (
            <React.Fragment>
                <TabNavigation navItems={navItems}

                />
            </React.Fragment>
        )
    }
}
OfficeMenu.propTypes = {
    match: PropTypes.object
}
export default withRouter(OfficeMenu)
