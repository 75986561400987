import PropTypes from "prop-types"
import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"
import TabNavigation from "components/tablink/tablink"

class LeadDetailMenu extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }
    render() {
        const navItems = [
            {
                id: 'lead-detail',
                label: 'Activities',
                link: `/lead/lead-detail/${this.props.match.params.id}/${this.props.match.params.leadId}`
            },
            {
                id: 'buyer-preference',
                label: 'Buying Preferences',
                link: `/lead/buyer-preference/${this.props.match.params.id}/${this.props.match.params.leadId}`
            },
            {
                id: 'buyer-eligibility',
                label: 'Buyer Eligibility',
                link: `/lead/buyer-eligibility/${this.props.match.params.id}/${this.props.match.params.leadId}`
            },
            {
                id: 'selling-preferences',
                label: 'Selling Preferences',
                link: `/lead/selling-preferences/${this.props.match.params.id}/${this.props.match.params.leadId}`
            },
            {
                id: 'investment-preferences',
                label: 'Investment Preferences',
                link: `/lead/investment-preferences/${this.props.match.params.id}/${this.props.match.params.leadId}`
            }
        ];
        return (
            <React.Fragment>
                <TabNavigation navItems={navItems}

                />
            </React.Fragment>
        )
    }
}
LeadDetailMenu.propTypes = {
    match: PropTypes.object
}
export default withRouter(LeadDetailMenu)
