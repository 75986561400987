import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import BreadcrumbClient from "../../components/Common/BreadcrumbClient"
import { withTranslation } from "react-i18next"
//import Lead from "./Lead";
import ClientLink from "./client-link";

import axios from "axios"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getClientDetail, addClientSellerPref } from "../../store/clients/actions"
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Input,
  Button,
  InputGroup,
  
} from "reactstrap"
import { AvField, AvForm, AvCheckbox } from "availity-reactstrap-validation"
import BreadcrumbsClient from "../../components/Common/BreadcrumbClient";
import ClientSidebar from "components/VerticalLayout/ClientSidebar";
import SaveButton from "components/buttons/save";
import ClientSellerBreadcrumbs from "components/Common/ClientSellerBreadcrumbClient";
import ClientSellerMenu from "./menu/client-seller-menu";
class ClientSalePref extends Component {
  constructor(props) {
    super(props)
    this.state = {
      sellerQuestions: [],
      sellerData: []
    }
    this.handleValidSubmit = this.handleValidSubmit.bind(this)
  }
  handleValidSubmit(event, values) {

    this.props.addClientSellerPref(values, this.props.history)
    
  }
  componentDidMount() {
    const { match: { params }, onGetClientDetail } = this.props;
    onGetClientDetail(params.clientId);
    
    const obj = JSON.parse(localStorage.getItem("authUser"));
    const token = obj.resultData.bearerToken;
    const str = 'Bearer ' + token;

    axios.get('https://realtyspace-001-site2.itempurl.com/api/QuestionAnswer/allforagenttype', {
      params: {
        forType: 2,

      }, headers: { Authorization: str }
    })
      .then(res => {
        const sellerQuestions = res.data;
        this.setState({ sellerQuestions });
      }),
      axios.get('https://realtyspace-001-site2.itempurl.com/api/ClientQuestionAnswer/all', {
        params: {
          ClientId: params.id,
          ClientTypeId: 2

        }, headers: { Authorization: str }
      })
        .then(res => {
          const sellerData = res.data;
          this.setState({ sellerData });
        })

  }
  render() {
    const { clietDetail } = this.props;
    const datafill = this.state.sellerData;
    console.log(datafill);
    this.state.sellerQuestions.forEach(function (record) {
      record.selectedvalue = '';
      record.selectedid = 0;
      datafill.forEach(function (sellervalue) {
        if (sellervalue.questionAnswerId == record.id) {
          record.selectedvalue = sellervalue.answersOption;
          record.selectedid = sellervalue.id;
        }

      });
    });
    const selectedValue = this.state.selectedValue;
    return (
      <React.Fragment>
        <ToastContainer autoClose={2000} />
         <div className="page-content project-page-content">
          <MetaTags>
            <title>Client Dashboard | Realty Space</title>
          </MetaTags>
          <Container fluid className="ps-0">
            <Row>
              <Col sm='2' className="pe-0">
                <ClientSidebar id={this.props.match.params.id} clientId={this.props.match.params.clientId} />
              </Col>
              <Col sm='10' className="ps-0 pe-0">
              <Row className="project-header">
                <Col sm="7">
             <ClientSellerBreadcrumbs
              title={this.props.t("Clients")}
              breadcrumbItem={this.props.t(" Preference")}
            />
            </Col>
            <Col sm="4" className="">
                <h2 className="">{this.props.t( clietDetail.firstName + " " + clietDetail.lastName)}</h2>
                </Col>
            </Row>
            <ClientSellerMenu />
            <Row>
              {/* <Col xl="3">
              <ClientLink id={this.props.match.params.id} clientId={this.props.match.params.clientId} />
              </Col> */}

              <Col xl="12">
              {/* <AvForm className="needs-validation"
                            method="post"
                            onValidSubmit={this.handleValidSubmit}
                          >
              <Card>
                          <CardBody>
                          <div className="mt-0 card-title pheading">Seller Preferences</div>
                            <Row className="mb15">
                              {
                                this.state.sellerQuestions
                                  .map(sellerQuestion =>

                                    <div key={sellerQuestion.key} className={sellerQuestion.layoutClass}>
                                      <FormGroup>
                                        <Label htmlFor={"sellerQuest" + sellerQuestion.id}>
                                          {sellerQuestion.question}
                                        </Label>
                                        {(() => {
                                          if (sellerQuestion.questionType == 1) {
                                            const asns = sellerQuestion.answersOptions;
                                            const res = asns.split(',');
                                            //console.log(res);
                                            const result = res.map((item, index) => <option key={index} value={item}>{item}</option>);
                                            return (
                                              <div>
                                                <AvField type="select" value={sellerQuestion.selectedvalue}  name={"sellerQuest[" + sellerQuestion.id + "]"}>
                                                  <option value=""></option>
                                                  {result}
                                                </AvField>
                                              </div>
                                            )
                                          } else if (sellerQuestion.questionType == 2) {
                                            return (
                                              <div className="square-switch leftdata">
                                                <input
                                                  name={"sellerQuest[" + sellerQuestion.id + "]"}
                                                  type="checkbox"
                                                  id={"sellerQuest[" + sellerQuestion.id + "]"}
                                                  value="Yes"
                                                />
                                                <label
                                                  htmlFor={"sellerQuest[" + sellerQuestion.id + "]"}
                                                 
                                                />

                                              </div>
                                            )
                                          } else if (sellerQuestion.questionType == 7) {
                                            return (
                                              <div>
                                                <AvField
                                                  name={"sellerQuest[" + sellerQuestion.id + "]"}
                                                  className="form-control"
                                                  type="text"
                                                  placeholder=""
                                                  value={sellerQuestion.selectedvalue}
                                                />
                                              </div>
                                            )
                                          } else if (sellerQuestion.questionType == 8) {
                                            return (
                                              <div>
                                                 <AvField
                                                    name={"sellerQuest[" + sellerQuestion.id + "]"}
                                                    className="form-control"
                                                    type="textarea"
                                                    value={sellerQuestion.selectedvalue}
                                                    placeholder=""
                                                  />
                                               
                                              </div>
                                            )
                                          } else {
                                            return (
                                              <div></div>
                                            )
                                          }
                                        })()}



                                      </FormGroup>
                                    </div>



                                  )}
                            </Row>
                            <Row className="mb15">
                              <Col lg="12">
                              <AvField type="hidden" name="clientId" value={this.props.match.params.id} />
                                <Button
                                  color="primary"
                                >
                                  Save Questions
                                </Button>
                              </Col>
                            </Row>

                          </CardBody>
                        </Card>

              </AvForm> */}

<Card>
                                <Formik
                                    enableReinitialize={true}
                                    initialValues={{
                                    
                                          leadId: 4,
                                          Reason: (this.state && this.state.Reason) || "",
                                          ExpectedPrice: (this.state && this.state.ExpectedPrice) || "",
                                          ByWhen: (this.state && this.state.ByWhen) || "",
                                          ListedProperty: (this.state && this.state.ListedProperty) || false ,
                                          LookingForNew: (this.state && this.state.LookingForNew) || false ,
                                          Neighbourhood: (this.state && this.state.Neighbourhood) || "" ,
                                          Desc: (this.state && this.state.Desc) || ""

                                    }}
                                  validationSchema={Yup.object().shape({
                                    Reason: Yup.string(),
                                    ExpectedPrice: Yup.number("Must be a number").min(1,'Value must be greater than 0').required("This is Required"),
                                    ByWhen: Yup.string(),
                                    ListedProperty: Yup.bool(),
                                    LookingForNew: Yup.bool(),
                                    Neighbourhood: Yup.string(),
                                    Desc: Yup.string(),
                                  })}

                                  onSubmit={values => {
                                    // setTimeout(() => {
                                    //   alert(JSON.stringify(values, '', 2));
                                    // }, 500);
                                  }}
                              >
                                {({ errors,  touched, values, handleChange, setFieldValue }) => (
                                <Form className="needs-validation" >
                                    <CardBody>

                                      <Row className="mb15">
                                        <Col lg="4">
                                        <FormGroup>
                                        <Label htmlFor="Reason" className="label-text">Reason to Sell
                                          </Label>
                                          <Field
                                            className={
                                              "form-control" +
                                              (errors.Reason && touched.Reason
                                                ? " is-invalid"
                                                : "")
                                            } 
                                            id="Reason"
                                            type="text"
                                            placeholder=""
                                            name="Reason"
                                          />  
                                          <ErrorMessage
                                                  name="Reason"
                                                  component="div"
                                                  className="invalid-feedback"
                                                />
                                        </FormGroup>
                                        </Col>

                                        <Col lg="4">
                                        <FormGroup>
                                        <Label htmlFor="ExpectedPrice" className="label-text"> Expected price* </Label>
                                          <Field
                                            className={
                                              "form-control" +
                                              (errors.ExpectedPrice && touched.ExpectedPrice
                                                ? " is-invalid"
                                                : "")
                                            } 
                                            type="number"
                                            placeholder=""
                                            name="ExpectedPrice"
                                          />  
                                          <ErrorMessage
                                                  name="ExpectedPrice"
                                                  component="div"
                                                  className="invalid-feedback"
                                                />
                                        </FormGroup>
                                        </Col>

                                        <Col lg="4">
                                        <FormGroup>
                                        <Label htmlFor="ByWhen" className="label-text"> By When to move out</Label>
                                          <Field
                                            className={
                                              "form-control" +
                                              (errors.ByWhen && touched.ByWhen
                                                ? " is-invalid"
                                                : "")
                                            } 
                                            type="text"
                                            name='ByWhen'
                                            placeholder=""
                                          />  
                                          <ErrorMessage
                                                  name="ByWhen"
                                                  component="div"
                                                  className="invalid-feedback"
                                                />
                                        </FormGroup>
                                        </Col>
                                        
                                        {/* <Col lg="3">
                                        <FormGroup>
                                            <Label htmlFor='test' className='form-label label-text'>Type</Label>
                                            <Field as="select" className='form-control' id='test'>
                                                  <option value="">Select</option>
                                                  <option value="">Option 1</option>
                                                  <option value="">Option 2</option>
                                            </Field>
                                        </FormGroup>
                                        </Col> */}
                                      </Row>

                                        <hr className="mt-2 mb-3"/>


                                        <Row className="">
                                          <FormGroup>
                                            <Field type='checkbox' className='me-1' name='ListedProperty' id='ListedProperty'/>
                                            <Label htmlFor='ListedProperty' className='form-label label-text'>Have you listed the property previously</Label>
                                          </FormGroup>
                                       </Row>

                                       <Row className="">
                                          <FormGroup>
                                            <Field type='checkbox' className='me-1' name='LookingForNew' id='LookingForNew'/>
                                            <Label htmlFor='LookingForNew' className='form-label label-text'>Are you looking to buy a new property after selling</Label>
                                          </FormGroup>
                                       </Row>

                                       <hr className="mt-2 mb-3"/>


                                        <Row className="mb15">
                                        <Col lg="12">
                                        <FormGroup>
                                        <Label htmlFor="Neighbourhood" className="label-text"> Tell a little bit about your home/neighbourhood </Label>
                                          <Field
                                            className={
                                              "form-control" +
                                              (errors.Neighbourhood && touched.Neighbourhood
                                                ? " is-invalid"
                                                : "")
                                            } 
                                            as="textarea"
                                            name="Neighbourhood"
                                            placeholder="Recently upgraded backyard. Excellent neighbors."
                                          />
                                          <ErrorMessage
                                                  name="Neighbourhood"
                                                  component="div"
                                                  className="invalid-feedback"
                                                />
                                        </FormGroup>
                                        </Col>
                                        </Row>
                                        {/* <hr className="mt-2 mb-3"/> */}

                                        <Row className="mb15">
                                        <Col lg="12">
                                        <FormGroup>
                                        <Label htmlFor="Desc" className="label-text"> Seller notes </Label>
                                        <Field
                                            className={
                                              "form-control" +
                                              (errors.Desc && touched.Desc
                                                ? " is-invalid"
                                                : "")
                                            } 
                                            as="textarea"
                                            placeholder="Motivated to sell."
                                            name="Desc"
                                          />
                                          <ErrorMessage
                                                  name="Desc"
                                                  component="div"
                                                  className="invalid-feedback"
                                                />
                                        </FormGroup>
                                        </Col>
                                        </Row>

                                        <Row className="mb15">
                                        <Col lg="12">
                                        <SaveButton>Save Questions</SaveButton>
                                        
                                        </Col>
                                        </Row>
                                    </CardBody>
                                    </Form>
                                )}
                                    </Formik>
                                </Card>


                        
              </Col>

            </Row>
            </Col>
            </Row>

          </Container>
        </div>
      </React.Fragment>
    )
  }
}
ClientSalePref.propTypes = {
  t: PropTypes.any,
  history: PropTypes.object,
  match: PropTypes.object,
  onGetClientDetail:PropTypes.func,
  clietDetail: PropTypes.object,
  addClientSellerPref:PropTypes.func

}
const mapStateToProps = ({ Clients }) => (
  {
    clietDetail: Clients.clietDetail,
     

  })
const mapDispatchToProps = dispatch => ({
  onGetClientDetail: clientId => dispatch(getClientDetail(clientId)),
  addClientSellerPref:(data) => dispatch(addClientSellerPref(data))
 
});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ClientSalePref))
