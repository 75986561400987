import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import { map } from "lodash"
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Flatpickr from "react-flatpickr"
import notes from "../../assets/images/rsicons/notes.png";
import follow from "../../assets/images/rsicons/follow-up.png";
import phonelog from "../../assets/images/rsicons/phone-log.png";
import emaillog from "../../assets/images/rsicons/email-log.png";
import { Container, Row, Col, Card, CardBody, Label, FormGroup, Input, InputGroup,
  DropdownItem, DropdownMenu, DropdownToggle, Table, UncontrolledDropdown, Offcanvas, OffcanvasHeader, OffcanvasBody } from "reactstrap"
import FilterMenu from "./filter-menu/filter-menu";
import { addLeadNoteTab, getLeads, getNoteLeadTab, getListLeadTab,  addFollowNoteTab, getFollowLeadTab, 
  getLeadCallLog, addLeadCallLog, addLeadEmailLog, getLeadEmailLog, getAllActLeadTab} from "../../store/leads/actions"
  import Moment from 'react-moment';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import CreateLead from './lead-create'
import RsAdNew from "components/buttons/Adnew";
class LeadList extends Component {
  constructor(props) {
    super(props)
    this.state = {
     leads: [],
      lead:''
  }
  this.toggleAddNew = this.toggleAddNew.bind(this);
  this.toggleEmailCanvas = this.toggleEmailCanvas.bind(this);
  this.toggleFollowCanvas = this.toggleFollowCanvas.bind(this);
  this.toggleCallLogCanvas = this.toggleCallLogCanvas.bind(this);
  this.toggleEmailLogCanvas = this.toggleEmailLogCanvas.bind(this);
  this.toggleSmsLogCanvas = this.toggleSmsLogCanvas.bind(this);
  this.toggleAddNoteCanvas = this.toggleAddNoteCanvas.bind(this);    
  this.toggleBackdrop = this.toggleBackdrop.bind(this);
  }
  toggleBackdrop() {
    this.setState({ isBackdrop: !this.state.isBackdrop });
  }
  toggle() {
    this.setState(prevState => ({
        isRight: !prevState.isRight
    }));
    
}
toggleFollow() {
  this.setState(prevState => ({
    isFollow: !prevState.isFollow
  }));
  
}
toggleAddNote() {
  this.setState(prevState => ({
    isAddNote: !prevState.isAddNote
  }));
  
}
toggleCallLog() {
  this.setState(prevState => ({
    isCallLog: !prevState.isCallLog
  }));
  
}

toggleEmailLog() {
  this.setState(prevState => ({
    isEmailLog: !prevState.isEmailLog
  }));
  
}
toggleSmsLog() {
  this.setState(prevState => ({
    isSmsLog: !prevState.isSmsLog
  }));
  
}
addNewtoggle(){
  this.setState(prevState => ({
    isAddNew: !prevState.isAddNew
  }));
}
toggleAddNew() {
  this.setState({ isAddNew: !this.state.isAddNew });
  
}
toggleEmailCanvas() {
  this.setState({ lead: "", isEdit: false, isRight: !this.state.isRight });
  this.toggle();
}
toggleAddNoteCanvas() {
  this.setState({ lead: "", isEdit: false, isFollow: !this.state.isAddNote });
  this.toggleAddNote();
}
toggleFollowCanvas() {
  this.setState({ lead: "", isEdit: false, isFollow: !this.state.isFollow });
  this.toggleFollow();
}

toggleCallLogCanvas() {
  this.setState({ lead: "", isEdit: false, isCallLog: !this.state.isCallLog });
  this.toggleCallLog();
}

toggleEmailLogCanvas() {
  this.setState({ lead: "", isEdit: false, isEmailLog: !this.state.isEmailLog });
  this.toggleEmailLog();
}
toggleSmsLogCanvas() {
  this.setState({ lead: "", isEdit: false, isSmsLog: !this.state.isSmsLog });
  this.toggleSmsLog();
}
  componentDidMount() {
    const { onGetAllLeads } = this.props;
    onGetAllLeads(localStorage.getItem('userId'));
  }
  toggleEmailCanvas = arg => {
    const lead = arg;
    const { onGetAllActLead } = this.props;
    this.setState({
      lead: {
        id: lead.id,
      },
        isEdit: true,
    });
    onGetAllActLead(lead.id)
    this.toggle();
};

toggleFollowCanvas = arg => {
  const lead = arg;
  const { onGetFollowLead } = this.props;
  this.setState({
    lead: {
      id: lead.id,
  },
  isEditFollow: true,
  });
  onGetFollowLead(lead.id);
  this.toggleFollow();
};
toggleAddNoteCanvas = arg => {
  const lead = arg;
  const { onGetNoteLead } = this.props;
  this.setState({
  lead: {
    id: lead.id,
  },
  isEdit: true,
  });
  onGetNoteLead(lead.id);
  this.toggleAddNote();
};

toggleCallLogCanvas = arg => {
  const lead = arg;
  const { onGetLeadCallLog } = this.props;
  this.setState({
    lead: {
      id: lead.id,
  },
  tableFilter: null,
  isEditCall: true,
  });
  onGetLeadCallLog(lead.id);
  this.toggleCallLog();
};
toggleEmailLogCanvas = arg => {
  const lead = arg;
  const { onGetLeadEmailLog } = this.props;
  this.setState({
  lead: {
      id: lead.id,
  },
  isEditCall: true,
  });
  onGetLeadEmailLog(lead.id);
  this.toggleEmailLog();
};
toggleSmsLogCanvas = arg => {
  const lead = arg;
  this.setState({
    lead: {
          id: lead.id
      },
      
    
  });
  this.toggleSmsLog();
};

filterTable = (event) => {
  this.setState({ tableFilter: event.target.value });
};
  render() {
    const { onAddLeadNote, onAddLeadFollowUp, onAddCallLog, onAddEmailLog, onSendWorksheetEmailLog, allLeads, gridLoading } = this.props;
    const { leadNotes, leadFollows, calllogs, emaillogs, activities } = this.props;
    const allLocal = localStorage.getItem('localData')
    const localization = JSON.parse(allLocal);
    const lead = this.state.lead;
    const userModule = localStorage.getItem('userModule');
    const { isEdit, isEditFollow, isEditCall, isEditEmail } = this.state;
    return (
      <React.Fragment>
         {gridLoading ? (
                    <div className="rs-loader-overlay">
                    <div className="rs-loader"></div>
                    </div>
                ) : null}
        <div className="page-content project-page-content ">
          <MetaTags>
            <title>All Leads | Realty Space</title>
          </MetaTags>
          <Container fluid className="ps-0">
          <ToastContainer autoClose={2000} />
          <Row className="project-header">
            <Col xl="7">
             <Breadcrumbs
              title={this.props.t("Lead")}
              breadcrumbItem={this.props.t("All Leads")}
            />
            </Col>
            <Col xl="5">
            {/* <WelcomeComp /> */}
            <div className="pt-2 float-end mt-2">
                  
                  <div className="">
                 
                  <RsAdNew to="/lead/import-lead" className="btn btn-light btn-md me-2" iconClass="dripicons-download">Import Lead</RsAdNew>

                  <RsAdNew to="#" onClick={ this.toggleAddNew } className="btn btn-light btn-md" iconClass="bx bx-plus-medical">Add New</RsAdNew>
                    
                  </div>
                 
                </div>
            </Col>
            </Row>
            <FilterMenu />
            <Row>
            <Col lg="12">
            <Card>
        <CardBody>
       
          <div className="row">
          
          <div className="col-sm-4"></div>
            
                      <div className="col-sm-4 mb20">
                        
                        <Input type="text" className="form-control" value={this.state.tableFilter}  onInput={this.filterTable} id="autoSizingInput" placeholder="Search By Name, Phone, Email" />
                      </div>


                    </div>
                    <div className="clearfix"></div>
          <div className="datatable-loading">
                 <DataTable className="table table-bordered data-table align-middle table-nowrap mb-0" value={allLeads} paginator rows={10} rowsPerPageOptions={[10, 20, 30, 50, 100, 150, 200, 500 ]} currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries " paginatorTemplate=" FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown   " tableStyle={{ minWidth: '50rem' }}  globalFilter={this.state.tableFilter } >
              <Column headerClassName="align-middle" body={(rowData) => (
                  <div className="form-check font-size-16">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id={rowData.id}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={rowData.id}
                        >
                          &nbsp;
                        </label>
                      </div>
                 
              )}></Column>
              <Column headerClassName="align-middle" bodyClassName="table-data" field="firstName" header="Name" body={(rowData) => (
                   <div className="row">
                   <div className="col-sm-8"><Link style={{color:'#4c535a'}}  to={"/lead/lead-detail/" + rowData.id + "/" + rowData.leadId }>{rowData.firstName} {rowData.lastName}</Link> </div>
                         <div className="col-sm-1 short-link"><Link className="link-open" to={"/lead/lead-detail/" + rowData.id + "/" + rowData.leadId }><i className="grid-icon bx bx-link-external"></i></Link></div>
                         <div className="col-sm-1 short-link"><Link onClick={() => this.toggleEmailCanvas(rowData)} to="#"><i className="grid-icon bx  bx-timer"></i></Link></div>
                         <div className="col-sm-1 short-link"><Link onClick={() => this.toggleAddNoteCanvas(rowData)} to="#"><i className="grid-icon bx bxs-message-alt-dots"></i></Link></div>
                         <div className="col-sm-1 short-link">
                         <UncontrolledDropdown>
                               <DropdownToggle tag="a" to="#" className="card-drop" data-bs-toggle="dropdown" aria-expanded="false">
                               <i className="grid-icon bx bx-dots-vertical-rounded"></i>
                               </DropdownToggle>
                               <DropdownMenu className="dropdown-menu-end">
                                 <DropdownItem onClick={() => this.toggleCallLogCanvas(rowData)} href="#"><i className="bx bx-phone-outgoing"></i> Add call log</DropdownItem>
                                 <DropdownItem onClick={() => this.toggleEmailLogCanvas(rowData)} href="#"><i className=" bx bx-mail-send"></i> Add email log</DropdownItem>
                                 <DropdownItem onClick={() => this.toggleSmsLogCanvas(rowData)} href="#"><i className=" bx bx-message-dots"></i> Add SMS log</DropdownItem>
                                 <DropdownItem onClick={() => this.toggleFollowCanvas(rowData)} href="#"><i className="bx bx-user-voice"></i> Set Next Follow-up</DropdownItem>
                               </DropdownMenu>
                             </UncontrolledDropdown>
                         
                         </div>
                   </div>
              )}></Column>
              <Column  field="mobile" header="Mobile"></Column>
              <Column  field="email" header="Email"></Column>
              <Column  field="lastContact" header="Last Contact" body={(rowData) => (
                  rowData.lastContact ? <Moment format="D MMM YY">{rowData.lastContact}</Moment> : ''
              )}></Column>
              <Column  field="leadSourceId" header="Source" body={(rowData) => (
                  rowData.leadSourceId ? localization["LeadSource_"+ rowData.leadSourceId] : 'N/A'
              )}></Column>
              <Column  field="leadStatusId" header="Status" body={(rowData) => (
                  rowData.leadStatusId ? localization["LeadStatus_"+ rowData.leadStatusId] : 'N/A'
              )}></Column>
              <Column  header="Action" body={(rowData) => (
                 <UncontrolledDropdown direction="up">
                 <DropdownToggle className="btn btn-action" type="button" id="defaultDropdown">
                   Action <i className="mdi mdi-chevron-down"></i>
                 </DropdownToggle>
                 <DropdownMenu>
                   <DropdownItem tag={Link}  to={"/lead/lead-detail/" + rowData.id + "/" + rowData.leadId }>Edit</DropdownItem>
                   { userModule.indexOf("BrokerAgent.RealtorLeads.Delete") > -1  ?
                   <DropdownItem  href="#">Delete</DropdownItem>
                   : ''
                   }
                   
                 </DropdownMenu>
               </UncontrolledDropdown>
              )}></Column>
          </DataTable>
          
        
                        <Offcanvas
                        isOpen={this.state.isAddNew}
                        direction="end"
                        toggle={this.toggleAddNew}
                        style={{ width: 800 }}
                      >
                        <OffcanvasHeader toggle={this.toggleAddNew}>
                         New Lead
                        </OffcanvasHeader>
                        <OffcanvasBody>
                          <CreateLead toggleAddNew={this.toggleAddNew} />
                        </OffcanvasBody>
                        </Offcanvas>
                      <Offcanvas
                            isOpen={this.state.isRight}
                            direction="end"
                            toggle={this.toggleEmailCanvas}
                            
                        >
                            <OffcanvasHeader toggle={this.toggleEmailCanvas}>
                                All Activities 
                            </OffcanvasHeader>
                            <OffcanvasBody>
                            <ul className="verti-timeline list-unstyled">
                                        {map(
                                          activities,
                                          (allactivity, allactkey) => (
                                            <li
                                              className="event-list eventlist"
                                              key={"_allact_" + allactkey}
                                            >
                                              <div className="event-timeline-dot">
                                                {(() => {
                                                  if (
                                                    allactivity.activitySubType ==
                                                      8 ||
                                                    allactivity.activitySubType ==
                                                      9 ||
                                                    allactivity.activitySubType ==
                                                      7
                                                  ) {
                                                    return (
                                                      <>
                                                        <img src={emaillog} />
                                                      </>
                                                    );
                                                  } else if (
                                                    allactivity.activitySubType ==
                                                      3 ||
                                                    allactivity.activitySubType ==
                                                      4 ||
                                                    allactivity.activitySubType ==
                                                      5
                                                  ) {
                                                    return (
                                                      <>
                                                        <img src={phonelog} />
                                                      </>
                                                    );
                                                  } else if (
                                                    allactivity.activitySubType ==
                                                    10
                                                  ) {
                                                    return (
                                                      <>
                                                        <img src={follow} />
                                                      </>
                                                    );
                                                  } else {
                                                    return (
                                                      <>
                                                        <img src={notes} />
                                                      </>
                                                    );
                                                  }
                                                })()}
                                              </div>
                                              <div className="d-flex">
                                                <div className="flex-shrink-0 me-3">
                                                  <h5 className="font-size-14">
                                                    <Moment format="D MMM YY">
                                                      {allactivity.addedDate}
                                                    </Moment>
                                                  </h5>
                                                </div>
                                                <div className="flex-grow-1">
                                                  <h5 className="font-size-14">
                                                    {
                                                      localization[
                                                        "ActivitySubType_" +
                                                          allactivity.activityType +
                                                          "_" +
                                                          allactivity.activitySubType
                                                      ]
                                                    }
                                                  </h5>
                                                </div>
                                              </div>
                                              <div className="exNote">
                                                {allactivity.activityRemarks}
                                              </div>
                                            </li>
                                          )
                                        )}
                                      </ul>
                            </OffcanvasBody>
                        </Offcanvas>

                        <Offcanvas
          isOpen={this.state.isAddNote}
          direction="end"
          toggle={this.toggleAddNoteCanvas}
        >
          <OffcanvasHeader toggle={this.toggleAddNoteCanvas}>
            Add Notes
          </OffcanvasHeader>
          <OffcanvasBody>
            <Formik
              enableReinitialize={true}
              initialValues={{

                refId: lead.id,
                addNote: (this.state && this.state.addNote) || "",
              


              }}
              validationSchema={Yup.object().shape({
                // addNote: Yup.string().required("This is Required"),
                addNote: Yup.string().max(500, 'This filed contains only 500 characters only').required("This is Required")
              })}

              onSubmit={values => {

                if (isEdit) {
                   
                  onAddLeadNote(values)
                    //onAddLeadNote(updateData);
                } else {

                }
                console.log()
                this.toggleAddNote();
            }}
            >
              {({ errors, touched, values, handleChange, setFieldValue }) => (
                <Form
                  className="needs-validation"
                >

                  <Row>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="addNote">
                          {this.props.t("Add Note")}
                        </Label>
                        <textarea
                          name="addNote"
                          onChange={handleChange}
                          type="textarea"
                          className={
                            "form-control" +
                            (errors.addNote && touched.addNote
                              ? " is-invalid"
                              : "")
                          }
                          id="addNote"
                        />
                        <ErrorMessage
                          name="addNote"
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <div className="clearfix"><button type="submit" className="btn btn-primary w-md">{this.props.t("Save")}</button></div>


                </Form>
              )}
            </Formik>
            <div className="mb-5 h4 card-title mt-20">Notes History</div>
                                <ul className="verti-timeline list-unstyled">

                                  {map(leadNotes, (noteLog, notekey) => (

                                    <li className="event-list eventlist" key={"_note_" + notekey}>
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle font-size-18" />
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-shrink-0 me-3">
                                          <h5 className="font-size-14">
                                            <Moment format="D MMM YY">{noteLog.addedDate}</Moment>


                                          </h5>
                                        </div>
                                        <div className="flex-grow-1">
                                          <h5 className="font-size-14"></h5>
                                        </div>
                                      </div>
                                      <div className="exNote">{noteLog.activityRemarks}</div>
                                    </li>
                                  ))}

                                </ul>
          </OffcanvasBody>
                        </Offcanvas>

                        <Offcanvas
          isOpen={this.state.isCallLog}
          direction="end"
          toggle={this.toggleCallLogCanvas}
          style={{ width: 800 }}
        >
          <OffcanvasHeader toggle={this.toggleCallLogCanvas}>
            Call Log
          </OffcanvasHeader>
          <OffcanvasBody>
            <Formik
              enableReinitialize={true}
              initialValues={{
                actionType: '',
                addedDate: new Date(),
                refId: lead.id
                // callNote: (this.state && this.state.callNote) || "",


              }}
              validationSchema={Yup.object().shape({
                actionType: Yup.string().required(
                  "Please Select Action"
                ),
                callNote: Yup.string().max(500, 'This filed contains only 500 characters only')
              })}

              onSubmit={values => {

                if (isEditCall) {
                   
                  onAddCallLog(values)
                    //onAddLeadNote(updateData);
                } else {

                }
                this.toggleCallLog();
            }}
            >
              {({ errors, touched, values, handleChange, setFieldValue }) => (
                <Form
                  className="needs-validation"
                >

                  <Row className="mt20 mb20">



                    <Col lg="12">
                      <div role="group" className="btnCheckGroup" aria-labelledby="my-radio-group">
                        <label>
                          <Field type="radio" name="actionType" id="actionType1" value="No Answer" onChange={handleChange} />
                          No Answer
                        </label>
                        <label>
                          <Field type="radio" name="actionType" id="actionType2" value="Left Voicemail" onChange={handleChange} />
                          Left Voicemail
                        </label>
                        <label>
                          <Field type="radio" name="actionType" id="actionType2" value="Bad Number" onChange={handleChange} />
                          Bad Number
                        </label>
                        <ErrorMessage
                          name="actionType"
                          component="div"
                          className="text-danger"
                        />  

                      </div>
                      

                    </Col>

                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="addedDate">
                          Log Date
                        </Label>
                        <Flatpickr
                          className={
                            "form-control" +
                            (errors.addedDate && touched.addedDate
                              ? " is-invalid"
                              : "")
                          }
                          placeholder=""
                          value={values.addedDate}
                          onChange={(value) => setFieldValue('addedDate', value[0])}
                          options={{
                            altInput: true,
                            altFormat: "F j, Y",
                            dateFormat: "Y-m-d"
                          }}
                        />
                        <ErrorMessage
                          name="addedDate"
                          component="div"
                          className="invalid-feedback"
                        />

                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="callNote">
                          Add Message (Optional)
                        </Label>
                        <textarea
                          name="callNote"
                          onChange={handleChange}
                          type="textarea"

                          className={
                            "form-control" +
                            (errors.callNote && touched.callNote
                              ? " is-invalid"
                              : "")
                          }

                          id="callNote"

                        />
                        <ErrorMessage
                          name="callNote"
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <div className="clearfix"><button type="submit" className="btn btn-primary w-md">Save</button></div>


                </Form>
              )}
            </Formik>
            <div className="mb-5 h4 card-title mt-20">Call Log History</div>
                                <ul className="verti-timeline list-unstyled">

                                  {map(calllogs, (callLog, callkey) => (

                                    <li className="event-list eventlist" key={"_call_" + callkey}>
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle font-size-18" />
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-shrink-0 me-3">
                                          <h5 className="font-size-14">
                                            <Moment format="D MMM YY">{callLog.addedDate}</Moment>

                                            <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                                          </h5>
                                        </div>
                                        <div className="flex-grow-1">
                                          <h5 className="font-size-14">{localization["ActivitySubType_"+ callLog.activityType+"_"+callLog.activitySubType]}</h5>
                                        </div>
                                      </div>
                                      <div className="exNote">{callLog.activityRemarks}</div>
                                    </li>
                                  ))}

                                </ul>               
          </OffcanvasBody>
        </Offcanvas>

        <Offcanvas
          isOpen={this.state.isFollow}
          direction="end"
          toggle={this.toggleFollowCanvas}

        >
          <OffcanvasHeader toggle={this.toggleFollowCanvas}>
            Follow Us
          </OffcanvasHeader>
          <OffcanvasBody>
            <Formik
              enableReinitialize={true}
              initialValues={{
                refId: lead.id,
                addNote: (this.state && this.state.addNote) || "",
                nextFollowupDate: new Date(),


              }}
              validationSchema={Yup.object().shape({
                addNote: Yup.string().max(500,'Only 500 Characters are allowed').required("This is Required")
              })}

              onSubmit={values => {

                if (isEditFollow) {
                 
                  onAddLeadFollowUp(values)
                  
                } else {

                }
                this.toggleFollow();
            }}
            >
              {({ errors, touched, values, handleChange, setFieldValue }) => (
                <Form
                  className="needs-validation"
                >

                  <Row>
                    <Col md="12">
                      <FormGroup className="mb-3">

                        <textarea
                          name="addNote"
                          onChange={handleChange}
                          type="textarea"
                          className={
                            "form-control" +
                            (errors.addNote && touched.addNote
                              ? " is-invalid"
                              : "")
                          }
                          id="addNote"
                        />
                        <ErrorMessage
                          name="addNote"
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>

                    <Col md="12">
                      <FormGroup className="mb15">

                        <Label htmlFor="logDate">
                          Date
                        </Label>
                        <Flatpickr
                          className={
                            "form-control" +
                            (errors.nextFollowupDate && touched.nextFollowupDate
                              ? " is-invalid"
                              : "")
                          }
                          placeholder=""
                          value={values.nextFollowupDate}
                          onChange={(value) => setFieldValue('nextFollowupDate', value[0])}
                          options={{
                            altInput: true,
                            altFormat: "F j, Y",
                            dateFormat: "Y-m-d"
                          }}
                        />
                        <ErrorMessage
                          name="logDate"
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup>

                    </Col>
                  </Row>

                  <div className="clearfix"><button type="submit" className="btn btn-primary w-md">Save</button></div>


                </Form>
              )}
            </Formik>
            <div className="mb-5 h4 card-title mt-20">Follow-up History</div>
                <ul className="verti-timeline list-unstyled">

                    {map(leadFollows, (follLog, follLogkey) => (

                    <li className="event-list eventlist" key={"_note_" + follLogkey}>
                        <div className="event-timeline-dot">
                        <i className="bx bx-right-arrow-circle font-size-18" />
                        </div>
                        <div className="d-flex">
                        <div className="flex-shrink-0 me-3">
                            <h5 className="font-size-14">
                            <Moment format="D MMM YY">{follLog.activityRefStartDate}</Moment>


                            </h5>
                        </div>
                        <div className="flex-grow-1">
                            <h5 className="font-size-14"></h5>
                        </div>
                        </div>
                        <div className="exNote">{follLog.activityRemarks}</div>
                    </li>
                    ))}

</ul>                          
            
          </OffcanvasBody>
        </Offcanvas>


        <Offcanvas
          isOpen={this.state.isEmailLog}
          direction="end"
          toggle={this.toggleEmailLogCanvas}
          style={{ width: 800 }}
        >
          <OffcanvasHeader toggle={this.toggleEmailLogCanvas}>
            Email Log
          </OffcanvasHeader>
          <OffcanvasBody>
            <Formik
              enableReinitialize={true}
              initialValues={{
                actionType:'',
                addedDate: new Date(),
                refId: lead.id  

              }}
              validationSchema={Yup.object().shape({
                actionType: Yup.string().required(
                  "Please Select Action"
                ),
                remarks: Yup.string().max(500, 'This filed contains only 500 characters only')
              })}

              onSubmit={values => {

                if (isEditCall) {
                   
                  onAddEmailLog(values)
                    //onAddLeadNote(updateData);
                } else {

                }
                this.toggleEmailLog();
            }}
            >
              {({ errors, touched, values, handleChange, setFieldValue }) => (
                <Form
                  className="needs-validation"
                >

                  <Row className="mt20 mb20">



                    <Col lg="12">
                      <div role="group" className="btnCheckGroup" aria-labelledby="my-radio-group">
                        <label>
                          <Field type="radio" name="actionType" id="actionType1" value="Email Sent" onChange={handleChange} />
                          Email Sent
                        </label>
                        <label>
                          <Field type="radio" name="actionType" id="actionType2" value="Email Received" onChange={handleChange} />
                          Email Received
                        </label>
                        <label>
                          <Field type="radio" name="actionType" id="actionType2" value="Email Bounced" onChange={handleChange} />
                          Email Bounced
                        </label>

                      </div>
                      <ErrorMessage
                        name="actionType"
                        component="div"
                        className="text-danger"
                      />
                    </Col>

                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="addedDate">
                          Log Date
                        </Label>
                        <Flatpickr
                          className={
                            "form-control" +
                            (errors.addedDate && touched.addedDate
                              ? " is-invalid"
                              : "")
                          }
                          placeholder=""
                          value={values.addedDate}
                          onChange={(value) => setFieldValue('addedDate', value[0])}
                          options={{
                            altInput: true,
                            altFormat: "F j, Y",
                            dateFormat: "Y-m-d"
                          }}
                        />
                        <ErrorMessage
                          name="addedDate"
                          component="div"
                          className="invalid-feedback"
                        />

                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="remarks">
                          Add Message (Optional)
                        </Label>
                        <textarea
                          name="remarks"
                          onChange={handleChange}
                          type="textarea"

                          className="form-control"

                          id="remarks"

                        />
                        <ErrorMessage
                          name="remarks"
                          component="div"
                          className="text-danger"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <div className="clearfix"><button type="submit" className="btn btn-primary w-md">Save</button></div>


                </Form>
              )}
            </Formik>
            <div className="mb-5 h4 card-title mt-20">Email Log History</div>
                                <ul className="verti-timeline list-unstyled">

                                {map(emaillogs, (emailLog, emailkey) => (

                                  <li className="event-list eventlist" key={"_email_" + emailkey}>
                                    <div className="event-timeline-dot">
                                      <i className="bx bx-right-arrow-circle font-size-18" />
                                    </div>
                                    <div className="d-flex">
                                      <div className="flex-shrink-0 me-3">
                                        <h5 className="font-size-14">
                                          <Moment format="D MMM YY">{emailLog.addedDate}</Moment>

                                          <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                                        </h5>
                                      </div>
                                      <div className="flex-grow-1">
                                        <h5 className="font-size-14">{localization["ActivitySubType_"+ emailLog.activityType+"_"+emailLog.activitySubType]}</h5>
                                      </div>
                                    </div>
                                    <div className="exNote">{emailLog.activityRemarks}</div>
                                  </li>
                                ))}

                                </ul>
          </OffcanvasBody>
        </Offcanvas>


                        <Offcanvas
                            isOpen={this.state.isSmsLog}
                            direction="end"
                            toggle={this.toggleSmsLogCanvas}
                            style={{width:800}}
                        >
                            <OffcanvasHeader toggle={this.toggleSmsLogCanvas}>
                               SMS Log
                            </OffcanvasHeader>
                            <OffcanvasBody>
                            <Formik
                        enableReinitialize={true}
                        initialValues={{
                          logDate: new Date(),
                          actionType: (this.state && this.state.actionType) || "",
                       
                        
                        }}
                        validationSchema={Yup.object().shape({
                          actionType: Yup.string().required(
                            "Please Select Action"
                          ),
                          smsNote: Yup.string().max(140, 'This filed contains only 140 characters only')
                        })}

                        onSubmit={this.handleLeadEmailSubmit}
                      >
                        {({ errors,  touched, values, handleChange, setFieldValue }) => (
                          <Form
                            className="needs-validation"
                          >
                          
                                  <Row className="mt20 mb20">

                                  
                                    
                                    <Col lg="12">
                                    <div role="group" className="btnCheckGroup" aria-labelledby="my-radio-group">
                                        <label>
                                          <Field type="radio" name="actionType" id="actionType1" value="SMS Sent"  onChange={handleChange}  />
                                          SMS Sent
                                        </label>
                                        <label>
                                          <Field type="radio" name="actionType" id="actionType2" value="SMS Received"  onChange={handleChange} />
                                          SMS Received
                                        </label>
                                        <label>
                                          <Field type="radio" name="actionType" id="actionType2" value="SMS Bounced"  onChange={handleChange}  />
                                          SMS Bounced
                                        </label>

                                        <ErrorMessage
                                              name="actionType"
                                              component="div"
                                              className="text-danger"
                                            />
                                        
                                      </div>

                                    </Col>

                                  </Row>
                                  <Row>
                                    <Col md="12">
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="logDate">
                                          Log Date
                                        </Label>
                                        <Flatpickr
                                              className={
                                                "form-control" +
                                                (errors.logDate && touched.logDate
                                                  ? " is-invalid"
                                                  : "")
                                              }
                                              placeholder=""
                                              value={values.logDate}
                                              onChange={(value) => setFieldValue('logDate', value[0])}
                                              options={{
                                                altInput: true,
                                                altFormat: "F j, Y",
                                                dateFormat: "Y-m-d"
                                              }}
                                            />
                                            <ErrorMessage
                                              name="logDate"
                                              component="div"
                                              className="invalid-feedback"
                                            />

                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md="12">
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="smsNote">
                                          Add Message (Optional)
                                        </Label>
                                        <textarea
                                          name="smsNote"
                                          onChange={handleChange}
                                          type="textarea"
                                         
                                          className={
                                            "form-control" +
                                            (errors.smsNote && touched.smsNote
                                              ? " is-invalid"
                                              : "")
                                          }
                                         
                                          id="smsNote"

                                        />
                                        <ErrorMessage
                                              name="smsNote"
                                              component="div"
                                              className="invalid-feedback"
                                            />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  <div className="clearfix"><button type="submit"  className="btn btn-primary w-md">Save</button></div>


                                  </Form>
                        )}
                      </Formik>
                            </OffcanvasBody>
                        </Offcanvas>
          </div>
        </CardBody>
      </Card>
            </Col>
          </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
LeadList.propTypes = {
  t: PropTypes.any,
  match: PropTypes.object,
  gridLoading:PropTypes.object,
  history: PropTypes.object,
  onAddLeadNote: PropTypes.func,
  onGetNoteLead:PropTypes.func,
  leadNotes:PropTypes.array,
  onGetFollowLead:PropTypes.func,
  leadFollows:PropTypes.array,
  onAddLeadFollowUp:PropTypes.func,
  onGetLeadEmailLog:PropTypes.func,
  onGetLeadCallLog:PropTypes.func,
  calllogs:PropTypes.array,
  emaillogs:PropTypes.array,
  onAddCallLog:PropTypes.func,
  onAddEmailLog:PropTypes.func,
  onSendWorksheetEmailLog:PropTypes.func,
  onGetAllActLead:PropTypes.func,
  activities:PropTypes.array,
  onGetAllLeads:PropTypes.func,
  allLeads:PropTypes.array
}
const mapStateToProps = ({ leads }) => (
 
  {
    gridLoading:leads.gridLoading,
    allLeads:leads.allLeads,
    leadNotes: leads.leadNotes,
    activities:leads.activities,
    leadFollows: leads.leadFollows,
    calllogs:leads.calllogs,
    emaillogs:leads.emaillogs


  })
  const mapDispatchToProps = dispatch => ({
   
    onAddLeadNote: (data) => dispatch(addLeadNoteTab(data)),
    onGetAllLeads:(agentId) => dispatch(getLeads(agentId)),
    onGetNoteLead: (leadId) => dispatch(getNoteLeadTab(leadId)),
    onGetFollowLead: (leadId) => dispatch(getFollowLeadTab(leadId)),
    onAddLeadFollowUp:(data) => dispatch(addFollowNoteTab(data)),
    onAddCallLog:(data) => dispatch(addLeadCallLog(data)),
    onGetLeadCallLog:(leadId) => dispatch(getLeadCallLog(leadId)),
    onAddEmailLog:(data) => dispatch(addLeadEmailLog(data)),
    onGetLeadEmailLog:(leadId) => dispatch(getLeadEmailLog(leadId)),
    onGetAllActLead:(leadId) => dispatch(getAllActLeadTab(leadId))
  
  });
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LeadList))