import React, { Component } from "react"
import { Link } from "react-router-dom"
import PropTypes from 'prop-types';
import {
  Button, Card, Col, Container, Input, Label, Row, TabContent, TabPane,
  Nav,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  NavItem,
  NavLink
} from "reactstrap"
import classnames from "classnames"
// Import Editor
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"


class EmailSideBar extends Component {
  constructor(props) {

    super(props)
    this.state = {
      modal: false,
      activeTab: "1",
    }

    this.togglemodal.bind(this)
    this.toggleTab = this.toggleTab.bind(this)
  }

  toggleTab(tab) {

    if (this.props.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }

  togglemodal = () => {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }))
  }

  render() {

    return (
      <React.Fragment>
        <Card className="email-leftbar">
                  <Button
                    type="button"
                    color="danger"
                    onClick={this.togglemodal}
                    block
                  >
                    Compose
                  </Button>
                  <div className="mail-list mt-4">
                    <Nav tabs className="nav-tabs-custom" vertical role="tablist">
                      <NavItem>
                      <Link
                        to="/email-inbox"
                        className="nav-link"
                      >
                      <i className="mdi mdi-email-outline me-2"></i> Inbox
                      </Link>
                      </NavItem>
                      <NavItem>
                      <Link
                        to="/sent-email"
                        className="nav-link"
                      >
                      <i className="mdi mdi-email-check-outline me-2"></i> Sent Mail
                      </Link>
                    
                      </NavItem>

                     

                    </Nav>

                  </div>
                </Card> 

        <Modal
          isOpen={this.state.modal}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal"
          tabIndex="-1"
          toggle={this.togglemodal}
        >
          <div className="modal-content">
            <ModalHeader toggle={this.togglemodal}>New Message</ModalHeader>
            <ModalBody>
              <form>
                <div className="mb-3">
                  <Input
                    type="email"
                    className="form-control"
                    placeholder="To"
                  />
                </div>

                <div className="mb-3">
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Subject"
                  />
                </div>
                <Editor
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                />
              </form>
            </ModalBody>
            <ModalFooter>
              <Button
                type="button"
                color="secondary"
                onClick={this.togglemodal}
              >
                Close
              </Button>
              <Button type="button" color="primary">
                Send <i className="fab fa-telegram-plane ms-1"></i>
              </Button>
            </ModalFooter>
          </div>
        </Modal>
      </React.Fragment>
    )
  }
}
EmailSideBar.propTypes = {
  activeTab: PropTypes.any
}
export default EmailSideBar