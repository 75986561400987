import React, { Component } from "react"
import PropTypes from "prop-types"
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import Moment from 'react-moment';
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Flatpickr from "react-flatpickr"
import { withTranslation } from "react-i18next"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import { getAllProjectLead, addLeadNoteTab, getNoteLeadTab,  addFollowNoteTab, getFollowLeadTab, 
  getLeadCallLog, addLeadCallLog, addLeadEmailLog, getLeadEmailLog, deleteProjectCampLead, SendLeadEmail } from "../../../store/actions";
  import DeleteModal from "../../../components/Common/DeleteModal";
  import JoditEditor from 'jodit-react';
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Label,
  Input,
  FormGroup,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  CardFooter,
  CardTitle,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
} from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { map } from "lodash"
import LeadChart from "../Projects/chart/lead-chart";
import ProjectFilterMenu from "./lead-filter-menu";
import RsCard from "components/Rscards/Rscard";
import RSBarChart from "components/charts/RSBarChart";
import RSPieChart from "components/charts/RSPieChart";
import ActionEdit from "components/buttons/actionEdit";
import ActionDelete from "components/buttons/actionDelete";
class ProjectLead extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lead: '',
      searchTerm: '', 
      selectAll: false,
      selectedLeads: {},
      reports: [
        { title: "# Leads", iconClass: "bx bx-copy-alt", description: "78" },
        {
          title: "Lead Acquistion cost ",
          iconClass: "bx bx-purchase-tag-alt",
          description: "$400",
        },
     
      ]
    }
    this.toggleEmailCanvas = this.toggleEmailCanvas.bind(this);
    this.toggleFollowCanvas = this.toggleFollowCanvas.bind(this);
    this.toggleCallLogCanvas = this.toggleCallLogCanvas.bind(this);
    this.toggleEmailLogCanvas = this.toggleEmailLogCanvas.bind(this);
    this.toggleSmsLogCanvas = this.toggleSmsLogCanvas.bind(this);
    this.toggleAddNoteCanvas = this.toggleAddNoteCanvas.bind(this);
    this.toggleBackdrop = this.toggleBackdrop.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.toggleSendMessageCanvas = this.toggleSendMessageCanvas.bind(this);
    this.submitLeadEmail = this.submitLeadEmail.bind(this);
    //this.updateOpenHouseData = this.updateOpenHouseData.bind(this);
    //this.deleteOpenHouseData = this.deleteOpenHouseData.bind(this);
  }
  onSearchChange(event) {
    this.setState({ searchTerm: event.target.value });
  }
  toggleBackdrop() {
    this.setState({ isBackdrop: !this.state.isBackdrop });
  }
  toggle() {
    this.setState(prevState => ({
      isRight: !prevState.isRight
    }));

  }
  toggleFollow() {
    this.setState(prevState => ({
      isFollow: !prevState.isFollow
    }));

  }
  toggleAddNote() {
    this.setState(prevState => ({
      isAddNote: !prevState.isAddNote
    }));

  }

  toggleSendMessage() {
    this.setState(prevState => ({
      isSendMessage: !prevState.isSendMessage
    }));

  }
  toggleCallLog() {
    this.setState(prevState => ({
      isCallLog: !prevState.isCallLog
    }));

  }

  toggleEmailLog() {
    this.setState(prevState => ({
      isEmailLog: !prevState.isEmailLog
    }));

  }
  toggleSmsLog() {
    this.setState(prevState => ({
      isSmsLog: !prevState.isSmsLog
    }));

  }
  toggleEmailCanvas() {
    this.setState({ lead: "", isEdit: false, isRight: !this.state.isRight });
    this.toggle();
  }
  toggleAddNoteCanvas() {
    this.setState({ lead: "", isEdit: false, isFollow: !this.state.isAddNote });
    this.toggleAddNote();
  }
  toggleSendMessageCanvas() {
    this.setState({ lead: "", isEdit: false, isSendMessage: !this.state.isSendMessage });
    this.toggleSendMessage();
  }
  toggleFollowCanvas() {
    this.setState({ lead: "", isEdit: false, isFollow: !this.state.isFollow });
    this.toggleFollow();
  }

  toggleCallLogCanvas() {
    this.setState({ lead: "", isEdit: false, isCallLog: !this.state.isCallLog });
    this.toggleCallLog();
  }

  toggleEmailLogCanvas() {
    this.setState({ lead: "", isEdit: false, isEmailLog: !this.state.isEmailLog });
    this.toggleEmailLog();
  }
  toggleSmsLogCanvas() {
    this.setState({ lead: "", isEdit: false, isSmsLog: !this.state.isSmsLog });
    this.toggleSmsLog();
  }

  componentDidMount() {
    const { onGetAllProjectLead } = this.props;
    onGetAllProjectLead(localStorage.getItem('userId'));
  }
  toggleEmailCanvas = arg => {
    const lead = arg;
    this.setState({
      lead: {
        id: lead.id,
      leadId: lead.leadId
      },
      isEdit: true,
    });
    this.toggle();
  };

  toggleFollowCanvas = arg => {
    const lead = arg;
    const { onGetFollowLead } = this.props;
    this.setState({
      lead: {
        leadId: lead.leadId,
      },
      isEditFollow: true,
    });
    onGetFollowLead(lead.leadId);
    this.toggleFollow();
  };
  toggleAddNoteCanvas = arg => {
    const lead = arg;
    const { onGetNoteLead } = this.props;
    this.setState({
      lead: {
        leadId: lead.leadId,
      },
      isEdit: true,
    });
    onGetNoteLead(lead.leadId);
    this.toggleAddNote();
  };

  toggleSendMessageCanvas = arg => {
    const lead = arg;
    const { onGetNoteLead } = this.props;
    this.setState({
      lead: {
        leadId: lead.leadId,
        
      },
      isEdit: true,
    });
    //onGetNoteLead(lead.leadId);
    this.toggleSendMessage();
  };

  toggleCallLogCanvas = arg => {
    const lead = arg;
    const { onGetLeadCallLog } = this.props;
    this.setState({
      lead: {
        leadId: lead.leadId,
      },
      isEditCall: true,
    });
    onGetLeadCallLog(lead.leadId);
    this.toggleCallLog();
  };
  toggleEmailLogCanvas = arg => {
    const lead = arg;
    const { onGetLeadEmailLog } = this.props;
    this.setState({
      lead: {
        leadId: lead.leadId,
      },
      isEditCall: true,
    });
    onGetLeadEmailLog(lead.id);
    this.toggleEmailLog();
  };
  toggleSmsLogCanvas = arg => {
    const lead = arg;
    this.setState({
      lead: {
        leadId: lead.leadId
      },


    });
    this.toggleSmsLog();
  };
  toggleDeleteLeadModal = () => {
    this.setState(prevState => ({
      deleteLeadModal: !prevState.deleteLeadModal,
    }));
  };
  onClickDeleteLead = (lead) => {
    this.setState({ lead: lead });
    this.setState({ deleteLeadModal: true });
  };

  handleDeleteCampLeqad = () => {
    const { onDeleteCampLead } = this.props;
    const { lead } = this.state;
    //console.log(project);
    if (lead.id !== undefined) {
      onDeleteCampLead(lead.id);
      this.setState({ deleteLeadModal: false });
    }
  };
  handleSelectAll = () => {
    const { selectAll } = this.state;
    const { allleads } = this.props;
    const newSelectAll = !selectAll;
    const newSelectedLeads = {};

    if (Array.isArray(allleads)) {
    
      allleads.forEach(lead => {
        if (lead.id) { 
          newSelectedLeads[lead.id] = newSelectAll;
        }
      });
    }

    this.setState({
      selectAll: newSelectAll,
      selectedLeads: newSelectedLeads
    });
  };
  handleSelectLead = (id) => {
    const { selectedLeads } = this.state;
    const newSelectedLeads = { ...selectedLeads, [id]: !selectedLeads[id] };
  
  
    const anySelected = Object.keys(newSelectedLeads).some((key) => newSelectedLeads[key]);
  
    this.setState({
      selectedLeads: newSelectedLeads,
      anySelected 
    });
  };
  submitLeadEmail(value, history){
    this.props.SendLeadEmail(value);
  }
  render() {
    const { onAddLeadNote, onAddLeadFollowUp, onAddCallLog, onAddEmailLog } = this.props;
    const { leadNotes, leadFollows, calllogs, emaillogs } = this.props;
    const allLocal = localStorage.getItem('localData')
    const localization = JSON.parse(allLocal);
    const { allleads } = this.props;
    const { selectAll, selectedLeads, anySelected  } = this.state;
    const lead = this.state.lead;
    const { isEdit, isEditFollow, isEditCall, isEditEmail, deleteLeadModal, searchTerm } = this.state;
    const showTrashButton = anySelected || (selectAll && allleads.length > 0);
    console.log(lead);
    const barchartdata = {
      series: [
        {
          name: "Clients",
          data: [3, 8, 16],
        },
        {
          name: "Marketing Spend",
          data: [6, 14, 18],
        },
      ],
      options: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
      
        grid: {
          borderColor: "#f1f1f1",
        },
        colors: ["#6CE5E8", "#41B8D5"],
        xaxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar"
          ],
        },
      },
    };
    const leadSourceData = {
      series: [20, 20, 20, 20, 20],
      options: {
        chart: {
          type: 'pie',
        },
        labels: ["Item 5", "Google", "Facebook", "Item 3",  "Item 4"],
        colors: ["#31356E", "#6CE5E8", "#41B8D5", "#2D8BBA", "#2F5F98"],
        legend: {
          show: true,
          position: "bottom",
          horizontalAlign: "center",
          verticalAlign: "middle",
          floating: false,
          fontSize: "14px",
          offsetX: 0,
          offsetY: -10,
        },
        responsive: [
          {
            breakpoint: 600,
            options: {
              chart: {
                height: 240,
              },
              legend: {
                show: false,
              },
            },
          },
        ],
      },
    };
    const filteredLeads = allleads?.filter(lead =>
      lead.firstName.toLowerCase().includes(searchTerm.toLowerCase())||
      lead.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      lead.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      lead.phone.toLowerCase().includes(searchTerm.toLowerCase()) ||
      lead?.project?.projectName.toLowerCase().includes(searchTerm.toLowerCase())
    );
    
    return (
      <React.Fragment>
         <ToastContainer autoClose={2000} />
        {this.props.loading && this.props.loading ? (
          <div className="loading">Loading&#8230;</div>
        ) : null}


        <div className="page-content project-page-content">
          <MetaTags>
            <title>Projects | Realty Space</title>
          </MetaTags>
          <DeleteModal
          show={deleteLeadModal}
          onDeleteClick={this.handleDeleteCampLeqad}
          onCloseClick={() => this.setState({ deleteLeadModal: false })}
        />
          <Container fluid className="">
            {/* Render Breadcrumbs */}

            <Row className="mb20 project-header">
              <Col lg="12">
                <Breadcrumbs
                  title={this.props.t("Projects")}
                  breadcrumbItem={this.props.t("Projects Lead")}
                />
              </Col>

              {/* <Col lg="6">

              </Col> */}
            </Row>
            <Row className="mb20" style={{marginTop:20}}> 
            {this.state.reports.map((report, key) => (
            <Col md="4" className="" key={key}>
                <RsCard title={report.title} description={report.description} iconClass={report.iconClass} />
              </Col>
            ))}
            </Row>   
            <Row className="mb20">
            <Col lg={4}>
            <RSBarChart title="" filter={false}  height="350"  series={barchartdata.series} options={barchartdata.options} />
            </Col>
            <Col lg="4">
            <RSPieChart title="Sources" filter={false} height="385" series={leadSourceData.series} options={leadSourceData.options} />
            </Col>
            <Col lg={4}>
            <div className="card">
            <div className="card-body">
                <div className="card-title mb-4 float-start">Top 5 Project</div>
                <Table
                      id=""
                      className="table table-striped table-bordered"
                      >
                        <Tbody>
                          <tr>
                            <td>Motto</td>
                            <td>Sierra</td>
                            <td> Toronto </td>
                            <td># 50 Clients</td>
                          </tr>
                          <tr>
                            <td>Mirabella Luxury Condominiums</td>
                            <td>Mirabella Development Corporation</td>
                            <td> Toronto </td>
                            <td># 18 Clients</td>
                          </tr>
                          <tr>
                            <td>Joya on the Queensway</td>
                            <td>Altree Developments</td>
                            <td> Toronto </td>
                            <td># 18 Clients</td>
                          </tr>
                          <tr>
                            <td>Kazmir Condos</td>
                            <td>Lormel Homes</td>
                            <td> Toronto </td>
                            <td># 18 Clients</td>
                          </tr>
                          <tr>
                            <td>Queen Church</td>
                            <td>Tridel and BAZIS</td>
                            <td> Toronto </td>
                            <td># 18 Clients</td>
                          </tr>
                        </Tbody>
                </Table>
                </div>
            </div>
                    </Col>
            </Row>
            <Row>
              <Col sm="12">
              <ProjectFilterMenu />
              </Col>
            </Row>
            <Row>
               
              <Card>
                <CardBody>
                  <Col lg="12">
                    <CardTitle className="mb-4">All Leads</CardTitle>
                    <div className="row mb20">
          
                    <div className="col-sm-4">
                    {showTrashButton && (
                      <Link to="#" className="btn btn-del">
                        <i className="bx bx-trash font-size-24"></i>
                      </Link>
                    )}
                      
                    </div>
          <div className="col-sm-4">
            
            <Input type="text" className="form-control"  value={searchTerm}   onChange={this.onSearchChange} id="autoSizingInput" placeholder="Search by name, email, project name" />
          </div>

         

        </div>
                    <div className="clearfix"></div>
                    <div className="table-rep-plugin">
                      <div
                        className=""
                        data-pattern="priority-columns"
                      >
                        <Table
                          id="tech-companies-1"
                          className="table table-striped table-bordered"
                        >
                          <Thead>
                            <Tr>
                              <Th className="align-middle"><div className="form-check font-size-16 align-middle">
                              <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="selectAll"
                                  checked={selectAll}
                                  onChange={this.handleSelectAll}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="customCheck1"
                                >
                                  &nbsp;
                                </label>
                              </div></Th>
                              <Th>{this.props.t("Name")}</Th>
                              <Th data-priority="3">{this.props.t("Project")}</Th>
                              <Th data-priority="1">{this.props.t("Date")}</Th>
                              <Th data-priority="3">{this.props.t("Phone")}</Th>
                              <Th data-priority="3">{this.props.t("Email")}</Th>
                              <Th data-priority="3">{this.props.t("Client Login")}</Th>
                              <Th data-priority="3"></Th>

                            </Tr>
                          </Thead>
                          <Tbody>
                            {map(filteredLeads, (lead, leadkey) => (
                              <Tr key={leadkey}>
                                <Td><div className="form-check font-size-16">
                                <input
                                      type="checkbox"
                                      className="form-check-input"
                                      checked={selectedLeads[lead.id] || false}
                                      onChange={() => this.handleSelectLead(lead.id)}
                                    />
                                  <label
                                    className="form-check-label"

                                  >
                                    &nbsp;
                                  </label>
                                </div></Td>
                                <Td className="table-data">
                                  <div className="row">
                                    <div className="col-sm-6" >{lead.firstName + " " + lead.lastName}</div>
                                    <div className="col-sm-1"><Link className="link-open" to={"/lead/lead-detail/" + lead.leadId + "/" + lead.leadGuid }><i className="grid-icon bx bx-link-external"></i></Link></div>
                                    <div className="col-sm-1"><Link to="#" onClick={() => this.toggleEmailCanvas(lead)}><i className="grid-icon bx bx-mail-send"></i></Link></div>
                                    <div className="col-sm-1"><Link to="#" onClick={() => this.toggleAddNoteCanvas(lead)}><i className="grid-icon  bx bx-notepad"></i></Link></div>
                                    <div className="col-sm-1"><Link to="#" onClick={() => this.toggleSendMessageCanvas(lead)}><i className="grid-icon bx bxs-message-alt-dots"></i></Link></div>
                                    <div className="col-sm-1">
                                      <UncontrolledDropdown direction="up">
                                        <DropdownToggle tag="a" to="#" className="card-drop" data-bs-toggle="dropdown" aria-expanded="false">
                                          <i className="grid-icon bx bx-dots-vertical-rounded"></i>
                                        </DropdownToggle>
                                        <DropdownMenu className="dropdown-menu-end">

                                          <DropdownItem href="#" onClick={() => this.toggleCallLogCanvas(lead)}><i className="bx bx-phone-outgoing"></i> Add call log</DropdownItem>
                                          <DropdownItem href="#" onClick={() => this.toggleEmailLogCanvas(lead)}><i className=" bx bx-mail-send"></i> Add email log</DropdownItem>

                                          <DropdownItem s href="#" onClick={() => this.toggleFollowCanvas(lead)}><i className="bx bx-user-voice"></i> Set Next Follow-up</DropdownItem>
                                        </DropdownMenu>
                                      </UncontrolledDropdown>

                                    </div>
                                  </div>

                                </Td>
                                <Td>{lead.project ? lead.project.projectName : ''}</Td>
                                <Td>{lead.lastContact	? <Moment format="D MMM YY">{lead.lastContact}</Moment> : '' }</Td>
                                <Td>{lead.phone}</Td>
                                

                                <Td>{lead.email}</Td>
                                <Td></Td>

                                <Td>
                                 <ActionEdit />{" "} <ActionDelete />

                                </Td>

                              </Tr>
                            ))}

                          </Tbody>
                        </Table>
                      </div>
                    </div>

                  </Col>
                </CardBody>
              </Card>
            </Row>
            <Offcanvas
                            isOpen={this.state.isRight}
                            direction="end"
                            toggle={this.toggleEmailCanvas}
                            style={{width:600}}
                        >
                            <OffcanvasHeader toggle={this.toggleEmailCanvas}>
                                Send Email
                            </OffcanvasHeader>
                            <OffcanvasBody>
                            <Formik
              enableReinitialize={true}
              initialValues={{
                emailLeadId:lead.leadId,
                emailLeadTemplateId:0,
                emailLeadSubject: '',
                emailLeadFullContent: '',
                agentId:parseInt(localStorage.getItem('userId')),
                isActive:true,
                emailLeadSendStatus:'pending',
                emailLeadCss:'',
                emailLeadTitle:'',
                emailLeadContent:'',


              }}
              validationSchema={Yup.object().shape({
                emailLeadSubject: Yup.string().required("This is Required")
              })}

              onSubmit={this.submitLeadEmail}
            >
              {({ errors, touched, values, handleChange, setFieldValue }) => (
                <Form
                  className="needs-validation"
                >

                  <Row>
                    <Col md="12">
                   
                      <FormGroup className="mb-3">
                        <Label htmlFor="emailLeadSubject">
                          {this.props.t("Subject *")}
                        </Label>
                        <Field
                          name="emailLeadSubject"
                          type="text"
                          onChange={handleChange}
                          className={
                            "form-control" +
                            (errors.emailLeadSubject && touched.emailLeadSubject
                              ? " is-invalid"
                              : "")
                          }
                          id="emailLeadSubject"
                        />
                        <ErrorMessage
                          name="emailLeadSubject"
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                              
                    </Col>

                    <Col md="12">
                    <JoditEditor
                          value={values.body} 
                          onBlur={(newContent) => {
                            setFieldValue('emailLeadFullContent', newContent);
                          }}
                          config={{
                            readonly: false,
                            placeholder: "Start typing here...",
                            height: 400,
                          }}
                        />
                    </Col>
                  </Row>
                  <div className="clearfix"><button type="submit" className="btn btn-primary w-md">{this.props.t("Send")}</button></div>


                </Form>
              )}
            </Formik>   
                            </OffcanvasBody>
                        </Offcanvas>

                        <Offcanvas
          isOpen={this.state.isAddNote}
          direction="end"
          toggle={this.toggleAddNoteCanvas}
        >
          <OffcanvasHeader toggle={this.toggleAddNoteCanvas}>
            Add Notes
          </OffcanvasHeader>
          <OffcanvasBody>
            <Formik
              enableReinitialize={true}
              initialValues={{

                refId: lead.leadId,
                addNote: (this.state && this.state.addNote) || "",
              


              }}
              validationSchema={Yup.object().shape({
                addNote: Yup.string().required("This is Required")
              })}

              onSubmit={values => {

                if (isEdit) {
                  console.log('hello'); 
                  onAddLeadNote(values)
                    //onAddLeadNote(updateData);
                } else {

                }
                //console.log()
                //this.toggleAddNote();
            }}
            >
              {({ errors, touched, values, handleChange, setFieldValue }) => (
                <Form
                  className="needs-validation"
                >

                  <Row>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="addNote">
                          {this.props.t("Add Note")}
                        </Label>
                        <textarea
                          name="addNote"
                          onChange={handleChange}
                          type="textarea"
                          className={
                            "form-control" +
                            (errors.addNote && touched.addNote
                              ? " is-invalid"
                              : "")
                          }
                          id="addNote"
                        />
                        <ErrorMessage
                          name="addNote"
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <div className="clearfix"><button type="submit" className="btn btn-primary w-md">{this.props.t("Save")}</button></div>


                </Form>
              )}
            </Formik>
            <div className="mb-5 h4 card-title mt-20">Notes History</div>
                                <ul className="verti-timeline list-unstyled">

                                  {map(leadNotes, (noteLog, notekey) => (

                                    <li className="event-list eventlist" key={"_note_" + notekey}>
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle font-size-18" />
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-shrink-0 me-3">
                                          <h5 className="font-size-14">
                                            <Moment format="D MMM YY">{noteLog.addedDate}</Moment>


                                          </h5>
                                        </div>
                                        <div className="flex-grow-1">
                                          <h5 className="font-size-14"></h5>
                                        </div>
                                      </div>
                                      <div className="exNote">{noteLog.activityRemarks}</div>
                                    </li>
                                  ))}

                                </ul>
          </OffcanvasBody>
                        </Offcanvas>
                        <Offcanvas
          isOpen={this.state.isSendMessage}
          direction="end"
          toggle={this.toggleSendMessageCanvas}
        >
          <OffcanvasHeader toggle={this.toggleSendMessageCanvas}>
            Send Message
          </OffcanvasHeader>
          <OffcanvasBody>
            <Formik
              enableReinitialize={true}
              initialValues={{

                refId: lead.leadId,
                addNote: (this.state && this.state.addNote) || "",
              


              }}
              validationSchema={Yup.object().shape({
                addNote: Yup.string().required("This is Required")
              })}

              onSubmit=""
            >
              {({ errors, touched, values, handleChange, setFieldValue }) => (
                <Form
                  className="needs-validation"
                >

                  <Row>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="addNote">
                          {this.props.t("Message")}
                        </Label>
                        <textarea
                          name="addNote"
                          onChange={handleChange}
                          type="textarea"
                          className={
                            "form-control" +
                            (errors.addNote && touched.addNote
                              ? " is-invalid"
                              : "")
                          }
                          id="addNote"
                        />
                        <ErrorMessage
                          name="addNote"
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <div className="clearfix"><button type="submit" className="btn btn-primary w-md">{this.props.t("Send")}</button></div>


                </Form>
              )}
            </Formik>
            <div className="mb-5 h4 card-title mt-20">Message History</div>
                                <ul className="verti-timeline list-unstyled">

                                  {map(leadNotes, (noteLog, notekey) => (

                                    <li className="event-list eventlist" key={"_note_" + notekey}>
                                      {/* <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle font-size-18" />
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-shrink-0 me-3">
                                          <h5 className="font-size-14">
                                            <Moment format="D MMM YY">{noteLog.addedDate}</Moment>


                                          </h5>
                                        </div>
                                        <div className="flex-grow-1">
                                          <h5 className="font-size-14"></h5>
                                        </div>
                                      </div>
                                      <div className="exNote">{noteLog.activityRemarks}</div> */}
                                    </li>
                                  ))}

                                </ul>
          </OffcanvasBody>
                        </Offcanvas>
                        <Offcanvas
          isOpen={this.state.isCallLog}
          direction="end"
          toggle={this.toggleCallLogCanvas}
          style={{ width: 800 }}
        >
          <OffcanvasHeader toggle={this.toggleCallLogCanvas}>
            Call Log
          </OffcanvasHeader>
          <OffcanvasBody>
            <Formik
              enableReinitialize={true}
              initialValues={{
                actionType: '',
                addedDate: new Date(),
                refId: lead.id


              }}
              validationSchema={Yup.object().shape({
                actionType: Yup.string().required(
                  "Please Select Action"
                )
              })}

              onSubmit={values => {

                if (isEditCall) {
                   
                  onAddCallLog(values)
                    //onAddLeadNote(updateData);
                } else {

                }
                this.toggleCallLog();
            }}
            >
              {({ errors, touched, values, handleChange, setFieldValue }) => (
                <Form
                  className="needs-validation"
                >

                  <Row className="mt20 mb20">



                    <Col lg="12">
                      <div role="group" className="btnCheckGroup" aria-labelledby="my-radio-group">
                        <label>
                          <Field type="radio" name="actionType" id="actionType1" value="No Answer" onChange={handleChange} />
                          No Answer
                        </label>
                        <label>
                          <Field type="radio" name="actionType" id="actionType2" value="Left Voicemail" onChange={handleChange} />
                          Left Voicemail
                        </label>
                        <label>
                          <Field type="radio" name="actionType" id="actionType2" value="Bad Number" onChange={handleChange} />
                          Bad Number
                        </label>

                      </div>
                      <ErrorMessage
                          name="actionType"
                          component="div"
                          className="invalid-feedback"
                        />  

                    </Col>

                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="addedDate">
                          Log Date
                        </Label>
                        <Flatpickr
                          className={
                            "form-control" +
                            (errors.addedDate && touched.addedDate
                              ? " is-invalid"
                              : "")
                          }
                          placeholder=""
                          value={values.addedDate}
                          onChange={(value) => setFieldValue('addedDate', value[0])}
                          options={{
                            altInput: true,
                            altFormat: "F j, Y",
                            dateFormat: "Y-m-d"
                          }}
                        />
                        <ErrorMessage
                          name="addedDate"
                          component="div"
                          className="invalid-feedback"
                        />

                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="callNote">
                          Add Message (Optional)
                        </Label>
                        <textarea
                          name="callNote"
                          onChange={handleChange}
                          type="textarea"

                          className="form-control"

                          id="callNote"

                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <div className="clearfix"><button type="submit" className="btn btn-primary w-md">Save</button></div>


                </Form>
              )}
            </Formik>
            <div className="mb-5 h4 card-title mt-20">Call Log History</div>
                                <ul className="verti-timeline list-unstyled">

                                  {map(calllogs, (callLog, callkey) => (

                                    <li className="event-list eventlist" key={"_call_" + callkey}>
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle font-size-18" />
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-shrink-0 me-3">
                                          <h5 className="font-size-14">
                                            <Moment format="D MMM YY">{callLog.addedDate}</Moment>

                                            <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                                          </h5>
                                        </div>
                                        <div className="flex-grow-1">
                                          <h5 className="font-size-14">{localization["ActivitySubType_"+ callLog.activityType+"_"+callLog.activitySubType]}</h5>
                                        </div>
                                      </div>
                                      <div className="exNote">{callLog.activityRemarks}</div>
                                    </li>
                                  ))}

                                </ul>               
          </OffcanvasBody>
                        </Offcanvas>

        <Offcanvas
          isOpen={this.state.isFollow}
          direction="end"
          toggle={this.toggleFollowCanvas}

        >
          <OffcanvasHeader toggle={this.toggleFollowCanvas}>
            Follow Us
          </OffcanvasHeader>
          <OffcanvasBody>
            <Formik
              enableReinitialize={true}
              initialValues={{
                refId: lead.id,
                addNote: (this.state && this.state.addNote) || "",
                nextFollowupDate: new Date(),


              }}
              validationSchema={Yup.object().shape({
                addNote: Yup.string().required("This is Required")
              })}

              onSubmit={values => {

                if (isEditFollow) {
                 
                  onAddLeadFollowUp(values)
                  
                } else {

                }
                this.toggleFollow();
            }}
            >
              {({ errors, touched, values, handleChange, setFieldValue }) => (
                <Form
                  className="needs-validation"
                >

                  <Row>
                    <Col md="12">
                      <FormGroup className="mb-3">

                        <textarea
                          name="addNote"
                          onChange={handleChange}
                          type="textarea"
                          className={
                            "form-control" +
                            (errors.addNote && touched.addNote
                              ? " is-invalid"
                              : "")
                          }
                          id="addNote"
                        />
                        <ErrorMessage
                          name="addNote"
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>

                    <Col md="12">
                      <FormGroup className="mb15">

                        <Label htmlFor="logDate">
                          Date
                        </Label>
                        <Flatpickr
                          className={
                            "form-control" +
                            (errors.nextFollowupDate && touched.nextFollowupDate
                              ? " is-invalid"
                              : "")
                          }
                          placeholder=""
                          value={values.nextFollowupDate}
                          onChange={(value) => setFieldValue('nextFollowupDate', value[0])}
                          options={{
                            altInput: true,
                            altFormat: "F j, Y",
                            dateFormat: "Y-m-d"
                          }}
                        />
                        <ErrorMessage
                          name="logDate"
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup>

                    </Col>
                  </Row>

                  <div className="clearfix"><button type="submit" className="btn btn-primary w-md">Save</button></div>


                </Form>
              )}
            </Formik>
            <div className="mb-5 h4 card-title mt-20">Follow-up History</div>
                <ul className="verti-timeline list-unstyled">

                    {map(leadFollows, (follLog, follLogkey) => (

                    <li className="event-list eventlist" key={"_note_" + follLogkey}>
                        <div className="event-timeline-dot">
                        <i className="bx bx-right-arrow-circle font-size-18" />
                        </div>
                        <div className="d-flex">
                        <div className="flex-shrink-0 me-3">
                            <h5 className="font-size-14">
                            <Moment format="D MMM YY">{follLog.activityRefStartDate}</Moment>


                            </h5>
                        </div>
                        <div className="flex-grow-1">
                            <h5 className="font-size-14"></h5>
                        </div>
                        </div>
                        <div className="exNote">{follLog.activityRemarks}</div>
                    </li>
                    ))}

</ul>                          
            
          </OffcanvasBody>
        </Offcanvas>


        <Offcanvas
          isOpen={this.state.isEmailLog}
          direction="end"
          toggle={this.toggleEmailLogCanvas}
          style={{ width: 800 }}
        >
          <OffcanvasHeader toggle={this.toggleEmailLogCanvas}>
            Email Log
          </OffcanvasHeader>
          <OffcanvasBody>
            <Formik
              enableReinitialize={true}
              initialValues={{
                actionType:'',
                addedDate: new Date(),
                refId: lead.id  

              }}
              validationSchema={Yup.object().shape({
                actionType: Yup.string().required(
                  "Please Select Action"
                )
              })}

              onSubmit={values => {

                if (isEditCall) {
                   
                  onAddEmailLog(values)
                    //onAddLeadNote(updateData);
                } else {

                }
                this.toggleEmailLog();
            }}
            >
              {({ errors, touched, values, handleChange, setFieldValue }) => (
                <Form
                  className="needs-validation"
                >

                  <Row className="mt20 mb20">



                    <Col lg="12">
                      <div role="group" className="btnCheckGroup" aria-labelledby="my-radio-group">
                        <label>
                          <Field type="radio" name="actionType" id="actionType1" value="Email Sent" onChange={handleChange} />
                          Email Sent
                        </label>
                        <label>
                          <Field type="radio" name="actionType" id="actionType2" value="Email Received" onChange={handleChange} />
                          Email Received
                        </label>
                        <label>
                          <Field type="radio" name="actionType" id="actionType2" value="Email Bounced" onChange={handleChange} />
                          Email Bounced
                        </label>

                      </div>
                      <ErrorMessage
                          name="actionType"
                          component="div"
                          className="invalid-feedback"
                        />
                    </Col>

                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="addedDate">
                          Log Date
                        </Label>
                        <Flatpickr
                          className={
                            "form-control" +
                            (errors.addedDate && touched.addedDate
                              ? " is-invalid"
                              : "")
                          }
                          placeholder=""
                          value={values.addedDate}
                          onChange={(value) => setFieldValue('addedDate', value[0])}
                          options={{
                            altInput: true,
                            altFormat: "F j, Y",
                            dateFormat: "Y-m-d"
                          }}
                        />
                        <ErrorMessage
                          name="addedDate"
                          component="div"
                          className="invalid-feedback"
                        />

                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="remarks">
                          Add Message (Optional)
                        </Label>
                        <textarea
                          name="remarks"
                          onChange={handleChange}
                          type="textarea"

                          className="form-control"

                          id="remarks"

                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <div className="clearfix"><button type="submit" className="btn btn-primary w-md">Save</button></div>


                </Form>
              )}
            </Formik>
            <div className="mb-5 h4 card-title mt-20">Email Log History</div>
                                <ul className="verti-timeline list-unstyled">

                                {map(emaillogs, (emailLog, emailkey) => (

                                  <li className="event-list eventlist" key={"_email_" + emailkey}>
                                    <div className="event-timeline-dot">
                                      <i className="bx bx-right-arrow-circle font-size-18" />
                                    </div>
                                    <div className="d-flex">
                                      <div className="flex-shrink-0 me-3">
                                        <h5 className="font-size-14">
                                          <Moment format="D MMM YY">{emailLog.addedDate}</Moment>

                                          <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                                        </h5>
                                      </div>
                                      <div className="flex-grow-1">
                                        <h5 className="font-size-14">{localization["ActivitySubType_"+ emailLog.activityType+"_"+emailLog.activitySubType]}</h5>
                                      </div>
                                    </div>
                                    <div className="exNote">{emailLog.activityRemarks}</div>
                                  </li>
                                ))}

                                </ul>
          </OffcanvasBody>
        </Offcanvas>
                 

          </Container>
        </div>
      </React.Fragment>
    )
  }
}

ProjectLead.propTypes = {

  className: PropTypes.any,
  t: PropTypes.any,
  onGetAllProjectLead: PropTypes.func,
  allleads: PropTypes.array,
  loading: PropTypes.object,
  onAddLeadNote: PropTypes.func,
  onGetNoteLead:PropTypes.func,
  leadNotes:PropTypes.array,
  onGetFollowLead:PropTypes.func,
  leadFollows:PropTypes.array,
  onAddLeadFollowUp:PropTypes.func,
  onGetLeadEmailLog:PropTypes.func,
  onGetLeadCallLog:PropTypes.func,
  calllogs:PropTypes.array,
  emaillogs:PropTypes.array,
  onAddCallLog:PropTypes.func,
  onAddEmailLog:PropTypes.func,
  onDeleteCampLead: PropTypes.func,
  SendLeadEmail:PropTypes.func
}

const mapStateToProps = ({ Project, leads }) => ({
  allleads: Project.allleads,
  loading: Project.loading,
  leadNotes: leads.leadNotes,
  leadFollows: leads.leadFollows,
  calllogs:leads.calllogs,
  emaillogs:leads.emaillogs
})

const mapDispatchToProps = dispatch => ({
  onGetAllProjectLead: (agentId) => dispatch(getAllProjectLead(agentId)),
  onAddLeadNote: (data) => dispatch(addLeadNoteTab(data)),
  onGetNoteLead: (leadId) => dispatch(getNoteLeadTab(leadId)),
  onGetFollowLead: (leadId) => dispatch(getFollowLeadTab(leadId)),
  onAddLeadFollowUp:(data) => dispatch(addFollowNoteTab(data)),
  onAddCallLog:(data) => dispatch(addLeadCallLog(data)),
  onGetLeadCallLog:(leadId) => dispatch(getLeadCallLog(leadId)),
  onAddEmailLog:(data) => dispatch(addLeadEmailLog(data)),
  onGetLeadEmailLog:(leadId) => dispatch(getLeadEmailLog(leadId)),
  onDeleteCampLead: (id) => dispatch(deleteProjectCampLead(id)),
  SendLeadEmail: (data) => dispatch(SendLeadEmail(data)),

})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(ProjectLead)))