import React, { Component } from "react";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { isEmpty, map } from "lodash";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { withTranslation } from "react-i18next";
import "react-rangeslider/lib/index.css";
import notes from "../../assets/images/rsicons/notes.png";
import follow from "../../assets/images/rsicons/follow-up.png";
import phonelog from "../../assets/images/rsicons/phone-log.png";
import emaillog from "../../assets/images/rsicons/email-log.png";
import { Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import CallLog from "./tab-comp/CallLog";
import EmaillLog from "./tab-comp/EmaillLog";
import SmsLog from "./tab-comp/SmsLog";
import AppointmentLog from "./tab-comp/AppointmentLog";
import LeadNote from "./tab-comp/LeadNote";
import FollowLead from "./tab-comp/FollowLead";
import LeadListing from "./tab-comp/Lead-listing";

import EditLead from "./forms/lead-edit";
import { Container,Row,Col, Card, CardBody, Table, Collapse, FormGroup, Label, Button, ButtonGroup, Nav, NavItem, NavLink,
  TabContent, TabPane, InputGroup, Offcanvas, OffcanvasHeader, OffcanvasBody} from "reactstrap";
import classnames from "classnames";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import Moment from "react-moment";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getLeadDetail, getAllActLeadTab,
  updateLead,
  convertLeadIntoClient,
  convertLeadIntoContact,
} from "../../store/actions";
import RsLink from "components/buttons/rsLink";
import LeadDetailMenu from "./menu/lead-detail-menu";
import EmailLeadInbox from "pages/Email/email-inbox-lead";

class leadDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      isRight: false,
      isRight2: false,
      isRight3: false,
      isBackdrop: false,
      col9: false,
      col15: false,
      col10: false,
      col11: false,
      col12: true,
      selectedGroup: null,
      activeTab: "1",
      verticalActiveTab: "1",
      volume: 0,
      leadData: [],
      buyersQuestions: [],
      sources: [],
      leadStatus: [],
      leadTypes: [],
      groupDrops: [],
      maritalStat: [],
      prefMediums: [],
      likelihoods: [],
      titles: [],
      total: 0,
      numbers: {
        comp1: 0,
        comp2: 0,
        comp3: 0,
        comp4: 0,
        comp5: 0,
      },
      leadId: "",
      Id: "",
      ccTab:"1"
    };
    this.toggleRightCanvas = this.toggleRightCanvas.bind(this);
    this.toggleRightCanvas2 = this.toggleRightCanvas2.bind(this);
    this.toggleRightCanvas3 = this.toggleRightCanvas3.bind(this);
    this.toggleBackdrop = this.toggleBackdrop.bind(this);
    this.t_col9 = this.t_col9.bind(this);
    this.t_col10 = this.t_col10.bind(this);
    this.t_col11 = this.t_col11.bind(this);
    this.t_col12 = this.t_col12.bind(this);
    this.t_col15 = this.t_col15.bind(this);
    this.handleSelectGroup = this.handleSelectGroup.bind(this);
  }

  toggleRightCanvas() {
    this.setState({ isRight: !this.state.isRight });
  }
  toggleRightCanvas2() {
    this.setState({ isRight2: !this.state.isRight2 });
  }

  toggleRightCanvas3() {
    this.setState({ isRight3: !this.state.isRight3 });
  }

  toggleBackdrop() {
    this.setState({ isBackdrop: !this.state.isBackdrop });
  }
  handleOnChange = value => {
    this.setState({
      volume: value,
    });
  };
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  cctoggle(tab) {
    if (this.state.ccTab !== tab) {
      this.setState({
        ccTab: tab,
      });
    }
  }
  handleSelectGroup = selectedGroup => {
    this.setState({ selectedGroup });
  };
  t_col9() {
    this.setState({
      col9: !this.state.col9,
      col10: false,
      col11: false,
      col12: false,
      col15: false,
    });
  }

  t_col10() {
    this.setState({
      col10: !this.state.col10,
      col9: false,
      col11: false,
      col12: false,
      col15: false,
    });
  }

  t_col11() {
    this.setState({
      col11: !this.state.col11,
      col9: false,
      col10: false,
      col12: false,
      col15: false,
    });
  }
  t_col12() {
    this.setState({
      col12: !this.state.col12,
      col11: false,
      col9: false,
      col10: false,
      col15: false,
    });
  }
  t_col15() {
    this.setState({
      col15: !this.state.col15,
      col11: false,
      col9: false,
      col10: false,
      col12: false,
    });
  }
  componentDidMount() {
    const {
      match: { params },
      onGetLeadDetail,
      onGetAllActLead,
      
    } = this.props;
    if (params && params.id && params.leadId) {
      onGetLeadDetail(params.leadId);
      onGetAllActLead(params.id);
      this.setState({ leadId: params.leadId });
      this.setState({ Id: params.id });
    }
   
  }


  render() {
    const {leads, onConvertLeadToClient,onCovertLeadIntoContact} = this.props;
    const { activities } = this.props;
    const allLocal = localStorage.getItem("localData");
    const localization = JSON.parse(allLocal);
    const userModule = localStorage.getItem("userModule");
    const leadQualificationPoints = leads?.leadQualificationPoints;
    const formattedPoints = leadQualificationPoints?.toFixed(2);
    const seriesData = [formattedPoints];
    return (
      
      <React.Fragment>
        <ToastContainer autoClose={2000} />
        <div className="page-content project-page-content">
          <MetaTags>
            <title>Lead Detail | Realty Space</title>
          </MetaTags>
          <Container fluid className="ps-0">
            <Row>
              <Col sm="3" className="pe-0">
                <Card className="overflow-hidden">
                 
                  <div className="pt-3">
                    <Row>
                      <Col sm="12">
                        <div className="pt-2">
                          <div className="float-end" style={{marginRight:10}}>
                                {userModule.indexOf("BrokerAgent.RealtorLeads.Edit") >
                                -1 ? (
                                  <RsLink onClick={this.toggleRightCanvas} className="btn-purpul w-md" iconClass="">Edit</RsLink>
                                ) : (
                                  ""
                                )}
                          </div>
                        </div>
                      </Col>
                      <Col sm="12">
                        <div className="pt-2">
                          <div className="text-center">
                         
                              
                          
                          </div>
                        </div>
                      </Col>
                      
                    </Row>
                  </div>
                </Card>
                <Card>
                  <CardBody>
                    <h4 className="card-title mb-4">
                    
                    </h4>
                    <div className="clearfix"></div>
                    {!isEmpty(leads) && (
                      <div className="table-responsive">
                        <Table className="table mb-0">
                          <tbody>
                            <tr>
                              <th scope="row"> Name</th>
                              <td> {leads.title+ " "+ leads.firstName +" "+ leads.lastName} </td>
                            </tr>
                            <tr>
                              <th scope="row"> Address</th>
                              <td></td>
                            </tr>
                           
                            

                            <tr>
                              <th scope="row"> Phone</th>
                              <td> {leads.phone} </td>
                            </tr>
                            <tr>
                              <th scope="row"> Mobile</th>
                              <td> {leads.mobile} </td>
                            </tr>
                            <tr>
                              <th scope="row"> Email</th>
                              <td> {leads.email} </td>
                            </tr>
                            <tr>
                              <th scope="row">Source</th>
                              <td>
                                {" "}
                                {leads.leadSourceId
                                  ? localization[
                                      "LeadSource_" + leads.leadSourceId
                                    ]
                                  : "N/A"}
                              </td>
                            </tr>

                            <tr>
                              <th scope="row">Status</th>
                              <td>
                                {leads.leadStatusId
                                  ? localization[
                                      "LeadStatus_" + leads.leadStatusId
                                    ]
                                  : "N/A"}{" "}
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">Type</th>
                              <td>
                                {" "}
                                {leads.leadTypeId
                                  ? localization["LeadType_" + leads.leadTypeId]
                                  : "N/A"}{" "}
                              </td>
                            </tr>


                            <tr>
                              <th scope="row">Stage</th>
                              <td>
                                {" "}
                                {leads.stageId
                                  ? localization[
                                      "LeadStage_" + leads.stageId
                                    ]
                                  : "N/A"}
                              </td>
                            </tr>

                            <tr>
                              <th scope="row">Group</th>
                              <td>
                                {" "}
                                {leads.stageId
                                  ? localization[
                                      "LeadStage_" + leads.stageId
                                    ]
                                  : "N/A"}
                              </td>
                            </tr>

                            <tr>
                              <th scope="row">Deal Value</th>
                              <td>
                                {leads.approxDealValue}
                               </td> 
                            </tr>
                            
                            <tr>
                              <th scope="row">Referred by</th>
                              <td>
                                {leads.referedBy}
                               </td> 
                            </tr>
                            <tr>
                              <th scope="row">Referral Code</th>
                              <td>
                                {leads.referralCode}
                               </td> 
                            </tr>
        
                          </tbody>
                        </Table>
                      </div>
                    )}
                  </CardBody>
                </Card>
                <Offcanvas
                  isOpen={this.state.isRight}
                  direction="end"
                  toggle={this.toggleRightCanvas}
                  style={{width:800}}
                >
                  <OffcanvasHeader toggle={this.toggleRightCanvas}>
                    Update Lead
                  </OffcanvasHeader>
                  <OffcanvasBody>
                      <EditLead
                       closed={this.toggleRightCanvas} 
                       match={this.props.match}
                      />
                  </OffcanvasBody>
                </Offcanvas>

                <Offcanvas
                  isOpen={this.state.isRight2}
                  direction="end"
                  toggle={this.toggleRightCanvas2}
                >
                  <OffcanvasHeader toggle={this.toggleRightCanvas2}>
                    <h2 className="text-center">Review</h2>
                  </OffcanvasHeader>
                  <OffcanvasBody>
                    <h2 className="offcanvas-heading">Profile Info</h2>
                    <p className="offcanvas-info">
                      {" "}
                      To make any changes to profile Info, please Edit the Lead
                    </p>
                    <br />
                    <p className="form-label">
                      Name : {leads.firstName + " " + leads.lastName}{" "}
                    </p>
                    <p className="form-label">Phone : {leads.phone}</p>
                    <p className="form-label">Email : {leads.email} </p>
                    <p className="form-label">Address : </p>
                    <hr />
                    <h2 className="offcanvas-heading">
                      Type of Client (<small>Check all that apply</small>)
                    </h2>
                    {/* <h5  >Check all that apply</h5> */}
                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        leadId: leads.id,
                        linkingId: 0,
                        contactId: 0,
                        clientId: 0,
                        buyerType: 1,
                        sellerType: 1,
                      }}
                      validationSchema={Yup.object().shape({
                        loginEmail: Yup.string().required("email is required"),
                        confirmEmail: Yup.string().oneOf(
                          [Yup.ref("loginEmail"), null],
                          "Email must match"
                        ),
                      })}
                      onSubmit={values => {
                        const updateData = {
                          leadId: values.leadId,
                          linkingId: values.linkingId,
                          contactId: values.contactId,
                          clientId: values.clientId,
                        };
                        onConvertLeadToClient(updateData);
                      }}
                    >
                      {({
                        errors,
                        touched,
                        values,
                        handleChange,
                        setFieldValue,
                      }) => (
                        <Form className="needs-validation">
                          {/* <FormGroup className="mb-3"> */}
                          <div className="d-flex justify-content-between mb-1">
                            <div>
                              <Field
                                name="isBuyer"
                                className="form-check-input me-2 offcanvas-chk-box"
                                type="checkbox"
                                id="isBuyer"
                                onClick={e => handleChange(e)}
                              />
                              <Label
                                htmlFor="isBuyer"
                                className="form-check-label"
                              >
                                {this.props.t("Buyer")}
                              </Label>
                            </div>
                            <div className="d-flex">
                              <ButtonGroup>
                                <Button
                                  color="primary"
                                  onClick={() => setFieldValue("sellerType", 1)}
                                  className={
                                    values.sellerType === 1
                                      ? "t-button-active"
                                      : ""
                                  }
                                >
                                  Rent
                                </Button>
                                <Button
                                  color="primary"
                                  onClick={() => setFieldValue("sellerType", 2)}
                                  className={
                                    values.sellerType === 2
                                      ? "t-button-active"
                                      : ""
                                  }
                                >
                                  Purchase
                                </Button>
                              </ButtonGroup>
                            </div>
                            {/* </FormGroup> */}
                          </div>
                          <div className="d-flex justify-content-between mb-1">
                            <div>
                              <Field
                                name="isSeller"
                                className="form-check-input me-2 offcanvas-chk-box"
                                type="checkbox"
                                id="isSeller"
                                onClick={e => handleChange(e)}
                                // checked='true'
                              />
                              <Label
                                htmlFor="isSeller"
                                className="form-check-label"
                              >
                                {this.props.t("Seller")}
                              </Label>
                            </div>
                            <div className="d-flex">
                              {/* <h5>Rnet </h5>
                            <Switch 
                            name="buyType"
                            uncheckedIcon={false}
                            checkedIcon={false}
                            height={18}
                            width={50}
                             value={values.buyType}
                             onChange={(value) => setFieldValue('buyType', !value)}
                            />
                            <h5>Rnet </h5> */}
                              <ButtonGroup>
                                <Button
                                  color="primary"
                                  onClick={() => setFieldValue("buyerType", 1)}
                                  className={
                                    values.buyerType === 1
                                      ? "t-button-active"
                                      : ""
                                  }
                                >
                                  Sell
                                </Button>
                                <Button
                                  color="primary"
                                  onClick={() => setFieldValue("buyerType", 2)}
                                  className={
                                    values.buyerType === 2
                                      ? "t-button-active"
                                      : ""
                                  }
                                >
                                  Lease
                                </Button>
                              </ButtonGroup>
                              {/* <Label htmlFor="buyType" className="form-check-label">
                              {this.props.t("purchase or Renat")}
                            </Label>  */}
                            </div>
                            {/* </FormGroup> */}
                          </div>
                          <FormGroup className="mb-3">
                            <Field
                              name="isInvestor"
                              className="form-check-input me-2 offcanvas-chk-box"
                              type="checkbox"
                              id="isInvestor"
                              onClick={e => handleChange(e)}
                              // checked='true'
                            />
                            <Label
                              htmlFor="isInvestor"
                              className="form-check-label"
                            >
                              {this.props.t("Investor")}
                            </Label>
                          </FormGroup>
                          <hr />
                          <h2 className="offcanvas-heading">Agreement Info</h2>
                          <FormGroup className="mb-3">
                            <Label htmlFor="openhouseDate">
                              {this.props.t("Sign Date")}
                            </Label>
                            <InputGroup>
                              <Flatpickr
                                className={
                                  "form-control" +
                                  (errors.openhouseDate && touched.openhouseDate
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder=""
                                value={values.openhouseDate}
                                onChange={value =>
                                  setFieldValue("openhouseDate", value[0])
                                }
                                options={{
                                  altInput: true,
                                  altFormat: "F j, Y",
                                  dateFormat: "Y-m-d",
                                }}
                              />
                              <div className="input-group-append">
                                <span className="input-group-text">
                                  <i className="mdi mdi-clock-outline" />
                                </span>
                              </div>
                            </InputGroup>
                          </FormGroup>

                          <hr />

                          <h2 className="offcanvas-heading">
                            Client Login Info
                          </h2>
                          <FormGroup className="mb-3">
                            <Label htmlFor="loginEmail">
                              {this.props.t("Login email")}
                            </Label>
                            <Field
                              name="loginEmail"
                              type="text"
                              place
                              // placeholder="Login email"
                              //value={values.referedBy}
                              onChange={handleChange}
                              className={
                                "form-control" +
                                (errors.loginEmail && touched.loginEmail
                                  ? " is-invalid"
                                  : "")
                              }
                              id="loginEmail"
                            />
                            <ErrorMessage
                              name="loginEmail"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>

                          <FormGroup className="mb-3">
                            <Label htmlFor="confirmEmail">
                              {this.props.t("Confirm email")}
                            </Label>
                            <Field
                              name="confirmEmail"
                              type="text"
                              //value={values.referedBy}
                              onChange={handleChange}
                              className={
                                "form-control" +
                                (errors.confirmEmail && touched.confirmEmail
                                  ? " is-invalid"
                                  : "")
                              }
                              id="confirmEmail"
                            />
                            <ErrorMessage
                              name="confirmEmail"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                          <p className="offcanvas-info">
                            The login email cannot be changed at a later date
                          </p>
                          <p className="offcanvas-info">
                            A system generated password is sent to the client
                          </p>

                          <div>
                            <button
                              type="submit"
                              className="btn btn-primary w-md"
                            >
                              Convert{" "}
                            </button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </OffcanvasBody>
                </Offcanvas>
                <Offcanvas
                  isOpen={this.state.isRight3}
                  direction="end"
                  toggle={this.toggleRightCanvas3}
                >
                  <OffcanvasHeader toggle={this.toggleRightCanvas3}>
                    <h2 className="text-center">Convert to Contact</h2>
                  </OffcanvasHeader>
                  <OffcanvasBody>
                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        leadId: leads.id,
                        linkingId: 0,
                        contactId: 0,
                        type: leads.name,
                      }}
                      validationSchema={Yup.object().shape({
                        // loginEmail: Yup.string().required('email is required'),
                        // confirmEmail: Yup.string()
                        // .oneOf([Yup.ref('loginEmail'), null], 'Email must match')
                      })}
                      onSubmit={values => {
                        const updateData = {
                          leadId: values.leadId,
                          linkingId: values.linkingId,
                          contactId: values.contactId,
                        };
                        onCovertLeadIntoContact(updateData);
                      }}
                    >
                      {({
                        errors,
                        touched,
                        values,
                        handleChange,
                        setFieldValue,
                      }) => (
                        <Form className="needs-validation">
                          {/* <FormGroup className="mb-3"> */}

                          <FormGroup className="mb-3">
                            <Label htmlFor="name">{this.props.t("Name")}</Label>
                            {/* <p className="form-label" >Name : {leads.firstName+" "+leads.lastName} </p> */}
                            <Field
                              name="name"
                              type="text"
                              value={leads.firstName + " " + leads.lastName}
                              // placeholder="Login email"
                              //value={values.referedBy}

                              className="form-control"
                              id="name"
                              disabled
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label
                              htmlFor="type"
                              className="form-label label-text"
                            >
                              Type
                            </Label>
                            <Field
                              as="select"
                              className={
                                "form-control" +
                                (errors.type && touched.type
                                  ? " is-invalid"
                                  : "")
                              }
                              name="type"
                              id="type"
                            >
                              <option value="">Select</option>
                              <option value="Silver">Silver </option>
                              <option value="Gold">Gold</option>
                              <option value="VIP">VIP</option>
                            </Field>
                            <ErrorMessage
                              name="type"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label
                              htmlFor="frequency"
                              className="form-label label-text"
                            >
                              Contact frequency
                            </Label>
                            <Field
                              as="select"
                              className={
                                "form-control" +
                                (errors.frequency && touched.frequency
                                  ? " is-invalid"
                                  : "")
                              }
                              name="frequency"
                              id="frequency"
                            >
                              <option value="">Select</option>
                              <option value="Monthly">Monthly </option>
                              <option value="Quatterly">Quatterly</option>
                              <option value="Bi-Annual">Bi-Annual</option>
                              <option value="Annual">Annual</option>
                            </Field>
                            <ErrorMessage
                              name="frequency"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                          <FormGroup className="mb-3">
                            <Field
                              name="isVendor"
                              className="form-check-input me-2 offcanvas-chk-box"
                              type="checkbox"
                              id="isVendor"
                              onClick={e => handleChange(e)}
                              // checked='true'
                            />
                            <Label
                              htmlFor="isVendor"
                              className="form-check-label"
                            >
                              {this.props.t("Is a Vendor")}
                            </Label>
                          </FormGroup>

                          <div>
                            <button
                              type="submit"
                              className="btn btn-primary w-md"
                            >
                              Convert{" "}
                            </button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </OffcanvasBody>
                </Offcanvas>
              </Col>
              <Col sm="9" className="ps-0 pe-0">
              <Row className="project-header">
                <Col sm='8'>
            <Breadcrumbs
              title={this.props.t("Lead")}
              breadcrumbItem={this.props.t("Lead Activity")}
            />
           
            </Col>
            <Col sm='4'>
                <h2>{leads.firstName + " " + leads.lastName }</h2>
            </Col>
            </Row>
             
                <Row>
                  <Col xl="12">
                  <Nav
                                    tabs
                                    className="lead-tab mt-10 mb-10"
                                  >
                                    <NavItem>
                                      <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                          active: this.state.activeTab === "1",
                                        })}
                                        onClick={() => {
                                          this.toggle("1");
                                        }}
                                      >
                                        <span className="">
                                          {this.props.t("All ")}
                                        </span>
                                      </NavLink>
                                    </NavItem>
                                    <NavItem>
                                      <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                          active: this.state.activeTab === "12",
                                        })}
                                        onClick={() => {
                                          this.toggle("12");
                                        }}
                                      >
                                        <span className="">
                                          {this.props.t("Inbox")}
                                        </span>
                                      </NavLink>
                                    </NavItem>
                                    <NavItem>
                                      <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                          active: this.state.activeTab === "2",
                                        })}
                                        onClick={() => {
                                          this.toggle("2");
                                        }}
                                      >
                                        <span className="">
                                          {this.props.t("Appointments")}
                                        </span>
                                      </NavLink>
                                    </NavItem>
                                    <NavItem>
                                      <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                          active: this.state.activeTab === "3",
                                        })}
                                        onClick={() => {
                                          this.toggle("3");
                                        }}
                                      >
                                        <span className="">
                                          {this.props.t("Notes")}
                                        </span>
                                      </NavLink>
                                    </NavItem>

                                    <NavItem>
                                      <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                          active: this.state.activeTab === "4",
                                        })}
                                        onClick={() => {
                                          this.toggle("4");
                                        }}
                                      >
                                        <span className="">
                                          {this.props.t("Follow-ups")}
                                        </span>
                                      </NavLink>
                                    </NavItem>

                                    <NavItem>
                                      <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                          active: this.state.activeTab === "5",
                                        })}
                                        onClick={() => {
                                          this.toggle("5");
                                        }}
                                      >
                                        <span className="k">
                                          {this.props.t("Logs")}
                                        </span>
                                      </NavLink>
                                    </NavItem>

                                  

                                    
                                    <NavItem>
                                      <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                          active: this.state.activeTab === "10",
                                        })}
                                        onClick={() => {
                                          this.toggle("10");
                                        }}
                                      >
                                        <span className="">
                                          {this.props.t("Marketing")}
                                        </span>
                                      </NavLink>
                                    </NavItem>
                                    <NavItem>
                                      <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                          active: this.state.activeTab === "11",
                                        })}
                                        onClick={() => {
                                          this.toggle("11");
                                        }}
                                      >
                                        <span className="">
                                          {this.props.t("Collections")}
                                        </span>
                                      </NavLink>
                                    </NavItem>
                                    <NavItem>
                                      <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                          active: this.state.activeTab === "9",
                                        })}
                                        onClick={() => {
                                          this.toggle("9");
                                        }}
                                      >
                                        <span className="">
                                          {this.props.t("Projects")}
                                        </span>
                                      </NavLink>
                                    </NavItem>
                    </Nav>
                    <Card>
                    
                    <TabContent
                      activeTab={this.state.activeTab}
                      className="p-3 text-muted"
                    >
                      <TabPane tabId="1">
                        <div className="mb-5 h4 card-title">
                          All Activities
                        </div>
                        <ul className="verti-timeline list-unstyled">
                          {map(
                            activities,
                            (allactivity, allactkey) => (
                              <li
                                className="event-list eventlist"
                                key={"_allact_" + allactkey}
                              >
                                <div className="event-timeline-dot">
                                  {(() => {
                                    if (
                                      allactivity.activitySubType ==
                                        8 ||
                                      allactivity.activitySubType ==
                                        9 ||
                                      allactivity.activitySubType ==
                                        7
                                    ) {
                                      return (
                                        <>
                                          <img src={emaillog} />
                                        </>
                                      );
                                    } else if (
                                      allactivity.activitySubType ==
                                        3 ||
                                      allactivity.activitySubType ==
                                        4 ||
                                      allactivity.activitySubType ==
                                        5
                                    ) {
                                      return (
                                        <>
                                          <img src={phonelog} />
                                        </>
                                      );
                                    } else if (
                                      allactivity.activitySubType ==
                                      10
                                    ) {
                                      return (
                                        <>
                                          <img src={follow} />
                                        </>
                                      );
                                    } else {
                                      return (
                                        <>
                                          <img src={notes} />
                                        </>
                                      );
                                    }
                                  })()}
                                </div>
                                <div className="d-flex">
                                  <div className="flex-shrink-0 me-3">
                                    <h5 className="font-size-14">
                                      <Moment format="D MMM YY">
                                        {allactivity.addedDate}
                                      </Moment>
                                    </h5>
                                  </div>
                                  <div className="flex-grow-1">
                                    <h5 className="font-size-14">
                                      {
                                        localization[
                                          "ActivitySubType_" +
                                            allactivity.activityType +
                                            "_" +
                                            allactivity.activitySubType
                                        ]
                                      }
                                    </h5>
                                  </div>
                                </div>
                                <div className="exNote">
                                  {allactivity.activityRemarks}
                                </div>
                              </li>
                            )
                          )}
                        </ul>
                      </TabPane>
                      <TabPane tabId="12">
                          <EmailLeadInbox />
                      </TabPane>
                      <TabPane tabId="2">
                        <AppointmentLog  />
                      </TabPane>
                      <TabPane tabId="3">
                        <LeadNote />
                      </TabPane>

                      <TabPane tabId="4">
                        <FollowLead />
                      </TabPane>
                      <TabPane tabId="5">
                      <Nav
                                    tabs
                                    className="lead-tab mt-10 mb-10 text-center"
                                  >
                                    

                                    <NavItem>
                                      <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                          active: this.state.ccTab === "1",
                                        })}
                                        onClick={() => {
                                          this.cctoggle("1");
                                        }}
                                      >
                                        <span className="k">
                                          {this.props.t("Call Log")}
                                        </span>
                                      </NavLink>
                                    </NavItem>
                                    <NavItem>
                                      <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                          active: this.state.ccTab === "2",
                                        })}
                                        onClick={() => {
                                          this.cctoggle("2");
                                        }}
                                      >
                                        <span className="k">
                                          {this.props.t("Email Log")}
                                        </span>
                                      </NavLink>
                                    </NavItem>      
                                    <NavItem>
                                      <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                          active: this.state.ccTab === "3",
                                        })}
                                        onClick={() => {
                                          this.cctoggle("3");
                                        }}
                                      >
                                        <span className="k">
                                          {this.props.t("SMS Log")}
                                        </span>
                                      </NavLink>
                                    </NavItem>      

                                  
                    </Nav>
                    <TabContent
                      activeTab={this.state.ccTab}
                      className="p-3 text-muted"
                    >
                       <TabPane tabId="1">
                       <CallLog />
                      </TabPane>
                      <TabPane tabId="2">
                      <EmaillLog />
                      </TabPane>
                      <TabPane tabId="3">
                      <SmsLog />
                      </TabPane>
                    </TabContent>
                       
                       
                       
                      </TabPane>
                      <TabPane tabId="6">
                       
                      </TabPane>

                      <TabPane tabId="7">
                        
                      </TabPane>

                      <TabPane tabId="8">
                        <LeadListing />
                      </TabPane>

                      <TabPane tabId="9">
                        <div className="mb-5 h4 card-title">
                          Projects
                        </div>
                        <div className="table-rep-plugin">
                          <div
                            className="table-responsive mb-0"
                            data-pattern="priority-columns"
                          >
                            <Table
                              id="tech-companies-1"
                              className="table table-striped table-bordered"
                            >
                              <Thead>
                                <Tr>
                                  <Th>{this.props.t("Date")}</Th>
                                  <Th>
                                    {this.props.t("Project")}
                                  </Th>
                                  <Th>
                                    {this.props.t("Source")}
                                  </Th>
                                </Tr>
                              </Thead>
                              <Tbody>
                                <Tr>
                                  <Th>Nov 26, 2021</Th>
                                  <Th>
                                    <span className="co-name">
                                      {" "}
                                      Liberty Market Tower
                                    </span>
                                  </Th>
                                  <Th></Th>
                                </Tr>
                              </Tbody>
                            </Table>
                          </div>
                        </div>
                      </TabPane>

                      <TabPane tabId="10">
                        <div className="mb-5 h4 card-title">
                          Marketing
                        </div>
                        <div className="table-rep-plugin">
                          <div
                            className="table-responsive mb-0"
                            data-pattern="priority-columns"
                          >
                            <Table
                              id="tech-companies-1"
                              className="table table-striped table-bordered"
                            >
                              <Thead>
                                <Tr>
                                  <Th>{this.props.t("Type")}</Th>
                                  <Th>{this.props.t("Name")}</Th>
                                  <Th>{this.props.t("Date")}</Th>
                                </Tr>
                              </Thead>
                              <Tbody>
                                <Tr>
                                  <Th>Newsletter</Th>
                                  <Th>
                                    <span className="co-name">
                                      {" "}
                                      Toronto News
                                    </span>
                                  </Th>
                                  <Th>5th August, 2023</Th>
                                </Tr>
                              </Tbody>
                            </Table>
                          </div>
                        </div>
                      </TabPane>

                      <TabPane tabId="11">
                        <div className="mb-5 h4 card-title">
                          Collection
                        </div>
                        <div className="table-responsive">
                          <table className="table table-bordered border-primary align-middle table-nowrap mb-0">
                            <thead className="">
                              <tr>
                                <th className="align-middle">
                                  <div className="form-check font-size-16 align-middle">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      id="customCheck1"
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="customCheck1"
                                    >
                                      &nbsp;
                                    </label>
                                  </div>
                                </th>
                                <th className="align-middle">
                                  Name
                                </th>
                                <th className="align-middle">
                                  Description
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <div className="form-check font-size-16 align-middle">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      id="customCheck1"
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="customCheck1"
                                    >
                                      &nbsp;
                                    </label>
                                  </div>
                                </td>
                                <td>Toronto-600k</td>
                                <td>
                                  Looking for condos with maximum
                                  price of $600,000 1 BR
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </TabPane>
                    </TabContent>
                     
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
leadDetail.propTypes = {
  t: PropTypes.any,
  match: PropTypes.object,
  onGetLeadDetail: PropTypes.object,
  leads: PropTypes.object,
  onGetAllActLead: PropTypes.func,
  activities: PropTypes.array,
  onConvertLeadToClient: PropTypes.func,
  onCovertLeadIntoContact: PropTypes.func,
};
const mapStateToProps = ({ leads }) =>
  //console.log(contact),
  ({
    leads: leads.leads,
    activities: leads.activities,
   
  });
const mapDispatchToProps = dispatch => ({
  onGetLeadDetail: leadId => dispatch(getLeadDetail(leadId)),
  onGetAllActLead: leadId => dispatch(getAllActLeadTab(leadId)),
  onConvertLeadToClient: data => dispatch(convertLeadIntoClient(data)),
  onCovertLeadIntoContact: data => dispatch(convertLeadIntoContact(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(leadDetail));
